import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import CustomAlert from "../../../../Material/CustomAlert";

const TabsOPD = (props) => {
//   const [baseUrlOPD, setBaseUrlOPD] = useState(
//     "https://dbd.admedika.co.id/trusted/"
//   );
  const [urlSektorOPD, setUrlSektorOPD] = useState("");
  const [alert, setAlert] = useState({
    type: "success",
    message: "",
    show: false,
  });
  const [flagSubmit, setFlagSubmit] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}usecase/getIKUOpd?id_usecase=${props.idUC}`)
      .then(({ data: { data } }) => {
        // setBaseUrlOPD(
        //   data.token_OPD === null
        //     ? "https://dbd.admedika.co.id/trusted/"
        //     : data.token_admedika
        // );
        setUrlSektorOPD(data.iku_opd === null ? "" : data.iku_opd);
      })
      .catch(() => {});
  }, [props.idUC, flagSubmit]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append(`id_usecase`, props.idUC);
    // formData.append(`token_OPD`, baseUrlOPD);
    formData.append(`iku_opd`, urlSektorOPD);
    axios
      .post(`${API_BASE_URL}usecase/setIKUOpd`, formData)
      .then(({ data: { data } }) => {
        setFlagSubmit((prev) => !prev);
        setAlert((prev) => ({
          ...prev,
          type: "success",
          message: data.message,
          show: true,
        }));
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            show: false,
          }));
        }, 5000);
      })
      .catch(() => {
        setFlagSubmit((prev) => !prev);
        setAlert((prev) => ({
          ...prev,
          type: "danger",
          message: "token gagal disimpan",
          show: true,
        }));
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            show: false,
          }));
        }, 5000);
      });
  };

  return (
    <>
      {alert.show && <CustomAlert type="success" message={alert.message} />}
      <div className="row">
        <div className="col-12">
          {/* <div className="row mb-3">
            <div className="col-6">
              <label className="textMenu2 labelUC">
                Base Url<span className="redText">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Masukan Url Sampul"
                value={baseUrlOPD}
                onChange={(e) => setBaseUrlOPD(e.target.value)}
              />
            </div>
          </div> */}
          <div className="row mb-3">
            <div className="col-6">
              <label className="textMenu2 labelUC">
                Token Sektor Prioritas OPD<span className="redText">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Masukan Url Produk"
                value={urlSektorOPD}
                onChange={(e) => setUrlSektorOPD(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <button
              type="button"
              className="btn btn-dark btnMenu ml-auto mx-3 mt-3"
              onClick={handleSubmit}
              data-testid="btn-submit"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabsOPD;

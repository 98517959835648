import React from "react";
import "./index.css";
import {NumberWithDot} from '../../../../Material/FormatNumber'

const ProgressBar = ({ bgcolor, completed }) => {
  return (
    <div className="containerStyle" style={{ textAlign: "right" }}>
      <div
        className="fillerStyles"
        style={{ backgroundColor: bgcolor, maxWidth: `${completed}%`, width:"100%" }}
      >
        <span
          className="labelStyles"
          style={{
            color: "#ffffff",
            fontStyle: "bold",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >{`${NumberWithDot( completed, 2)}`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;

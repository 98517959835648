import React, {useState, useEffect} from 'react'
import TabsBlogs from './Tabs/Blogs'
import TabsForums from './Tabs/Forums'
import TabsNews from './Tabs/News'
import TabsYoutube from './Tabs/Youtube'
import '../../../../assets/css/Analytics/Analytics.css'
import {API_GET_ANALYTICS} from '../../../../constants/apiConstants';
import {reactLocalStorage} from 'reactjs-localstorage';
import axios from 'axios';

const Index = (props) => {
  const [state, setState] = useState({
    linkNews: '',
    linkYoutube: '',
    linkForums: '',
    linkBlogs: '',
  });
  const baseColor = reactLocalStorage.getObject('base_color');
  useEffect(() => {    
    axios.get(`${API_GET_ANALYTICS}`)
    .then(({data: {data}, status}) => {
      if(status === 200){
        setState((prevState) => ({
          ...prevState,
          linkNews: data.bigbox_news,
          linkYoutube: data.bigbox_youtube,
          linkForums: data.bigbox_forums,
          linkBlogs: data.bigbox_blogs,
        }))
        }
      })
    .catch(() => {})
  }, [props])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p className="h3 mb-3">Digital Media Analytic</p>
          <span
            className="line-2nd mb-4"
            style={{ borderBottomColor: baseColor.color2 }}
          ></span>
        </div>
      </div>

      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <a
            className="nav-link active"
            id="nav-news-tab"
            data-toggle="tab"
            href="#nav-news"
            role="tab"
            aria-controls="nav-news"
            aria-selected="false"
          >
            News
          </a>
          <a
            className="nav-link"
            id="nav-youtube-tab"
            data-toggle="tab"
            href="#nav-youtube"
            role="tab"
            aria-controls="nav-youtube"
            aria-selected="false"
          >
            Youtube
          </a>
          <a
            className="nav-link"
            id="nav-forums-tab"
            data-toggle="tab"
            href="#nav-forums"
            role="tab"
            aria-controls="nav-forums"
            aria-selected="false"
          >
            Forums
          </a>
          <a
            className="nav-link"
            id="nav-blogs-tab"
            data-toggle="tab"
            href="#nav-blogs"
            role="tab"
            aria-controls="nav-blogs"
            aria-selected="true"
          >
            Blogs
          </a>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade position-relative min-vh-50 bg-white show active"
          id="nav-news"
          role="tabpanel"
          aria-labelledby="nav-news-tab"
        >
          <TabsNews link={state.linkNews}></TabsNews>
        </div>
        <div
          className="tab-pane fade position-relative min-vh-50 bg-white "
          id="nav-youtube"
          role="tabpanel"
          aria-labelledby="nav-youtube-tab"
        >
          <TabsYoutube link={state.linkYoutube}></TabsYoutube>
        </div>
        <div
          className="tab-pane fade position-relative min-vh-50 bg-white "
          id="nav-forums"
          role="tabpanel"
          aria-labelledby="nav-forums-tab"
        >
          <TabsForums link={state.linkForums}></TabsForums>
        </div>
        <div
          className="tab-pane fade position-relative min-vh-50 bg-white "
          id="nav-blogs"
          role="tabpanel"
          aria-labelledby="nav-blogs-tab"
        >
          <TabsBlogs link={state.linkBlogs}></TabsBlogs>
        </div>
      </div>
    </>
  );
}

export default Index

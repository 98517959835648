import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import YearPicker from "../../../../../utils/YearPicker";
import { YearRangePicker } from "react-year-range-picker";

import esStyle from "../index.module.css";
import LuasArealChart from "./LuasArealChart";
import LuasArealMap from "./LuasArealMap";
import TrenLuasAreal from "./TrenLuasAreal";

const Perkebunan = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [statusDate, setStatusDate] = useState(false);
  const [yearWData, setYearWData] = useState([]);
  const [initialIndikator, setInitialIndikator] = useState([]);
  const [indikator, setIndikator] = useState();
  const [indikatorTitle, setIndikatorTitle] = useState();
  const [initialJenis, setInitialJenis] = useState([]);
  const [jenis, setJenis] = useState();
  const [startYear, setStartYear] = useState();
  const [endYear, setEndYear] = useState();
  const [maxYear, setMaxYear] = useState();
  const [minYear, setMinYear] = useState();
  const [flagPodaIndikator, setFlagPodaIndikator] = useState("");
  const [dataIndikatorCheck, setDataIndikatorCheck] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}poda/sda/perkebunan/indikator`)
      .then(({ data: { data, flag_poda }, status }) => {
        setInitialIndikator(data);
        setFlagPodaIndikator(flag_poda);

        if (data.length > 0) {
          setDataIndikatorCheck(true);
          setIndikator(data[0].value);
          setIndikatorTitle(data[0].nama);
        } else {
          setDataIndikatorCheck(false);
        }
      })
      .catch(() => {
        console.log("error");
      });
  }, []);

  useEffect(() => {
    // if(flagPodaIndikator !== "show") return
    if (!dataIndikatorCheck) return;
    axios
      .get(`${API_BASE_URL}poda/sda/perkebunan/jenis?indikator=${indikator}`)
      .then(({ data: { data }, status }) => {
        setInitialJenis(data.jenis);
        setJenis(data.selected_jenis);
      })
      .catch(() => {});
  }, [dataIndikatorCheck, indikator]);

  useEffect(() => {
    // if(flagPodaIndikator !== "show") return
    if (!dataIndikatorCheck) return;
    axios
      .get(`${API_BASE_URL}poda/sda/perkebunan/tahun?indikator=${indikator}`)
      .then(({ data: { data, flag_poda }, status }) => {
        setStartDate(new Date(`${data.selected_years}`));
        setYearWData(data.years);
      })
      .catch(() => {})
      .finally(() => {});
  }, [dataIndikatorCheck, indikator]);

  useEffect(() => {
    // if(flagPodaIndikator !== "show") return
    if (!dataIndikatorCheck) return;
    axios
      .get(`${API_BASE_URL}poda/sda/perkebunan/periode?indikator=${indikator}`)
      .then(({ data: { data, flag_poda }, status }) => {
        setStartYear(data.startYear);
        setEndYear(data.endYear);
        setMaxYear(data.maxYear);
        setMinYear(data.minYear);
      })
      .catch(() => {})
      .finally(() => {});
  }, [dataIndikatorCheck, indikator]);
  return (
    <>
      <div
        className={`card border-light box-content mt-4 ${
          flagPodaIndikator !== "show"
            ? dataIndikatorCheck
              ? ""
              : "d-none"
            : ""
        } `}
      >
        <div className="card-body">
          <div className="row">
            <div className="col">
              <span className={esStyle.textEs}>Perkebunan</span>
            </div>
            <div className="col-sm">
              <div className="row">
                <div className="col-sm mb-2">
                  <div className="form-group row mb-0">
                    <label className="col col-form-label">Indikator</label>
                    <div className="col-7">
                      <select
                        className={`form-control form-control-md ${esStyle.ellipsis}`}
                        data-testid="select-indikator"
                        onChange={(event) => {
                          setIndikator(event.target.value);

                          const idx = event.target.selectedIndex;
                          const option =
                            event.target.querySelectorAll("option")[idx];
                          const nama = option.getAttribute("data-nama");
                          setIndikatorTitle(nama);
                        }}
                      >
                        {initialIndikator.map((v, i) => {
                          return (
                            <option key={i} value={v.value} data-nama={v.nama}>
                              {v.nama}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-group row mb-0">
                    <label className="col col-form-label">Jenis</label>
                    <div className="col-7 col-md-8">
                      <select
                        className={`form-control form-control-md ${esStyle.ellipsis}`}
                        data-testid="select-jenis"
                        onChange={(event) => {
                          setJenis(event.target.value);
                        }}
                      >
                        {initialJenis.map((v, i) => {
                          return (
                            <option key={i} value={v}>
                              {v}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body pt-1">
          <div className={`row `}>
            <div className="col-sm">
              <p className={esStyle.textEs4}>
                {indikatorTitle} Menurut Kab/Kota
              </p>
            </div>
            <div className="col-sm">
              <div className={`${esStyle.marginBottom20} float-right`}>
                <div className={esStyle.divEs}>
                  <YearPicker
                    yearWithData={yearWData}
                    onChange={(date) => setStartDate(date)}
                    selected={startDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`row   `}>
            <div className="col-md-6">
              <LuasArealMap
                indikatorTitle={indikatorTitle}
                indikator={indikator}
                jenis={jenis}
                startDate={new Date(startDate).getFullYear()}
                statusDate={statusDate}
                dataIndikatorCheck={dataIndikatorCheck}
              />
            </div>
            <div className="col-md-6">
              <LuasArealChart
                indikatorTitle={indikatorTitle}
                indikator={indikator}
                jenis={jenis}
                startDate={new Date(startDate).getFullYear()}
                statusDate={statusDate}
                dataIndikatorCheck={dataIndikatorCheck}
              />
            </div>
          </div>
          <div className={`row  `}>
            <div className="col-sm">
              <p className={`pt-3 ${esStyle.textEs4}`}>
                Trend {indikatorTitle} Perkebunan
              </p>
            </div>
            <div className="col-sm">
              <div className="form-group row mb-0 float-right">
                <div className="col">
                  <div className={`${esStyle.marginBottom20} float-right`}>
                    <label className="mr-3">Periode</label>
                    <YearRangePicker
                      minYear={minYear}
                      maxYear={maxYear}
                      onSelect={(startYear, endYear) => {
                        setStartYear(startYear);
                        setEndYear(endYear);
                      }}
                      startYear={startYear}
                      endYear={endYear}
                      style={{ borderRadius: "10px" }}
                      spacer="-"
                      startText="Start Year"
                      endText="End Year"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`row  `}>
            <div className="col-md-12">
              <TrenLuasAreal
                indikatorTitle={indikatorTitle}
                indikator={indikator}
                jenis={jenis}
                startYear={startYear}
                endYear={endYear}
                dataIndikatorCheck={dataIndikatorCheck}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Perkebunan;

import React from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { NumberWithDot } from '../../../../Material/FormatNumber';

const HargaStatus = (props) => {
  const {data} = props;
  const status = data.details;
  const baseColor = reactLocalStorage.getObject('base_color');
  
  let fa = "fa fa-pause";
  let color = "#3782ab";
  let info = "Harga Tetap";
  
  if(status.status === "naik"){
    fa = "fa fa-caret-up";
    color = "#e83e1f";
    info = `${status.persentase}% - Rp${NumberWithDot(status.nilai)}`;
  } 
  if(status.status === "turun"){
    fa = "fa fa-caret-down";
    color = "#31934c";
    info = `${status.persentase}% - Rp${NumberWithDot(status.nilai)}`;
  }

  return (
    <div className='text-right'>
        <h5 className='m-0 font-weight-bold'>Rp{NumberWithDot(data.harga)}</h5>
        <p className='small mb-4'>per Kg</p>
        <div className='badge w-100 p-2' style={{backgroundColor:color, color:"white", fontSize:"x-small"}}>
            <i className={fa}></i> &nbsp;
            {info}
        </div>
    </div>
  )
}

export default HargaStatus
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BarChart from '../../../Charts/BarChart';
import style from './Index.module.css';
import {
  API_GET_DEMOGRAFI_PROFESSION,
} from '../../../../constants/apiConstants';
import { NumberWithDot, NumberFormat } from '../../../Material/FormatNumber';
import YearPicker from '../../../../utils/YearPicker';

const BarProf = (props) => {
  const [stateYearBarProf, setStateYearBarProf] = useState(new Date());
  const [statusYearBarProf, setStatusYearBarProf] = useState(false);
  const [yearWData, setYearWData] = useState([]);
  const [stateBarProf, setStateBarProf] = useState({
    data: undefined,
    categories: [],
    sourceData: '',
    status: '',
    year: null,
    options: {},
  });

  useEffect(() => {
    axios
      .get(`${API_GET_DEMOGRAFI_PROFESSION}/year`)
      .then(({ data: {data}, status }) => {
        if (status === 200) {
          setStateYearBarProf(new Date(data.selected_years));
          setStatusYearBarProf(true);
          setYearWData(data.years);
        }
      })
      .catch((err) => {});
  }, []);

  /**
   * Demografi Prof (Pekerjaan) on Change
   */
  useEffect(() => {
    if (statusYearBarProf === true) {
      axios
        .get(
          `${API_GET_DEMOGRAFI_PROFESSION}?year=${stateYearBarProf.getFullYear()}`
        )
        .then(({ data, status }) => {
          if (status === 200) {
            setStateBarProf((prevState) => ({
              ...prevState,
              data: data.chart_data,
              categories: data.chart_categories,
              sourceData: data.sumber_data,
              status: data.status,
              year: data.year,
              options: {
                colors:[props.color],
                xAxis: {
                  title: {
                    text: 'Pekerjaan',
                  },
                },
                yAxis: {
                  title: {
                    text: 'Jumlah Jiwa',
                  },
                  labels: {
                    formatter: function () {
                      return NumberFormat(this.value, 2);
                    },
                  },
                },
                tooltip: {
                  formatter: function () {
                    return `<div style="border-color:${this.color}">
                      <div>${stateYearBarProf.getFullYear()}</div>
                      <table>
                        <tr>
                          <td> <span>${this.x}: </span> </td>
                          <td> <span><strong>${NumberWithDot(
                            this.y, 2
                          )} Jiwa</strong></span></td>
                        </tr>
                      </table>
                    </div>`;
                  },
                  shared: true,
                  useHTML: true,
                },
              },
            }));
          }
        })
        .catch((err) => {});
    }
  }, [stateYearBarProf, statusYearBarProf]);

  return (
    <>
      <p className="h5">Pekerjaan</p>
      <div className="row">
        <div className="col-12 text-right">
          {stateBarProf.status === 'Blank' ? (
            <></>
          ) : (
            <YearPicker 
              className="mb-3"
              yearWithData={yearWData}
              selected={stateYearBarProf}
              onChange={(date) => setStateYearBarProf(date)}
            />
          )}
        </div>
      </div>
      <div className="chart bar-top-profession">
        <BarChart
          key='demo-bar-top-profession'
          data={stateBarProf.data}
          categories={stateBarProf.categories}
          options={stateBarProf.options}
        />
      </div>
      <p
        className={`${style.sourceData} ${
          stateBarProf.status === 'Empty' || stateBarProf.status === 'Blank'
            ? 'text-center'
            : 'text-left'
        }`}
      >
        {stateBarProf.status === 'Empty'
          ? 'Tidak ada data untuk ditampilkan'
          : stateBarProf.status === 'Blank'
          ? 'Data tidak tersedia'
          : `Sumber Data: ${stateBarProf.sourceData}`}
      </p>
    </>
  );
};

export default BarProf;

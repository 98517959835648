import React, { useState, useEffect } from 'react';
import IframeResizer from 'iframe-resizer-react'
import sektorstyle from '../Sektor.module.css';
import Loading from '../../../../utils/Loading';
import BlankImg from '../../../../assets/img/sektorempty.svg';
import ElementData from './ElementData';

const ElemenData = (props) => {
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setLoading(true);
  }, [props])


  const resizedCallback = (iframe) => {
    if (iframe.type === 'resetPage') {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }

  return (
    <>
      {
        (props.data?.state_elemen?.toUpperCase() === 'BIGVIEW' || props.data?.state_elemen?.toUpperCase() === 'DB') &&
        <div className="min-vh-50 position-relative bg-white">
          <ElementData data={props.data} />
        </div>
      }

      {
        (props.data?.state_elemen?.toUpperCase() === 'EMPTY') &&
        <div className={`${sektorstyle.blankContainer}`}>
          <img className={`${sektorstyle.blankImg}`} src={BlankImg} alt="" />
          <p className={`${sektorstyle.blankP}`}>Anda belum memiliki elemen data</p>
        </div>
      }

      {
        (props.data?.state_elemen?.toUpperCase() === 'BIGBOX') &&
        // <div className="min-vh-50 position-relative bg-white">
        //   <ElementData data={props.data} />
        // </div>
        <div className="min-vh-50 position-relative bg-white">
          { loading === true && <Loading /> }
          <IframeResizer
            title={`elemen-${props.data.id_sektor}`}
            src={`${props.data.link_elemen}`}
            frameBorder="0"
            resizedCallback={resizedCallback}
            style={{ 
              width: '1px', 
              minWidth: '100%',
              visibility: (loading === true) ? 'hidden' : 'visible',
              maxHeight: (loading === true) ? '0px' : 'unset',
            }}
          />
        </div>
      }
    </>
  );
};

export default ElemenData;

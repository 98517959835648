import React from 'react';
import * as ReactDOM from 'react-dom';

// export const NumberWithDot = (x, floating = 0, locale = "id-ID") => {  
//   console.log('x == ', x);
//   if (typeof x === 'undefined') { x = 0; }
//   x = parseFloat(x).toFixed(floating);

//   return x.toLocaleString("id-ID").toString();
  
// }
export const NumberWithDot = (x, floating = 5, locale = "id-ID") => {  
  if (typeof x === 'undefined' || x === null) { x = 0; }
  x = parseFloat(x).toFixed(floating);
  if (floating === 0) {
    return x.toLocaleString(locale);
  }
  x = x.match(/(\d+)\.(\d+)/);
  let xInt = Number(x[1]).toLocaleString(locale);
  let xFloat = String(Number(Number('0.'+x[2]).toFixed(floating))).replace('0.','');
  x = xInt + (xFloat !== '0' ? (','+xFloat) : ''); 
  return x; 
}
export const NumberFormat = (labelValue, floating = 0) => {  
  return Math.abs(Number(labelValue)) >= 1.0e12
    ? NumberWithDot((Math.abs(Number(labelValue)) / 1.0e12), floating) + " T" // Nine Zeroes for Millions
    : Math.abs(Number(labelValue)) >= 1.0e9
    ? NumberWithDot((Math.abs(Number(labelValue)) / 1.0e9), floating) + " M" // Six Zeroes for Millions
    : Math.abs(Number(labelValue)) >= 1.0e6
    ? NumberWithDot((Math.abs(Number(labelValue)) / 1.0e6), floating) + " Jt" // Three Zeroes for Ribu
    : Math.abs(Number(labelValue)) >= 1.0e3
    ? NumberWithDot((Math.abs(Number(labelValue)) / 1.0e3), floating) + " Rb"
    : Math.abs(Number(labelValue)).toFixed(0);
}

export default {
  NumberWithDot,
  NumberFormat
}
import React, { useState, useEffect, useRef } from 'react';

import datatablestyle from './datatable.module.css'
import Loader from '../../utils/Loading'

const SimpleDatatable = (props) => {
  const listPerPage = useRef(props.listPerPage);
  const [search, setSearch] = useState('');
  const [per_page, setPerPage] = useState(props.listPerPage[0]);
  const [searchable, setSearchable] = useState(true);
  const [header, setHeader] = useState([]);
  const [sortField, setSortField] = useState({
    column: '',
    dir: ''
  });

  const [datapaging, setDatapaging] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //setListPerPage(props.listPerPage);
    setSearchable(props.searchable);
    setHeader(props.header.current);
    setDatapaging(props.datapaging);
    if (props.loading !== undefined) {
      setLoading(props.loading);
    }
  }, [props]);

  const changeSearch = (e) => {
    setSearch(e.target.value);
    props.changeSearch(e.target.value);
  }

  const changePerPage = (e) => {
    setPerPage(e.target.value);
    //per_page.current = e.target.value;
    props.changePerPage(e.target.value);
  }

  const clickSort = (e) => {
    let field = e.currentTarget.getAttribute('data-field');

    let column = '';
    let dir = '';

    if (sortField.column === field) {
      if (sortField.dir === 'asc') {
        column = field;
        dir = 'desc'
      }
      else if (sortField.dir === 'desc') {
        column = '';
        dir = ''
      }
      else {
        column = field;
        dir = 'asc'
      }
    } else {
      column = field;
      dir = 'asc'
    }

    setSortField({
      column,
      dir
    })

    props.changeSort({
      column,
      dir
    })
  }

  const ThSDT = ({ name, value, sortable }) => {
    if (sortable === true) {
      return (
        <th onClick={clickSort} data-testid="th-sort" className={datatablestyle.sortTh} data-field={name}>
          {value} {sortField.column === name ? <i className={`fas ${sortField.dir === 'asc' ? 'fa-sort-amount-up-alt' : 'fa-sort-amount-down'}`}></i> : ''}
        </th>
      )
    } else {
      return (
        <th data-testid="th-no-sort">{value}</th>
      )
    }
  }

  const ButtonPaging = ({ url, active, label }) => {
    //let {url, active, label} = post;
    let opts = {};

    opts['className'] = `btn ${datatablestyle.btn2}`;
    if (url === null) {
      opts['disabled'] = 'disabled';
      opts['className'] = `btn ${datatablestyle.btn}`;
    }

    if (active === true) {
      opts['className'] = `btn ${datatablestyle.btn3}`;
    }

    const handleClickPaging = () => {
      if (active === false && url !== null) {
        //setUrldata(`${url}&per_page=${per_page}&order_column=${sortField.column}&order_dir=${sortField.dir}`)
        props.changePage(url);
      }
    }


    return (
      <button {...opts} onClick={handleClickPaging} data-testid="btn-pagination">
        {label === 'pagination.previous' ? <i className="fas fa-chevron-left"></i> :
          label === 'pagination.next' ? <i className="fas fa-chevron-right"></i> : label}
      </button>
    )
  }

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-8 col-xl-10">
          <label>
            Show
            <select data-testid="change-perpage" value={per_page} onChange={changePerPage} className={datatablestyle.select}>
              {listPerPage.current.map((dt, index) => (
                <option key={index} value={dt}>{dt}</option>
              ))}
            </select> Entries
          </label>
        </div>
        <div className="col-12 col-md-4 col-xl-2">
          {searchable === true ? <>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className={`input-group-text ${datatablestyle.appendSearch}`}>
                  <i className="fas fa-search"></i>
                </div>
              </div>
              <input type="text" data-testid="input-search" size="10" className={`form-control ${datatablestyle.search}`} placeholder="Search" value={search} onChange={changeSearch} />
            </div>
          </> : ''}
        </div>
      </div>

      {loading === true ? <>
        <div className={`row ${datatablestyle.marginTop20}`}>
          <div className="col-12 text-center">
            <Loader></Loader>
          </div>
        </div>
      </> : <>
        <div className="table-responsive">
          <table className={`table table-hover table-striped`}>
            <thead>
              <tr>
                {header.map((dt, index) => (
                  <ThSDT
                    key={index}
                    name={dt.name}
                    value={dt.value}
                    sortable={dt.sortable}></ThSDT>
                ))}
              </tr>
            </thead>
            {props.children}
          </table>
        </div>
        <div className="row">
          <div className={`col-12 text-right ${datatablestyle.colPaging}`}>
            {(typeof datapaging !== 'undefined') && datapaging.map((dt, index) => (
              <ButtonPaging
                key={index}
                url={dt.url}
                active={dt.active}
                label={dt.label}></ButtonPaging>
            ))}
          </div>
        </div>
      </>}
    </>
  );
}

export default SimpleDatatable;
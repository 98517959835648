import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../App.css";
import { API_GET_CAPTCHA, API_POST_LOGIN, } from "../../constants/apiConstants";
import reloadCaptchaImg from "../../assets/img/reload.svg";
import poweredTelkom from "../../assets/img/powered-telkom.svg";
import poweredBigbox from "../../assets/img/powered-bigbox.svg";
import bgImg from "../../assets/img/bg_login.svg";
import MySwal from "../../utils/MySwal";
import Cookies from "universal-cookie";
import Logo from "../../assets/img/logo_IDSG.svg";

import { Link } from "react-router-dom";

const LoginForm = (props) => {
   const [submitted, setSubmitted] = useState(false);

   const [state, setState] = useState({
      nik: "",
      password: "",
      captcha: { img: null, value: "", key: null },
      status: true,
      disabled: true,
      showPassword: false,
   });

   const [error, setError] = useState({
      nik: "",
      password: "",
      captcha: "",
   });

   /**
    * Function to reload captcha
    * reloadCaptcha called on Document Ready and onClick re-captcha icon
    */
   const reloadCaptcha = () => {
      axios.get(`${API_GET_CAPTCHA}`).then(({ data, status }) => {
         if (status === 200) {
            setState((prevState) => ({
               ...prevState,
               captcha: {
                  ...prevState.captcha,
                  img: data.img,
                  key: data.key,
               },
            }));
         }
      });
   };

   /**
    * Call reloadCaptcha on document ready
    */
   useEffect(() => {
      // /**
      //  * Reset token every 2 mins
      //  */
      const interval = setInterval(() => {
         reloadCaptcha();
      }, 120000);

      reloadCaptcha();
      return () => {
         clearInterval(interval);
      };
   }, [state.status]);

   /**
    * Handle input NIK
    */
   const handleChangeNIK = (e) => {
      const { value } = e.target;
      if (value.length > 17) {
         return true;
      }
      if (value.match(/[^0-9]/g)) {
         setError((prevState) => ({
            ...prevState,
            nik: "NIK berupa angka",
         }));
      }
      setTimeout(() => {
         setError((prevState) => ({
            ...prevState,
            nik: "",
         }));
      }, 3000);

      setState((prevState) => ({
         ...prevState,
         nik: value.replace(/[^0-9]/g, ""),
      }));
   };

   /**
    * Handle input Password
    */
   const handleChangePassword = (e) => {
      const { value } = e.target;
      setState((prevState) => ({
         ...prevState,
         password: value,
      }));
   };

   /**
    * Handle input Captcha
    */
   const handleChangeCaptcha = (e) => {
      const { value } = e.target;
      if (value.length > 5) {
         return true;
      }
      setState((prevState) => ({
         ...prevState,
         captcha: {
            ...prevState.captcha,
            value: value.toUpperCase().replace(/[^0-9A-Z]/g, ""),
         },
      }));
   };

   /**
    * Enable and Disable Button Masuk
    * Enable when all input have been filled
    * Disable when all input haven't been filled
    */
   const handleKeyUp = (e) => {
      if (state.nik && state.password && state.captcha.value && state.captcha.value.length === 5) {
         setState((prevState) => ({
            ...prevState,
            disabled: false,
         }));
      } else {
         setState((prevState) => ({
            ...prevState,
            disabled: true,
         }));
      }
   };

   /**
    * Handle to reset input when someone click 'x'(times) icon
    */
   const handleClickReset = (e) => {
      let key = e.target.getAttribute("data-key");
      setState((prevState) => ({
         ...prevState,
         [key]: "",
      }));
   };

   /**
    * Handle to show and hide password when someone click 'eye' icon
    */
   const handleClickShowPass = (e) => {
      setState((prevState) => ({
         ...prevState,
         showPassword: !state.showPassword,
      }));
   };

   /**
    * Handle to reload captcha when someone click 're-captcha' icon
    */
   const handleReloadCaptcha = (e) => {
      reloadCaptcha();
   };

   /**
    * Handle submit
    */
   const handleSubmitClick = (e) => {
      e.preventDefault();
      if (submitted) return;

      setSubmitted(true);
      const payload = {
         nik: state.nik,
         password: state.password,
         key: state.captcha.key,
         captcha: state.captcha.value,
      };

      axios
         .post(`${API_POST_LOGIN}`, payload)
         .then(({ data, status }) => {
            if (status === 200) {
               MySwal.fire({
                  title: "Login Berhasil",
                  // text: 'Redirecting...',
                  icon: "success",
                  timer: 2000,
                  showConfirmButton: false,
                  timerProgressBar: true,
               }).then(() => {
                  var now = new Date();
                  var time = now.getTime();
                  var expireTime = time + 3600;
                  now.setTime(expireTime);
                  let cookies = new Cookies();
                  cookies.set("login_access_token", `Bearer ${data.data.profile.api_token}`, { path: "/", maxAge: 18000 });
                  props.history.push(data.data.profile.redirect_to);
               });
            }
         })
         .catch(function ({ response }) {
            if (response.status === 400) {
               MySwal.fire({
                  title: "Gagal Login",
                  text: response.data.message,
                  icon: "error",
               }).then(() => {
                  reloadCaptcha();
                  setSubmitted(false);
                  handleChangeCaptcha({ target: { value: "" } });
               });
            }

            if (response.status === 422) {
               MySwal.fire({
                  title: "Gagal Login",
                  text: "Chaptcha Salah !",
                  icon: "error",
               }).then(() => {
                  reloadCaptcha();
                  setSubmitted(false);
                  handleChangeCaptcha({ target: { value: "" } });
               });
            }
         });
   };

   const getCurrentYear = () => {
      return new Date().getFullYear();
   };

   return (
      <>
         <div className="box-login">
            <div className="right-login" style={{ backgroundImage: `url(${bgImg})` }}>
               <div className="bg-overlay"></div>
               <div className="box-ttl-login">
                  <div className="row">
                     <div className="col">
                        <span className="ttl-1">Selamat datang di</span>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col">
                        <span className="ttl-2">
                           Integrated Dashboard<br></br> Smart Government
                        </span>
                     </div>
                  </div>
               </div>
            </div>
            <div className="left-login">
               <div className="box-table-login">
                  <div className="display-table">
                     <div className="display-table-cell">
                        <div className="box-action-login">
                           <div className="title-action">
                              {/* <small>Selamat Datang di</small> */}
                              <img style={{ display: "block", paddingBottom: "10px" }} data-testid="full-logo" className="imgLogo img-fluid" src={Logo} alt="logo idsg" />
                           </div>
                           <form>
                              <div className="form-group text-left box-field mb-0">
                                 <label htmlFor="nik">NIK</label>
                                 <input type="text"
                                 className="form-control text-field"
                                 id="nik" placeholder="Masukan NIK Anda"
                                 value={state.nik} onKeyUp={handleKeyUp}
                                 onChange={handleChangeNIK}
                                 data-testid="input-nik"
                                 />
                                 <div className="icon-container">
                                    {state.nik && <i className="fa fa-times form-icon" onClick={handleClickReset} data-key="nik" data-testid="x-icon-nik"></i>}
                                 </div>
                              </div>
                              <div className="hint-container">
                                 {error.nik && (
                                    <>
                                       <i className="fa fa-exclamation-circle"></i>
                                       <span className="hint-red"> {error.nik}</span>
                                    </>
                                 )}
                              </div>
                              <div className="form-group text-left box-field">
                                 <label htmlFor="password">Password</label>
                                 <input
                                    type={`${state.showPassword ? "text" : "password"}`}
                                    className="form-control text-field"
                                    id="password"
                                    placeholder="Masukan Password"
                                    value={state.password}
                                    onKeyUp={handleKeyUp}
                                    onChange={handleChangePassword}
                                    data-testid="input-password"
                                 />
                                 <div className="icon-container">
                                    {state.password && (
                                       <i
                                          className="fa fa-times form-icon"
                                          onClick={handleClickReset}
                                          data-key="password"
                                          data-testid="x-icon-password"
                                          style={{
                                             borderRight: "2px solid black",
                                             paddingRight: "5px",
                                          }}
                                       ></i>
                                    )}
                                    <i className={`fa fa-eye${state.showPassword ? "" : "-slash"} form-icon`} onClick={handleClickShowPass} data-testid="eye-icon"></i>
                                 </div>
                              </div>
                              <div className="box-link-action">
                                 <div className="right-link-action">
                                    <span>
                                       <Link to="/forgot-password" className="idsg-color">
                                          Lupa Password?
                                       </Link>
                                    </span>
                                 </div>
                                 <div className="clearer"></div>
                              </div>

                              <div className="form-group text-left box-field">
                                 <div className="captcha-img">
                                    <img className="captcha" src={state.captcha.img} alt="" data-testid="captcha" data-key={state.captcha.key} />
                                    <img data-testid="reload-captcha" onClick={handleReloadCaptcha} className="captcha-reload ml-3" src={reloadCaptchaImg} alt="" />
                                 </div>
                                 <input
                                    type="text"
                                    className="form-control text-field"
                                    id="captcha"
                                    placeholder="Masukan captcha"
                                    value={state.captcha.value}
                                    onKeyUp={handleKeyUp}
                                    onChange={handleChangeCaptcha}
                                    data-testid="input-captcha"
                                 />
                              </div>

                              <div className="form-check"></div>
                              <button type="submit" className="button-red" onClick={handleSubmitClick} disabled={state.disabled} data-testid="btn-submit">
                                 Masuk
                              </button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="powered-contain">
               <div>Powered by</div>
               <div className="powered">
                  <div className="powered-img">
                     <img src={poweredBigbox} alt="" />
                  </div>
                  <div className="powered-img">
                     <img src={poweredTelkom} alt="" />
                  </div>
               </div>
            </div>

            <footer>{getCurrentYear()} © BigView. All Right Reserved</footer>
         </div>
      </>
   );
};

export default LoginForm;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import moment from "moment";
import SimpleDatatable from "../../../../Material/SimpleDatatable";

const DataKemiskinanDesa = (props) => {
  const [listModeling, setListModeling] = useState("");
  const [isFilterModeling, setIsFilterModeling] = useState(false);
  const [stateOptionModeling, setStateOptionModeling] = useState([]);
  const [stateSelectModeling, setStateSelectModeling] = useState("");
  const [stateSelectModelingName, setStateSelectModelingName] = useState("");
  const hiddenFileInput = useRef(null);
  const listPerPage = useRef([5, 10, 25, 50]);
  const [item, setItem] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedSector, setSelectedSector] = useState();
  const [linkDataModeling, setLinkDataModeling] = useState();
  const [per_page, setPerPage] = useState(listPerPage.current[0]);
  const [sortField, setSortField] = useState({
    column: "",
    dir: "",
  });

  const [datapaging, setDatapaging] = useState([]);
  const [loading, setLoading] = useState(false);
  const header = useRef([
    {
      name: "nama",
      value: "Nama",
      sortable: true,
    },
    {
      name: "link",
      value: "Link",
      sortable: true,
    },
    {
      name: "action",
      value: "Action",
      sortable: true,
    },
  ]);

  const submit = () => {
    let formData = new FormData();

    formData.append("id_usecase", props.idUC);
    formData.append("id_model", stateSelectModeling);
    formData.append("name_model", stateSelectModelingName);
    formData.append("link_model", linkDataModeling);

    axios
      .post(`${API_BASE_URL}analisis-modeling/list-model`, formData)
      .then(({ data: { data } }) => {
        alert("sukses");
        changePage(`${API_BASE_URL}analisis-modeling/list-model?id_usecase=${props.idUC}`);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}analisis-modeling/filter-model`)
      .then(({ data }) => {
        setStateOptionModeling(data.data);
        setStateSelectModeling(
          data.data[0]?.id //=== undefined ? "" : data.data[0]?.value
        );
        setStateSelectModelingName(
          data.data[0]?.name //=== undefined ? "" : data.data[0]?.value
        );
        setIsFilterModeling(true);
      })
      .catch(() => {
        console.log("error");
      });
  }, []);

  const changeSearch = (data) => {
    setSearch(data);
  };

  const changePerPage = (data) => {
    setPerPage(data);
  };

  const changeSort = (data) => {
    setSortField(data);
  };

  const changePage = (data) => {
    setLoading(true);
    axios
      .get(
        `${data}&per_page=${per_page}&order_column=${sortField.column}&order_dir=${sortField.dir}&keyword=${search}`
      )
      .then(({ data: { data, links, from } }) => {
        setItem(data);
        setDatapaging(links);
        setLoading(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setLoading(true);
    if (props.idUC !== "") {
      axios
        .get(
          `${API_BASE_URL}analisis-modeling/list-model?id_usecase=${props.idUC}`
        )
        .then(({ data: { data, links, from } }) => {
          setItem(data);
          setDatapaging(links);
          setLoading(false);
        })
        .catch((error) => {});
    } else {
      setItem([]);
      setDatapaging([]);
      setLoading(false);
    }
  }, [per_page, sortField, search, props]);

  const handleDelete = (id_model) => {
    axios
      .delete(`${API_BASE_URL}analisis-modeling/list-model?id_usecase=${props.idUC}&id_model=${id_model}`)
      .then(({ data: { data } }) => {
        alert("Data Berhasil Dihapus");
        changePage(`${API_BASE_URL}analisis-modeling/list-model?id_usecase=${props.idUC}`);
      })
      .catch((err) => {});
  };

  const datachange = (el) => {
    setStateSelectModeling(el.value);
    setStateSelectModelingName(el.options[el.value-1].dataset.name);
  }

  return (
    <>
    <div className="box-frame">
      <div className="row mb-3">
        <div className="col-4">
          <label className="textMenu2 labelUC">
            Pilih Sector<span className="redText">*</span>
          </label>
          <select
            className="form-control"
            value={stateSelectModeling}
            onChange={(e) => datachange(e.target)}
            data-testid="select-menu"
          >
            {stateOptionModeling.map((data, index) => {
              return (
                <option
                  value={data.id}
                  key={index}
                  data-name={data.name}
                  //selected={setStateSelectModeling}
                >
                  {data.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-4">
          <label className="textMenu2 labelUC">
            Link Analytics<span className="redText">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Masukan Link Analytics"
            value={linkDataModeling}
            onChange={(e) => setLinkDataModeling(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <button
          type="button"
          className="btn btn-dark btnMenu ml-auto mx-3 mt-3"
          onClick={submit}
          data-testid="btn-submit"
        >
          Simpan
        </button>
      </div>
      <br></br>
      <div className="row">
        <div className="col-12">
          <SimpleDatatable
            listPerPage={listPerPage.current} //useRef
            searchable={true}
            header={header} //useRef
            datapaging={datapaging}
            loading={loading}
            //props
            changeSearch={changeSearch}
            changePerPage={changePerPage}
            changeSort={changeSort}
            changePage={changePage}
          >
            <tbody id="tbodyDataUser">
              {item.map((data, index) => (
                <tr key={index}>
                  <td>{data.name_model}</td>
                  <td>{data.link_model}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-dark"
                      onClick={() => handleDelete(data.id_model)}
                    >
                      <i className="fas fa-trash"></i> Hapus
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </SimpleDatatable>
        </div>
      </div>
    </div>
    </>
  );
};

export default DataKemiskinanDesa;

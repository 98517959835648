export const colorfull = [
    '86085',
]

export const welcomeProfile = {
    "1223334444": "Pemerintah Daerah",
    "99887766": "Universitas Gadjah Mada",
    "11223344": "Bank Jambi",
    "86085": "Polda Provinsi Riau",
    "86086": "Polda Kepulauan Riau",
    "86030": "Polda Kalimantan Timur",
    "30053": "Badan Gizi Nasional",
    "98709": "Universitas Muhammadiyah Yogyakarta",
}

export const jabatanProfile = {
    "1223334444" : "Kepala Daerah",
    "99887766" : "Rektor Universitas Gadjah Mada",
    "11223344" : "Direktur Utama Bank Jambi",
    "00228" : "Pj Gubernur Provinsi Gorontalo",
    "86085" : "KAPOLDA PROVINSI RIAU",
    "86086" : "KAPOLDA KEPULAUAN RIAU",
    "86030" : "KAPOLDA KALIMANTAN TIMUR",
    "30053" : "Ketua Badan Gizi Nasional",
    "98709" : "Rektor Universitas Muhammadiyah Yogyakarta",
}

export const wakilJabatanProfile = {
   "1223334444": "Wakil Kepala Daerah",
    "86085":"WAKAPOLDA PROVINSI RIAU",
    "86086": "WAKAPOLDA KEPULAUAN RIAU",
    "86030": "WAKAPOLDA KALIMANTAN TIMUR",
    "30053": "Wakil Badan Gizi Nasional",
    "98709": "Wakil Rektor Universitas Muhammadiyah Yogyakarta",
}

export const withoutWakil = [
    "99887766",
    "11223344",
    "98709",
]

export const locationProfile ={
    "1223334444" : "Kantor Pemerintahan",
    "99887766" : "Universitas Gadjah Mada",
    "11223344" : "Bank Jambi",
    "86085" : "POLDA PROVINSI RIAU",
    "86086" : "POLDA KEPULAUAN RIAU",
    "86030" : "POLDA KALIMANTAN TIMUR",
    "30053" : "Badan Gizi Nasional",
    "98709" : "Universitas Muhammadiyah Yogyakarta",
}
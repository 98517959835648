import React, { useState, useEffect, useRef } from 'react';
import NoLogo from '../../../../../../assets/img/nologo.png';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../../constants/apiConstants';

const PimpinanDaerah = (props) => {
  const [file, setFile] = useState(null);
  const [imgData, setImgData] = useState(NoLogo);
  const [file2, setFile2] = useState(null);
  const [imgData2, setImgData2] = useState(NoLogo);

  const [nama_gubernur, setNamaGubernur] = useState('');
  const [nama_wakil, setNamaWakil] = useState('');

  const [is_edit_img, setIsEditImg] = useState(0);
  const [is_edit_img2, setIsEditImg2] = useState(0);


  useEffect(() => {
    setNamaGubernur(props.nama_gubernur);
    setNamaWakil(props.nama_wakil);

    if (props.imgData !== '') {
      setImgData(`${props.imgData}`);
    } else {
      setImgData(NoLogo);
    }

    if (props.imgData2 !== '') {
      setImgData2(`${props.imgData2}`);
    } else {
      setImgData2(NoLogo);
    }

  }, [props]);

  const hiddenFileInput = useRef(null);
  const hiddenFileInput2 = useRef(null);

  const handleClick = event => {

    if (event.target.getAttribute('data-btn') === 'btn1') {
      hiddenFileInput.current.click();
    }

    if (event.target.getAttribute('data-btn') === 'btn2') {
      hiddenFileInput2.current.click();
    }
  };

  const handleChangeImg = event => {
    if (event.target.getAttribute('data-img') === 'img1') {
      if (event.target.files[0]) {
        setIsEditImg(1);

        //data gambar
        const fileUploaded = event.target.files[0];
        setFile(fileUploaded);
  
        //data sementara
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(event.target.files[0]);
      }
    }

    if (event.target.getAttribute('data-img') === 'img2') {
      if (event.target.files[0]) {
        setIsEditImg2(1);

        //data gambar
        const fileUploaded = event.target.files[0];
        setFile2(fileUploaded);
  
        //data sementara
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData2(reader.result);
        });
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  };

  const deleteImage = (e) => {
    if (e.target.getAttribute('data-btn') === 'btn1') {
      setIsEditImg(1);

      setFile(null);
      setImgData(NoLogo);
      hiddenFileInput.current.value = null;
    }

    if (e.target.getAttribute('data-btn') === 'btn2') {
      setIsEditImg2(1);

      setFile2(null);
      setImgData2(NoLogo);
      hiddenFileInput2.current.value = null;
    }
  }

  const handleChange = (e) => {
    if (e.target.name === 'nama_gubernur') {
      setNamaGubernur(e.target.value);
    }

    if (e.target.name === 'nama_wakil') {
      setNamaWakil(e.target.value);
    }
  }

  const submit = () => {
    let formData = new FormData();

    formData.append('nama_gubernur', nama_gubernur);
    formData.append('nama_wakil', nama_wakil);
    formData.append('pic_gubernur', file);
    formData.append('pic_wakil', file2);
    formData.append('is_edit_img', is_edit_img);
    formData.append('is_edit_img2', is_edit_img2);
    formData.append('id_usecase', props.idUC);

    axios.post(`${API_BASE_URL}usecase/updateKepalaDaerah`, formData)
      .then(({ data: { data } }) => {
        alert('sukses')
      })
      .catch((err) => {});
  }

  return (
    <>
      <div className="row rowMenu">
        <div className="col-4">

          <div className="row">
            <div className="col-12">
              <div className="form-group text-left">
                <label className="textMenu2 labelUC">Nama Gubernur/Walikota/Bupati<span className="redText">*</span></label>
                <input data-testid="inputName" name="nama_gubernur" type="text" className="form-control" placeholder="Masukan Nama Anda" value={nama_gubernur} onChange={handleChange}  />
              </div>
            </div>
          </div>

          <div className="row rowMenu">
            <div className="col-12 colMenuFile">
              <img className="imgPD" src={imgData} alt="nologo" />
              <div id="divUpload">
                <input
                  data-testid="inputFile"
                  type="file"
                  data-img="img1"
                  ref={hiddenFileInput}
                  onChange={handleChangeImg}
                  style={{ display: 'none' }}
                />

                {file !== null ?
                  <button data-testid="btnDelete" data-btn="btn1" type="button" className="btn btn-outline-primary btnMenu" onClick={deleteImage}>
                    Delete
                  </button>
                  : ''}

                <button data-testid="btnUpload" data-btn="btn1" type="button" className="btn btn-dark btnMenu" onClick={handleClick}>
                  Upload
                </button>
                <p className="textMenu3">Gambar ekstensi .jpg, .png max. 5mb</p>
              </div>
            </div>
          </div>

        </div>

        <div className="col-4">

          <div className="row">
            <div className="col-12">
              <div className="form-group text-left">
                <label className="textMenu2 labelUC">Nama Gubernur/Walikota/Bupati<span className="redText">*</span></label>
                <input data-testid="inputName2" name="nama_wakil" type="text" className="form-control" placeholder="Masukan Nama Anda" value={nama_wakil} onChange={handleChange}  />
              </div>
            </div>
          </div>

          <div className="row rowMenu">
            <div className="col-12 colMenuFile">
              <img className="imgPD" src={imgData2} alt="nologo" />
              <div id="divUpload">
                <input
                  data-testid="inputFile2"
                  type="file"
                  data-img="img2"
                  ref={hiddenFileInput2}
                  onChange={handleChangeImg}
                  style={{ display: 'none' }}
                />

                {file2 !== null ?
                  <button data-testid="btnDelete2" data-btn="btn2" type="button" className="btn btn-outline-primary btnMenu" onClick={deleteImage}>
                    Delete
                  </button>
                  : ''}

                <button data-testid="btnUpload2" data-btn="btn2" type="button" className="btn btn-dark btnMenu" onClick={handleClick}>
                  Upload
                </button>
                <p className="textMenu3">Gambar ekstensi .jpg, .png max. 5mb</p>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div className="row rowMenu">
        <div className="col-12 text-right">
          <button data-testid="btnSubmit" type="button" className="btn btn-dark" onClick={submit}>Save</button>
        </div>
      </div>
    </>
  );
}

export default PimpinanDaerah;
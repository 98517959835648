import React, { useState, useEffect } from "react";
import { Map as LeafletMap, TileLayer, CircleMarker, Popup, GeoJSON, ZoomControl} from 'react-leaflet';
import { reactLocalStorage } from 'reactjs-localstorage';
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import axios from "axios";
import { BackgroundShadow } from '../../../PotensiDaerah/SDA/Utils';

import esStyle from '../../../PotensiDaerah/SDA/index.module.css';
import { NumberWithDot } from "../../../../Material/FormatNumber";
// import esStyle from '../../../PotensiDaerah';

const Peta = (props) => {
    const {jenis, tanggal, komoditas, pasar} = props;

    const [tempMaps, setTempMaps] = useState([]);
    const [leaflet, setleaflet]     = useState({});
    const [polyMap, setPolyMap]   = useState({});
    const baseColor               = reactLocalStorage.getObject("base_color");
    const lonlat                  = reactLocalStorage.getObject("lonlat");
    const [load, setLoad]         = useState(true);

    const [getZ, setZ]        = useState(7);
    const [getZoom, setZoom]  = useState(7);
    const onViewportChanged   = (viewport) => {
        let newPixel = viewport.zoom;
        setZ(newPixel)
    };

    useEffect(() => {
        axios
          .get(`${API_BASE_URL}pariwisata/map-usecase`)
          .then(({ data }) => {
            setPolyMap(data.features);
          })
          .catch(() => {
            console.log("error");
          });
    }, []);

    useEffect(() => {

      setLoad(false);

      axios
      .get(
          `${API_BASE_URL}sosial-ekonomi/harga-pangan/harga/map-harga?jenis=${jenis}&komoditas=${komoditas}&tanggal=${tanggal}&pasar=${pasar}`
      )
      .then(({ data: { data }, status }) => {
        setLoad(true)
        setleaflet(data);
      })
      .catch(() => {
        console.log("error");
      });
  }, [jenis, komoditas, tanggal,pasar]);

  useEffect(() => {
    if (  (typeof jenis   === "undefined") || (typeof komoditas === "undefined") ||
          (typeof tanggal === "undefined") || (typeof pasar     === "undefined")) {
      return;
    }
    setTempMaps(leaflet);
  }, [leaflet]);

    return (
        <div className={esStyle.leafletContainer}>
          {
            load ?
              <div className="position-relative" style={{height:'400px'}}>
              <LeafletMap
                center={[lonlat.center_lat, parseInt(lonlat.center_lon) +1]} // +1 untuk geser ke kiri
                zoom={getZoom}
                height={335}
                onViewportChanged={onViewportChanged}
                zoomControl={false}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
                <ZoomControl position="bottomleft" />
                  {polyMap?.geometry && Object.keys(polyMap.geometry).length && (
                    <GeoJSON
                      data={polyMap}
                      style={{ color: baseColor.color1 }}
                      fillOpacity="0.3"
                      weight="2"
                    />
                  )}
                  {tempMaps?.map((index, key) => {
                    let rad = 0;
                    if(getZ < getZoom){
                      rad = getZoom-getZ
                    }
                      return (
                        <CircleMarker
                          key={key}
                          center={[index.lat, index.lon]}
                          radius={(Math.log(index.data*10))-(rad*3)}
                          color={"#FFFFFF"}
                          fillColor={baseColor.color1}
                          stroke={true}
                          fillOpacity="0.6"
                          weight="1"
                          onMouseOver={(e) => {
                            e.target.openPopup();
                          }}
                          onMouseOut={(e) => {
                            e.target.closePopup();
                          }}
                        >
                          <Popup>
                            <div className="d-flex row pt-2 pb-2 pl-2 pr-2 ll-marker">
                              <table>
                                <tbody>
                                <tr>
                                  <td>
                                    <span>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span>
                                      <strong>{index.kota}</strong>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span>
                                      <strong>{index.city}</strong><br />
                                      Harga {jenis} per Kg(Rp):
                                      <strong>{`${NumberWithDot(index.data, 2)} `}</strong>
                                    </span>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </Popup>
                        </CircleMarker>
                      );
                    })
                    }
                </LeafletMap>
                  {
                      typeof tempMaps === "undefined" ?
                      <BackgroundShadow content="" />
                      :
                      tempMaps.length === 0
                      ?
                      <BackgroundShadow content="No Data to Display" />
                      : ""
                  }
              </div>
            : <div className="position-relative" style={{height:'400px'}}><BackgroundShadow content="" background="#c0c2c6" /></div>
            }
        </div>
    )
}

export default Peta
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { API_BASE_URL } from '../../../../../constants/apiConstants';
import YearPickerOnly from "../../../../../utils/YearPickerOnly";
import esStyle from '../index.module.css';
import './index.css'


import { reactLocalStorage } from 'reactjs-localstorage';
const baseColor               = reactLocalStorage.getObject("base_color");


const RencanaDanRealisasi = (props) => {
    const {indikatorTitle, indikator } = props;
    
    const [startDate, setStartDate]                 = useState(new Date());
    const [statusDate, setStatusDate]               = useState(false);
    const [yearWData, setYearWData]                 = useState([]);
    const [card, setCard]                           = useState({rencana:'', realisasi:'', persentase:'',})
    const [loading, setLoading]                     = useState(true)

    useEffect(() => {
        if(typeof indikator === "undefined") return 
       
        axios
          .get(
            `${API_BASE_URL}poda/sda/pertanian/tahun?indikator=${indikator}`
          )
          .then(({ data: { data }, status }) => {              
            setStartDate(new Date(`${data.selected_years}`));            
            setYearWData(data.years);
          })
          .catch(() => {})
          .finally(() => {
            setStatusDate(true);
          });
      }, [indikator]);

    useEffect(() => {
        handleCard();        
    }, [indikator, startDate]);

    const handleCard = () => {
        if ( (typeof indikator === "undefined") ||(typeof new Date(startDate).getFullYear() === "undefined") ) {
            return;
        }
        setLoading(true)
        axios
        .get(
            `${API_BASE_URL}poda/sda/pertanian/card?year=${new Date(startDate).getFullYear()}&indikator=${indikator}`
        )
        .then(({ data: { data }, status }) => {
            setLoading(false)
            data.status === 'Exist' ?
                setCard({
                    rencana: data.rencana.toLocaleString() + ' ton',
                    realisasi: data.realisasi.toLocaleString() + ' ton',
                    persentase: data.persentase.toLocaleString() + ' %'
                })
            : data.status === 'Empty' ?
                setCard({
                    rencana: 'N/A',
                    realisasi: 'N/A',
                    persentase: 'N/A'
                })
            : setCard({
                    rencana: '',
                    realisasi: '',
                    persentase: ''
                })
        })
        .catch(() => {});
    }

    return (
        <>
          {/* Box Lama Menginap */}
          <div className="box-content-custom card border-light">
            <div className="card-body">
              <div className="row row-cols-1">
                <div className="col">
                  {/** Box Total Start */}
                  <div
                    data-testid="box-total"
                    className="box-status"
                    style={{ backgroundColor: loading? '#c0c2c6' : baseColor.color1 }}
                  >
                    <div className="stc-container">
                      <div className="row">
                        <div className="col">
                          <span className="stc-font" style={{display: loading ? 'none' : 'block'  }}>{indikatorTitle}</span>
                        </div>
                        <div className="col">
                            <div className={`${esStyle.marginBottom20} float-right`}>
                                <div className={esStyle.divEs}>
                                    <YearPickerOnly
                                        buttonStyle={{backgroundColor: "transparent", color: baseColor.color3}}
                                        yearWithData={yearWData}
                                        selected={startDate}
                                        onChange={(date) => {
                                            setStartDate(date)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col">
                            <table className={`text-white w-75 ${loading ? "d-none" : ""}`}>
                              <tbody>
                                <tr>
                                    <td>Rencana</td>
                                    <td>:</td>
                                    <td className="text-right">{card.rencana}</td>
                                </tr>
                                <tr>
                                    <td>Realisasi</td>
                                    <td>:</td>
                                    <td className="text-right">{card.realisasi}</td>
                                </tr>
                                <tr>
                                    <td>Persentase</td>
                                    <td>:</td>
                                    <td className="text-right">{card.persentase}</td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                      </div>
                    </div>
                    <div className="stc" style={{display: loading ? 'none' : 'block'  }}></div>
                    <div className="stc2" style={{display: loading ? 'none' : 'block'  }}></div>
                  </div>
                  {/** Box Total End */}
                </div>
              </div>
    
              
            </div>
          </div>
        </>
      );
};

export default RencanaDanRealisasi;
import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./index.module.css";
// import Datatable from '../../../../Datatable';
import DataTable from "react-data-table-component";

import { API_BASE_URL } from "../../../../../constants/apiConstants";
import Datatable from "../../../../Material/Datatable";

import CustomPagination from "./CustomPagination";

const Index = (props) => {
  const [state, setState] = useState({
    tableData: {},
    title: "",
  });

  const [tableColumn, setTableColumn] = useState();
  const [tableData, setTableData] = useState();

  const [loading, setLoading] = useState(true);

  const columns = [
    {
      name: "Name",
      selector: (row) => row["nama"],
    },
    {
      name: "Kelas",
      selector: (row) => row["kelas"],
    },
  ];

  useEffect(() => {
    if (!props.data || props.data.id_sektor == 0) {
      return;
    }
    axios
      .get(
        `${API_BASE_URL}sector/iku/indikator/${props.data.id_sektor}?public=${props.public}`
      )
      .then(({ data: { data } }) => {
        pengolahanData(data.widget_data);
        setState({
          tableData: data.widget_data,
          title: data.widget_title,
        });
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          tableData: {},
          title: "",
        }));
      });
  }, [props.data]);

  const pengolahanData = async (dataTable) => {
    const columnTemp = [];
    // set up column
    await dataTable.order_col.map((data) => {
      columnTemp.push({
        name: data,
        selector: (row) => row[data],
      });
    });
    setTableColumn(columnTemp);

    setTableData(dataTable.t_body);

    setLoading(false);
  };

  return (
    Object.values(state.tableData).length !== 0 && (
      <div className={style.boxIndicatorSummary}>
        <p data-testid="title" className={style.title}>
          {state.title}
        </p>
        <div className={style.tableCard}>
          {loading ? (
            ""
          ) : (
            <DataTable
              columns={tableColumn}
              data={tableData}
              pagination
              paginationPerPage={5}
              paginationComponent={CustomPagination}
            />
          )}
          {/* <Datatable {...state.tableData} serverside={true} /> */}
        </div>
      </div>
    )
  );
};

export default React.memo(Index);

import React, { useState, useEffect } from 'react';
import PimpinanDaerah from './ChildBeranda/PimpinanDaerah';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../constants/apiConstants';
//import Sektor from './ChildBeranda/Sektor';
import Alamat from './ChildBeranda/Alamat';

const Beranda = (props) => {
  const [section, setSection] = useState('pimpinanDaerah');

  const [alamat, setAlamat] = useState('');
  const [telepon, setTelpon] = useState('');
  const [link_map, setLinkMap] = useState('');

  const [nama_gubernur, setNamaGubernur] = useState('');
  const [nama_wakil, setNamaWakil] = useState('');
  const [imgData, setImgData] = useState('');
  const [imgData2, setImgData2] = useState('');

  useEffect(() => {
    if (props.idUC !== '') {
      axios.get(`${API_BASE_URL}usecase/detail/${props.idUC}`)
        .then(({ data: { data } }) => {
          if (data.alamat !== null) {
            setAlamat(data.alamat);
          } else {
            setAlamat('');
          }
  
          if (data.telepon !== null) {
            setTelpon(data.telepon);
          } else {
            setTelpon('');
          }
  
          if (data.link_map !== null) {
            setLinkMap(data.link_map);
          } else {
            setLinkMap('');
          }

          if (data.nama_gubernur !== null) {
            setNamaGubernur(data.nama_gubernur);
          } else {
            setNamaGubernur('');
          }

          if (data.nama_wakil !== null) {
            setNamaWakil(data.nama_wakil);
          } else {
            setNamaWakil('');
          }

          if (data.pic_gubernur !== null) {
            setImgData(data.pic_gubernur);
          } else {
            setImgData('');
          }

          //console.log(data.pic_wakil);
          if (data.pic_wakil !== null) {
            setImgData2(data.pic_wakil);
          } else {
            setImgData2('');
          }
        })
        .catch((err) => {});
    }
  }, [props])

  useEffect(() => {
    //console.log(props.idUC);
  }, [section, props]);

  const changeSection = (e) => {
    setSection(e.target.value);
  }

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="form-group row">
            <label className="col-2 col-form-label textMenu2 labelUC">Section</label>
            <div className="col-4">
              <select data-testid="selectSection" id="selectUC" className="form-control" onChange={changeSection}>
                <option value="pimpinanDaerah">Pimpinan Daerah</option>
                <option value="alamat">Alamat</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {section === 'pimpinanDaerah' ? <PimpinanDaerah nama_gubernur={nama_gubernur} nama_wakil={nama_wakil} imgData={imgData} imgData2={imgData2} idUC={props.idUC} /> :
      section === 'alamat' ? <Alamat alamat={alamat} telepon={telepon} link_map={link_map} idUC={props.idUC} /> : ''}
    </>
  );
}

export default Beranda;
import React, { useState } from 'react';
import {
  Link,
  useHistory,
  useParams
} from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import SettingPassword from './SettingPassword.module.css'

import MySwal from '../../utils/MySwal'
import { API_BASE_URL } from '../../constants/apiConstants'
import HeaderGlobal from '../Layouts/HeaderGlobal'

const Index = () => {
  const history = useHistory();
  const params = useParams();

  const [is_password, setIsPassword] = useState(true);
  const [is_password2, setIsPassword2] = useState(true);

  const deletePassword = () => {
    formik.setFieldValue('password', '')
  }

  const deleteConfirmPassword = () => {
    formik.setFieldValue('confirm_password', '')
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password harus diisi')
        .min(8, 'Password minimal harus 8 karakter')
        .max(16, 'Password maximal harus 16 karakter'),
      confirm_password: Yup.string()
        .required('Konfirmasi password harus diisi')
        .oneOf([Yup.ref('password'), null], 'Harus sama dengan field password'),
    }),
    onSubmit: values => {
      let formData = {
        remember_token: params.token,
        new_password: values.password,
        password_confirmation: values.confirm_password
      }

      axios.post(`${API_BASE_URL}resetPassword`, formData)
        .then(() => {
          MySwal.fire({
            icon: 'success',
            title: 'Kata sandi berhasil diubah',
            html: 'Login kembali menggunakan kata sandi baru',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#DC4233'
          })
            .then((result) => {
              if (result.isConfirmed) {
                history.push('/')
              }
            })
        })
        .catch((error) => {
          if (error.response.status === 400) {
            MySwal.fire({
              icon: 'error',
              title: 'Peringatan!',
              text: error.response.data.message,
              confirmButtonText: 'Ke halaman lupa password',
              confirmButtonColor: '#DC4233'
            })
              .then((result) => {
                if (result.isConfirmed) {
                  history.push('/forgot-password')
                }
              })
          }
        })
    },
  })

  return (
    <>
      <HeaderGlobal>
        <div className="container">
          {/*{JSON.stringify(formik)}*/}
          <div className={`row justify-content-center ${SettingPassword.paddingTop30VH}`}>
            <div className="col-12 col-lg-7">
              <form onSubmit={formik.handleSubmit}>
                <div className={`card mb-3 ${SettingPassword.card}`}>
                  <div className={`card-header ${SettingPassword.cardHeader}`}>
                    {params.key === 'atur-password' ? 'Atur Password' :
                      params.key === 'atur-ulang-password' ? 'Atur Ulang Password' : ''}
                  </div>
                  <div className="card-body">
                    <label htmlFor="password" className={`${SettingPassword.text} ${SettingPassword.color}`}>
                      Password Baru
                    </label>

                    <div className={`input-group ${SettingPassword.inputSet} ${formik.touched.password && formik.errors.password ? 'is-invalid' : null}`}>
                      <input
                        data-testid="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        className={`form-control ${SettingPassword.inputGroup} ${SettingPassword.roundedFieldleft} ${formik.touched.password && formik.errors.password ? `is-invalid ${SettingPassword.isInvalid}` : ''}`}
                        type={is_password === true ? 'password' : 'text'}
                        id="password"
                        placeholder="Masukan Password Baru"
                        name="password"
                        {...formik.getFieldProps('password')} />

                      <div className={`input-group-append ${SettingPassword.inputAppend} ` } >
                        <span className={`input-group-text ${SettingPassword.roundedFieldRight}`}>
                          {formik.values.password.length > 0 && <>
                            <i data-testid="deletePassword" onClick={deletePassword} className={`fas stack-position fa-times`}></i>
                          </>}

                          <i data-testid="showPassword" onClick={() => setIsPassword(!is_password)} className={`fas stack-position ${is_password === true ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                        </span>
                      </div>
                    </div>

                    {formik.touched.password && formik.errors.password && <>
                      <div className="invalid-feedback">
                        {formik.errors.password}
                      </div>
                    </>}

                    <label htmlFor="confirmPassword" className={`${SettingPassword.text} ${SettingPassword.color} ${SettingPassword.marginTop20}`}>
                      Ulangi Password Baru
                    </label>

                    <div className={`input-group ${SettingPassword.inputSet}`}>
                      <input
                        data-testid="password2"
                        onChange={formik.handleChange}
                        value={formik.values.confirm_password}
                        className={`form-control ${SettingPassword.inputGroup} ${SettingPassword.roundedFieldleft} ${formik.touched.confirm_password && formik.errors.confirm_password ? `is-invalid ${SettingPassword.isInvalid}` : ''}`}
                        type={is_password2 === true ? 'password' : 'text'}
                        id="confirm_password"
                        placeholder="Masukan Password Baru"
                        name="confirm_password"
                        {...formik.getFieldProps('confirm_password')} />

                      <div className={`input-group-append ${SettingPassword.inputAppend}`}>
                        <span className={`input-group-text ${SettingPassword.roundedFieldRight}`}>
                          {formik.values.confirm_password.length > 0 && <>
                            <i data-testid="deleteConfirmPassword" onClick={deleteConfirmPassword} className={`fas stack-position fa-times`}></i>
                          </>}

                          <i data-testid="showPassword2" onClick={() => setIsPassword2(!is_password2)} className={`fas stack-position ${is_password2 === true ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                        </span>
                      </div>
                    </div>

                    {formik.touched.confirm_password && formik.errors.confirm_password && <>
                      <div className="invalid-feedback">
                        {formik.errors.confirm_password}
                      </div>
                    </>}

                  </div>

                  <div className={`card-footer`}>
                    <div className="row">
                      <div className={`col-6 ${SettingPassword.col}`}>
                        <Link className={SettingPassword.btn2} to="/">
                          Kembali ke halaman login
                        </Link>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          data-testid="btnSubmit"
                          type={formik.isValid && formik.dirty ? 'submit' : 'button'}
                          className={`btn btn-secondary ${!formik.isValid || !formik.dirty ? `${SettingPassword.btn}` : `${SettingPassword.btn3}`}`}
                        >Lanjutkan</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </HeaderGlobal>
    </>
  )
}

export default Index;
import React, { useState } from 'react';
import SektorEmpty from '../assets/img/sektorempty.png'
const NoContent =()=>{
    return (
        <div className="box-app">
            <div className="row">
                <div className="col-12 text-center">
                  <img className="imgMenu" src={SektorEmpty} alt="sektor kosong" />
                  <p>Anda tidak memiliki akses menu, silahkan hubungi administrator.</p>
                  
                </div>
            </div>
        </div>
    );
}

export default NoContent;
import React, { useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { reactLocalStorage } from "reactjs-localstorage";
import EmptyLine from "../../../src/assets/img/EmptyLine.svg";
import { NumberWithDot } from "../Material/FormatNumber";
import Loading from "../../utils/Loading";

let mounted = false;

let chartOptions = {
  chart: {
    type: "area",
    height: "300px",
  },
  title: {
    text: "",
  },
  exporting: {
    enabled: false,
  },
  yAxis: {
    title: {
      text: false,
    },
    labels: {
      formatter: function () {
        return NumberWithDot(this.value, 2);
      },
    },
  },
  colors: [reactLocalStorage.getObject("base_color")?.color1],
  tooltip: {
    shared: true,
  },
  legend: {
    enabled: false,
    layout: "horizontal",
    align: "center",
    verticalAlign: "bottom",
  },
  plotOptions: {
    area: {
      fillOpacity: 0.5,
      lineWidth: 1,
      pointStart: 0,
      marker: {
        enabled: false,
        symbol: "circle",
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
  series: [
    {
      name: "",
      data: [],
    },
  ],
  loading: {
    buffer: true,
    flag: true,
  },
  credits: false,
  status: false,
};

const ChartContainer = ({ children }) => (
  <>
    <div className="position-relative">
      <div className="flex-center mt-3 mb-5">{children}</div>
    </div>
  </>
);

const AreaChart2 = (props) => {
  const { data, categories, options } = props;
  const [state, setState] = useState(chartOptions);
  useEffect(() => {
    if (typeof data === "undefined") {
      return;
    }

    setState({
      ...state,
      ...options,
      chart: {
        ...state.chart,
        ...options.chart,
      },
      xAxis: {
        ...state.xAxis,
        ...options.xAxis,
        categories: categories,
      },
      yAxis: {
        ...state.yAxis,
        ...options.yAxis,
        labels: {
          ...state.yAxis?.labels,
          ...options.yAxis?.labels,
        },
      },
      exporting: {
        ...state.exporting,
        ...options.exporting,
      },
      plotOptions: {
        area: {
          ...state.plotOptions?.area,
          ...options.plotOptions?.area,
        },
      },
      series: [
        {
          ...state.series[0],
          data: data,
        },
      ],
      colors: [...state.colors],
      loading: {
        buffer: true,
        flag: !state.loading.flag,
      },
    });
  }, [props]);

  useEffect(() => {
    if (mounted) {
      setState((prev) => ({
        ...prev,
        loading: {
          flag: state.loading.flag,
          buffer: false,
        },
      }));
    }
    mounted = true;
  }, [state.loading.flag]);

  return (
    <>
      {props.data === undefined ||
      props.loading ||
      state.loading.buffer === true ? (
        <ChartContainer>
          <Loading />
        </ChartContainer>
      ) : state.series[0].data && state.series[0].data.length ? (
        <HighchartsReact
          containerProps={{ style: { height: "100%" } }}
          highcharts={Highcharts}
          options={state}
        />
      ) : (
        <ChartContainer>
          <img src={EmptyLine} alt="Empty Data" />
        </ChartContainer>
      )}
    </>
  );
};

AreaChart2.defaultProps = {
  options: {},
};
export default React.memo(AreaChart2);

import React, { useState, useEffect } from 'react';

import premiumstyle from '../premium.module.css';

import { API_BASE_URL } from '../../../../../constants/apiConstants';
import axios from 'axios';

import { dataWarningSA } from '../../../../../utils/tools'

import { useFormik } from 'formik';
import * as Yup from 'yup';

//import Swal from 'sweetalert2'
//import withReactContent from 'sweetalert2-react-content'

//const MySwal = withReactContent(Swal);

import MySwal from '../../../../../utils/MySwal'

function Poi(props) {
  const [initialValues, setInitialValues] = useState({
    embed_url: '',
    username: '',
    password: ''
  })

  const [is_password, setIsPassword] = useState(true)

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      embed_url: Yup.string()
        .required('URL harus diisi'),
      username: Yup.string(),
      password: Yup.string()
    }),
    onSubmit: values => {
      const data = {
        title: 'Simpan data',
        text: 'Apakah anda ingin menyimpan data ini?'
      }

      MySwal.fire(dataWarningSA(data))
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            let formData = {
              embed_url: values.embed_url,
              username: values.username,
              password: values.password,
              id_usecase: props.idUC, 
            }

            axios.post(`${API_BASE_URL}poi/update`, formData)
              .then(({ data: { data } }) => {
                MySwal.fire('Berhasil', data.message, 'success')
              })
              .catch((error) => {});
          }
        })
    },
  })

  const deletePassword = () => {
    formik.setFieldValue('password', '')
  }

  useEffect(() => {
    axios.get(`${API_BASE_URL}poi/detail/${props.idUC}`)
      .then(({ data: { data } }) => {
        setInitialValues({
          embed_url: data.embed_url,
          username: data.username,
          password: data.password
        })
      })
      .catch(() => false)
  }, [props])

  return (
    <>
      <div className={premiumstyle.box}>
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            {/*{JSON.stringify(formik)}*/}
            <div className="col-12 col-lg-4">
              <label className={`${premiumstyle.text2} ${premiumstyle.color2}`}>
                URL <font color="red">*</font>
              </label>

              <input
                data-testid="inputUrl"
                type="text"
                name="embed_url"
                id="embed_url"
                className={`form-control ${formik.touched.embed_url && formik.errors.embed_url ? 'is-invalid' : null}`}
                placeholder="Masukan URL Anda"
                value={formik.values.embed_url}
                onChange={formik.handleChange}
                {...formik.getFieldProps('embed_url')}
              />

              {formik.touched.embed_url && formik.errors.embed_url && <>
                <div className="invalid-feedback">
                  {formik.errors.embed_url}
                </div>
              </>}
            </div>
            <div className="col-12 col-lg-4">
              <label className={`${premiumstyle.text2} ${premiumstyle.color2}`}>
                Username
              </label>

              <input
                data-testid="inputUsername"
                type="text"
                name="username"
                id="username"
                className={`form-control ${formik.touched.username && formik.errors.username ? 'is-invalid' : null}`}
                placeholder="Masukan URL Anda"
                value={formik.values.username}
                onChange={formik.handleChange}
                {...formik.getFieldProps('username')}
              />

              {formik.touched.username && formik.errors.username && <>
                <div className="invalid-feedback">
                  {formik.errors.username}
                </div>
              </>}
            </div>
            <div className="col-12 col-lg-4">
              <label className={`${premiumstyle.text2} ${premiumstyle.color2}`}>
                Password 
              </label>

              <div className={`input-group ${formik.touched.password && formik.errors.password ? 'is-invalid' : null}`}>
                <input
                  data-testid="inputPassword"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  type={is_password === true ? 'password' : 'text'} name="password"
                  id="password"
                  placeholder="Masukkan password"
                  className={`form-control ${premiumstyle.inputGroup} ${formik.touched.password && formik.errors.password ? `is-invalid ${premiumstyle.isInvalid}` : ''}`}
                  {...formik.getFieldProps('password')}></input>

                <div className={`input-group-append ${premiumstyle.inputAppend}`}>
                  <span className="input-group-text">
                    {formik.values.password.length > 0 && <>
                      <i data-testid="deletePassword" onClick={deletePassword} className={`fas stack-position fa-times`}></i>
                    </>}
                    <i data-testid="showPassword" onClick={() => setIsPassword(!is_password)} className={`fas stack-position ${is_password === true ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                  </span>
                </div>
              </div>

              {formik.touched.password && formik.errors.password && <>
                <div className="invalid-feedback">
                  {formik.errors.password}
                </div>
              </>}
            </div>
          </div>

          <div className={`row ${premiumstyle.marginTop20}`}>
            <div className="col-12 text-right">
              <button
                className={`btn btn-dark btnMar ${!formik.isValid ? 'disabled' : ''}`}
                type={formik.isValid ? 'submit' : 'button'}
              //onClick={postData}
              >Simpan</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Poi;
import styled from "styled-components";

const ContainerHeader = styled.div`
    .content {          
      ${({ location }) =>
        (location === "/region-profile" ||
          location === "/region-profile/" ||
          location === "/point-of-interest" ||
          location === "/point-of-interest/") &&
        `
        height: 100%;
      `}
    }
    .custom-control-input:checked~.custom-control-label::before {
      color: #fff;
      border-color:  ${(props) => props.primary};
      background-color: ${(props) => props.primary};
      cursor:pointer;
    }
    .btn-logout:hover a {
      text-decoration: none;
      color: ${(props) => props.sidemenucolor};
    }
    .btn-logout:hover .lg path {
      fill:${(props) => props.sidemenucolor}
    }
    .sideMenu{
      background:${(props) => props.sidemenucolor}
    }
    .title-usecase{
      color:${(props) => props.tertiary};
      text-align: center;
    }
    li.menu:hover {
      background-color: ${(props) => props.tertiary};
      color:${(props) => props.sidemenucolor};
      opacity: 0.7;
      border-radius: 4px;
    }
    li.actives {
      background-color:${(props) => props.tertiary};
      color:${(props) => props.sidemenucolor};
      border-radius: 5px;
    }
    .nav-link.active{
      border-top-color:${(props) => {
        if (props.sidemenucolor === undefined) {
          return "#373737";
        } else {
          return props.sidemenucolor;
        }
      }} !important; 
      color:${(props) => {
        if (props.sidemenucolor === undefined) {
          return "#373737";
        } else {
          return props.sidemenucolor;
        }
      }} !important; 
    }
    .nav-link2.nav-link.active{
      background-color:${(props) => {
        if (props.sidemenucolor === undefined) {
          return "#373737";
        } else {
          return props.sidemenucolor;
        }
      }} !important; 
      color: #fff !important; 
    }

    .nav-pdrb.active{
      border-top-color:${(props) => {
        if (props.primary === undefined) {
          return "#373737";
        } else {
          return props.primary;
        }
      }} !important; 
      color:${(props) => {
        if (props.primary === undefined) {
          return "#373737";
        } else {
          return props.primary;
        }
      }} !important; 
    }
    .nav-pdrb2.nav-pdrb.active{
      background-color:${(props) => {
        if (props.primary === undefined) {
          return "#373737";
        } else {
          return props.primary;
        }
      }} !important; 
      color: #fff !important; 
    }


    button.pdrb:hover{
      color:${(props) => {
        if (props.primary === undefined) {
          return "#373737";
        } else {
          return props.primary;
        }
      }} !important; 
    }

    .nav-pills:hover a {
      color:${(props) => {
        if (props.primary === undefined) {
          return "#373737";
        } else {
          return props.primary;
        }
      }} !important; 
    }


    a:hover{
      color:${(props) => {
        if (props.sidemenucolor === undefined) {
          return "#373737";
        } else {
          return props.sidemenucolor;
        }
      }} !important; 
    }

    .linkRouter  {
      color:${(props) => props.tertiary};
    }
    @media (min-width: 1367px) {
      .sideMenu {
        ${({ hide }) =>
          hide &&
          `
          width: 6.3%;
          transition: 0.5s linear;
          overflow: hidden;
        `}
      }
      .menu-name{
        ${({ hide }) =>
          hide &&
          `
          display:none;
        `}
      }
      .content {
        ${({ hide }) =>
          hide &&
          `
          width: 93%;
          margin-left: 7%;
          transition: 0.5s linear;
        `}
      }
      .only-logo-web{
        ${({ hide }) =>
          hide &&
          `
          display:inline-block;
        `}
        width: auto;
        height: 48px;
      }
      .logo-w-t{
        ${({ hide }) =>
          hide &&
          `
          display:none;
        `}
        transition: display 5s;
      }
      .usecaselogo{
        ${({ hide }) =>
          hide &&
          `
          width: auto;
          height: 52px;
          margin-bottom:30px;
        
      `}
      }
      .title-usecase{
        ${({ hide }) =>
          hide &&
          `
        display:none;
      `}
      }
    
      .footer {
        ${({ hide }) =>
          hide &&
          `
          width: 95%;
          margin-left: 6.5%;
          overflow: hidden;
        `}
      }
    }
    
    @media (max-width: 1366px) and (min-width: 1300px){
      .sideMenu {
        ${({ hide }) =>
          hide &&
          `
          width: 7%;
          transition: 0.5s linear;
          overflow: hidden;
        `}
      }
      
      .menu-name{
        ${({ hide }) =>
          hide &&
          `
          display:none;
        `}
      }
      .content {
        ${({ hide }) =>
          hide &&
          `
          width: 83%;
          margin-left: 10%;
          transition: 0.5s linear;
        `}
      }
      .only-logo-web{
        ${({ hide }) =>
          hide &&
          `
          display:inline-block;
        `}
        width: auto;
        height: 48px;
      }
      .logo-w-t{
        ${({ hide }) =>
          hide &&
          `
          display:none;
        `}
        transition: display 5s;
      }
      .usecaselogo{
        ${({ hide }) =>
          hide &&
          `
          width: auto;
          height: 52px;
          margin-bottom:30px;
        
      `}
      }
      .title-usecase{
        ${({ hide }) =>
          hide &&
          `
        display:none;
      `}
      }
      .wlogout{
        ${({ wlog }) =>
          wlog &&
          `
        height: 180px;
        margin-top:68px;
        margin-bottom:40px;
        transition: height  0.3s linear;
      `}
      }
      .panel-wl{
        ${({ wlog }) =>
          wlog &&
          `
          opacity: 1;
          margin-top:40px;
          visibility:visible;
          transition: visibility 0s, opacity 2.5s linear;
        `}
      }
      .footer {
        ${({ hide }) =>
          hide &&
          `
          width: 93%;
          margin-left: 7%;
          overflow: hidden;
        `}
      }
    }
  
    }
    @media (max-width: 1300px) and (min-width: 481px) {
      .sideMenu {
        z-index: 5;         
        ${({ burger }) =>
          burger &&
          `
          width: 250px;
          box-shadow:0 0 0 10000px rgba(0,0,0,.50);
        `}
        transition: 0.5s linear;
      }
      .rightmenu{
        z-index: 5;         
        ${({ rmenu }) =>
          rmenu &&
          `
          width: 250px;
          box-shadow:0 0 0 10000px rgba(0,0,0,.50);
        `}
        overflow:hidden;
        height:100%
        transition: 0.5s linear;
      }
      .only-logo-web{
        display:none;
      }
    
    }
    @media (max-width: 480px) {
      /* custom mobile */

        .sideMenu {
          z-index: 5;         
          ${({ burger }) =>
            burger &&
            `
            width: 100%;
            box-shadow:0 0 0 10000px rgba(0,0,0,.50);
          `}
          transition: 0.5s linear;
        }
        .rightmenu{
          z-index: 5;         
          ${({ rmenu }) =>
            rmenu &&
            `
            width: 100%;
            box-shadow:0 0 0 10000px rgba(0,0,0,.50);
          `}
          transition: 0.5s linear;
        }
        

      /* custom mobile */
    }`;

export default ContainerHeader;

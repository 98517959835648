import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import history from "./utils/history";
import Cookies from "universal-cookie";

import * as serviceWorker from "./serviceWorker";
import {
  BrowserRouter,
  //useHistory
} from "react-router-dom";

import axios from "axios";
import { ACCESS_TOKEN_NAME, API_BASE_URL } from "./constants/apiConstants";

import "animate.css";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const cookies = new Cookies();

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

axios.interceptors.request.use((config) => {
  config.headers.common["Authorization"] = cookies.get("login_access_token");
  return config;
});

//let history = useHistory();

axios.interceptors.response.use(
  (res) => {
    //console.log('Response Interceptor', res)
    //console.log('history', history);
    return res;
  },
  (error) => {
    //console.log('intercept', error.response);
    if (error.response && error.response.status === 401) {
      //console.log('history', history);
      // document.cookie = `${ACCESS_TOKEN_NAME}=;expires=Thu, 01 Jan 1970 00:00:00 GMT";`;
      cookies.remove("login_access_token");
      history.push("/login");
      window.location.reload();
      return error;
    }
    // else if (error.response && error.response.status === 400) {
    //   MySwal.fire({
    //     icon: 'warning',
    //     iconColor: 'orange',
    //     title: 'Peringatan!',
    //     text: error.response.data.message,
    //     confirmButtonText: 'Oke',
    //     confirmButtonColor: '#DC4233'
    //   })
    //   return new Promise((resolve, reject) => {
    //     reject(error)
    //   })
    // }
    else if (error.response && error.response.status === 403) {
      axios
        .post(`${API_BASE_URL}user/logout`)
        .then(() => {
          // document.cookie = `${ACCESS_TOKEN_NAME}=;expires=Thu, 01 Jan 1970 00:00:00 GMT";`;
          cookies.remove("login_access_token");
          history.push("/login");
          window.location.reload();
        })
        .catch((error) => console.console.log(error));
      return error;
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

ReactDOM.render(
  //let history = useHistory();
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
serviceWorker.unregister();

import React, { useState, useEffect } from "react";
import { BackgroundShadow, Satuan } from '../../../PotensiDaerah/SDA/Utils';

import { NumberWithDot, NumberFormat } from '../../../../Material/FormatNumber';
import esStyle from '../../../PotensiDaerah/SDA/index.module.css';
import AreaChart2 from "../../../../Charts/AreaChart2";

const Chart = (props) => {
  const {data, jenis, kota, pasar}       = props;
  const [dataChart, setDataChart] = useState({data: [], categories: [], options: {}});

  useEffect(() => {
    setDataChart({
      data: data.widget_data.chart_data,
      categories: data.widget_data.chart_categories,
      options: {
        chart: {
          height: '120px',
          events: {
            load() {
              this.series.forEach(series => {
                if (typeof series.options.marker.fillColorFun === 'function') {
                  series.points.forEach(point => {
                    point.update({
                      color: point.series.options.marker.fillColorFun(point.y, point.series.dataMin, point.series.dataMax)
                    }, false);
                  });
                }
              });

              this.redraw();
            }
          }
        },
        // colors:[props.color],
        tooltip: {
          formatter: function () {
            return `<div style="border-color:${this.color}">
                <table>
                  <tr>
                    <td>${kota} </td>
                  </tr>
                  <tr>
                    <td> <strong>${this.x} </strong> </td>
                  </tr>
                  <tr>
                    <td><strong>${pasar} </strong></td>
                  </tr>
                  <tr>
                    <td>Harga ${jenis}: <strong>Rp ${NumberWithDot(this.y, 2)} </strong></td>
                  </tr>
                </table>
              </div>`;
          },
          shared: true,
          useHTML: true,
        },
        xAxis: {
          min: 0,
          max: 4,
          scrollbar: {
            enabled: false,
          },
          title: {
            text: null,
          },
          labels: {
            enabled: false
          },
        },
        yAxis: {
          type: 'logarithmic',
          // tickInterval: 1,
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            formatter: function () {
              return NumberFormat(this.value, 1);
            },
          },
        },
        plotOptions: {
          area: {
            keys: ['x', 'y', 'marker', 'tooltipData'],
            marker: {
              enabled: true,
              fillColorFun: (pointData, dataMin, dataMax) => {
                let color;
                dataMin === dataMax
                ? color = "#3782ab"
                :
                  pointData >= dataMax
                  ? color = '#e83e1f'
                  : color = '#31934c'
                return color;
              }
            }
          }
        }
      }
    })
  },[data, jenis, kota, pasar])



  return (
    <div className={esStyle.leafletContainer}>
        <AreaChart2
          key="bar-top-luas-panen"
          data={dataChart.data}
          categories={dataChart.categories}
          options={dataChart.options}
          tooltip={dataChart.options.tooltip}
          />
        {/* <div className="position-relative" style={{height:'400px'}}><BackgroundShadow content="" background="#c0c2c6" /></div> */}
    </div>
  )
}

export default Chart
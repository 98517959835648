import React, { useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { reactLocalStorage } from "reactjs-localstorage";
import EmptyColumn from "../../../src/assets/img/EmptyColumn.svg";
import { NumberWithDot } from "../Material/FormatNumber";
import Loading from "../../utils/Loading";
import Values from "values.js";

let mounted = false;

const getBaseColors = () => {
  const baseColor = reactLocalStorage.getObject("base_color");
  const listColors = new Values(baseColor?.color1).shades(30);
  let pieColors = [];
  for (var i = 0; i < listColors.length; i++) {
    pieColors.push(listColors[i].hexString());
  }
  return pieColors;
};

const baseColor = reactLocalStorage.getObject("base_color");
const listColors = new Values(baseColor?.color1).shades(30);
let chartOptions = {
  chart: {},
  title: {
    text: "",
  },
  subtitle: {
    // text: 'Source: WorldClimate.com'
  },
  colors: [reactLocalStorage.getObject("base_color")?.color1],
  legend: {
    enabled: true,
  },
  xAxis: {
    categories: [],
    crosshair: true,
    max: 9,
    scrollbar: {
      enabled: true,
    },
  },
  yAxis: [
    {
      title: {
        text: false,
      },
      labels: {
        formatter: function () {
          return NumberWithDot(this.value, 3);
        },
      },
    },
    {
      title: {
        text: false,
      },
      labels: {
        formatter: function () {
          return NumberWithDot("m");
        },
      },
      opposite: true,
    },
  ],
  tooltip: {
    formatter: function () {
      return `<div style="border-color:${this.color}">
          <table>
            <tr>
              <td> <span>${this.x}: </span> </td>
              <td> <span><strong>${NumberWithDot(
                this.y,
                3
              )}</strong></span></td>
            </tr>
          </table>
        </div>`;
    },
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        rotation: -45,
        y: -17.5,
        x: 12.5,
        allowOverlap: true,
        crop: false,
        overflow: "allow",
        formatter: function () {
          return `<span style="font-style: normal; font-weight: normal; font-family: DM Sans, sans-serif; font-size: 12px;">${NumberWithDot(
            this.y,
            3
          )}</span>`;
        },
      },
    },
  },
  series: [
    {
      name: "",
      data: [],
    },
  ],
  loading: {
    buffer: true,
    flag: true,
  },
  credits: false,
};

const ChartContainer = ({ children }) => (
  <>
    <div className="position-relative">
      <div className="flex-center mt-3 mb-5">{children}</div>
    </div>
  </>
);

const ColumnChart = (props) => {
  const {
    height,
    data,
    dataColumn2,
    dataSpline,
    categories,
    options,
    tooltip,
    optionsSpline,
    color_column,
    color_column2,
    color_spline,
    legend_column,
    legend_column2,
    legend_spline,
    radius,
    scroll
  } = props;
  const [state, setState] = useState(chartOptions);

  let customTooltip = tooltip
    ? tooltip
    : {
        formatter: function () {
          return `<div style="border-color:${this.color}">
          <table>
            <tr>
              <td> <span>${this.x}: </span> </td>
              <td> <span><strong>${NumberWithDot(
                this.y,
                2
              )}</strong></span></td>
            </tr>
          </table>
        </div>`;
        },
        shared: true,
        useHTML: true,
      };

  useEffect(() => {
    if (typeof data === "undefined") {
      return;
    }
    let max =
      options.xAxis && options.xAxis.max
        ? options.xAxis.max
        : chartOptions.xAxis.max;
    let enabled =
      options.xAxis &&
      options.xAxis.scrollbar &&
      options.xAxis.scrollbar.enabled
        ? options.xAxis.scrollbar.enabled
        : chartOptions.xAxis.scrollbar.enabled;
    setState({
      ...state,
      ...options,
      chart: {
        ...state.chart,
        ...options.chart,
        height: height?height:null
      },
      xAxis: {
        ...state.xAxis,
        ...options.xAxis,
        max: data && data.length - 1 >= max ? max : data.length - 1,
        scrollbar: {
          enabled: scroll? false :  data && data.length - 1 >= max ? enabled : false,
        },
        categories: categories,
      },
      yAxis: [
        {
          ...state.yAxis,
          ...options.yAxis,
          labels: {
            ...state.yAxis?.labels,
            ...options.yAxis?.labels,
          },
          max: Math.max(...data) * 1.1,
        },
        {
          ...state.yAxis,
          ...optionsSpline.yAxis,
          labels: {
            ...state.yAxis?.labels,
            ...optionsSpline.yAxis?.labels,
          },
          max: Math.max(...dataSpline) * 1.1,
          opposite: true,
        },
      ],
      plotOptions: {
        column: {
          ...state.plotOptions.column,
          ...options.plotOptions?.column,
          dataLabels: {
            ...state.plotOptions.column.dataLabels,
            ...options.plotOptions?.column?.dataLabels,
            rotation:
              data.length > 5 && data.some((d) => Math.abs(d) > 1.0e5)
                ? -45
                : 0,
            y:
              data.length > 5 && data.some((d) => Math.abs(d) > 1.0e5)
                ? -22.5
                : 0,
            x:
              data.length > 5 && data.some((d) => Math.abs(d) > 1.0e5)
                ? 12.5
                : 0,
          },
        },
      },
      series: [
        {
          ...state.series[0],
          name: legend_column,
          type: "column",
          data: data,
        },
        {
          ...state.series[0],
          name: legend_column2,
          type: "column",
          data: dataColumn2,
        },
        {
          ...state.series[0],
          name: legend_spline,
          type: "spline",
          data: dataSpline,
          yAxis: 1,
          marker: {
            enabled: false,
          },
        },
      ],
      tooltip: customTooltip,
      colors: [color_column, color_column2, color_spline],
      loading: {
        buffer: true,
        flag: !state.loading.flag,
      },
    });
  }, [props]);

  useEffect(() => {
    if (mounted) {
      setState((prev) => ({
        ...prev,
        loading: {
          flag: state.loading.flag,
          buffer: false,
        },
      }));
    }
    mounted = true;
  }, [state.loading.flag]);

  return (
    <>
      {props.data === undefined ||
      props.loading ||
      state.loading.buffer === true ? (
        <ChartContainer>
          <Loading />
        </ChartContainer>
      ) : state.series[0].data.length ? (
        <HighchartsReact highcharts={Highcharts} options={state} />
      ) : (
        <ChartContainer>
          <img src={EmptyColumn} alt="Empty Data" />
        </ChartContainer>
      )}
    </>
  );
};

ColumnChart.defaultProps = {
  options: {},
};
export default React.memo(ColumnChart);

import React, {useState, useEffect} from 'react'
import style from './index.module.css';
import Datatable from '../../../../Datatable';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../constants/apiConstants';

const TableElement = (props) => {
  const [pageTitle, setPageTitle] = useState('');
  const [tableProps, setTableProps] = useState({});

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}sector/elemen/table?id_sektor=${props.data?.id_sektor}&tahun=${props.filter.tahun || false}&element=${props.filter.element || false}`)
      .then(({ data: { data } }) => {
        setTableProps(data?.widget_data);
        setPageTitle(data?.widget_title);
      })
      .catch(() => {
        setTableProps({});
        setPageTitle('');
      });
  }, [props.data, props.filter.tahun]);

  return (
    (Object.keys(tableProps).length && (pageTitle !== '')) ?
    <div>
      <Datatable 
        {...tableProps} 
        serverside={true} 
        params={`&id_sektor=${props.data?.id_sektor}&tahun=${props.filter.tahun || false}&element=${props.filter.element || false}`}
      />
    </div>
    : <div></div>
  )
}

export default TableElement
import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {reactLocalStorage} from 'reactjs-localstorage';
import EmptyColumn from '../../../src/assets/img/EmptyColumn.svg';
import {NumberWithDot} from '../Material/FormatNumber';
import Loading from '../../utils/Loading';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

// if (typeof Highcharts === 'object') {
//   HighchartsExporting(Highcharts)
//   HighchartsExportData(Highcharts)
// }

require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);



let mounted = false;
let chartOptions = {
  chart: {
    type: 'column',
  },
  title: {
      text: ''
  },
  exporting: {
    enabled: false,
  },
  subtitle: {
      // text: 'Source: WorldClimate.com'
  },
  colors: [reactLocalStorage.getObject('base_color')?.color1],
  legend: {
    enabled: false,
  },
  xAxis: {
    categories: [],
    crosshair: true,
    max: 9,
    scrollbar: {
      enabled: true
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: false,
    },
    labels: {
      formatter: function () {
        return NumberWithDot(this.value, 3);
      },
    },
  },
  tooltip: {
    formatter: function () {
      return (
        `<div style="border-color:${this.color}">
          <table>
            <tr>
              <td> <span>${this.x}: </span> </td>
              <td> <span><strong>${NumberWithDot(this.y, 3)}</strong></span></td>
            </tr>
          </table>
        </div>`
      );
    },
    shared: true,
    useHTML: true,
  },
  plotOptions: {
      column: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            rotation: -45,
            y: -17.5,
            x: 12.5,
            allowOverlap: true,
            crop: false,
            overflow: 'allow',
            formatter: function() {
              return (`<span style="font-style: normal; font-weight: normal; font-family: DM Sans, sans-serif; font-size: 12px;">${NumberWithDot(this.y, 3)}</span>`)
            }
          }
      }
  },
  series: [{
      name: '',
      data: []
  }],
  loading: {
    buffer: true,
    flag: true,
  },
  credits: false,
}

const ChartContainer = ({children}) => (
  <>
    <div className="position-relative">
      <div className="flex-center mt-3 mb-5">
        {children}
      </div>
    </div>
  </>
)

const ColumnChart = (props) => {
  const { data, categories, options, radius, tooltip, isHeight, isShimmer } = props;
  const [state, setState] = useState(chartOptions);

  let customTooltip = tooltip ? tooltip : {
    formatter: function () {
      return (
        `<div style="border-color:${this.color}">
          <table>
            <tr>
              <td> <span>${this.x}: </span> </td>
              <td> <span><strong>${NumberWithDot(this.y, 2)}</strong></span></td>
            </tr>
          </table>
        </div>`
      );
    },
    shared: true,
    useHTML: true,
  };

  useEffect(() => {
    if (typeof data === 'undefined') { return; }
    let max = (options.xAxis && options.xAxis.max) ? options.xAxis.max : chartOptions.xAxis.max;
    let enabled = ((options.xAxis && options.xAxis.scrollbar && options.xAxis.scrollbar.enabled) ? options.xAxis.scrollbar.enabled : chartOptions.xAxis.scrollbar.enabled);
    setState({
      ...state,
      ...options,
      chart: {
        ...state.chart,
        ...options.chart,
         height: isHeight ? 400 : null
      },
      exporting: {
        ...state.exporting,
        ...options.exporting,
      },
      xAxis: {
        ...state.xAxis,
        ...options.xAxis,
        max: (data && data.length-1 >= max) ? max : data.length-1,
        scrollbar: {
          enabled: (data && data.length-1 >= max) ? enabled : false,
        },
        categories: categories,
      },
      yAxis: {
        ...state.yAxis,
        ...options.yAxis,
        labels: {
          ...state.yAxis?.labels,
          ...options.yAxis?.labels,
        },
        max: Math.max(...data) * 1.1,
      },
      plotOptions: {
        column: {
          ...state.plotOptions.column,
          ...options.plotOptions?.column,
          dataLabels: {
            ...state.plotOptions.column.dataLabels,
            ...options.plotOptions?.column?.dataLabels,
            rotation: (data.length > 5 && data.some((d) => String(d)?.length > 5)) ? -45 : 0,
            y: (data.length > 5 && data.some((d) => String(d)?.length > 5)) ? -22.5 : 0,
            x: (data.length > 5 && data.some((d) => String(d)?.length > 5)) ? 12.5 : 0,
          }
        }
      },
      tooltip:{
        ...(tooltip ? customTooltip : {
          ...state.tooltip,
          ...options.tooltip,
        })
      },
      series: [{
        ...state.series[0],
        data: data,
        borderRadius: radius ? radius : 0
      }],
      colors: [reactLocalStorage.getObject('base_color')?.color1],
      loading: {
        buffer: true,
        flag: !state.loading.flag
      }
    });
  }, [props])

  useEffect(() => {
    if (mounted) {
      setState((prev) => ({
        ...prev,
        loading: {
          flag: state.loading.flag,
          buffer: false,
        }
      }))
    }
    mounted = true;
  }, [state.loading.flag])

  return (
    <>
      {
        (props.data === undefined || props.loading || state.loading.buffer === true) ?
        (isShimmer?  ( <div style={{height:'400px', background:'#c0c2c6'}}></div>) :
          <ChartContainer>
            <Loading />
          </ChartContainer>)
        : (state.series[0].data.length) ?
          <HighchartsReact highcharts={Highcharts} options={state} />
        :
          <ChartContainer>
            <img src={EmptyColumn} alt="Empty Data" />
          </ChartContainer>
      }
    </>
  )
}

ColumnChart.defaultProps = {
  options: {},
};
export default React.memo(ColumnChart)

import React, { useRef, useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import PieChart from '../../../Charts/PieChart';
import style from './Index.module.css';
import YearPicker from '../../../../utils/YearPicker';
import { API_GET_DEMOGRAFI_GENDER } from '../../../../constants/apiConstants';
import { NumberWithDot } from '../../../Material/FormatNumber';
import Loading from '../../../../utils/Loading';
import {reactLocalStorage} from 'reactjs-localstorage';

const PieGender = (props) => {
  const { color3 } = reactLocalStorage.getObject('base_color');
  const [stateYearPieGender, setStateYearPieGender] = useState(new Date());
  const [yearWData, setYearWData] = useState([]);
  const [statusYearPieGender, setStatusYearPieGender] = useState(false);
  const [statePieGender, setStatePieGender] = useState({
    data: undefined,
    year: null,
    men: 0,
    women: 0,
    total: 0,
    sourceData: '',
    options: {},
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${API_GET_DEMOGRAFI_GENDER}/year`)
      .then(({ data: {data}, status }) => {
        if (status === 200) {
          setStateYearPieGender(new Date(data.selected_years));
          setYearWData(data.years)
          setStatusYearPieGender(true);
        }
      })
      .catch(() => {})
  }, []);

  /**
   * Demografi Gender on Change
   */
  useEffect(() => {
    setLoading(true);

    if (statusYearPieGender === true) {
      axios
        .get(
          `${API_GET_DEMOGRAFI_GENDER}?year=${stateYearPieGender.getFullYear()}`
        )
        .then(({ data, status }) => {
          if (status === 200) {
            setStatePieGender((prevState) => ({
              ...prevState,
              status: data.status,
              data: data.chart_data,
              men: data.total_pria,
              women: data.total_wanita,
              total: data.total,
              sourceData: data.sumber_data,
              year: data.year,
              options: {
                tooltip: {
                  formatter: function () {
                    return `<div style="border-color:${this.color}">
                        <div>${stateYearPieGender.getFullYear()}</div>
                        <table>
                          <tr>
                            <td> <span>${this.key}: </span> </td>
                            <td> <span><strong>${NumberWithDot(
                              this.y, 2
                            )} jiwa (${NumberWithDot(
                      this.percentage,
                      2
                    )}%)</strong></span></td>
                          </tr>
                        </table>
                      </div>`;
                  },
                  shared: true,
                  useHTML: true,
                },
                legend: {},
                plotOptions: {
                  pie: {
                    colors: props.color,
                  },
                },
              },
            }));
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [stateYearPieGender, statusYearPieGender]);

  return (
    <>
      <p className="h5">Gender</p>
      <div className="row">
        <div data-testid="datepicker-gender" className="col-12 text-right">
          {statePieGender.status === 'Blank' ? (
            <></>
          ) : (
            <YearPicker 
              className="mb-3"
              yearWithData={yearWData}
              selected={stateYearPieGender}
              onChange={(date) => setStateYearPieGender(date)}
            />
            )}
        </div>
      </div>
      <div className="row">
        <div
          className={`${
            statePieGender.status === 'Empty'
              ? 'col-md-12 col-lg-12'
              : 'col-lg-7 col-md-7'
          } col-sm-12`}
        >
          <div className="chart pie-gender">
            <PieChart
              key="demo-pie-gender"
              data={statePieGender.data}
              options={statePieGender.options}
            />
          </div>
        </div>
        {statePieGender.status === 'Empty' ? (
          null
        ) : (
          <div className="col-lg-5 col-md-5 col-sm-12">
            <div className={style.genderCard} style={props.color && {background:props.color[0]}}>
              { 
                loading === true ? 
                <div className="text-center position-relative">
                  <Loading />
                </div>
                :
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 p-0 m-auto text-center">
                    <svg width="17" height="39" viewBox="0 0 17 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.3197 3.84064C12.3197 5.96096 10.565 7.67972 8.40038 7.67972C6.23417 7.67972 4.47949 5.96096 4.47949 3.84064C4.47949 1.71876 6.23417 0 8.40038 0C10.565 0 12.3197 1.71876 12.3197 3.84064Z" fill={color3} />
                      <path d="M14.1995 11.5199C13.6284 9.76055 11.9535 8.56055 10.0743 8.56055H6.80739C4.92829 8.56055 3.255 9.76055 2.68216 11.5199L0.150666 18.9995C-0.134867 19.8792 0.354845 20.8401 1.25291 21.1589C2.15097 21.4386 3.13201 20.9589 3.45739 20.0792L5.04933 15.32L4.68404 20.7996L2.51942 35.72C2.35671 36.7997 3.13195 37.7997 4.23419 37.9606C4.31555 37.9606 4.43837 38.0013 4.51973 38.0013C5.49913 38.0013 6.39883 37.2809 6.52003 36.2809L8.35764 23.5217L10.1953 36.2809C10.358 37.2809 11.2161 38.0013 12.1956 38.0013C12.2769 38.0013 12.3997 38.0013 12.4811 37.9606C13.5833 37.8013 14.3602 36.8013 14.1959 35.72L12.0312 20.7996L11.6644 15.2404L13.2563 19.9996C13.5019 20.7199 14.1958 21.1996 14.9312 21.1996C15.0939 21.1996 15.2981 21.159 15.4624 21.1199C16.3605 20.8402 16.8917 19.8793 16.5646 18.9605L14.1995 11.5199Z" fill={color3} />
                    </svg>
                  </div>
                  <div
                    className={`col-lg-8 col-md-8 col-sm-12 p-0 ${style.genderCardValC}`}
                    style={{color: color3}}
                  >
                    <p>Pria</p>
                    <p
                      data-testid="card-gender-pria"
                      className={style.genderCardVal}
                    >
                      {NumberWithDot(statePieGender.men)} Jiwa
                    </p>
                  </div>
                </div>
              }
            </div>
            <div className={style.genderCard} style={props.color && {background:props.color[0]}}>
              { 
                loading === true ? 
                <div className="text-center position-relative">
                  <Loading />
                </div>
                :
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 p-0 m-auto text-center">
                    <svg width="19" height="38" viewBox="0 0 19 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.2262 4.12029C13.2262 6.19685 11.5083 7.87965 9.38833 7.87965C7.26838 7.87965 5.54883 6.19685 5.54883 4.12029C5.54883 2.04373 7.26838 0.359375 9.38833 0.359375C11.5083 0.359375 13.2262 2.04373 13.2262 4.12029Z" fill={color3} />
                      <path d="M14.7783 11.1211C14.0845 9.7211 12.6137 8.80078 11.0218 8.80078H7.7549C6.16296 8.80078 4.65073 9.7211 3.99833 11.1211L0.240172 18.8007C-0.168186 19.6413 0.198699 20.6413 1.05689 21.0413C1.91509 21.4413 2.93599 21.082 3.34435 20.2413L5.75301 15.2821V17.6821C5.18196 19.8009 4.16106 22.8009 2.73181 24.8805C2.20062 25.6805 2.52763 26.8009 3.46718 27.1212C3.83407 27.2415 4.2839 27.3618 4.7736 27.4415L3.62987 35.4415C3.46717 36.5212 4.24241 37.4821 5.30479 37.6415H5.59033C6.56973 37.6415 7.42794 36.9212 7.55077 35.9618L8.73436 27.721H9.34689H9.95943L11.143 35.9618C11.2658 36.9212 12.1224 37.6415 13.1035 37.6415H13.389C14.4912 37.4821 15.2266 36.4821 15.0639 35.4415L13.9202 27.4415C14.4099 27.3618 14.8597 27.2415 15.2266 27.1212C16.1662 26.8009 16.533 25.6805 15.962 24.8805C14.5327 22.8009 13.5118 19.8009 12.9408 17.6805V15.2805L15.3494 20.2397C15.635 20.8397 16.2475 21.1991 16.9015 21.1991C17.1472 21.1991 17.3912 21.1585 17.6369 21.0397C18.4951 20.6397 18.862 19.6397 18.4536 18.7991L14.7783 11.1211Z" fill={color3} />
                    </svg>
                  </div>
                  <div
                    className={`col-lg-8 col-md-8 col-sm-12 p-0 ${style.genderCardValC}`}
                    style={{color: color3}}
                  >
                    <p>Wanita</p>
                    <p
                      data-testid="card-gender-wanita"
                      className={style.genderCardVal}
                    >
                      {NumberWithDot(statePieGender.women)} Jiwa
                    </p>
                  </div>
                </div>
              }
            </div>
            <div className={style.genderCard} style={props.color && {backgroundColor:props.color[0]}}>
              { 
                loading === true ? 
                <div className="text-center position-relative">
                  <Loading />
                </div>
                :
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 p-0 m-auto text-center">
                    <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M27.8342 19.406V23.914L21.7139 17.7936C24.4201 13.4592 23.8904 7.6856 20.1201 3.9172C17.9248 1.72188 15.0529 0.625 12.1793 0.625V5.3986C13.8308 5.3986 15.484 6.02984 16.7449 7.2908C19.2683 9.81424 19.2683 13.8988 16.7449 16.4236C14.2214 18.947 10.1369 18.947 7.61209 16.4236C6.34961 15.1611 5.71989 13.5095 5.71989 11.858H0.946289C0.946289 14.7314 2.04317 17.6048 4.23849 19.7988C5.82129 21.3816 7.75725 22.3941 9.79329 22.8347V26.7363H6.31985V31.5083H9.79329V35.3739H14.5653V31.5083H18.0387V26.7379H14.5653V22.8363C15.8559 22.5566 17.1059 22.0457 18.2528 21.3066L18.3481 21.2754L24.4105 27.3378L19.9041 27.3362V30.8628H31.3605V27.3362L31.359 19.4066L27.8342 19.406Z" fill={color3} />
                    </svg>
                  </div>
                  <div
                    className={`col-lg-8 col-md-8 col-sm-12 p-0 ${style.genderCardValC}`}
                    style={{color: color3}}
                  >
                    <p>Total Pria dan Wanita</p>
                    <p
                      data-testid="card-gender-total"
                      className={style.genderCardVal}
                    >
                      {NumberWithDot(statePieGender.total)} Jiwa
                    </p>
                  </div>
                </div>
              }
            </div>
          </div>
        )}
      </div>
      <p
        className={`${style.sourceData} ${
          statePieGender.status === 'Empty' || statePieGender.status === 'Blank'
            ? 'text-center'
            : 'text-left'
        }`}
      >
        {statePieGender.status === 'Empty'
          ? 'Tidak ada data untuk ditampilkan'
          : statePieGender.status === 'Blank'
          ? 'Data tidak tersedia'
          : `Sumber Data: ${statePieGender.sourceData}`}
      </p>
    </>
  );
};

export default PieGender;

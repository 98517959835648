import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {reactLocalStorage} from 'reactjs-localstorage';
import EmptyLine from '../../../src/assets/img/EmptyLine.svg';
import {NumberWithDot} from '../Material/FormatNumber';
import Loading from '../../utils/Loading';

let mounted = false;

let chartOptions = {
  chart: {
    type: 'line'
  },
  title: {
    text: ''
  },
  exporting: {
    enabled: false,
  },
  yAxis: {
    title: {
        text: false,
    },
    labels: {
      formatter: function () {
        return NumberWithDot(this.value, 2);
      },
    },
  },
  colors: [reactLocalStorage.getObject('base_color')?.color1],
  tooltip: {
    formatter: function () {
      return (
        `<div style="border-color:${this.color}">
          <table>
            <tr>
              <td> <span>${this.x}: </span> </td>
              <td> <span><strong>${NumberWithDot(this.y, 2)}</strong></span></td>
            </tr>
          </table>
        </div>`
      );
    },
    shared: true,
    useHTML: true,
  },
  legend: {
    enabled: false,
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle'
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: false,
        // rotation: 45,
        y: -7.5,
        allowOverlap: true,
        crop: false,
        overflow: 'none',
        formatter: function() {
          return (`<span style="font-style: normal; font-weight: normal; font-family: DM Sans, sans-serif; font-size: 12px;">${NumberWithDot(this.y, 2)}</span>`)
        }
      }
    }
  },
  series: [{
      name: '',
      data: [],
  },],
  loading: {
    buffer: true,
    flag: true,
  },
  credits: false,
  status: false,
}

const ChartContainer = ({children}) => (
  <>
    <div className="position-relative">
      <div className="flex-center mt-3 mb-5"> 
        {children}
      </div>
    </div>
  </>
)

const LineChart = (props) => {
  const {data, categories, options} = props;
  const [state, setState] = useState(chartOptions);
  useEffect(() => {
    setState({
      ...state,
      ...options,
      xAxis: {
        ...state.xAxis,
        ...options.xAxis,
        categories: categories,
      },
      yAxis: {
        ...state.yAxis,
        ...options.yAxis,
        labels: {
          ...state.yAxis?.labels,
          ...options.yAxis?.labels,
        },
        max: (data.length > 3 && data.some((d) => Math.abs(d) > 1.0e5)) ? Math.max(...data) * 1.05 : undefined,
      },
      exporting: {
        ...state.exporting,
        ...options.exporting,
      },
      plotOptions: {
        line: {
          ...state.plotOptions?.line,
          ...options.plotOptions?.line,
          dataLabels: {
            ...state.plotOptions.line.dataLabels,
            ...options.plotOptions?.line?.dataLabels,
            rotation: (data.length > 3 && data.some((d) => Math.abs(d) > 1.0e5)) ? -45 : 0,
            y: (data.length > 3 && data.some((d) => Math.abs(d) > 1.0e5)) ? -40 : 0,
            x: (data.length > 3 && data.some((d) => Math.abs(d) > 1.0e5)) ? 20 : 0,
          }
        }
      },
      series: [{
        ...state.series[0],
        data: data,
      }],
      colors: [reactLocalStorage.getObject('base_color')?.color1],
      loading: {
        buffer: true,
        flag: !state.loading.flag
      },
    });
  }, [props])

  useEffect(() => {
    if (mounted) {
      setState((prev) => ({
        ...prev,
        loading: {
          flag: state.loading.flag,
          buffer: false,
        }
      }))
    }
    mounted = true;
  }, [state.loading.flag])
  return (
    <>
      {
        (props.data === undefined || props.loading || state.loading.buffer === true) ?
          <ChartContainer>
            <Loading />
          </ChartContainer>
        : (state.series[0].data.length) ?
          <HighchartsReact highcharts={Highcharts} options={state} />
        :
          <ChartContainer>
            <img src={EmptyLine} alt="Empty Data" />
          </ChartContainer>
      }
    </>
  )
}

LineChart.defaultProps = {
  options: {},
};
export default React.memo(LineChart)

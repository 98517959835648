import React, { useEffect, useState } from 'react';
import {
  Link
} from 'react-router-dom'
import Cookies from 'universal-cookie';
import axios from 'axios';

import HeaderGlobal from '../Layouts/HeaderGlobal'
import SektorEmpty from '../../assets/img/sektorempty.png'
import NotFoundStyle from './NotFound.module.css'
import { API_BASE_URL } from '../../constants/apiConstants'

const cookies = new Cookies();

const Index = () => {
  const [readyBtn, setReadyBtn] = useState(false);
  const [baseColor, setBaseColor] = useState('#DC4233');

  useEffect(() => {
    setReadyBtn(false);
    if (cookies.get('login_access_token') !== undefined) {
      axios.get(`${API_BASE_URL}user/userInfo`)
        .then(({ data: { data } }) => {
          if (data.usecase !== undefined) {
            setBaseColor(data.usecase.base_color1)
            setReadyBtn(true);
          } else {
            setBaseColor('#DC4233')
            setReadyBtn(true);
          }
        })
        .catch((err) => { })
    } else {
      setBaseColor('#DC4233')
      setReadyBtn(true);
    }
  }, [])

  return (
    <HeaderGlobal>
      <div className="container">
        <div className="row">
          <div className={`col-12 text-center ${NotFoundStyle.marginTop30vh}`}>
            <img className="imgMenu" src={SektorEmpty} alt="sektor kosong" />
            <p className={`${NotFoundStyle.color} ${NotFoundStyle.text}`}>Halaman tidak ditemukan</p>
            <p className={`${NotFoundStyle.color2} ${NotFoundStyle.text2}`}>Tidak perlu khawatir kami akan pandu Anda</p>
            {readyBtn && <>
              <Link
                style={{
                  backgroundColor: `${baseColor}`
                }}
                className={`btn btn-danger ${NotFoundStyle.btn}`}
                to="/">Ke Laman Utama</Link>
            </>}
          </div>
        </div>
      </div>
    </HeaderGlobal>
  )
}

export default Index;
import React from 'react'
import { reactLocalStorage } from 'reactjs-localstorage';

const DinamisMapZoom = () => {
    const ZoomLevel = reactLocalStorage.getObject("zoom");
    const level_zoom = ZoomLevel.zoom_map;
    
    return level_zoom
}

export default DinamisMapZoom;
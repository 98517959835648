import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import CustomAlert from "../../../../Material/CustomAlert";

const Kesehatan = (props) => {
  const [baseUrlAdmedika, setBaseUrlAdmedika] = useState(
    "https://dbd.admedika.co.id/trusted/"
  );
  const [urlSampul, setUrlSampul] = useState("");
  const [urlProduk, setUrlProduk] = useState("");
  const [urlLayanan, setUrlLayanan] = useState("");
  const [urlSebaranPenyakit, setUrlSebaranPenyakit] = useState("");

  const [alert, setAlert] = useState({
    type: "success",
    message: "",
    show: false,
  });
  const [flagSubmit, setFlagSubmit] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}usecase/getTokenAdmedika?id_usecase=${props.idUC}`)
      .then(({ data: { data } }) => {
        setBaseUrlAdmedika(
          data.token_admedika === null
            ? "https://dbd.admedika.co.id/trusted/"
            : data.token_admedika
        );
        setUrlProduk(data.token_produk === null ? "" : data.token_produk);
        setUrlSampul(data.token_sampul === null ? "" : data.token_sampul);
        setUrlLayanan(data.token_layanan === null ? "" : data.token_layanan);
        setUrlSebaranPenyakit(
          data.token_sebaran_penyakit === null
            ? ""
            : data.token_sebaran_penyakit
        );
      })
      .catch(() => {});
  }, [props.idUC, flagSubmit]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append(`id_usecase`, props.idUC);
    formData.append(`token_admedika`, baseUrlAdmedika);
    formData.append(`token_produk`, urlProduk);
    formData.append(`token_sampul`, urlSampul);
    formData.append(`token_layanan`, urlLayanan);
    formData.append(`token_sebaran_penyakit`, urlSebaranPenyakit);
    axios
      .post(`${API_BASE_URL}usecase/setTokenAdmedika`, formData)
      .then(({ data: { data } }) => {
        setFlagSubmit((prev) => !prev);
        setAlert((prev) => ({
          ...prev,
          type: "success",
          message: data.message,
          show: true,
        }));
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            show: false,
          }));
        }, 5000);
      })
      .catch(() => {
        setFlagSubmit((prev) => !prev);
        setAlert((prev) => ({
          ...prev,
          type: "danger",
          message: "token gagal disimpan",
          show: true,
        }));
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            show: false,
          }));
        }, 5000);
      });
  };

  return (
    <>
      {alert.show && <CustomAlert type="success" message={alert.message} />}
      <div className="row">
        <div className="col-12">
          <div className="row mb-3">
            <div className="col-12">
              <label className="textMenu2 labelUC">
                Base Url<span className="redText">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Masukan Url Sampul"
                value={baseUrlAdmedika}
                onChange={(e) => setBaseUrlAdmedika(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <label className="textMenu2 labelUC">
                Url Sampul<span className="redText">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Masukan Url Sampul"
                value={urlSampul}
                onChange={(e) => setUrlSampul(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label className="textMenu2 labelUC">
                Url Produk<span className="redText">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Masukan Url Produk"
                value={urlProduk}
                onChange={(e) => setUrlProduk(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label className="textMenu2 labelUC">
                Url Layanan<span className="redText">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Masukan Url Layanan"
                value={urlLayanan}
                onChange={(e) => setUrlLayanan(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label className="textMenu2 labelUC">
                Url Sebaran Penyakit<span className="redText">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Masukan Url Sebaran Penyakit"
                value={urlSebaranPenyakit}
                onChange={(e) => setUrlSebaranPenyakit(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <button
              type="button"
              className="btn btn-dark btnMenu ml-auto mx-3 mt-3"
              onClick={handleSubmit}
              data-testid="btn-submit"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Kesehatan;

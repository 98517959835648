import React from 'react'
import css from './CustomAlert.module.css';

const CustomAlert = (props) => {
  const {type, page, message, topPosition} = props;

  let innerText = '', style = {};
  if (message) {
    innerText = message;
  } else {
    innerText = page + ' berhasil ' + ((type === 'success') ? 'disimpan' : (type === 'danger') ? 'dihapus' : (type === 'warning') ? 'diubah' : '')
  }

  if (topPosition) {
    style = { top: topPosition }
  }
  function createMarkup() {
    return {__html: innerText};
  }
  return (
    <div className={`alert-success-custom alert alert-${type} ${css['alert'+type]}`} data-testid="custom-alert" style={style }>
      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  )
}


CustomAlert.defaultProps = {
  page: 'Data',
  type: 'success'
};
export default CustomAlert

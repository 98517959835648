import React, {useState, createContext} from 'react';

function AppContext(){
  const GlobalContext = createContext();
  const {Provider} = GlobalContext;

  const GlobalProvider = (props) => {
    const [globalState, setGlobalState] = useState({
      detailUsecase: [],
      profile: [],
      id_role:2
    });

    const [dashboardLoading, setDashboardLoading] = useState(true);

    const data = {globalState, setGlobalState, dashboardLoading, setDashboardLoading}
    return(
      <Provider value={data}>
        { props.children }
      </Provider>
    )
  }


  return {
    GlobalContext,
    GlobalProvider
  }
}

export default AppContext();
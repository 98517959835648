import React, { useState, useEffect } from "react";
import AreaChart2 from "../../../../Charts/AreaChart2";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import { NumberWithDot, NumberFormat } from "../../../../Material/FormatNumber";
import Datatable from "../../../../Datatable";
import esStyle from "../index.module.css";
import { reactLocalStorage } from "reactjs-localstorage";
import { BackgroundShadow } from "../Utils";
import Loading from "../../../../../utils/Loading";
import styled from "styled-components";
const baseColor = reactLocalStorage.getObject("base_color");

const TrendProduksi = (props) => {
  const {
    indikatorTitle,
    indikator,
    jenis,
    startYear,
    endYear,
    dataIndikatorCheck,
  } = props;
  const [dataChart, setDataChart] = useState({
    data: [],
    categories: [],
    options: {},
  });
  const [word, setWord] = useState("");
  const [stateDataTable, setStateDataTable] = useState({
    tableData: {},
    title: "",
  });
  const [load, setLoad] = useState(false);

  function hexToRgb(hex) {
    // Convert hex to RGB values
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    const rgbaColor = `rgba(${r}, ${g}, ${b}, 0.7)`;
    return rgbaColor;
  }
  const ButtonHighlight = styled.div`
    color: ${(props) => props.fontColor} !important;
    text-decoration: none;
    transition: color 0.2s ease-in-out !important;

    &&:hover {
      color: ${(props) => hexToRgb(props.fontColor)} !important;
      cursor: pointer;
    }
  `;

  useEffect(() => {
    // if (
    //   !dataIndikatorCheck ||
    //   typeof jenis === "undefined" ||
    //   typeof startYear === "undefined" ||
    //   typeof endYear === "undefined"
    // )
    //   return;

    // setLoad(false);

    axios
      .get(
        `${API_BASE_URL}poda/sda/perikanan/area-chart?&indikator=${indikator}&jenis=${jenis}&periode=${startYear}-${endYear}`
      )
      .then((data) => {
        setLoad(true);
        data = data.data;
        setDataChart({
          data: data.widget_data.chart_series[0].data,
          categories: data.widget_data.chart_categories,
          options: {
            colors: [props.color],
            tooltip: {
              formatter: function () {
                return `<div style="border-color:${this.color}">
                    <span>${this.x} </span><br />
                    <span>
                      ${
                        jenis.indexOf("ton") !== -1
                          ? "Produksi"
                          : jenis.indexOf("juta") !== -1
                          ? "Nilai Produksi"
                          : "Produksi"
                      } :
                        <strong> 
                          ${NumberWithDot(this.y, 2)}
                          <!--<i class="fas fa-arrow-up text-success"></i>-->
                        </strong>
                      </span>
                  </div>`;
              },
              shared: true,
              useHTML: true,
            },
            xAxis: {
              title: {
                text: "Tahun",
              },
            },
            yAxis: {
              title: {
                text: `${
                  jenis.indexOf("ton") !== -1
                    ? "Produksi (ton)"
                    : jenis.indexOf("juta") !== -1
                    ? "Nominal (Rupiah)"
                    : ""
                }`,
              },
              labels: {
                formatter: function () {
                  return NumberFormat(this.value, 1);
                },
              },
            },
          },
        });
      })
      .catch(() => {
        setLoad(true);
      });
  }, [jenis, startYear, endYear, dataIndikatorCheck]);

  useEffect(() => {
    if (
      !dataIndikatorCheck ||
      typeof jenis === "undefined" ||
      typeof startYear === "undefined" ||
      typeof endYear === "undefined"
    ) {
      setWord("Tidak ada data");
      return;
    }
    axios
      .get(
        `${API_BASE_URL}poda/sda/perikanan/detail?&indikator=${indikator}&jenis=${jenis}&periode=${startYear}-${endYear}`
      )
      .then(({ data: { data } }) => {
        setWord(data.sumber_data);
        setStateDataTable({
          tableData: {
            ...data.widget_data,
            base_endpoint: `${API_BASE_URL}poda/sda/perikanan/detail?&indikator=${indikator}&jenis=${jenis}&periode=${startYear}-${endYear}`,
            right: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          },
          title: data.title,
          sumber_data: data.sumber_data,
          catatan: data.catatan,
        });
      })
      .catch(() => {
        setStateDataTable((prev) => ({
          ...prev,
          tableData: {},
          title: "",
        }));
      });
  }, [jenis, startYear, endYear, dataIndikatorCheck]);

  return (
    <>
      <div
        className={`position-relative ${
          !Object.keys(dataChart.data).length
            ? "d-flex justify-content-center align-items-center"
            : ""
        }`}
        style={{ height: "300px" }}
      >
        {load ? (
          <>
          <div className="col">
          <div className="row">
          <AreaChart2
            key="bar-top-luas-panen"
            data={dataChart.data}
            categories={dataChart.categories}
            options={dataChart.options}
            loading={!load}
          />
          </div>
          <div className="row">
          <p
          className={`${esStyle.nodata} ${
            word === "Tidak ada data"
              ? "text-center sumber-data"
              : "text-left d-none"
          }`}
        >
          {word === "Tidak ada data"
            ? "Not available for this version"
            : ``}
        </p></div></div>
        </>
        ) : (
          <Loading />
          // <BackgroundShadow content="" background="#c0c2c6" />
        )}
      </div>

      <div>
        <span className={esStyle.tooltipContainer}>
          <i
            className={`${esStyle.icon} ${esStyle.cursorDefault} fas fa-info-circle`}
          ></i>
          <span className={`ml-1 ${esStyle.sumberData}`}>Sumber Data</span>
          <div className={`${esStyle.tooltipHover}`}>
            <p className="m-0">{word}</p>
          </div>
        </span>

        {/* <button
          className="btn btn-link btn-sm float-right"
          type="button"
          data-toggle="modal"
          data-target="#detailTabulasiPerikanan"
          style={{ color: baseColor.color1 }}
        >
          Lihat Detail &nbsp;
          <i className="fa fa-long-arrow-alt-right"></i>
        </button> */}
        <div className="link-info float-right">
          <ButtonHighlight
            fontColor={reactLocalStorage.getObject("base_color")?.color4}
          >
            <p
              className="link-info-tag"
              data-toggle="modal"
              data-target="#detailTabulasiPerikanan"
            >
              Lihat Detail <i className="fa fa-long-arrow-alt-right"></i>
            </p>
          </ButtonHighlight>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="detailTabulasiPerikanan"
        aria-labelledby="detailTabulasiPerikanan"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg isi-modal modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-8 mt-2">
                  <strong>
                    <p>
                      {stateDataTable.title}, {jenis}
                    </p>
                  </strong>
                </div>
                <div className="col-4">
                  <div className="text-right">
                    <button
                      type="button"
                      // style={{ color: local.color1 }}
                      className="btn button-ghost"
                      aria-label="Close"
                      data-dismiss="modal"
                    >
                      <p className="close">X</p>
                    </button>
                  </div>
                </div>
              </div>
              {word === "Tidak ada data" ? (
                <center className="mb-5">
                  Tidak ada data untuk ditampilkan
                </center>
              ) : (
                <Datatable serverside={false} {...stateDataTable.tableData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrendProduksi;

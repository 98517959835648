import React, { useState, useEffect } from 'react';
import Index from './ChildSektor/Index';
import Create from './ChildSektor/Create';
import Edit from './ChildSektor/Edit';
import Empty from './ChildSektor/Empty';

const Sektor = (props) => {
  const [page, setPage] = useState('index');
  const [idEdit, setIdEdit] = useState('');

  const handleChange = (page) => {
    setPage(page);
  }

  const handleIdPage = (id) => {
    setIdEdit(id);
    setPage('edit');
  };

  return (
    <>
      {page === 'index' ? <Index pageEdit={handleIdPage} changePage={handleChange} idUC={props.idUC}></Index> : 
      page === 'create' ? <Create idUC={props.idUC} changePage={handleChange}></Create> :
      page === 'edit' ? <Edit idUC={props.idUC} idEdit={idEdit} changePage={handleChange}></Edit> : 
      page === 'empty' ? <Empty idUC={props.idUC} changePage={handleChange}></Empty> : ''}
    </>
  );
}

export default Sektor;
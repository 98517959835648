import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../../App.css";

import {
  API_INSERT_USECASE,
  API_GET_PROPINSI,
  API_GET_KOTA_ALL,
  API_GET_KOTA_PROPINSI,
} from "../../../../constants/apiConstants";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { SketchPicker, BlockPicker } from "react-color";
import Tippy from "@tippyjs/react";

import imgdefault from "../../../../assets/img/img-default.png";

import ReactDOM from "react-dom";
import { useHistory, withRouter } from "react-router-dom";

import { dataWarningSA } from "../../../../utils/tools";
import { useFormik } from "formik";
import * as Yup from "yup";
import MySwal from "../../../../utils/MySwal";

function Create() {
  const history = useHistory();
  const [propinsi, setPropinsi] = useState("");
  // const [kota, setKota] = useState('');
  const [base_color1, setColor1] = useState("#6b2222");
  const [base_color2, setColor2] = useState("#4f1414");
  const [base_color3, setColor3] = useState("#4f1414");
  const [base_color4, setColor4] = useState("#6b2222");
  const [response, setResponse] = useState([]);
  const [respKota, setResponseKota] = useState([]);
  const [iku, setIku] = useState(0);
  const [ikuChart, setIkuChart] = useState(0);
  const [flagPoda, setFlagPoda] = useState(1);

  const deff = {
    default: {
      input: {
        display: "none",
      },
      hash: {
        display: "none",
      },
    },
  };

  useEffect(() => {
    Request("GET", `${API_GET_PROPINSI}`, []);
  }, []);

  const Request = (method, EP, payload) => {
    if (method === "GET") {
      axios
        .get(`${EP}`)
        .then(({ data: { data } }) => {
          setResponse(data);
        })
        .catch(function (error) {});
    }
  };

  const formik = useFormik({
    initialValues: {
      provinsi: propinsi,
      kota: "",
      name_usecase: "",
      // tokenBigBox: '',
    },
    validationSchema: Yup.object().shape({
      provinsi: Yup.string().required("Provinsi harus dipilih"),
      kota: Yup.string(),
      name_usecase: Yup.string().required("Nama Usecase harus diisi"),
      // tokenBigBox: Yup.string()
      //   .required('Token Harus diisi')
    }),
    onSubmit: (values) => {
      const msg = {
        title: "Simpan data",
        html: "Apakah anda ingin menyimpan data ini?",
      };
      MySwal.fire(dataWarningSA(msg)).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const payload = {
            kode_provinsi: values.provinsi,
            kode_kab_kota: values.kota,
            name_usecase: values.name_usecase,
            // "token_bigbox": values.tokenBigBox,
            base_color1: base_color1,
            base_color2: base_color2,
            base_color3: base_color3,
            base_color4: base_color4,
            iku: Number(iku),
            iku_chart: Number(ikuChart),
            flag_poda: flagPoda,
          };

          axios
            .post(`${API_INSERT_USECASE}`, payload)
            .then(({ data: { data } }) => {
              formik.resetForm({ provinsi: "", kota: "", name_usecase: "" });
              setColor1("#6b2222");
              setColor3("//#region 4f1414");
              setColor2("#4f1414");
              setColor4("#6b2222");
              setIku(0);
              ikuChart(0);
              setFlagPoda(1);
              MySwal.fire("Berhasil", data.message, "success");
            })
            .catch((error) => {});
        }
      });
    },
  });

  const handleOnChange = (event) => {
    if (
      event._targetInst.elementType === "select" &&
      event._targetInst.stateNode.name === "provinsi"
    ) {
      if (event.target.value !== "") {
        axios
          .get(`${API_GET_KOTA_PROPINSI}${event.target.value}`)
          .then(({ data: { data } }) => {
            setResponseKota(data);
          })
          .catch(function (error) {});
      }
    }
  };

  const back = () => {
    history.push("/account-management/cases");
  };

  console.log('isi flag', flagPoda)
  return (
    <div className="boxContainer">
      <div data-testid="title" className="box-page-title">
        Add Use Case
      </div>
      <div className="box-frame">
        <form
          onSubmit={formik.handleSubmit}
          onChange={handleOnChange}
          data-testid="form-submit"
        >
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="box-field-input">
                <div className="title-field-input">
                  Propinsi <span>*</span>
                </div>
                <div className="box-selected">
                  <select
                    data-testid="select-province"
                    type="text"
                    id="kode_provinsi"
                    name="kode_provinsi"
                    placeholder="Propinsi ..."
                    onChange={formik.handleChange}
                    value={formik.values.provinsi}
                    className={`text-field-input form-control ${
                      formik.touched.provinsi && formik.errors.provinsi
                        ? "is-invalid"
                        : null
                    }`}
                    {...formik.getFieldProps("provinsi")}
                  >
                    <option value="">Pilih Propinsi</option>
                    {response.map((data, index) => (
                      <option value={data.kode_provinsi} key={index}>
                        {data.nama_provinsi}
                      </option>
                    ))}
                  </select>

                  {formik.touched.provinsi && formik.errors.provinsi && (
                    <>
                      <div className="invalid-feedback">
                        {formik.errors.provinsi}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="box-field-input">
                <div className="title-field-input">Kabupaten / Kota</div>
                <div className="box-selected">
                  <select
                    data-testid="select-district"
                    type="text"
                    id="kode_kab_kota"
                    name="kode_kab_kota"
                    placeholder="Kabupaten / Kota ..."
                    onChange={formik.handleChange}
                    value={formik.values.kota}
                    className={`text-field-input form-control ${
                      formik.touched.kota && formik.errors.kota
                        ? "is-invalid"
                        : null
                    }`}
                    {...formik.getFieldProps("kota")}
                  >
                    <option value="">Pilih Kota</option>
                    {respKota.map((data, index) => (
                      <option value={data.kode_kab_kota} key={index}>
                        {data.nama_kab_kota}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="box-field-input">
                <div className="title-field-input">
                  Name Use Case <span>*</span>
                </div>
                <input
                  data-testid="input-usecase"
                  type="text"
                  id="name_usecase"
                  name="name_usecase"
                  placeholder="Nama Use Case ..."
                  onChange={formik.handleChange}
                  value={formik.values.name_usecase}
                  className={`text-field-input form-control ${
                    formik.touched.name_usecase && formik.errors.name_usecase
                      ? "is-invalid"
                      : null
                  }`}
                  {...formik.getFieldProps("name_usecase")}
                ></input>

                {formik.touched.name_usecase && formik.errors.name_usecase && (
                  <>
                    <div className="invalid-feedback">
                      {formik.errors.name_usecase}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            {/* <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="box-field-input">
                <div className="title-field-input">
                  Token Analytics <span>*</span>
                </div>
                <input
                  data-testid="input-token"
                  type="text"
                  id="token_bigbox"
                  name="token_bigbox"
                  onChange={formik.handleChange}
                  value={formik.values.tokenBigBox}
                  placeholder="Masukan Token"
                  className={`text-field-input form-control ${formik.touched.tokenBigBox && formik.errors.tokenBigBox ? 'is-invalid' : null}`}
                  {...formik.getFieldProps('tokenBigBox')}></input>

                {formik.touched.tokenBigBox && formik.errors.tokenBigBox && <>
                  <div className="invalid-feedback">
                    {formik.errors.tokenBigBox}
                  </div>
                </>}
              </div>
            </div> */}
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="box-field-input">
                <div className="title-field-input">BigBox IKU</div>
                <input
                  data-testid="iku"
                  type="number"
                  value={iku}
                  placeholder=""
                  className="text-field-input form-control"
                  onChange={(e) => setIku(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="box-field-input">
                <div className="title-field-input">BigBox IKU Chart</div>
                <input
                  data-testid="iku_chart"
                  type="number"
                  value={ikuChart}
                  placeholder=""
                  className="text-field-input form-control"
                  onChange={(e) => setIkuChart(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="box-field-input">
                <div className="title-field-input">
                  Flag PODA <span>*</span>
                </div>
                <div className="box-selected">
                  <select
                    data-testid="select-poda"
                    type="text"
                    id="flag_poda"
                    name="flag_poda"
                    placeholder="Flag PODA"
                    value={flagPoda}
                    className={`text-field-input form-control`}
                    onChange={(e) => setFlagPoda(e.target.value)}
                  >
                    <option value={1}>Show</option>
                    <option value={2}>Hide</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="box-field-input">
                <div className="title-field-input">
                  Color Schema <span>*</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2 col-lg-2">
              <div className="box-field-input">
                <Tippy
                  interactive={true}
                  placement={"bottom-start"}
                  content={
                    <div className="mdl bubble-bottom-left">
                      <span>
                        Warna ini untuk dibeberapa area seperti pada text link,
                        menu sidebar aktif (text & icon), button, background
                        sidebar, gradasi banner
                      </span>
                    </div>
                  }
                >
                  <div
                    className="title-field-input aks"
                    style={{ color: "#818E9B" }}
                  >
                    Primary <i className={`far fa-question-circle`}></i>{" "}
                  </div>
                </Tippy>
              </div>
            </div>
            <div className="col-sm-10 col-md-10 col-lg-10">
              <div className="box-field-input">
                <div
                  className="box-color inline-block color-picker-border"
                  style={{ backgroundColor: base_color4 }}
                ></div>
                <Tippy
                  interactive={true}
                  placement={"right"}
                  content={
                    <SketchPicker
                      data-testid="color-4"
                      triangle="hide"
                      color={base_color4}
                      onChange={(color) => setColor4(color.hex)}
                    />
                  }
                >
                  <button className="box-color-action inline-block">
                    Select Color
                  </button>
                </Tippy>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 col-lg-2">
              <div className="box-field-input">
                <Tippy
                  interactive={true}
                  placement={"bottom-start"}
                  content={
                    <div className="mdl bubble-bottom-left">
                      <span>Warna ini untuk semua char</span>
                    </div>
                  }
                >
                  <div
                    className="title-field-input aks"
                    style={{ color: "#818E9B" }}
                  >
                    Chart <i className={`far fa-question-circle`}></i>{" "}
                  </div>
                </Tippy>
              </div>
            </div>
            <div className="col-sm-10 col-md-10 col-lg-10">
              <div className="box-field-input">
                <div
                  className="box-color inline-block color-picker-border"
                  style={{ backgroundColor: base_color1 }}
                ></div>
                <Tippy
                  interactive={true}
                  placement={"right"}
                  content={
                    <SketchPicker
                      data-testid="color-1"
                      triangle="hide"
                      color={base_color1}
                      onChange={(color) => setColor1(color.hex)}
                    />
                  }
                >
                  <button className="box-color-action inline-block">
                    Select Color
                  </button>
                </Tippy>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 col-lg-2">
              <div className="box-field-input">
                <Tippy
                  interactive={true}
                  placement={"bottom-start"}
                  content={
                    <div className="mdl bubble-bottom-left">
                      <span>
                        Warna ini untuk ditempatkan pada garis dibawah setiap
                        judul halaman
                      </span>
                    </div>
                  }
                >
                  <div
                    className="title-field-input aks"
                    style={{ color: "#818E9B" }}
                  >
                    Secondary <i className={`far fa-question-circle`}></i>{" "}
                  </div>
                </Tippy>
              </div>
            </div>
            <div className="col-sm-10 col-md-10 col-lg-10">
              <div className="box-field-input">
                <div
                  className="box-color inline-block color-picker-border"
                  style={{ backgroundColor: base_color2 }}
                ></div>
                <Tippy
                  interactive={true}
                  placement={"right"}
                  content={
                    <SketchPicker
                      data-testid="color-2"
                      triangle="hide"
                      color={base_color2}
                      onChange={(color) => setColor2(color.hex)}
                    />
                  }
                >
                  <button className="box-color-action inline-block">
                    Select Color
                  </button>
                </Tippy>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 col-lg-2">
              <div className="box-field-input">
                <Tippy
                  interactive={true}
                  placement={"bottom-start"}
                  content={
                    <div className="mdl bubble-bottom-left">
                      <span>
                        Warna ini untuk dibeberapa area seperti pada text
                        banner, menu sidebar aktif (background), element
                        background
                      </span>
                    </div>
                  }
                >
                  <div
                    className="title-field-input aks"
                    style={{ color: "#818E9B" }}
                  >
                    Text <i className={`far fa-question-circle`}></i>{" "}
                  </div>
                </Tippy>
              </div>
            </div>
            <div className="col-sm-10 col-md-10 col-lg-10">
              <div className="box-field-input">
                <div
                  className="box-color inline-block color-picker-border"
                  style={{ backgroundColor: base_color3 }}
                ></div>
                <Tippy
                  interactive={true}
                  placement={"right"}
                  content={
                    <BlockPicker
                      data-testid="color-3"
                      triangle="hide"
                      styles={deff}
                      colors={["#fcfafa", "#373737"]}
                      color={base_color3}
                      onChange={(color) => setColor3(color.hex)}
                    />
                  }
                >
                  <button className="box-color-action inline-block">
                    Select Color
                  </button>
                </Tippy>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 col-md-6 col-lg-4"></div>
            <div className="col-sm-4 col-md-6 col-lg-4"></div>
            <div className="col-sm-4 col-md-6 col-lg-4">
              <button
                data-testid="button-back"
                className="btn btn-outline-dark btnMenu"
                onClick={back}
              >
                Kembali
              </button>
              <button
                data-testid="button-save"
                className="btn btn-dark"
                type="submit"
              >
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default withRouter(Create);

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ColumnChart from '../../../Charts/ColumnChart';
import style from './Index.module.css';
import {
  API_GET_DEMOGRAFI_AGE,
} from '../../../../constants/apiConstants';
import { NumberWithDot, NumberFormat } from '../../../Material/FormatNumber';
import YearPicker from '../../../../utils/YearPicker';

const ColumnAge = (props) => {
  const [stateYearColumnAge, setStateYearColumnAge] = useState(new Date());
  const [statusYearColumnAge, setStatusYearColumnAge] = useState(false);
  const [yearWData, setYearWData] = useState([]);
  const [stateColumnAge, setStateColumnAge] = useState({
    data: undefined,
    categories: [],
    sourceData: '',
    status: '',
    year: null,
    options: {},
  });

  useEffect(() => {
    axios
      .get(`${API_GET_DEMOGRAFI_AGE}/year`)
      .then(({ data: {data}, status }) => {
        if (status === 200) {
          setStateYearColumnAge(new Date(data.selected_years));
          setYearWData(data.years);
          setStatusYearColumnAge(true);
        }
      })
      .catch((err) => {});
  }, []);

  /**
   * Demografi Age (Kelompok Umur) on Change
   */
  useEffect(() => {
    if (statusYearColumnAge === true) {
      axios
        .get(
          `${API_GET_DEMOGRAFI_AGE}?year=${stateYearColumnAge.getFullYear()}`
        )
        .then(({ data, status }) => {
          if (status === 200) {
            setStateColumnAge((prevState) => ({
              ...prevState,
              data: data.chart_data,
              categories: data.chart_categories,
              sourceData: data.sumber_data,
              status: data.status,
              year: data.year,
              options: {
                colors:[props.color],
                xAxis: {
                  title: {
                    text: 'Usia',
                  },
                },
                yAxis: {
                  title: {
                    text: 'Jumlah Jiwa',
                  },
                  labels: {
                    formatter: function () {
                      return NumberFormat(this.value, 2);
                    },
                  },
                },
                tooltip: {
                  formatter: function () {
                    return `<div style="border-color:${this.color}">
                      <div>${stateYearColumnAge.getFullYear()}</div>
                      <table>
                        <tr>
                          <td> <span>${this.x}: </span> </td>
                          <td> <span><strong>${NumberWithDot(
                            this.y, 2
                          )} Jiwa</strong></span></td>
                        </tr>
                      </table>
                    </div>`;
                  },
                  shared: true,
                  useHTML: true,
                },
              },
            }));
          }
        })
        .catch((err) => {});
    }
  }, [stateYearColumnAge, statusYearColumnAge]);

  return (
    <>
      <p className="h5">Kelompok Usia</p>
      <div className="row">
        <div className="col-12 text-right">
          {stateColumnAge.status === 'Blank' ? (
            <></>
          ) : (
            <YearPicker 
              className="mb-3"
              yearWithData={yearWData}
              selected={stateYearColumnAge}
              onChange={(date) => setStateYearColumnAge(date)}
            />
          )}
        </div>
      </div>
      <div className="chart bar-group-age">
        <ColumnChart
          key='demo-column-group-age'
          data={stateColumnAge.data}
          categories={stateColumnAge.categories}
          options={stateColumnAge.options}
        />
      </div>
      <p
        className={`${style.sourceData} ${
          stateColumnAge.status === 'Empty' || stateColumnAge.status === 'Blank'
            ? 'text-center'
            : 'text-left'
        }`}
      >
        {stateColumnAge.status === 'Empty'
          ? 'Tidak ada data untuk ditampilkan'
          : stateColumnAge.status === 'Blank'
          ? 'Data tidak tersedia'
          : `Sumber Data: ${stateColumnAge.sourceData}`}
      </p>
    </>
  );
}

export default ColumnAge;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import SimpleDatatable from "../../../../Material/SimpleDatatable";

const NonIku = (props) => {
  const header = useRef([
    {
      name: "title",
      value: "Nama File",
      sortable: true,
    },
    {
      name: "file_type",
      value: "Type File",
      sortable: true,
    },
    {
      name: "file_link",
      value: "Link",
      sortable: true,
    },
    {
      name: "action",
      value: "Action",
      sortable: false,
    },
  ]);

  const listPerPage = useRef([5, 10, 25, 50]);
  const [item, setItem] = useState([]);
  const [search, setSearch] = useState("");
  const [per_page, setPerPage] = useState(listPerPage.current[0]);
  const [sortField, setSortField] = useState({
    column: "",
    dir: "",
  });

  const [datapaging, setDatapaging] = useState([]);
  const [loading, setLoading] = useState(false);

  const [listSector, setListSector] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");

  const [stateNameFile, setStateNameFile] = useState("");
  const [stateTypeFile, setStateTypeFile] = useState("");
  const [stateLinkFile, setStateLinkFile] = useState("");

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}sector/list-sector/${props.idUC}`)
      .then(({ data: { data } }) => {
        setListSector(data);
        setSelectedSector(data.length > 0 ? data[0].id_sektor : "");
      })
      .catch((err) => {});
  }, [props]);

  const submitNonIku = () => {
    if (selectedSector !== "") {
      let formData = new FormData();
      formData.append(`title`, stateNameFile);
      formData.append(`file_type`, stateTypeFile);
      formData.append(`file_link`, stateLinkFile);

      axios
        .post(`${API_BASE_URL}sector/data-non-iku/${selectedSector}`, formData)
        .then(({ data: { data } }) => {
          alert("Data Berhasil Disimpan");
          setStateNameFile("");
          setStateTypeFile("");
          setStateLinkFile("");

          changePage(`${API_BASE_URL}sector/data-non-iku/${selectedSector}`);
        })
        .catch((err) => {});
    } else {
      alert("Sektor Belum Dipilih");
    }
  };

  const changeSearch = (data) => {
    setSearch(data);
  };

  const changePerPage = (data) => {
    setPerPage(data);
  };

  const changeSort = (data) => {
    setSortField(data);
  };

  const changePage = (data) => {
    setLoading(true);
    axios
      .get(
        `${data}&per_page=${per_page}&order_column=${sortField.column}&order_dir=${sortField.dir}&keyword=${search}`
      )
      .then(({ data: { data, links, from } }) => {
        setItem(data);
        setDatapaging(links);
        setLoading(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setLoading(true);
    if (selectedSector !== "") {
      axios
        .get(
          `${API_BASE_URL}sector/data-non-iku/${selectedSector}?per_page=${per_page}&page=1&order_column=${sortField.column}&order_dir=${sortField.dir}&keyword=${search}`
        )
        .then(({ data: { data, links, from } }) => {
          setItem(data);
          setDatapaging(links);
          setLoading(false);
        })
        .catch((error) => {});
    } else {
      setItem([]);
      setDatapaging([]);
      setLoading(false);
    }
  }, [per_page, sortField, search, selectedSector]);

  const handleDelete = (id) => {
    axios
      .delete(`${API_BASE_URL}sector/data-non-iku/${selectedSector}?id=${id}`)
      .then(({ data: { data } }) => {
        alert("Data Berhasil Dihapus");
        changePage(`${API_BASE_URL}sector/data-non-iku/${selectedSector}`);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-6">
          <form className="form-inline">
            <label className="my-1 mr-2 textMenu2 labelUC">Pilih Sektor</label>
            <select
              name="id_menu"
              className="form-control my-1 mr-sm-2"
              value={selectedSector}
              onChange={(e) => setSelectedSector(e.target.value)}
            >
              {listSector.map((d, i) => (
                <option key={i} value={d.id_sektor}>
                  {d.nama_sektor}
                </option>
              ))}
            </select>
          </form>
        </div>
        <div className="col-6 text-right">
          <button
            className="btn btn-dark"
            data-toggle="modal"
            data-target="#modalNonIku"
            disabled={listSector.length > 0 ? false : true}
          >
            Import File
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <SimpleDatatable
            listPerPage={listPerPage.current} //useRef
            searchable={true}
            header={header} //useRef
            datapaging={datapaging}
            loading={loading}
            //props
            changeSearch={changeSearch}
            changePerPage={changePerPage}
            changeSort={changeSort}
            changePage={changePage}
          >
            <tbody id="tbodyDataUser">
              {item.map((data, index) => (
                <tr key={index}>
                  <td width="40%">{data.title}</td>
                  <td>{data.file_type}</td>
                  <td width="40%">{data.file_link}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-dark"
                      onClick={() => handleDelete(data.id)}
                    >
                      <i className="fas fa-trash"></i> Hapus
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </SimpleDatatable>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="modalNonIku"
        aria-labelledby="modalNonIku"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg isi-modal modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-8 mt-2">
                  <strong>
                    <p>Input File</p>
                  </strong>
                </div>
                <div className="col-4">
                  <div className="text-right">
                    <button
                      type="button"
                      className="btn button-ghost"
                      aria-label="Close"
                      data-dismiss="modal"
                    >
                      <p className="close">X</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="textMenu2 labelUC">
                      Nama File<span className="redText">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Masukan Nama File"
                      value={stateNameFile}
                      onChange={(e) => setStateNameFile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="textMenu2 labelUC">
                      Type File<span className="redText">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Masukan Type File"
                      value={stateTypeFile}
                      onChange={(e) => setStateTypeFile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="textMenu2 labelUC">
                      Link File<span className="redText">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Masukan Link"
                      value={stateLinkFile}
                      onChange={(e) => setStateLinkFile(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-right">
                  <button
                    type="button"
                    className="btn btn-outline-dark btnMenu"
                    aria-label="Close"
                    data-dismiss="modal"
                  >
                    Kembali
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark btnMenu"
                    aria-label="Close"
                    data-dismiss="modal"
                    onClick={submitNonIku}
                    disabled={
                      stateNameFile !== "" &&
                      stateTypeFile !== "" &&
                      stateLinkFile !== ""
                        ? false
                        : true
                    }
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NonIku;

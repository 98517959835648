import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../constants/apiConstants';
import style from './index.module.css';
import Datatable from '../../../../Datatable';

const Index = (props) => {
  const [state, setState] = useState({
    tableData: [],
    title: '',
  });
  
  useEffect(() => {
    if (!props.data || props.data.id_sektor == 0) {
      return;
    }
    axios
      .get(`${API_BASE_URL}sector/iku/kategori/${props.data.id_sektor}`)
      .then(({ data: { data, title } }) => {
        setState({
          tableData: data,
          title: title,
        });
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          title: '',
          tableData: [],
        }));        
      });
  }, [props.data]);

  return (
    state.tableData.length !== 0 && (
      <div className={style.boxDataCategory}>
        <p className={style.title}>{state.title}</p>
        {state.tableData.map?.((data, index) => (
          <div key={index} className={`${style.tableCard}`}>
            <p className={style.tableTitle} title={data.widget_title}>
              {data.widget_title}
            </p>
            {data.widget_subtitle && <p className={style.tableSubtitle}>{data.widget_subtitle}</p>}
            <Datatable {...data.widget_data} />
          </div>
        ))}
      </div>
    )
  );
};

export default React.memo(Index);

import React, { useState, useEffect } from "react";
import AreaChart2 from "../../../../Charts/AreaChart2";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import { NumberWithDot, NumberFormat } from "../../../../Material/FormatNumber";
import Datatable from "../../../../Datatable";
import esStyle from "../index.module.css";
import { reactLocalStorage } from "reactjs-localstorage";
import { BackgroundShadow } from "../Utils";
import ChartLineAndColumn2 from "../../../../Charts/ChartLineAndColumn2";
const baseColor = reactLocalStorage.getObject("base_color");

const TrendRencanaDanRealisasi = (props) => {
  const { indikatorTitle, indikator, startYear, endYear, statusDate } = props;

  const [dataChart, setDataChart] = useState({
    data: [],
    categories: [],
    options: {},
  });
  const [word, setWord] = useState("");
  const [stateDataTable, setStateDataTable] = useState({
    tableData: {},
    title: "",
  });
  const [load, setLoad] = useState(false);

  const [stateChartColumn, setStateChartColumn] = useState({});

  useEffect(() => {
    // if ( (typeof indikator === "undefined") || (typeof startYear === "undefined") || (typeof endYear === "undefined")) {
    //   return;
    // }
    // setLoad(false)
    axios
      .get(
        `${API_BASE_URL}poda/sda/pertanian/dual-axes-chart?&indikator=${indikator}&periode=${startYear}-${endYear}`
      )
      .then((data) => {
        setLoad(true);
        data = data.data;
        setStateChartColumn((prev) => ({
          ...prev,
          height: 300,
          data: data.widget_data.chart_data[0].data_column,
          data_column2: data.widget_data.chart_data[1].data_column,
          data_spline: data.widget_data.chart_data[2].data_spline,
          color_column: data.widget_data.chart_data[0].color,
          color_column2: data.widget_data.chart_data[1].color,
          color_spline: data.widget_data.chart_data[2].color,
          legend_column: data.widget_data.chart_data[0].name,
          legend_column2: data.widget_data.chart_data[1].name,
          legend_spline: data.widget_data.chart_data[2].name,
          categories: data.widget_data.chart_categories,
          tooltip: {
            formatter: function () {
              return `<div>
                  <table>
                    <tr>
                      <td> <strong><span> ${this.x} </span> </strong></td>
                    </tr>
                    <tr>
                      <td> <span>${
                        data.widget_data.chart_data[0].name
                      }: <strong>${NumberWithDot(
                this.y,
                2
              )}</strong></span></td>
                    </tr>
              
                    <tr>
                      <td> <span>${
                        data.widget_data.chart_data[1].name
                      }: <strong>${NumberWithDot(
                this.points[1].y,
                2
              )}</strong></span></td>
                    </tr>
              
                    <tr>
                      <td> <span>${
                        data.widget_data.chart_data[2].name
                      }: <strong>${NumberWithDot(
                this.points[2].y,
                2
              )}%</strong></span></td>
                    </tr>              
                  </table>
                </div>`;
            },
            borderColor: "#BCBCBC",
            borderRadius: 10,
            backgroundColor: "#fff",
            shared: true,
            useHTML: true,
          },
          options: {
            scrollbar: {
              enabled:
                data.widget_data.chart_categories.length > 5 ? true : false,
              barBackgroundColor: "#BCBCBC",
              barBorderRadius: 7,
              barBorderWidth: 0,
              rifleColor: "#BCBCBC",
              buttonBackgroundColor: "white",
              buttonBorderColor: "white",
              buttonArrowColor: "white",
              buttonBorderWidth: 0,
              buttonBorderRadius: 0,
              trackBorderWidth: 0,
              height: 8,
              width: 20,
              trackBorderRadius: 0,
              trackBorderColor: "#D2D2D2",
            },
            xAxis: {
              title: {
                text: `<p style='color: #909090;'>${data.widget_data.xAxis_title}</p>`,
              },
              max: 4,
            },
            yAxis: {
              title: {
                text: `<p style='color: #909090;'>${data.widget_data.chart_data[0].yAxis_title}</p>`,
              },
              labels: {
                formatter: function () {
                  return NumberFormat(this.value, 2);
                },
              },
              max: Math.max(data.widget_data.chart_data[0].data_column),
            },
          },
          optionsSpline: {
            scrollbar: {
              enabled: false,
            },
            yAxis: {
              title: {
                text: `<p style='color: #909090;'>${data.widget_data.chart_data[2].yAxis_title}</p>`,
              },
              labels: {
                formatter: function () {
                  return NumberFormat(this.value, 2);
                },
              },
              max: Math.max(data.widget_data.chart_data[2].data_spline),
            },
          },
        }));
      })
      .catch(() => {
        setStateChartColumn({ data: [], data_spline: [], optionsSpline: [] });
        setLoad(true);
      });
  }, [indikator, startYear, endYear]);

  useEffect(() => {
    if (
      typeof indikator === "undefined" ||
      typeof startYear === "undefined" ||
      typeof endYear === "undefined"
    ) {
      return;
    }
    axios
      .get(
        `${API_BASE_URL}poda/sda/pertanian/detail?&indikator=${indikator}&periode=${startYear}-${endYear}`
      )
      .then(({ data: { data } }) => {
        setWord(data.sumber_data);
        setStateDataTable({
          tableData: {
            ...data.widget_data,
            base_endpoint: `${API_BASE_URL}poda/sda/pertanian/detail?&indikator=${indikator}&periode=${startYear}-${endYear}`,
            right: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          },
          title: `${
            indikator === "Rencana dan Realisasi"
              ? data.title + " (Ton)"
              : data.title
          }`,
          sumber_data: data.sumber_data,
          catatan: data.catatan,
        });
      })
      .catch(() => {
        setStateDataTable((prev) => ({
          ...prev,
          tableData: {},
          title: "",
        }));
      });
  }, [indikator, startYear, endYear]);

  return (
    <>
      <div className="position-relative" style={{ height: "300px" }}>
        {load ? (
          <ChartLineAndColumn2
            height={stateChartColumn.height}
            data={stateChartColumn.data}
            dataColumn2={stateChartColumn.data_column2}
            dataSpline={stateChartColumn.data_spline}
            categories={stateChartColumn.categories}
            options={stateChartColumn.options}
            optionsSpline={stateChartColumn.optionsSpline}
            color_column={stateChartColumn.color_column}
            color_column2={stateChartColumn.color_column2}
            color_spline={stateChartColumn.color_spline}
            legend_column={stateChartColumn.legend_column}
            legend_column2={stateChartColumn.legend_column2}
            legend_spline={stateChartColumn.legend_spline}
            tooltip={stateChartColumn.tooltip}
            scroll
          />
        ) : (
          <BackgroundShadow content="" background="#c0c2c6" />
        )}
      </div>

      <div>
        <span className={esStyle.tooltipContainer}>
          <i
            className={`${esStyle.icon} ${esStyle.cursorDefault} fas fa-info-circle`}
          ></i>
          <span className={`ml-1 ${esStyle.sumberData}`}>Sumber Data</span>
          <div className={`${esStyle.tooltipHover}`}>
            <p className="m-0">{word}</p>
          </div>
        </span>

        <button
          className="btn btn-link btn-sm float-right"
          type="button"
          data-toggle="modal"
          data-target="#detailTabulasiPertanian"
          style={{ color: baseColor.color1 }}
        >
          Lihat Detail &nbsp;
          <i className="fa fa-long-arrow-alt-right"></i>
        </button>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="detailTabulasiPertanian"
        aria-labelledby="detailTabulasiPertanian"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg isi-modal modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-8 mt-2">
                  <strong>
                    <p>{stateDataTable.title}</p>
                  </strong>
                </div>
                <div className="col-4">
                  <div className="text-right">
                    <button
                      type="button"
                      // style={{ color: local.color1 }}
                      className="btn button-ghost"
                      aria-label="Close"
                      data-dismiss="modal"
                    >
                      <p className="close">X</p>
                    </button>
                  </div>
                </div>
              </div>
              <Datatable serverside={false} {...stateDataTable.tableData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrendRencanaDanRealisasi;

import React, { useState, useEffect } from 'react';
//import axios from 'axios';
import '../../../../App.css';
import '../../../../assets/css/ContentMenu/ContentMenu.css';
import { API_BASE_URL } from '../../../../constants/apiConstants';

import TabsBeranda from './Tabs/Beranda';
import TabsVisiMisi from './Tabs/VisiMisi';
import TabsSektor from './Tabs/Sektor';
import TabsAnalytics from './Tabs/Analytics';
import TabsKesehatan from './Tabs/Kesehatan';
import TabsOPD from './Tabs/SektorPrioritasOPD';
import TabsSentimenMedia from './Tabs/SentimenMedia'
import TabsNonIku from "./Tabs/NonIku";

import { withRouter } from "react-router-dom";
import axios from "axios";
//import ReactDOM from 'react-dom'

function Index(props) {
  const [useCase, setUseCase] = useState([]);
  const [idUC, setIdUC] = useState("");

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}usecase/list`)
      .then(({ data: { data } }) => {
        setIdUC(data[0].id_usecase);
        setUseCase(data);
      })
      .catch((err) => {});
  }, []);

  const handleChangeUC = (e) => {
    setIdUC(e.target.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p className="textMenu text-left">Menu</p>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group row">
            <label className="col-2 col-form-label textMenu2 labelUC">
              Use Case
            </label>
            <div className="col-4">
              <select
                id="selectUC"
                className="form-control"
                onChange={handleChangeUC}
                data-testid="select-usecase"
              >
                {useCase.map((dataUC, index) => (
                  <option key={index} value={dataUC.id_usecase}>
                    {dataUC.name_usecase}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <a
            className="nav-link active"
            id="nav-beranda-tab"
            data-toggle="tab"
            href="#nav-beranda"
            role="tab"
            aria-controls="nav-beranda"
            aria-selected="true"
          >
            Beranda
          </a>
          <a
            className="nav-link"
            id="nav-visimisi-tab"
            data-toggle="tab"
            href="#nav-visimisi"
            role="tab"
            aria-controls="nav-visimisi"
            aria-selected="false"
          >
            Visi / Misi
          </a>
          <a
            className="nav-link"
            id="nav-sektor-tab"
            data-toggle="tab"
            href="#nav-sektor"
            role="tab"
            aria-controls="nav-sektor"
            aria-selected="false"
          >
            Sektor
          </a>
          <a
            className="nav-link"
            id="nav-non-iku-tab"
            data-toggle="tab"
            href="#nav-non-iku"
            role="tab"
            aria-controls="nav-non-iku"
            aria-selected="false"
          >
            Non IKU
          </a>
          <a
            className="nav-link"
            id="nav-analytics-tab"
            data-toggle="tab"
            href="#nav-analytics"
            role="tab"
            aria-controls="nav-analytics"
            aria-selected="false"
          >
            Analytics
          </a>
          <a
            className="nav-link"
            id="nav-admedika-tab"
            data-toggle="tab"
            href="#nav-admedika"
            role="tab"
            aria-controls="nav-admedika"
            aria-selected="false"
          >
            Kesehatan
          </a>
          <a
            className="nav-link"
            id="nav-admedika-tab"
            data-toggle="tab"
            href="#nav-sektorprioritasopd"
            role="tab"
            aria-controls="nav-admedika"
            aria-selected="false"
          >
            Sektor Prioritas OPD
          </a>
          <a
            className="nav-link"
            id="nav-admedika-tab"
            data-toggle="tab"
            href="#nav-sentimen-media"
            role="tab"
            aria-controls="nav-admedika"
            aria-selected="false"
          >
            Sentimen Media
          </a>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane tab-pane-contentMenu fade show active"
          id="nav-beranda"
          role="tabpanel"
          aria-labelledby="nav-beranda-tab"
        >
          <TabsBeranda idUC={idUC}></TabsBeranda>
        </div>
        <div
          className="tab-pane tab-pane-contentMenu fade"
          id="nav-visimisi"
          role="tabpanel"
          aria-labelledby="nav-visimisi-tab"
        >
          <TabsVisiMisi idUC={idUC}></TabsVisiMisi>
        </div>
        <div
          className="tab-pane tab-pane-contentMenu fade"
          id="nav-sektor"
          role="tabpanel"
          aria-labelledby="nav-sektor-tab"
        >
          <TabsSektor idUC={idUC}></TabsSektor>
        </div>
        <div
          className="tab-pane tab-pane-contentMenu fade"
          id="nav-non-iku"
          role="tabpanel"
          aria-labelledby="nav-non-iku-tab"
        >
          <TabsNonIku idUC={idUC}></TabsNonIku>
        </div>
        <div
          className="tab-pane tab-pane-contentMenu fade"
          id="nav-analytics"
          role="tabpanel"
          aria-labelledby="nav-analytics-tab"
        >
          <TabsAnalytics idUC={idUC}></TabsAnalytics>
        </div>
        <div
          className="tab-pane tab-pane-contentMenu fade"
          id="nav-admedika"
          role="tabpanel"
          aria-labelledby="nav-admedika-tab"
        >
          <TabsKesehatan idUC={idUC}></TabsKesehatan>
        </div>
        <div
          className="tab-pane tab-pane-contentMenu fade"
          id="nav-sektorprioritasopd"
          role="tabpanel"
          aria-labelledby="nav-sektorprioritasOPD-tab"
        >
          <TabsOPD idUC={idUC}></TabsOPD>
        </div>
        <div
          className="tab-pane tab-pane-contentMenu fade"
          id="nav-sentimen-media"
          role="tabpanel"
          aria-labelledby="nav-sentimen-media-tab"
        >
          <TabsSentimenMedia idUC={idUC}></TabsSentimenMedia>
        </div>
      </div>
    </>
  );
}

export default withRouter(Index);
import React, { useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../../constants/apiConstants';

import SektorEmpty from '../../../../../../assets/img/sektorempty.png';

const Empty = (props) => {

  useEffect(() => {
    axios.get(`${API_BASE_URL}sector?page=1&per_page=10&id_usecase=${props.idUC}`)
      .then(({ data: { data } }) => {
        if (data.length > 0) {
          props.changePage('index');
        }
      })
      .catch((err) => console.log(err));
  }, [props]);

  const handleClick = (e) => {
    //e.stopPropagation;
    //console.log(e.currentTarget);
    props.changePage(e.currentTarget.getAttribute('data-page'));
  }

  return (
    <>
      <div className="row rowMenu rowMenuEmpty">
        <div className="col-12 text-center">
          <img className="imgMenu" src={SektorEmpty} alt="sektor kosong" />
          <p>Anda belum menentukan sektor</p>
          <button data-page="create" type="button" className="btn btn-dark" onClick={handleClick}>Tambah Sektor</button>
        </div>
      </div>
    </>
  );
}

export default Empty;
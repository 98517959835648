import React, { useState, useEffect } from "react";
import BarChart from "../../../../Charts/BarChart";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import { NumberWithDot, NumberFormat } from "../../../../Material/FormatNumber";
import { BackgroundShadow } from "../Utils";
import Loading from "../../../../../utils/Loading";
import esStyle from "../Pertanian/index.css";


const ProduksiChart = (props) => {
  const {
    indikatorTitle,
    indikator,
    jenis,
    startDate,
    statusDate,
    dataIndikatorCheck,
  } = props;
  const [dataChart, setDataChart] = useState({
    data: [],
    categories: [],
    options: {},
  });
  const [load, setLoad] = useState(false);

  useEffect(() => {
    // if( (!dataIndikatorCheck) || (typeof jenis === "undefined") ) return
    // setLoad(false);
    axios
      .get(
        `${API_BASE_URL}poda/sda/perikanan/bar-chart?&year=${startDate}&indikator=${indikator}&jenis=${jenis}`
      )
      .then(({ data: { data } }) => {
        // console.log(data)
        setLoad(true);
        setDataChart({
          data: data.widget_data.chart_data,
          categories: data.widget_data.chart_categories,
          options: {
            colors: [props.color],
            tooltip: {
              formatter: function () {
                return `<div style="border-color:${this.color}">
                    <table>
                    <tr>
                      <td>${startDate} </td>
                    </tr>
                    <tr>
                      <td><strong>${this.x}<strong> </td>
                    </tr>
                      <tr>
                        <td>
                          ${
                            jenis.indexOf("ton") !== -1
                              ? "Produksi"
                              : jenis.indexOf("juta") !== -1
                              ? "Nilai Produksi"
                              : "Produksi"
                          }
                          : 
                          <strong>
                          ${jenis.indexOf("juta") !== -1 ? "Rp " : ""} 
                          ${NumberWithDot(this.y, 2)}
                          ${jenis.indexOf("ton") !== -1 ? " ton" : ""} 
                          </strong>
                        </td>
                      </tr>
                    </table>
                  </div>`;
              },
              shared: true,
              useHTML: true,
            },
            xAxis: {
              min: 0,
              max: 4,
              scrollbar: {
                enabled: true,
              },
              title: {
                text: "Kab/Kota",
              },
            },
            yAxis: {
              title: {
                text: `${
                  jenis.indexOf("ton") !== -1
                    ? "Produksi (ton)"
                    : jenis.indexOf("juta") !== -1
                    ? "Nominal (Rupiah)"
                    : ""
                }`,
              },
              labels: {
                formatter: function () {
                  return NumberFormat(this.value, 1);
                },
              },
            },
          },
        });
      })
      .catch(() => {});
  }, [indikator, jenis, startDate, statusDate, dataIndikatorCheck]);

  return (
    <>
      <div
        className="position-relative"
        style={{
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {
          load ? (
            <>
            <div className="col">
            <div className="row">
              <BarChart
                key="bar-top-luas-panen"
                data={dataChart.data}
                categories={dataChart.categories}
                options={dataChart.options}
                tooltip={dataChart.options.tooltip}
              /></div>
              <div className="row">
              <p
                className={`${esStyle.nodata} ${
                  !dataChart?.data?.length
                    ? "text-center sumber-data"
                    : "text-left d-none"
                }`}
              >
                {!dataChart?.data?.length
                  ? "Not available for this version"
                  : "avail"}
              </p></div></div>
            </>
          ) : (
            <Loading />
          )
          // <BackgroundShadow content="" background="#c0c2c6" />
        }
      </div>
    </>
  );
};

export default ProduksiChart;

import React, { useState } from 'react';
import Index from './ChildVisiMisi/Index';
import CreateVisi from './ChildVisiMisi/CreateVisi';
import CreateMisi from './ChildVisiMisi/CreateMisi';
import EditVisi from './ChildVisiMisi/EditVisi';
import EditMisi from './ChildVisiMisi/EditMisi';
import CreatePeriode from './ChildVisiMisi/CreatePeriode';
import EditPeriode from './ChildVisiMisi/EditPeriode';

const VisiMisi = (props) => {
  const [page, setPage] = useState('index');
  const [idVisi, setIdVisi] = useState('');
  const [idMisi, setIdMisi] = useState('');
  const [idPeriode, setIdPeriode] = useState('');
  const [periode, setPeriode] = useState('');


  /**
   * Link to add form without parameters
   */
  const handleChange = (page, period = null) => {
    setPeriode(period);
    setPage(page);
  };

  /**
   * Link to edit form visi
   */
  const handleIdPage = (id, period = null) => {
    setPeriode(period);
    setIdVisi(id);
    setPage("editVisi");
  };

  /**
   * Link to edit form misi
   */
  const handleIdPageMisi = (id, period = null) => {
    setPeriode(period);
    setIdMisi(id);
    setPage("editMisi");
  };

  /**
   * Link to edit form periode
   */
  const handleIdPagePeriode = (id) => {
    setIdPeriode(id);
    setPage("editPeriode");
  };

  return (
    <>
      {page === 'index' ? <Index key={props.idUC} idUC={props.idUC} pageEditMisi={handleIdPageMisi} pageEdit={handleIdPage} pagePeriode={handleIdPagePeriode} changePage={handleChange}></Index> : 
      page === 'createVisi' ? <CreateVisi key={props.idUC} idUC={props.idUC} periode={periode} changePage={handleChange}></CreateVisi> : 
      page === 'createMisi' ? <CreateMisi key={props.idUC} idUC={props.idUC} periode={periode} changePage={handleChange}></CreateMisi> : 
      page === 'editVisi' ? <EditVisi key={props.idUC} idUC={props.idUC} idVisi={idVisi} periode={periode} changePage={handleChange}></EditVisi> : 
      page === 'editMisi' ? <EditMisi key={props.idUC} idUC={props.idUC} idMisi={idMisi} periode={periode} changePage={handleChange}></EditMisi> : 
      page === 'editPeriode' ? <EditPeriode key={props.idUC} idUC={props.idUC} idPeriode={idPeriode} changePage={handleChange}></EditPeriode> : 
      page === 'createPeriode' ? <CreatePeriode key={props.idUC} idUC={props.idUC} changePage={handleChange}></CreatePeriode> : ''}      
    </>
  );
}

export default VisiMisi;
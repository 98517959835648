import React from 'react'
import Chart from './Chart'
import HargaStatus from './HargaStatus'

import esStyle from '../../../PotensiDaerah/SDA/index.module.css';
import FooterCard from './FooterCard';
import { API_BASE_URL } from '../../../../../constants/apiConstants';

const Widget = (props) => {
    const {data, jenis, kota, tanggal, pasar} = props;
    const url =  `${API_BASE_URL+data.detail.url}?jenis=${jenis}&komoditas=${data.title}&kabkot=${kota}&tanggal=${tanggal}&pasar=${pasar}`;

    return (
        <>      
            <div className='col-xs-4 col-sm-4 mb-5'>
                <div className="col"><span className={esStyle.textEs4}>{data.title}</span></div>
                <div className="col">
                    <div className='row'>
                        <div className='col-7 p-0'><Chart data={data.area_chart} jenis={jenis} kota={kota} pasar={pasar}/></div>
                        <div className='col-5 p-2'><HargaStatus data={data}/></div>
                    </div>
                </div>

                <FooterCard title={data.title} url={url}/>
            </div>
        </>
    )
}

export default Widget
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../../constants/apiConstants';

const Alamat = (props) => {
  const [alamat, setAlamat] = useState('');
  const [telepon, setTelpon] = useState('');
  const [link_map, setLinkMap] = useState('');

  useEffect(() => {
    //console.log(alamat);
  }, [alamat])

  useEffect(() => {
    setAlamat(props.alamat);
    setTelpon(props.telepon);
    setLinkMap(props.link_map);
  }, [props])

  const handleChange = (e) => {
    if (e.target.name === 'alamat') {
      setAlamat(e.target.value);
    }

    if (e.target.name === 'telepon') {
      setTelpon(e.target.value);
    }

    if (e.target.name === 'link_map') {
      setLinkMap(e.target.value);
    }
  };

  const handleSubmit = () => {

    let payload = {
      alamat,
      telepon,
      link_map,
      id_usecase: props.idUC
    }

    axios.post(`${API_BASE_URL}usecase/updateLokasiDaerah`, payload)
      .then(({ data: { data } }) => {
        alert('sukses')
      })
      .catch((err) => {});
  }

  return (
    <>
      <div className="row rowMenu">
        <div className="col-4">
          <div className="row">
            <div className="col-12">
              <div className="form-group text-left">
                <label className="textMenu2 labelUC">Nama Alamat<span className="redText">*</span></label>
                <input data-testid="inputAlamat" name="alamat" type="text" className="form-control" placeholder="Masukan Alamat Anda" value={alamat} onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="row">
            <div className="col-12">
              <div className="form-group text-left">
                <label className="textMenu2 labelUC">Telepon<span className="redText">*</span></label>
                <input data-testid="inputTelepon" name="telepon" type="text" className="form-control" placeholder="Masukan No. Telepon" value={telepon} onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="row">
            <div className="col-12">
              <div className="form-group text-left">
                <label className="textMenu2 labelUC">Link Map<span className="redText">*</span></label>
                <input data-testid="inputLink" name="link_map" type="text" className="form-control" placeholder="Masukan Link Map" value={link_map} onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row rowMenu">
        <div className="col-12 text-right">
          <button data-testid="btnSubmit" type="button" className="btn btn-dark" onClick={handleSubmit}>Save</button>
        </div>
      </div>
    </>
  );
}

export default Alamat;
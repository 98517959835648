import React, { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import Datatable from '../../../../../Material/Datatable';
import { 
  API_GET_PERIODE, 
  API_DELETE_PERIODE,
  API_GET_VISI_BY_UC_ID, 
  API_DELETE_VISI, 
  API_GET_MISI_BY_UC_ID, 
  API_DELETE_MISI, 
  API_LIST_PERIODE_BY_ID_USECASE,
  API_SUBMIT_PERIODE,
} from '../../../../../../constants/apiConstants';
import CustomAlert from '../../../../../Material/CustomAlert';

const Index = (props) => {
  const [filterPeriode, setFilterPeriode] = useState([]);
  const [periode, setPeriode] = useState('');
  const [dashPeriode, setDashPeriode] = useState({idPeriode: '', idUsecase: ''});
  const [visi, setVisi] = useState([]);
  const [successUpdate, setSuccessUpdate] = useState(false);

  useEffect(() => {
    /**
     * Set periode visi / misi dashboard
     */
    axios
    .get(`${API_GET_VISI_BY_UC_ID}${props.idUC}`)
    .then(({ data: {data: {data}}}) => {
      setVisi(data);
      if (data[0]) {
        setDashPeriode({
          idPeriode: data[0].id_periode,
          idUsecase: props.idUC
        })
      }
    })
    .catch((err) => {});

    /**
     * Filter for visi & misi
     */
    axios
    .get(`${API_LIST_PERIODE_BY_ID_USECASE}?id_usecase=${props.idUC}`)
    .then(({ data: { data } }) => {
      if (data[0]) {
        setPeriode(data[0]);
      }
      setFilterPeriode(data);
    })
    .catch((err) => {});
  }, [])


  const handleClick = (e) => {
    props.changePage(e.currentTarget.getAttribute('data-page'), periode);
  };

  const handleChangeFilter = (e) => {
    setPeriode({
      id: e.target.value,
      periode: e.target.options[e.target.options.selectedIndex].innerText,
      id_usecase: props.idUC,
    });
  };

  const handleChangeDashPeriode = (e) => {
    setDashPeriode({
      idPeriode: e.target.value,
      idUsecase: props.idUC,
    });
  };

  const handleSubmitDashPeriode = (e) => {
    let formData = new URLSearchParams();
    formData.append("id_usecase", Number(dashPeriode.idUsecase));
    formData.append("id_periode", Number(dashPeriode.idPeriode));

    axios
      .put(`${API_SUBMIT_PERIODE}`, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(() => {
        setSuccessUpdate(true);
        setTimeout(() => {
          setSuccessUpdate(false);
        }, 2000);
      })
      .catch((err) => {});
  };

  return (
    <>
      {successUpdate && (
        <CustomAlert page="Periode" type="warning"></CustomAlert>
      )}
      {/* Add Periode */}
      <div className="row">
        <div className="col-6">
          <p className="textMenu4">Periode</p>
        </div>
        <div className="col-6 text-right">
          <button
            data-page="createPeriode"
            onClick={handleClick}
            data-testid="add-periode"
            className="btn btn-dark"
          >
            Add Periode
          </button>
        </div>
      </div>

      <Datatable
        key={1}
        columns={[{ th: "Periode Tahun", td: "periode" }]}
        unusedField={["id", "no", "create_date", "modify_date", "id_usecase"]}
        endPointGet={`${API_GET_PERIODE}?id_usecase=${props.idUC}&`}
        endPointDelete={API_DELETE_PERIODE}
        editFunc={(id) => {
          props.pagePeriode(id);
        }}
      ></Datatable>

      {/* Periode Visi / Misi */}
      <div className="row rowMenu">
        <div className="col-12">
          <form className="form">
            <p className="text-left textMenu4">Periode Visi / Misi</p>
            <p>Silahkan pilih periode untuk ditampilkan di depan</p>
            <div className="row">
              <div className="col-4">
                <select
                  className="form-control my-1 mr-sm-2"
                  data-testid="select-periode"
                  onChange={handleChangeDashPeriode}
                  value={dashPeriode.idPeriode}
                >
                  {visi.map((val) => {
                    return (
                      <option
                        key={"dash-" + val.id_periode + dashPeriode.idPeriode}
                        value={val.id_periode}
                      >
                        {val.periode}
                      </option>
                    );
                  })}
                </select>
                <button
                  type="button"
                  data-testid="btn-aturperiode"
                  className="btn btn-dark my-1"
                  onClick={handleSubmitDashPeriode}
                >
                  Atur Periode
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <br />
      <hr />
      <br />

      {/* Filter Visi / Misi */}
      <div className="row rowMenu">
        <div className="col-4">
          <form className="form">
            <span className="text-left textMenu4">Filter</span>
            <select
              data-testid="filter-periode"
              className="form-control my-1 mr-sm-2"
              onChange={handleChangeFilter}
            >
              {filterPeriode.map((val) => {
                return (
                  <option key={val.id} value={val.id}>
                    {val.periode}
                  </option>
                );
              })}
            </select>
          </form>
        </div>
      </div>

      <br />
      {/* Add Visi */}
      <div className="row rowMenu">
        <div className="col-6">
          <p className="text-left textMenu4">Visi</p>
        </div>
        <div className="col-6 text-right">
          <button
            data-page="createVisi"
            className="btn btn-dark"
            onClick={handleClick}
          >
            Add Visi
          </button>
        </div>
      </div>

      <Datatable
        key={2}
        columns={[
          { th: "Periode Tahun", td: "tahun" },
          { th: "Visi", td: "visi" },
        ]}
        unusedField={["id", "no", "id_periode"]}
        endPointGet={`${API_GET_VISI_BY_UC_ID}${props.idUC}?id_periode=${periode.id}&`}
        endPointDelete={API_DELETE_VISI}
        editFunc={(id) => {
          props.pageEdit(id, periode);
        }}
      ></Datatable>

      <br />
      {/* Add Misi */}
      <div className="row rowMenu">
        <div className="col-6">
          <p className="text-left textMenu4">Misi</p>
        </div>
        <div className="col-6 text-right">
          <button
            data-page="createMisi"
            className="btn btn-dark"
            onClick={handleClick}
          >
            Add Misi
          </button>
        </div>
      </div>

      {periode !== '' && (
        <Datatable
          key={3}
          columns={[
            { th: "Periode Tahun", td: "periode" },
            { th: "Misi", td: "misi" },
          ]}
          unusedField={["id", "no"]}
          endPointGet={`${API_GET_MISI_BY_UC_ID}${props.idUC}?id_periode=${periode.id}&`}
          endPointDelete={API_DELETE_MISI}
          editFunc={(id) =>  {props.pageEditMisi(id, periode)}}
        ></Datatable>
      )}
    </>
  );
};

export default Index;

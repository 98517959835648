import React, { useState, useEffect } from "react";
import style from "./CustomPagination.module.css";

function Pagination({ count, currentPage, rowsPerPage, onChangePage, onChangeRowsPerPage }) {
   const [pagination_max, setPagination_max] = useState(5);
   const [pagination_max_current, setPagination_max_current] = useState(1);
   const per_page_list = [5, 10, 25, 50, 100];
   const [per_page, setPer_page] = useState(5);
   const [dataPagination, setDataPagination] = useState([]);

   const handleFirstPageButtonClick = () => {
      onChangePage(1);
      setPagination_max_current(1);
   };

   useEffect(() => {
      returnPageButton(currentPage, per_page);
   }, []);

   const handleBackButtonClick = () => {
      onChangePage(currentPage - 1);
      returnPageButton(currentPage - 1, per_page);
      let maxCurrent = currentPage - 1 < pagination_max_current ? currentPage - pagination_max : pagination_max_current;
      setPagination_max_current(maxCurrent);
   };

   const handleNextButtonClick = () => {
      onChangePage(currentPage + 1);
      returnPageButton(currentPage + 1, per_page);
      let maxCurrent = currentPage + 1 >= pagination_max_current + pagination_max ? currentPage + 1 : pagination_max_current;
      setPagination_max_current(maxCurrent);
   };

   const handleLastPageButtonClick = () => {
      const lastPage = Math.ceil(count / rowsPerPage);
      onChangePage(lastPage);
      let maxCurrent = Math.ceil(count / rowsPerPage) + 1 - (Math.ceil(count / rowsPerPage) % pagination_max ? Math.ceil(count / rowsPerPage) % pagination_max : pagination_max);

      setPagination_max_current(maxCurrent);
   };

   const handleClickPagination = (val, perpage) => {
      onChangePage(val);
      returnPageButton(val, perpage);
   };

   const returnPageButton = async (val, perpage) => {
      let totalPages = numPages(perpage, count);

      const numButtons = 5;
      let startPage = 1;
      let endPage = totalPages;

      // Calculate start and end page numbers
      if (totalPages > numButtons) {
         const halfButtons = Math.floor(numButtons / 2);
         if (val > halfButtons) {
            startPage = val - halfButtons;
            if (val > totalPages - halfButtons) {
               startPage = totalPages - numButtons + 1;
            }
         }
         endPage = startPage + numButtons - 1;
      }

      // Create array of page button numbers
      const pageButtons = [];
      for (let i = startPage; i <= endPage; i++) {
         pageButtons.push(i);
      }
      console.log(pageButtons);
      setDataPagination(pageButtons);
      return pageButtons;
   };

   function numPages(records_per_page, data) {
      return Math.ceil(data / records_per_page);
   }

   const handleNextPagination = () => {
      onChangePage(pagination_max_current + pagination_max);
      setPagination_max_current(pagination_max_current + pagination_max);
   };

   const handlePrevPagination = () => {
      onChangePage(pagination_max_current - pagination_max);
      setPagination_max_current(pagination_max_current - pagination_max);
   };

   const handleDisplayPageChange = (e) => {
      setPer_page(e);
      onChangeRowsPerPage(e);
      handleClickPagination(1, e);
   };

   return (
      <>
         <div className={style.wrapper}>
            <div className={style.display_wrapper}>
               <label>Tampilkan</label>
               <select className={style.select} onChange={(e) => handleDisplayPageChange(e.target.value)} value={per_page}>
                  {per_page_list.map((data, index) => (
                     <option key={index} value={data}>
                        {data}
                     </option>
                  ))}
               </select>
            </div>
            <div className={style.pagination_wrapper}>
               <span>
                  Menampilkan {currentPage * rowsPerPage - rowsPerPage + 1} - {currentPage * rowsPerPage > count ? currentPage * rowsPerPage - count + (currentPage - 1) * rowsPerPage : currentPage * rowsPerPage} dari {count} baris
               </span>
               <div className={style.pagination}>
                  <button className={style.btn_icon} onClick={handleBackButtonClick} disabled={currentPage === 1 ? true : false}>
                     <i className="fas fa-chevron-left"></i>
                  </button>

                  {dataPagination.map((val, index) => (
                     <button key={index} className={`${style.btn} ${currentPage == val ? style.active : ""}`} onClick={() => handleClickPagination(val, per_page)}>
                        {val}
                     </button>
                  ))}

                  <button className={style.btn_icon} onClick={handleNextButtonClick} disabled={currentPage === Math.ceil(count / rowsPerPage) ? true : false}>
                     <i className="fas fa-chevron-right"></i>
                  </button>
               </div>
            </div>
         </div>
      </>
   );
}

const CustomPagination = ({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage }) => {
   return <Pagination count={rowCount} rowsPerPage={rowsPerPage} currentPage={currentPage} onChangePage={onChangePage} onChangeRowsPerPage={(perPage) => onChangeRowsPerPage(perPage)} />;
};

export default CustomPagination;

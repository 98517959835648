import React, {useState, useEffect} from 'react';
import CustomAlert from './CustomAlert';
import axios from 'axios';
import  { useHistory } from 'react-router-dom'

const Datatable = (props) => {
  let history = useHistory();
  /**
   * Show PerPages Default Values [10, 25, 50]
   */
  const { data, columns, showPerPage, showPerPageSelected, unusedField, currentPage, showNumbering, showAction, endPointGet, search, totalData, urlEdit, editFunc, endPointDelete, orderBy, orderDir } = props;
  const [state, setState] = useState(props)
  const [status, setStatus] = useState(true);
  const [alert, setAlert] = useState(false);
  
  useEffect(() => {
    let isMounted = true;
    axios
      .get(
        `${props.endPointGet}page=${state.currentPage}&per_page=${state.showPerPageSelected}&keyword=${state.search}&order_by=${state.orderBy}&order_dir=${state.orderDir}`
      )
      .then(({ data: { data: {data, total} } }) => {
        if (isMounted) {
          setState({
            ...state,
            data: data,          
            totalData: total,
          })
        }
      })
      .catch((err) => {});
      return () => { isMounted = false };
  }, [status, props, state.orderDir, state.orderBy]);


  const handleShowPerPageSelect = (e) => {
    setState({
      ...state,
      showPerPageSelected: e.target.value
    })
    setStatus(!status);
  }
  const handleSearch = (e) => {
    setState({
      ...state,
      search: e.target.value
    })
    setStatus(!status);
  }

  const handleButtonPaginate = (e) => {    
    setState({
      ...state,
      currentPage: Number(e.target.value),
    })
    setStatus(!status);
  }

  const handleButtonPaginateNext = (e) => {    
    let i = Number(state.currentPage) + 1;
    if (i <= Math.ceil((state.totalData) / (state.showPerPageSelected))) {
      setState({
        ...state,
        currentPage: i,
      })
      setStatus(!status);
    }
  }

  const handleButtonPaginatePrev = (e) => {    
    let i = Number(state.currentPage) - 1;
    if (i > 0) {
      setState({
        ...state,
        currentPage: i,
      })
      setStatus(!status);
    }
  }

  const handleButtonEdit = (e) => {
    if(state.urlEdit !== null) {
      history.push(e.currentTarget.getAttribute('data-url'))
    } else {
      state.editFunc(e.currentTarget.getAttribute('data-id'));
    }
  }

  const handleButtonDelete = (e) => {
    if (window.confirm("Yakin Hapus ?")) {
      axios
        .delete(
          `${endPointDelete}${e.currentTarget.getAttribute('data-id')}`
        )
        .then(({ data: { data: {data, total} } }) => {
          setStatus(!status)
          setAlert(true)
          setTimeout(() => {
            setAlert(false)
          }, 1000)
        })
        .catch((err) => {});
    }
  }

  const handleSortTable = (e) => {
    const orderDir = e.target.getAttribute('data-orderdir');
    const orderBy = e.target.getAttribute('data-orderby');
    if (orderDir === 'asc') {
      setState({
        ...state,
        orderDir: 'desc',
        orderBy: orderBy,
      });
    } 
    else {
      setState({
        ...state,
        orderDir: 'asc',
        orderBy: orderBy,
      });
    }
  }

  return (
    <>
      { alert && <CustomAlert type="danger"></CustomAlert>}
      {/* Show Entries and Search */}
      <div className="row rowMenu">
        <div className="col-10 text-left">
          <label className="labelPerPage">
            Show
            <select data-testid="select-perpage" className="selectPerPage" onChange={handleShowPerPageSelect} value={state.showPerPageSelected}>
              {state.showPerPage && state.showPerPage.map((val, index) => {
                return <option key={index} value={val}>{val}</option>;
              })}
            </select>
            entries
          </label>
        </div>
        <div className="col-2">
          <input type="text" data-testid="input-search" className="form-control" placeholder="Search" value={state.search} onChange={handleSearch}/>
        </div>
      </div>

      {/* Table */}
      <div className="row rowMenu">
        <div className="col-12">
          <table className="custom-datatable table table-hover table-striped">
            <thead>
              <tr>
                {showNumbering && <th data-testid="sort-no" onClick={handleSortTable} data-orderby={''} data-orderdir={state.orderDir}>
                  No 
                  <i className={`${state.orderDir === 'asc' && state.orderBy === '' ? 'fas fa-sort-amount-down-alt' : (state.orderDir === 'desc' && state.orderBy === '') ? 'fas fa-sort-amount-up-alt' : ''} iconSort`}></i> 
                </th>}
                {showAction && <th>
                  Action 
                  </th>}
                {columns && columns.map((col, index) => {
                    return (
                      <th data-testid="sort-column" onClick={handleSortTable} key={index} data-orderby={col.td} data-orderdir={state.orderDir}>
                      {col.th} 
                      <i className={`${state.orderDir === 'asc' && state.orderBy === col.td ? 'fas fa-sort-amount-down-alt' : (state.orderDir === 'desc' && state.orderBy === col.td) ? 'fas fa-sort-amount-up-alt' : ''} iconSort`}></i> 
                      </th>
                      )
                })}
              </tr>
            </thead>
            <tbody>
              {state.data && state.data.map((row, index) => {
                return !(index+1 > showPerPage) && (
                <tr key={index}>
                  { showNumbering && (<td key={'td-1-'+index}>{ state.orderDir === 'asc' ? ((index+1) + (state.showPerPageSelected * (state.currentPage-1))) : ((state.totalData+1)-((index+1) + (state.showPerPageSelected * (state.currentPage-1))))}</td>) }
                  { showAction && (<td key={props.endPointGet+index}>
                      <i data-id={row.id} data-url={state.urlEdit} data-testid="btn-edit" onClick={handleButtonEdit} className="fa fa-user-edit mr-2 datatable-icon-action"></i>
                      <i data-id={row.id} onClick={handleButtonDelete} data-testid="btn-delete" className="fa fa-trash mr-2 datatable-icon-action"></i>
                    </td>) }
                  {
                    Object.keys(row) && Object.keys(row).map((key, index) => {
                      return !unusedField.includes(key) && <td key={index}>{row[key]}</td>
                    }) 
                  }
                </tr>) 
              })}
            </tbody>
          </table>
        </div>
      </div>
    
      {/* Pagination */}
      <div className="row rowMenu">
        <div className="col-12 text-right">
          <button onClick={handleButtonPaginatePrev} data-testid="btn-paginate-prev" className="btn btn-outline-primary"><i className="fas fa-chevron-left"></i></button>
          {
            Array.from(Array( Math.ceil((state.totalData || 0) / (state.showPerPageSelected)) ), (e, i) => {
              return <button key={i} 
              onClick={handleButtonPaginate}
              value={i+1}
              data-testid="btn-paginate" className={`btn ${i+1 === state.currentPage ? 'btn-primary' : 'btn-outline-primary'} btnPaging`}>{i+1}</button>
            })
          } 
          <button onClick={handleButtonPaginateNext} data-testid="btn-paginate-next" className="btn btn-outline-primary"><i className="fas fa-chevron-right"></i></button>
        </div>
      </div>
    </>
  );
};

Datatable.defaultProps = {
  showPerPage: [10, 25, 50],
  showPerPageSelected: 10,
  currentPage: 1,
  data: [],
  unusedField: [],
  columns: [],
  showNumbering: true,
  showAction: true,
  endPointGet: '',
  totalData: 0,
  search: '',
  urlEdit: null,
  editFunc: ()=>{},
  endPointDelete: '',
  orderBy: '',
  orderDir: 'asc',
};

export default Datatable;

import React, { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../../../App.css';
import Loading from '../../../../utils/Loading';
import { API_BASE_URL } from '../../../../constants/apiConstants';
import MySwal from '../../../../utils/MySwal';
import { dataWarningSA } from '../../../../utils/tools';
import style from './Roles.module.css';

export default function Update(props) {
  const history = useHistory();
  const param = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [namaRole, setNamaRole] = useState('');

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}role/detail/${param.id}`)
      .then(({ data: { data } }) => {
        setNamaRole(data.nama_role);
      })
      .catch(() => {});
  }, []);

  const refresh = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      history.push('/account-management/roles');
    }, 400);
  };

  const handleUpdateData = () => {
    const data = {
      title: 'Update Data',
      html: `Apakah Anda yakin ingin update role <strong>${namaRole}</strong>?`,
      confirmButtonText: 'Update',
    };
    MySwal.fire(dataWarningSA(data)).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          id_usecase: param.id_usecase,
          name_role: namaRole,
        };

        axios
          .put(`${API_BASE_URL}role/edit/${param.id}`, payload)
          .then(({ data, status }) => {})
          .catch(function (error) {});
        refresh();
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="boxContainer">
          <div className="box-frame">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="boxContainer">
          <div data-testid="title" className={`${style.textBody1} mb-3`}>
            Edit Role
          </div>
          <div className="box-frame d-flex flex-column justify-content-between">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="box-field-input">
                  <div className="title-field-input">
                    Nama Role <span>*</span>
                  </div>
                  <input
                    data-testid="input-nama-role"
                    type="text"
                    value={namaRole}
                    placeholder="Masukan Nama Role"
                    className="text-field-input"
                    onChange={(e) => setNamaRole(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                data-testid="button-back"
                className="btn btn-outline-dark btnMenu"
                onClick={refresh}
              >
                Kembali
              </button>
              <button
                data-testid="button-save"
                className="btn btn-dark"
                onClick={handleUpdateData}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {reactLocalStorage} from 'reactjs-localstorage';
import EmptyColumn from '../../../src/assets/img/EmptyColumn.svg';
import {NumberWithDot, NumberFormat} from '../Material/FormatNumber';
import Loading from '../../utils/Loading';

let mounted = false;
let chartOptions = {
  chart: {
    type: 'bar',
    height: '300px'
  },
  title: {
      text: ''
  },
  exporting: {
    enabled: false,
  },
  colors: [reactLocalStorage.getObject('base_color')?.color1],
  subtitle: {
      // text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
  },
  xAxis: {
    categories: [],
    title: {
        text: false,
    },
    max: 4,
    scrollbar: {
      enabled: true
    },
  },
  yAxis: {
      min: 0,
      title: {
          text: false,
          // align: 'high'
      },
      labels: {
          overflow: 'justify',
          formatter: function () {
            return NumberWithDot(this.value, 2);
          },
      }
  },
  tooltip: {
    formatter: function () {
      return (
        `<div style="border-color:${this.color}">
          <table>
            <tr>
              <td> <span>${this.x}: </span> </td>
              <td> <span><strong>${NumberWithDot(this.y, 2)}</strong></span></td>
            </tr>
          </table>
        </div>`
      );
    },
    shared: true,
    useHTML: true,
  },
  plotOptions: {
      bar: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              return (`<span style="font-style: normal; font-weight: normal; font-family: DM Sans, sans-serif; font-size: 12px;">${NumberWithDot(this.y, 2)}</span>`)
            }
          }
      }
  },
  legend: {
    enabled: false,
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 1,
    backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    shadow: true
  },
  credits: {
      enabled: false
  },
  series: [{
      name: '',
      data: []
  },],
  loading: {
    buffer: true,
    flag: true,
  },
}

const ChartContainer = ({children}) => (
  <>
    <div className="position-relative">
      <div className="flex-center mt-3 mb-5">
        {children}
      </div>
    </div>
  </>
)

const BarChart = (props) => {
  const {data, categories, options, radius, scroll,status, tooltip, container, isHeight, isShimmer, handleChartClick} = props;
  const [state, setState] = useState(chartOptions);
  let customTooltip = tooltip ? tooltip : {
    formatter: function () {
      return (
        `<div style="border-color:${this.color}">
          <table>
            <tr>
              <td> <span>${this.x}: </span> </td>
              <td> <span><strong>${NumberWithDot(this.y, 2)}</strong></span></td>
            </tr>
          </table>
        </div>`
      );
    },
    shared: true,
    useHTML: true,
  };

  useEffect(() => {
    if (typeof data === 'undefined') { return; }
    let max = (options.xAxis && options.xAxis.max) ? options.xAxis.max : chartOptions.xAxis.max;
    let enabled = ((options.xAxis && options.xAxis.scrollbar && options.xAxis.scrollbar.enabled) ? options.xAxis.scrollbar.enabled : chartOptions.xAxis.scrollbar.enabled);

    setState({
      ...state,
      ...options,
      chart: {
        ...state.chart,
        ...options.chart,
        height: isHeight ? 400 : null
      },
      exporting: {
        ...state.exporting,
        ...options.exporting,
      },
      xAxis: {
        ...state.xAxis,
        ...options.xAxis,
        max: (data && data.length-1 >= max) ? max : data.length-1,
        scrollbar: {
          enabled: scroll? false : (data && data.length-1 >= max) ? enabled : false,
        },
        categories: categories,
      },
      yAxis: {
        ...state.yAxis,
        ...options.yAxis,
        labels: {
          ...state.yAxis?.labels,
          ...options.yAxis?.labels,
        }
      },
      tooltip:customTooltip,
      plotOptions: {
        ...state.plotOptions,
        series: {
          borderRadius: radius ? 2 : 0,
          point: {
            events: {
              click: handleChartClick, // pastikan event handler ini dipanggil dengan benar
            },
          },
      },
      },
      series: [{
        ...state.series[0],
        data: data,
      }],
      colors: [reactLocalStorage.getObject('base_color')?.color1],
      loading: {
        buffer: true,
        flag: !state.loading.flag
      }
    });
  }, [props])

  useEffect(() => {
    if (mounted) {
      setState((prev) => ({
        ...prev,
        loading: {
          flag: state.loading.flag,
          buffer: false,
        }
      }))
    }
    mounted = true;
  }, [state.loading.flag])

  return (
    <>
      {
        (props.data === undefined || props.loading || state.loading.buffer === true) ?
        (isShimmer?  ( <div style={{height:'400px', background:'#c0c2c6'}}></div>) :
          <ChartContainer>
            <Loading />
          </ChartContainer>)
        : (state.series[0].data && state.series[0].data.length) ?
          <HighchartsReact containerProps={{style: { height: "100%" } }} highcharts={Highcharts} options={state} />
        :
          <ChartContainer>
            <img src={EmptyColumn} alt="Empty Data" />
          </ChartContainer>
      }
    </>
  )
}

BarChart.defaultProps = {
  options: {},
};
export default React.memo(BarChart)

import React, {useRef, useState, useEffect,forwardRef} from 'react'
import PieChart from '../../../Charts/PieChart'
import axios from 'axios';
import BarChart from '../../../Charts/BarChart'
import style from "./Index.module.css";
import {API_GET_PERDAGANGAN} from '../../../../constants/apiConstants'
import { NumberWithDot, NumberFormat } from '../../../Material/FormatNumber';
import Loading from '../../../../utils/Loading';
import YearPicker from '../../../../utils/YearPicker';
import Values from 'values.js';
import {reactLocalStorage} from 'reactjs-localstorage';


const Index = () => {
  const [loading, setLoading ] = useState(false)
  const baseColor = reactLocalStorage.getObject('base_color');
  const [list, setList] = useState(new Values(baseColor.color1).shades(12))
  let temp = [];
  for(var i = 0; i < list.length; i++){
    temp.push(list[i].hexString())
  }

  const [years, setYears] = useState([]);
  const [hitApiYear, setHitApiYear] = useState(true)
  const [year, setYear] = useState(null);
  const [hitApi, setHitApi] = useState(true)
  const [emptyData, setEmptyData] = useState({
    card_1 : "gray",
    card_2 : "gray",
    card_3 : "gray",
    card_4 : "gray",
  })
  const [response, seResponse] = useState({
    year : "",
    level : "",
    sumber_data: "",
    pembelian : {
      sumber_data: "",
      score_card: {
          status: "Empty",
          volume: 0,
          nilai: 0
        },
        top_asal: {
            status: "Empty",
            chart_categories: [],
            chart_data: []
        },
        komoditas_pembelian: {
            status: "Empty",
            data: [],
        }
    },
    penjualan: {
        score_card: {
          status: "Empty",
          volume: 0,
          nilai: 0
        },
        top_tujuan: {
            status: "Empty",
            chart_categories: [],
            chart_data: []
        },
        komoditas_penjualan: {
            status: "Empty",
            data: [],
        }
    }
  });
  const dataPiePembeli = [];
  const dataPenjual = [];
  const [statePiePembeli, setStatePiePembeli] = useState({
    data: dataPiePembeli,
    options: {
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
            colors:temp,
            showInLegend: true,
            dataLabels: {
              format: '<b>{point.percentage:.2f} %',
            }
          }
      },
      legend: {
        align: 'center',
        layout: 'vertical',
        verticalAlign: 'bottom',
      },
      tooltip: {
        formatter: function () {
          return (
            `<div style="border-color:${this.color}">
              <table>
                <tr>
                  <td> <span>${this.key}: </span> </td>
                  <td> <span><strong>${NumberWithDot(this.y)}%</strong></span></td>
                </tr>
              </table>
            </div>`
          );
        },
      }

    },
  });

  const [statePiePenjual, setStatePiePenjual] = useState({
    data: dataPenjual,
    options: {
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
            colors:temp,
            showInLegend: true,
            dataLabels: {
              format: '<b>{point.percentage:.2f} %',
            }
          }
      },
      legend: {
        align: 'center',
        layout: 'vertical',
        verticalAlign: 'bottom',
      },
      tooltip: {
        formatter: function () {
          return (
            `<div style="border-color:${this.color}">
              <table>
                <tr>
                  <td> <span>${this.key}: </span> </td>
                  <td> <span><strong>${NumberWithDot(this.y)}%</strong></span></td>
                </tr>
              </table>
            </div>`
          );
        },
      }
    
    }
  });
  
  
  useEffect(()=>{
    if(hitApi || hitApiYear){
      GetData();
    }
    
  }, [hitApi, hitApiYear])

  const hitEnpoint = (date) => {
    
    setYear(date)
    setHitApi(true)
  }

  const GetData = () => {
    setLoading(true)
    if (year === null) {
      axios.get(`${API_GET_PERDAGANGAN}/year`)
      .then(({data: {data}, status}) => {
        setYears(data.years);
        setYear(new Date(data.selected_years));
        setHitApiYear(false);
      })
      .catch(() => {})
    } else {
      axios.get(`${API_GET_PERDAGANGAN}?year=${year.getFullYear()}`)
      .then(({data, status}) => {
        if(status === 200 && data.status !== "Blank"){
          setYear(new Date(data.year));
          seResponse((prevState) => ({
            ...prevState,
            ...data,
          }))
          setStatePiePenjual(
            (prevState) => ({
              ...prevState,
              data : data.penjualan.komoditas_penjualan.data,
          }))
          setStatePiePembeli(
            (prevState) => ({
              ...prevState,
              data : data.pembelian.komoditas_pembelian.data,
          }))
          // setCcard(data.status === "Exists" ? "blue" : "gray")
          setEmptyData({
            card_1 : data.pembelian.score_card.status === "Exists" ? "blue" : "gray",
            card_2 : data.pembelian.score_card.status === "Exists" ? "blue" : "gray",
            card_3 : data.penjualan.score_card.status === "Exists" ? "blue" : "gray",
            card_4 : data.penjualan.score_card.status === "Exists" ? "blue" : "gray",     
          })
          setHitApi(false)
          setLoading(false)
        }
      })
      .catch(function (error) {});
    }
  }
  
  return (
    <>
      <p className="h3 mb-3">Perdagangan Antar Wilayah</p>
      <span className="line-2nd mb-3" style={{borderBottomColor : baseColor.color2}}></span>
      <div className="row mt-3 ml-1 mb-2">
        <div className="col-xs-3">
            <div className={`d-flex mr-auto ${style.yearContainer}`}>
              <div>
                <YearPicker 
                  yearWithData={years}
                  selected={year}
                  onChange={(date) => hitEnpoint(date)}
                />
              </div>
            </div>
        </div>
      </div>

      <div className="bg-white p-4">
        <div className="row mb-5">
          <div className="col">
            <h5 className="mb-4">Pembelian</h5>
            <div className="row">
              <div className="col">
               {loading === true && <Loading />}
               <div className={"card  " +emptyData.card_1 } style={ loading === true ? { display:"none"} : { display:"block",backgroundColor:baseColor.color1, color:baseColor.color3} }>
                      <div className="p-3">
                        <p><span className="title">Volume</span></p>
                        <span data-testid="card-purchase-volume" className="values">{NumberWithDot(response.pembelian.score_card.volume)} Ton</span>
                      </div>
                  </div>
              </div>
              <div className="col">
              {loading === true && <Loading />}
                <div className={"card " +emptyData.card_2 } style={ loading === true ? { display:"none"} : { display:"block",backgroundColor:baseColor.color1, color:baseColor.color3} }>
                      <div className="p-3">
                          <p><span className="title">Nilai</span></p>
                          <span data-testid="card-purchase-value" className="values">Rp{NumberWithDot(response.pembelian.score_card.nilai)}</span>
                      </div>
                  </div>
              
              </div>
            </div>
          </div>
          
          <div className="col">
            <h5 className="mb-4">Penjualan</h5>
            <div className="row">
              <div className="col">
              {loading === true && <Loading />}
               <div className={"card " +emptyData.card_3} style={ loading === true ? { display:"none"} : { display:"block",backgroundColor:baseColor.color1, color:baseColor.color3} }>
                    <div className="p-3">
                      <p><span className="title">Volume</span></p>
                      <span data-testid="card-sales-volume" className="values">{NumberWithDot(response.penjualan.score_card.volume)} Ton</span>
                    </div>
                </div>
              
              </div>
              <div className="col">
              {loading === true && <Loading />}
                <div className={"card "  +emptyData.card_4 } style={ loading === true ? { display:"none"} : { display:"block",backgroundColor:baseColor.color1, color:baseColor.color3} } >
                    <div className="p-3">
                        <p><span className="title">Nilai</span></p>
                        <span data-testid="card-sales-value" className="values">Rp{NumberWithDot(response.penjualan.score_card.nilai)}</span>
                    </div>
                </div>
               
              </div>
            </div>
          </div>
          
        </div>

        {/* row 2 */}
        
        <div className="row mb-5">
      
          {/* Top 10 provinsi */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="chart-title">Top 10 Provinsi Asal Terbesar</p>
      
            <div className="chart-template">
              <BarChart 
                loading={loading}
                data={response.pembelian.top_asal.chart_data} 
                categories={response.pembelian.top_asal.chart_categories}
                options={{
                  colors:[ baseColor.color1 ],
                  tooltip: {
                    formatter: function () {          
                      return (
                        `<div style="border-color:${this.color}">
                          <table>
                            <tr>
                              <td><strong>${year.getFullYear()}</strong></td>
                            </tr>
                            <tr>
                              <td> <span>${this.x}: </span> </td>
                              <td> <span><strong>Rp ${NumberWithDot(this.y)}</strong></span></td>
                            </tr>
                          </table>
                        </div>`
                      );
                    },
                    shared: true,
                    useHTML: true,
                  },
                  xAxis: {
                    min: 0,
                    max: 4,
                    scrollbar: {
                      enabled: true
                    },
                    title: {
                      text: "Provinsi",
                    },
                  },
                  yAxis: {
                    title: {
                        text: "Nominal",
                    },
                    labels: {
                      formatter: function () {
                        return NumberFormat(this.value, 1);
                      },
                    },
                  },
                
                }}
              />
              {
                response.pembelian.top_asal.status !==  "Exists" && loading === false  && (
                  <div className={style.empty}>
                    <p>Tidak ada data untuk ditampilkan</p>
                  </div>
                )
              }
            </div>
          </div>

          {/* col top 10 pekerjaan */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="chart-title">Top 10 Provinsi Tujuan Terbesar</p>
          
            <div className="chart-template">
            <BarChart 
              loading={loading}
              data={response.penjualan.top_tujuan.chart_data} 
              categories={response.penjualan.top_tujuan.chart_categories}
              options={{
                colors:[ baseColor.color1 ],
                xAxis: {
                  min: 0,
                  max: 4,
                  scrollbar: {
                    enabled: true
                  },
                  title: {
                    text: "Provinsi",
                  },
                },
                yAxis: {
                  title: {
                      text: "Nominal",
                  },
                  labels: {
                    formatter: function () {
                      return NumberFormat(this.value, 1);
                    },
                  },
                },
                tooltip: {
                  formatter: function () {          
                    return (
                      `<div style="border-color:${this.color}">
                        <table>
                          <tr>
                            <td><strong>${year.getFullYear()}</strong></td>
                          </tr>
                          <tr>
                            <td> <span>${this.x}: </span> </td>
                            <td> <span><strong>Rp ${NumberWithDot(this.y)}</strong></span></td>
                          </tr>
                        </table>
                      </div>`
                    );
                  },
                  shared: true,
                  useHTML: true,
                },

              }}
            />
            {
              response.penjualan.top_tujuan.status !==  "Exists" && loading === false && (
                <div className={style.empty}>
                  <p>Tidak ada data untuk ditampilkan</p>
                </div>
              )
            }
            </div>
          </div>

        </div>

        {/* row 2*/}
        
        <div className="row">
        
          {/* col kelompok komoditas nilai pembelian terbesar */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="chart-title">Kelompok Komoditas Nilai Pembelian Terbesar</p>
      
            <div className="chart-template">              
              <PieChart data={statePiePembeli.data} options={statePiePembeli.options} loading={loading}/>
              {
                response.pembelian.komoditas_pembelian.status !==  "Exists" && (
                  <div className={style.empty}>
                    <p>Tidak ada data untuk ditampilkan</p>
                  </div>
                )
              }
            </div>
          </div>

          {/* col kelompok komoditas nilai penjualan terbesar */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="chart-title">Kelompok Komoditas Nilai Penjualan Terbesar</p>
           
            <div className="chart-template">              
              <PieChart data={statePiePenjual.data} options={statePiePenjual.options} loading={loading}/>
              {
                response.penjualan.komoditas_penjualan.status !==  "Exists" && (
                  <div className={style.empty}>
                    <p>Tidak ada data untuk ditampilkan</p>
                  </div>
                )
              }
            </div>
          </div>

        </div>
        {
          response.sumber_data !==  "" && (
            <p className={style.sumber}>
            *) Sumber data : {response.sumber_data}. informasi chart diatas adalah data level <strong>{response.level}</strong>.
            </p>
          )
        }
        
        <div className="my-5"></div>

      </div>
    </>
  )
}

export default Index

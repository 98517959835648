import React from 'react';

import {
  Redirect,
  Route,
  withRouter
} from "react-router-dom";
import { ACCESS_TOKEN_NAME } from '../constants/apiConstants';
import Header from '../components/Layouts/Header';
import ScrollToTop from './ScrollToTop'

const getCookie = (name) => {
  var dc = document.cookie;
  var prefix = name + "=";
  var begin = dc.indexOf("; " + prefix);
  if (begin === -1) {
    begin = dc.indexOf(prefix);
    if (begin !== 0) return null;
  }
  else {
    begin += 2;
    var end = document.cookie.indexOf(";", begin);
    if (end === -1) {
      end = dc.length;
    }
  }
  // because unescape has been deprecated, replaced with decodeURI
  //return unescape(dc.substring(begin + prefix.length, end));
  return decodeURI(dc.substring(begin + prefix.length, end));
}

function PrivateRoute({ component: Component, children, ...rest }) {
  //console.log('tes');

  return (
    <Route
      {...rest}
      render={props =>
        getCookie(ACCESS_TOKEN_NAME) ? (
          <>
            <ScrollToTop />
            <Header>
              {<Component {...props} />}
            </Header>
          </>
        ) : (
          <Redirect
            to="/"
          />
        )
      }
    />
  );
}

export default withRouter(PrivateRoute);
import React, { useEffect, useState } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { reactLocalStorage } from "reactjs-localstorage";
import styled from "styled-components";

import { BrowserRouter as Router, Link } from "react-router-dom";
import { Fade } from "react-bootstrap";


const StyledLink = styled(Link)`
   color: ${(props) => props.fontColor} !important;
   text-decoration: none;
   transition: color 0.2s ease-in-out !important;
`;

// function hexToRgb(hex) {
//    // Convert hex to RGB values
//    const r = parseInt(hex.substring(1, 3), 16);
//    const g = parseInt(hex.substring(3, 5), 16);
//    const b = parseInt(hex.substring(5, 7), 16);

//    const rgbaColor = `rgba(${r}, ${g}, ${b}, 0.7)`;
//    return rgbaColor;
// }

const ListSector = (props) => {
   const [screenSize, getDimension] = useState({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
   });

   const setDimension = () => {
      getDimension({
         dynamicWidth: window.innerWidth,
         dynamicHeight: window.innerHeight,
      });
   };

   useEffect(() => {
      window.addEventListener("resize", setDimension);

      return () => {
         window.removeEventListener("resize", setDimension);
      };
   }, [screenSize]);

   const loadItem = (dimension) => {
      let value = 4;

      if (dimension.dynamicWidth >= 0 && dimension.dynamicWidth < 481) value = 1;
      if (dimension.dynamicWidth >= 481 && dimension.dynamicWidth < 768) value = 2;
      if (dimension.dynamicWidth >= 768 && dimension.dynamicWidth < 992) value = 3;
      if (dimension.dynamicWidth >= 992) value = 4;

      return value;
   };

   const handleDescript = (desc) => {
      let dsc = "";
      if (desc.length > 80) {
         dsc = desc.substr(0, 79) + "....";
      } else {
         dsc = desc;
      }
      return dsc;
   };

   return (
      <CarouselProvider
         naturalSlideWidth={40}
         naturalSlideHeight={30}
         visibleSlides={loadItem(screenSize)}
         totalSlides={props.value.length}
         // className="carousel-custom"
      >
         <Slider>
            <div>
               {props.value.map((data, key) => (
                  <Slide className="carousel-slide-custom" key={key} index={key}>
                     <div className={`col h-100 ${key == 0 ? "pl-0" : null} ${key + 1 == loadItem(screenSize) ? "pr-0" : null} `}>
                        <div className="list-info h-100">
                           <div className="card-title mb-0">
                              <h5>{data.nama_sektor}</h5>
                           </div>
                           <div data-testid={`${"desc-info-" + key}`} className="desc-info">
                              {handleDescript(data.deskripsi)}
                           </div>
                           <div className="link-info">
                              <StyledLink to={data.link} fontColor={reactLocalStorage.getObject("base_color")?.color4}>
                                 <p className="link-info-tag">
                                    Lihat Selengkapnya <i className="fa fa-long-arrow-alt-right"></i>
                                 </p>
                              </StyledLink>
                           </div>
                           <div className="line-sector" style={{ borderColor: reactLocalStorage.getObject("base_color")?.color4 }}></div>
                        </div>
                     </div>
                  </Slide>
               ))}
            </div>
         </Slider>
      </CarouselProvider>
   );
};

export default ListSector;

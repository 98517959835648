import React, { useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import { reactLocalStorage } from 'reactjs-localstorage';

import 'bootstrap-daterangepicker/daterangepicker.css';
import HargaPanganPerJenisKomoditas from './HargaPanganPerJenisKomoditas/index';
import HargaRataRataDanPerubahan from './HargaRataRataDanPerubahan';
import axios from 'axios';
import { API_BASE_URL } from '../../../../constants/apiConstants';

const HargaPangan = () => {
    const baseColor = reactLocalStorage.getObject('base_color');  
    const [listJenis, setListJenis]         = useState();
    const [jenis, setJenis]                 = useState();
    const [tanggal, setTanggal]             = useState();
    const [pasar, setPasar]                 = useState("Pasar Tradisional");

    useEffect(() => {
        axios
        .get(
            `${API_BASE_URL}sosial-ekonomi/harga-pangan/filter-jenis`
        )
        .then(({ data: { data }, status }) => {
            setListJenis(data);
            setJenis(data[2].value);
        })
        .catch(() => {});            
    }, []);

    useEffect(() => {
        axios
        .get(
            `${API_BASE_URL}sosial-ekonomi/harga-pangan/filter-tanggal`
        )
        .then(({ data: { data }, status }) => {
            setTanggal(data.tanggal);
        })
        .catch(() => {});            
    }, []);

    return (
        <>
            <p className="h3 mb-3">Harga Pangan</p>
            <span
                className="line-2nd mb-3"
                style={{ borderBottomColor: baseColor.color2 }}
            ></span>

            <div className="form-inline">
                <div className="form-group mb-2 mr-2 col-4-auto pl-0">
                    <label  className="col-auto pl-0">Jenis</label>
                    <div className='col pl-0'>
                        <select className={`form-control form-control-md `}
                            data-testid="select-jenis"
                            value={jenis}
                            onChange={(event) => {setJenis(event.target.value)}}
                        >
                            {
                                listJenis?.map((v, i)=>{
                                    return (
                                        <option key={i} value={v.value} data-nama={v.nama}>{v.nama}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group mb-2">
                    <label className="col-auto pl-0">Tanggal</label>
                    {
                        tanggal 
                        ?
                            <DateRangePicker
                                data-testid="date-range-picker"
                                initialSettings={{
                                    singleDatePicker: true,
                                    showDropdowns: false,
                                    startDate: moment(tanggal),
                                    minYear: 2020,
                                    maxYear: parseInt(moment().format('YYYY'), 10),
                                    locale: { format: "DD MMM YYYY" },
                                    autoApply: true,
                                }}
                                onCallback={(start) => {
                                    const date = moment(start).format('YYYY-MM-DD');
                                    setTanggal(date)
                                }}
                            >
                                <input type="text" className="form-control col-6" />
                            </DateRangePicker>
                        :
                        ""
                    }
                </div>
                <div className="form-group mb-2">
                    <label className="col-auto pl-0">Pasar</label>
                    <div className='col pl-0'>
                        <select className={`form-control form-control-md `}
                            data-testid="select-pasar"
                            value={pasar}
                            onChange={(event) => {setPasar(event.target.value)}}
                        >
                            <option value="Pasar Tradisional">Pasar Tradisional</option>
                            <option value="Pasar Modern">Pasar Modern</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-12"><HargaPanganPerJenisKomoditas jenis={jenis} tanggal={tanggal} pasar={pasar}/></div>
            </div>
            <div className="row mb-4">
                <div className="col-md-12"><HargaRataRataDanPerubahan jenis={jenis} tanggal={tanggal} pasar={pasar}/></div>
            </div>
        </>
    )
}

export default HargaPangan
import React, { useEffect } from 'react';
import axios from 'axios';
import '../../App.css';
import { API_GET_TOKEN_NOLOGIN } from '../../constants/apiConstants';
import poweredTelkom from "../../assets/img/powered-telkom.svg";
import poweredBigbox from "../../assets/img/powered-bigbox.svg";
import bgImg from "../../assets/img/bg_login.svg";
import MySwal from '../../utils/MySwal'
import Cookies from 'universal-cookie';
import { reactLocalStorage } from "reactjs-localstorage";

const NoLogin = (props) => {
  /**
  * by pass handleSubmitClick()
  */
   useEffect(() => {
    handleSubmitClick();
  }, [])

  /**
   * Handle submit
   */
  const handleSubmitClick = (e) => {

    axios.post(`${API_GET_TOKEN_NOLOGIN}`)
      .then(({ data, status }) => {
        if (status === 200) {
          MySwal.fire({
            // title: 'Login Berhasil',
            title: 'Selamat Datang',
            // text: 'Redirecting...',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            timerProgressBar: true,
          }).then(() => {
            var now = new Date();
            var time = now.getTime();
            var expireTime = time + 3600;
            now.setTime(expireTime);
            let cookies = new Cookies();
            // cookies.set('login_access_token', `Bearer ${data.data.profile.api_token}`, { path: '/', maxAge: 18000 });            
            cookies.set(  'login_access_token', `Bearer ${data.data.token}`, { path: '/', maxAge: 18000, sameSite: 'none', secure: true } );
            reactLocalStorage.setObject("nologin", {enable:true});
            props.history.push('/');
          })

        }
      })
      .catch(function ({ response }) {
        
    });
}

    const getCurrentYear = () => {
    return new Date().getFullYear();
    };

    return (
        <>
            <div className="box-login">
                <div className="right-login" style={{ backgroundImage: `url(${bgImg})` }}>
                <div className="bg-overlay" >
                    
                </div>
                <div className="box-ttl-login">
                    <div className="row">
                    <div className="col">
                    <span className="ttl-1">Selamat datang di</span>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col">
                    <span className="ttl-2">Integrated Dashboard<br></br> Smart Government</span>
                    </div>
                    </div>
                </div>
                    
                </div>
                <div className="left-login">
                <div className="box-table-login">
                    <div className="display-table">
                    <div className="display-table-cell">
                        {/* <div className="box-action-login"> </div> */}
                    </div>
                    </div>
                </div>
                </div>

                <div className='powered-contain'>
                <div>Powered by</div>
                <div className="powered">
                    <div className="powered-img">
                    <img src={poweredBigbox} alt="" />
                    </div>
                    <div className="powered-img">
                    <img src={poweredTelkom} alt="" />
                    </div>
                </div>
                </div>

                <footer>
                {getCurrentYear()} © BigView. All Right Reserved
                </footer>
            </div>
        </>
    )
}

export default NoLogin
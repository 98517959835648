import React from 'react';

 const Satuan = (indikatorTitle) => {
    let satuan = ""

    if ((indikatorTitle.indexOf("Luas Panen") !== -1  ) || (indikatorTitle.indexOf("Luas Areal") !== -1  ) ){
        satuan = "ha";
    } else if (indikatorTitle.indexOf( "Produksi") !== -1) {
        satuan = "ton"
    } else {
        satuan = ""
    }

    return satuan;
}

export default Satuan;
import React from 'react';
import { BackgroundShadow } from '../../../PotensiDaerah/SDA/Utils';
import Datatable from '../../../../Datatable'

import esStyle from '../../../PotensiDaerah/SDA/index.module.css';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { API_BASE_URL } from '../../../../../constants/apiConstants';
import ResApiStatus from '../../../../../utils/ResApiStatus';
// import esStyle from '../../../PotensiDaerah';
// import error from '../../../../../assets/img/error.svg';

const Tabulasi = (props) => {
    const {jenis, komoditas, pasar, tanggal, kota, periode}    = props;
    const [stateDataTable, setStateDataTable]           = useState({ tableData: {}, title: ""});
    const [load, setLoad]                               = useState(false);
    const [successResponse, setSuccessResponse]         = useState(true)

    useEffect(() => {
        if (    (typeof jenis   === "undefined") || (typeof komoditas === "undefined") ||  
                (typeof tanggal === "undefined") || (typeof pasar     === "undefined") ||
                (typeof kota    === "undefined") || (typeof periode   === "undefined") 
    ) {
        return;
    }
        
        setLoad(false)
        axios
        .get(
            `${API_BASE_URL}sosial-ekonomi/harga-pangan/perubahan-harga/tabulasi?jenis=${jenis}&komoditas=${komoditas}&kabkot=${kota}&periode=${periode}&tanggal=${tanggal}&pasar=${pasar}`
        )
        .then(({ data: { data } }) => {
            setLoad(true)
            setSuccessResponse(true)
            setStateDataTable({
              tableData: {
                ...data.data.data,
                base_endpoint:  `${API_BASE_URL}sosial-ekonomi/harga-pangan/harga/tabulasi?jenis=${jenis}&komoditas=${komoditas}&kabkot=${kota}&periode=${periode}&tanggal=${tanggal}&pasar=${pasar}`,
                right:[1]
              },
              title: data.title
            });
          })
          .catch((err) => {
            setLoad(true)
            setSuccessResponse(false)
            setStateDataTable((prev) => ({
                ...prev,
                tableData: {},
                title: "",
            }));
          });
    }, [jenis, komoditas, pasar, kota, periode, tanggal]);

    return (
        <div className={esStyle.leafletContainer}>
            {
                load
                ?
                    successResponse
                    ?
                        <Datatable serverside={false} {...stateDataTable.tableData} />
                    :
                        <ResApiStatus status='error' height='300px' message='Terjadi kesalahan di server'/>
                :
                    <div className="position-relative" style={{height:'300px'}}><BackgroundShadow content="" background="#c0c2c6" /></div>
            }
        </div>
    )
}

export default Tabulasi
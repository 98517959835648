// import React from "react";
// import Loading from "../../../../utils/Loading";
// import { reactLocalStorage } from "reactjs-localstorage";

// // import useLoadIframe from '../../../../utils/useLoadIframe';

// const index = (props) => {
//   const link = "https://bigmarket.bigbox.co.id/dashboard/3217";
//   const baseColor = reactLocalStorage.getObject("base_color");

//   //   const [delayedState, status] = useLoadIframe(link)

//   return (
//     <>
//       <div className="row">
//         <div className="col-12">
//           <p className="h3 mb-3">E-commerce Analytic</p>
//           <span
//             className="line-2nd mb-4"
//             style={{ borderBottomColor: baseColor.color2 }}
//           ></span>
//         </div>
//       </div>

//       {/* {delayedState === null ? <Loading /> : null} */}
//       <div className={baseColor.kode_kab_kota === "3217" ? "block" : baseColor.kode_kab_kota === "3300" ? "block" : "d-none"}>
//       <iframe
//         data-testid="iframe-news"
//         src={link}
//         frameBorder="0"
//         width="100%"
//         style={{
//           width: "1px",
//           minWidth: "100%",
//           //   visibility: delayedState === null ? 'hidden' : 'visible',
//           height: "3200px",
//           //   maxHeight: delayedState === null ? '50vh' : 'unset',
//           backgroundColor: "#ffffff",
//         }}
//       />
//       </div>
//     </>
//   );
// };

// export default index;
import React, { useState, useEffect } from 'react'
import TabsSummary from "./Tabs/Summary"
import TabsTokopedia from './Tabs/Tokopedia'
import TabsShopee from './Tabs/Shopee'
import TabsBukalapak from './Tabs/Bukalapak'
import '../../../../assets/css/Analytics/Analytics.css'
import {API_GET_ANALYTICS} from '../../../../constants/apiConstants';
import axios from 'axios';
import {reactLocalStorage} from 'reactjs-localstorage';

const Index = () => {
  const [state, setState] = useState({
    linkSummary:'https://demo.bigbox.co.id/bigmarket/public/summary/_token/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI2ODI1NzQiLCJ1bmFtZSI6ImFkbWluX2JpZ21hcmtldF9wYXN1cnVhbiJ9.DmJ5Y9FxCc9B-4_GeXiN8SnY-KNEPMC6IYi3oM1__Eo?header=false&v2=true',
    linkTokpedia: 'https://demo.bigbox.co.id/bigmarket/public/tokopedia/_token/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI2ODI1NzQiLCJ1bmFtZSI6ImFkbWluX2JpZ21hcmtldF9wYXN1cnVhbiJ9.DmJ5Y9FxCc9B-4_GeXiN8SnY-KNEPMC6IYi3oM1__Eo?header=false&v2=true',
    linkShopee: 'https://demo.bigbox.co.id/bigmarket/public/shopee/_token/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI2ODI1NzQiLCJ1bmFtZSI6ImFkbWluX2JpZ21hcmtldF9wYXN1cnVhbiJ9.DmJ5Y9FxCc9B-4_GeXiN8SnY-KNEPMC6IYi3oM1__Eo?header=false&v2=true',
    linkBukalapak: 'https://demo.bigbox.co.id/bigmarket/public/bukalapak/_token/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI2ODI1NzQiLCJ1bmFtZSI6ImFkbWluX2JpZ21hcmtldF9wYXN1cnVhbiJ9.DmJ5Y9FxCc9B-4_GeXiN8SnY-KNEPMC6IYi3oM1__Eo?header=false&v2=true',
  });
  const baseColor = reactLocalStorage.getObject('base_color');

  // useEffect(() => {
  //   axios.get(`${API_GET_ANALYTICS}`)
  //   .then(({data: {data}, status}) => {
  //     if (status === 200) {
  //       setState((prevState) => ({
  //         ...prevState,
  //         linkTwitter: data.bigbox_twitter,
  //         linkFacebook: data.bigbox_fb,
  //         linkInstagram: data.bigbox_ig,
  //       }));
  //     }
  //     })
  //   .catch(() => {})
  // }, [])

  return (
    <>      
      <div className="row">
        <div className="col-12">
          <p className="h3 mb-3">E-commerce Analytic</p>
          <span className="line-2nd mb-4" style={{borderBottomColor : baseColor.color2}}></span>
        </div>
      </div>

      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
        <a className="nav-link active" id="nav-summary-tab" data-toggle="tab" href="#nav-summary" role="tab" aria-controls="nav-summary" aria-selected="true">Ringkasan</a>
          <a className="nav-link" id="nav-twitter-tab" data-toggle="tab" href="#nav-twitter" role="tab" aria-controls="nav-twitter" aria-selected="true">Tokopedia</a>
          <a className="nav-link" id="nav-facebook-tab" data-toggle="tab" href="#nav-facebook" role="tab" aria-controls="nav-facebook" aria-selected="false">Shopee</a>
          <a className="nav-link" id="nav-instagram-tab" data-toggle="tab" href="#nav-instagram" role="tab" aria-controls="nav-instagram" aria-selected="false">Bukalapak</a>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
      <div className="tab-pane fade  position-relative min-vh-50 bg-white show active" id="nav-summary" role="tabpanel" aria-labelledby="nav-summary-tab">
          <TabsSummary link={state.linkSummary}></TabsSummary>
        </div>
        <div className="tab-pane fade  position-relative min-vh-50 bg-white" id="nav-twitter" role="tabpanel" aria-labelledby="nav-twitter-tab">
          <TabsTokopedia link={state.linkTokpedia}></TabsTokopedia>
        </div>
        <div className="tab-pane fade  position-relative min-vh-50 bg-white" id="nav-facebook" role="tabpanel" aria-labelledby="nav-facebook-tab">
          <TabsShopee link={state.linkShopee}></TabsShopee>
        </div>
        <div className="tab-pane fade  position-relative min-vh-50 bg-white" id="nav-instagram" role="tabpanel" aria-labelledby="nav-instagram-tab">
          <TabsBukalapak link={state.linkBukalapak}></TabsBukalapak>
        </div>
      </div>
    </>
  )
}

export default Index


export const dataWarningSA = ({title, html, confirmButtonText = null, icon, confirmButtonColor, denyButton}) => {
  if (confirmButtonText === null) {
    confirmButtonText = 'Simpan'
  }

  const data = {
    customClass: {
      denyButton: denyButton? denyButton :'btnDeny',
    },
    icon: icon? 'warning' : false,
    title,
    html,
    showDenyButton: true,
    confirmButtonText,
    confirmButtonColor: confirmButtonColor? confirmButtonColor : '#3989DA',
    denyButtonText: `Batal`,
    denyButtonColor: '#3989DA',
    reverseButtons: true
  }

  return data;
}

const pad = (num) => {
  return ("0" + num).slice(-2);
}

export const hhmmss = (secs) => {
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60)
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}
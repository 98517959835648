import React, { useState, useEffect } from "react";
import { BackgroundShadow, Satuan } from '../../../PotensiDaerah/SDA/Utils';
// import ColumnChart from '../../../../Charts/ColumnChart';


import { NumberWithDot, NumberFormat } from '../../../../Material/FormatNumber';


import esStyle from '../../../PotensiDaerah/SDA/index.module.css';
import AreaChart2 from "../../../../Charts/AreaChart2";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import axios from "axios";
// import esStyle from '../../../PotensiDaerah';

const Chart = (props) => {
  const {jenis, komoditas, tanggal, pasar, kota, periode}     = props;
  const [load, setLoad]                     = useState(false)
  const [dataChart, setDataChart]           = useState({data: [], categories: [], options: {}});

  useEffect(() => {
    if (  (typeof jenis   === "undefined") || (typeof komoditas === "undefined") ||  
          (typeof tanggal === "undefined") || (typeof pasar     === "undefined") ||
          (typeof kota    === "undefined") || (typeof periode   === "undefined") 
    ) {
        return;
    }
    
    setLoad(false)
    axios
    .get(
        `${API_BASE_URL}sosial-ekonomi/harga-pangan/perubahan-harga/chart-area?jenis=${jenis}&komoditas=${komoditas}&kabkot=${kota}&periode=${periode}&tanggal=${tanggal}&pasar=${pasar}`
    )
    .then(({ data: { data } }) => {            
        setLoad(true)
        setDataChart({
          data: data.widget_data.chart_data,
          categories: data.widget_data.chart_categories,
          options: {
            chart: {
              height:'250px'
            },
            // colors:[props.color],
             tooltip: {
              formatter: function () {
                return `<div style="border-color:${this.color}">
                    <table>
                      <tr>
                        <td>${kota} </td>
                      </tr>
                      <tr>
                        <td> <strong>${this.x} </strong> </td>
                      </tr>
                      <tr>
                        <td>Harga ${jenis}: <strong>Rp ${NumberWithDot(this.y, 2)} </strong></td>
                      </tr>
                    </table>
                  </div>`;
              },
               shared: true,
               useHTML: true,
             },
             xAxis: {
              //  min: 0,
              //  max: 4,
              //  scrollbar: {
              //    enabled: false,
              //  },
               title: {
                 text: null,
               },
             },
             yAxis: {
               title: {
                 text: `Harga ${jenis} per Kg (Rp)`,
               },
               labels: {
                 formatter: function () {
                   return NumberFormat(this.value, 1);
                 },
               },
             },
             plotOptions: {
              area: {
                marker: {
                  enabled: true,
                }
              }
             }
           }
        })

      })
      .catch(() => {});
}, [jenis, tanggal, pasar, komoditas, kota, periode]);



  return (
    <div className={esStyle.leafletContainer}>
      {
        load 
          ?
          <AreaChart2
            key="bar-top-luas-panen"
            data={dataChart.data}
            categories={dataChart.categories}
            options={dataChart.options}
            tooltip={dataChart.options.tooltip}
            />
          : <div className="position-relative" style={{height:'300px'}}><BackgroundShadow content="" background="#c0c2c6" /></div>
        }
    </div>
  )
}

export default Chart
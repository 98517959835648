import React, { useState, useEffect, forwardRef } from 'react';

import esStyle from './EkonomiSda.module.css';

import LineChart from '../../../Charts/LineChart';

import axios from 'axios';
import { API_BASE_URL } from '../../../../constants/apiConstants';
import { NumberWithDot, NumberFormat } from '../../../Material/FormatNumber';
import YearPicker from '../../../../utils/YearPicker';

const LineTrendTrade = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [statusDate, setStatusDate] = useState(false);
  const [statusChart, setStatusChart] = useState('');
  const [yearWData, setYearWData] = useState([]);

  const [dataChart, setDataChart] = useState({
    data: [],
    categories: [],
    options: {
      tooltip: {
        formatter: function () {
          return `<div style="border-color:${this.color}">
              <table>
                <tr>
                  <td> <span>${startDate.getFullYear() || new Date().getFullYear()} </span> </td>
                </tr>
                <tr>
                  <td> <span>${this.x}: </span> </td>
                  <td> <span><strong>Rp ${NumberWithDot(
                    this.y
                  )}</strong></span></td>
                </tr>
              </table>
            </div>`;
        },
        shared: true,
        useHTML: true,
      },
      xAxis: {
        title: {
          text: 'Bulan',
        },
      },
      yAxis: {
        title: {
          text: 'Nominal',
        },
        labels: {
          formatter: function () {
            return NumberFormat(this.value, 1);
          },
        },
      },
      plotOptions: {
        series: {
            color: props.color
        }
      },
    },
  });
  const [word, setWord] = useState('');

  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}potensi_daerah/get-ekonomi-trend-perdagangan-kecamatan/year`
      )
      .then(({ data: { data, status } }) => {
        setStartDate(new Date(`${data.selected_years}`));
        setYearWData(data.years);
      })
      .catch(() => {})
      .finally(() => {
        setStatusDate(true);
      });
  }, []);

  useEffect(() => {
    if (statusDate === false) { return; }
    axios
      .get(
        `${API_BASE_URL}potensi_daerah/get-ekonomi-trend-perdagangan-kecamatan?year=${startDate.getFullYear() || new Date().getFullYear()}`
      )
      .then(({ data: { data, sumber_data, year, status } }) => {
        setStatusChart(status);
        setWord(sumber_data);
        let tempCategory = [];
        let tempData = [];

        data.forEach((dt, index) => {
          tempCategory[index] = dt.city;
          tempData[index] = dt.pad;
        });

        setDataChart({
          data: tempData,
          categories: tempCategory,
        });
      })
      .catch(() => {});
  }, [startDate, statusDate]);

  return (
    <>
      <p className={esStyle.textEs4}>Trend Perdagangan by Kecamatan</p>
      {statusChart !== 'Blank' ? (
        <>
          <div className={`text-right ${esStyle.marginBottom20}`}>
            <div className={esStyle.divEs}>
              <YearPicker
                yearWithData={yearWData}
                onChange={(date) => setStartDate(date)}
                selected={startDate}
              />
            </div>
          </div>
        </>
      ) : (
        ''
      )}

      <LineChart
        key="eco-line-trend-trade"
        data={dataChart.data}
        categories={dataChart.categories}
        options={dataChart.options}
      />

      {statusChart === 'Exists' ? (
        <>
          <p className={`${esStyle.textEs5} ${esStyle.colorEs3}`}>{word}</p>
        </>
      ) : (
        <p className={`${esStyle.textEs5} ${esStyle.colorEs3} text-center`}>
          {statusChart === 'Empty'
            ? 'Tidak ada data untuk ditampilkan'
            : 'Data tidak tersedia'}
        </p>
      )}
    </>
  );
};

export default LineTrendTrade;

import React, { useRef } from 'react';
import {
  withRouter
} from 'react-router-dom';

import Logo from '../../assets/img/whitelogo.svg'
import HeaderGlobalStyle from './HeaderGlobal.module.css'

const HeaderGlobal = (props) => {
  const year = useRef(new Date().getFullYear());

  return (
    <div className={HeaderGlobalStyle.body}>
      <img src={Logo} className={HeaderGlobalStyle.img} alt="logo idsg" />

      {props.children}

      <footer className={HeaderGlobalStyle.footer}>
        <p className={`text-center ${HeaderGlobalStyle.text2} ${HeaderGlobalStyle.color2}`}>
          {year.current} © BigView. All Right Reserved
        </p>
      </footer>
    </div>
  )
}

export default withRouter(HeaderGlobal);
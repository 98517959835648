import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import EmptyPie from '../../../src/assets/img/EmptyPie.svg';
import {NumberWithDot, NumberFormat} from '../Material/FormatNumber';
import Loading from '../../utils/Loading';
import {reactLocalStorage} from 'reactjs-localstorage';
import Values from 'values.js';
  
let mounted = false;

const getBaseColors = () => {
  const baseColor = reactLocalStorage.getObject('base_color');
  const listColors = new Values(baseColor?.color1).shades(12)
  let pieColors = [];
  for(var i = 0; i < listColors.length; i++){
    pieColors.push(listColors[i].hexString())
  }
  return pieColors;
}

const getCustomColors = () => {
  const baseColor = reactLocalStorage.getObject('base_color');
  const listColors = new Values(baseColor?.color1).tints(20)
  let pieColors = [];
  for(var i = 0; i < listColors.length; i++){
    pieColors.push(listColors[i].hexString())
  }
  return pieColors;
}

let chartOptions = {
  chart: {
    type: 'pie',
    height: '250px',
  },
  title: {
    text: '',
  },
  legend: {
    align: 'right',
    layout: 'vertical',
    verticalAlign: 'middle',
  },
  tooltip: {
    formatter: function () {
      return (
        `<div style="border-color:${this.color}">
          <table>
            <tr>
              <td> <span>${this.key}: </span> </td>
              <td> <span><strong>${NumberWithDot(this.y, 2)} (${NumberWithDot(this.percentage, 2)}%)</strong></span></td>
            </tr>
          </table>
        </div>`
      );
    },
    shared: true,
    useHTML: true,
  },
  // tooltip: {
  //   pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  // },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      colors: getBaseColors(),
      showInLegend: true,
      dataLabels: {
        enabled: true,
        // format: '<br>{point.percentage:.2f} %',
        formatter: function() {
          return (`<span style="font-style: normal; font-weight: normal; font-family: DM Sans, sans-serif; font-size: 12px;">${NumberWithDot(this.percentage, 2)} %</span>`)
        },
        distance: -50,
        filter: {
          property: 'percentage',
          operator: '>',
          value: 2,
        },
      },
    },
  },
  series: [
    {
      name: '',
      colorByPoint: true,
      data: [],
    },
  ],
  credits: false,
  loading: {
    buffer: true,
    flag: true,
  },
};

const ChartContainer = ({children}) => (
  <>
    <div className="position-relative">
      <div className="flex-center mt-3 mb-5"> 
        {children}
      </div>
    </div>
  </>
)

const PieChart = (props) => {
  const { data, options, donat, tooltip , isHeight, customColor, customFormatter, isShimmer} = props;
  const [state, setState] = useState(chartOptions);
  let customTooltip = tooltip ? tooltip : {
    formatter: function () {          
      return (
        `<div style="border-color:${this.color}">
          <table>
            <tr>
              <td> <span>${this.key}: </span> </td>
              <td> <span><strong>${NumberWithDot(this.y, 2)} (${NumberWithDot(this.percentage, 2)}%)</strong></span></td>
            </tr>
          </table>
        </div>`
      );
    },
    shared: true,
    useHTML: true,
  };
  useEffect(() => {
    if (typeof data === 'undefined') { return; }
    setState({
      ...state,
      ...options,
      chart: {
        ...state.chart,
        ...options.chart,
      },
      plotOptions: {
        ...state.plotOptions,
        pie: {
          ...state.plotOptions.pie,
          colors: donat || customColor? getCustomColors() : getBaseColors(),
          dataLabels: {
            ...state.plotOptions.pie.dataLabels,
            distance: donat? -35 : -50,
            formatter: donat || customFormatter? function() {
              return (`<span style="font-style: normal; font-weight: reguler; font-family: DM Sans, sans-serif; font-size: 12px;">${NumberWithDot(this.percentage, 2)} %</span>`)
            } : function() {
              return (`<span style="font-style: normal; font-weight: normal; font-family: DM Sans, sans-serif; font-size: 12px;">${NumberWithDot(this.percentage, 2)} %</span>`)
            },
          }
        }
      },
      tooltip:customTooltip,
      series: [
        {
          ...state.series[0],
          data: data,
          innerSize: donat? '60%': '0%'
        },
      ],
      loading: {
        buffer: true,
        flag: !state.loading.flag,
      }
    });
  }, [props]);

  useEffect(() => {
    /**
     * Mounted used because of default data is empty []
     * and we want to show loading gif instead of empty image
     */
    if (mounted) {
      setState((prev) => ({
        ...prev,
        loading: {
          flag: state.loading.flag,
          buffer: false,
        }
      }))
    }
    mounted = true;
  }, [state.loading.flag])

  return (
    <>
      {
        (props.data === undefined || props.loading ||  state.loading.buffer === true) ?
            <ChartContainer>
              <Loading />
            </ChartContainer>
        : (state.series[0].data && state.series[0].data.length) ?
          <HighchartsReact highcharts={Highcharts} options={state} />
        : 
          <ChartContainer>
            <img src={EmptyPie} alt="Empty Data" />
          </ChartContainer>
      }
    </>
  );
};

PieChart.defaultProps = {
  options: {
  },
};
export default React.memo(PieChart);

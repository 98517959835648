import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from 'react-router-dom';
import { API_BASE_URL } from '../../../../constants/apiConstants';
import '../../../../App.css';
import style from './Roles.module.css';
// import Loading from '../../../../utils/Loading';
import CustomAlert from '../../../Material/CustomAlert';
import SimpleDatatable from '../../../Material/SimpleDatatable';
import IconEdit from '../../../../assets/img/icon-user-edit.svg';
import IconDelete from '../../../../assets/img/icon-delete.svg';
import MySwal from '../../../../utils/MySwal';
import { dataWarningSA } from '../../../../utils/tools';
import { randomNumber } from '../../../../utils/random';


const Roles = (props) => {
  const history = useHistory();
  const [alert, setAlert] = useState({
    isShow: false,
    type: 'success',
    message: '',
  });

  /**
   * State for *component select option usecase*
   */
  const [usecase, setUsecase] = useState([]);
  const [selectedIdUsecase, setSelectedIdUsecase] = useState(null);

  /**
   * State roles
   * Used at *component table roles*
   */
  const [roles, setRoles] = useState([]);
  const header = useRef([
    {
      name: 'no',
      value: 'No',
      sortable: false,
    },
    {
      name: 'action',
      value: 'Action',
      sortable: false,
    },
    {
      name: 'nama_role',
      value: 'Nama Role',
      sortable: true,
    },
  ]);
  const listPerPage = useRef([5, 10, 25, 50]);
  const [page, setPage] = useState(`${API_BASE_URL}${API_BASE_URL}role?page=1`);
  const [datapaging, setDatapaging] = useState([]);
  const [isLoadingDT, setIsLoadingDT] = useState(false);
  const [flagDeleted, setFlagDeleted] = useState(false)
  const [search, setSearch] = useState('');
  const [per_page, setPerPage] = useState(listPerPage.current[0]);
  const [sortField, setSortField] = useState({
    column: '',
    dir: '',
  });
  const [fromItem, setFromItem] = useState(0);


  /**
   * State for *component select option role menu*
   */
  const [selectedCatMenu, setSelectedCatMenu] = useState('Menu Utama');
  const [turunanDari, setTurunanDari] = useState([]);
  const [selectedTurunanDari, setSelectedTurunanDari] = useState(null);

  /**
   * State for *component table role menu*
   */
  const [menus, setMenus] = useState([]);
  const [roles2, setRoles2] = useState([]);
  const [rbac, setRbac] = useState([]);
  const [flagChecked, setFlagChecked] = useState(false);

  /**
   * ======================================================================================
   */

  /**
   * Effect for *component select option usecase*
   */
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}usecase/list`)
      .then(({ data: { data } }) => {
        if (data.length <= 0) {
          return;
        }
        setUsecase(data);
        setSelectedIdUsecase(data[0].id_usecase);
      })
      .catch(() => {});
  }, []);

  /**
   * Effect to set roles
   * Used at *component table roles*
   */
  useEffect(() => {
    if (selectedIdUsecase === null) {
      return;
    }
    setPage(`${API_BASE_URL}role?page=1`);

    axios
      .get(
        `${API_BASE_URL}role?per_page=${per_page}&page=1&order_column=${sortField.column}&order_dir=${sortField.dir}&keyword=${search}&id_usecase=${selectedIdUsecase}`
      )
      .then(({ data: { data, links, from } }) => {
        setFromItem(from);
        setRoles(data);
        setDatapaging(links);
        setIsLoadingDT(false);
      })
      .catch(() => {});
  }, [selectedIdUsecase, per_page, sortField, search, flagDeleted]);

  /**
   * Effect for 'component select option role menu'
   */
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}manage/parent-menu`)
      .then(({ data: { data } }) => {
        setTurunanDari(data);
        setSelectedTurunanDari(null);
      })
      .catch(() => {});
  }, []);

  /**
   * Effect for *component table role menu*
   */
  useEffect(() => {
    if (selectedIdUsecase === null) {
      return;
    }
    const epMenu =
      selectedCatMenu === 'Menu Utama'
        ? 'menu-utama'
        : `child-menu?id_parent=${selectedTurunanDari}`;

    axios
      .get(`${API_BASE_URL}manage/${epMenu}`)
      .then(({ data: { data } }) => {
        setMenus(data);
      })
      .catch(() => {});

    axios
      .get(`${API_BASE_URL}user_menu/findByUseCase/${selectedIdUsecase}`)
      .then(({ data: { data } }) => {
        setRbac(data);
      })
      .catch(() => {});

    axios
      .get(`${API_BASE_URL}role/findByUseCase/${selectedIdUsecase}`)
      .then(({ data: { data } }) => {
        setRoles2(data.reverse());
      })
      .catch(() => {});
  }, [
    selectedIdUsecase,
    selectedCatMenu,
    selectedTurunanDari,
    flagChecked,
    flagDeleted,
  ]);

  /**
   * ======================================================================================
   */

  /**
   * Event handlers at roles table
   */
   const changeSearch = (data) => {
    setSearch(data);
  };
  const changePerPage = (data) => {
    setPerPage(data);
  };
  const changeSort = (data) => {
    setSortField(data);
  };
  const changePage = (data) => {
    setPage(data);

    setIsLoadingDT(true);
    axios
      .get(
        `${data}&per_page=${per_page}&order_column=${sortField.column}&order_dir=${sortField.dir}&keyword=${search}&id_usecase=${selectedIdUsecase}`
      )
      .then(({ data: { data, links, from } }) => {
        setFromItem(from);
        setRoles(data);
        setDatapaging(links);
        setIsLoadingDT(false);
      })
      .catch(() => {});
  };

  const btnAdd = () => {
    history.push(`/account-management/roles/create/${selectedIdUsecase}`);
  };

  const btnEdit = (e) => {
    let payload = {
      id_role: e.currentTarget.getAttribute('data-id_role'),
      id_usecase: e.currentTarget.getAttribute('data-id_usecase'),
      name_role: e.currentTarget.getAttribute('data-name_role'),
    };
    history.push({
      pathname: `/account-management/roles/update/${payload.id_role}/${payload.id_usecase}`,
      state: payload,
    });
  };

  const btnDelete = (e) => {
    const id = e.currentTarget.getAttribute('data-id_role');
    const nameRole = e.currentTarget.getAttribute('data-name_role');

    const data = {
      title: 'Hapus data',
      html: `Apakah Anda yakin ingin menghapus role <strong>${nameRole}</strong>?`,
      confirmButtonText: 'Hapus',
    };

    MySwal.fire(dataWarningSA(data)).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${API_BASE_URL}role/delete/${id}`)
          .then(({ data: { data } }) => {
            MySwal.fire('Berhasil', data?.message, 'success');
            setRoles((prev) => prev.filter((d) => d.id != id));
            setFlagDeleted(!flagDeleted);
          })
          .catch(() => {});
      }
    });
  };

  /**
   * Event handlers checkbox role menu table
   */
  const handleCheckRole = (e) => {
    e.preventDefault();
    const payload = {
      id_role: e.target.getAttribute('data-id_role'),
      id_menu: e.target.getAttribute('data-id_menu'),
    };
    e.target.style.cursor = 'not-allowed';
    e.target.setAttribute('disabled', true);
    const checkedRole = roles2.find(
      (r) => r.id_role == payload.id_role
    )?.nama_role;
    const checkedMenu = menus.find(
      (r) => r.id_menu == payload.id_menu
    )?.name_menu;
    if (e.target.checked) {
      axios
        .post(`${API_BASE_URL}user_menu/add`, payload)
        .then(() => {
          setAlert({
            isShow: true,
            type: 'success',
            message: `Akses Menu: <strong>${checkedMenu}</strong> untuk Role: <strong>${checkedRole}</strong> berhasil disimpan`,
          });
          setTimeout(() => {
            setAlert((prev) => ({ ...prev, isShow: false }));
          }, 5000);
          setFlagChecked(!flagChecked);
        })
        .catch(() => {
          setFlagChecked(!flagChecked);
        });
    } else {
      axios
        .delete(
          `${API_BASE_URL}user_menu/delete?id_role=${payload.id_role}&id_menu=${payload.id_menu}`
        )
        .then(() => {
          setAlert({
            isShow: true,
            type: 'success',
            message: `Akses Menu: <strong>${checkedMenu}</strong> untuk Role: <strong>${checkedRole}</strong> berhasil dihapus`,
          });
          setTimeout(() => {
            setAlert((prev) => ({ ...prev, isShow: false }));
          }, 5000);
          setFlagChecked(!flagChecked);
        })
        .catch(() => {
          setFlagChecked(!flagChecked);
        });
    }
  };

  return (
    <>
      {alert.isShow && (
        <CustomAlert type={alert.type} message={alert.message} />
      )}

      <>
        <div className={`${style.textBody1} mb-3`}>Role Menu</div>
        <div className="box-field-input d-flex align-items-center mb-3">
          <p className={`${style.textBody3} m-0 mr-4`}>Use Case</p>
          <select
            data-testid="select-usecase"
            type="text"
            id="id_usecase"
            name="id_usecase"
            placeholder="ID Use Case ..."
            className={`${style.selectOption} ${style.textBody3} ${style.colorBlack300}`}
            onChange={(e) => setSelectedIdUsecase(e.target.value)}
            value={selectedIdUsecase || ''}
          >
            {usecase.map((data) => (
              <option key={data.id_usecase} value={data.id_usecase}>
                {data.name_usecase}
              </option>
            ))}
          </select>
        </div>

        <div className="box-frame">
          <div className="row mb-2">
            <div className="col-6">
              <p data-testid="title-role" className={`${style.textBody2}`}>
                Role Management
              </p>
            </div>
            <div className="col-6 text-right">
              <button
                data-testid="button-add"
                className={`btn btn-dark`}
                onClick={btnAdd}
              >
                Add Role
              </button>
            </div>
          </div>

          <SimpleDatatable
            listPerPage={listPerPage.current} //useRef
            searchable={true}
            header={header} //useRef
            datapaging={datapaging}
            loading={isLoadingDT}
            //props
            changeSearch={changeSearch}
            changePerPage={changePerPage}
            changeSort={changeSort}
            changePage={changePage}
          >
            <tbody data-testid="simpledatatable-tbody">
              {roles.map((role, index) => (
                <tr key={`role-${role.id_role}-${index}-${randomNumber()}`}>
                  <td className={`${style.roleText}`}>{(parseInt(fromItem) || 0) + index}</td>
                  <td className={`${style.roleText}`}>
                    <img
                      src={IconEdit}
                      style={{cursor: 'pointer'}}
                      className="svgUser mx-1"
                      data-testid="button-edit"
                      onClick={btnEdit}
                      data-id_role={role.id_role}
                      data-id_usecase={role.id_usecase}
                      data-name_role={role.nama_role}
                      title="Edit Role"
                      alt=""
                    />
                    <img
                      src={IconDelete}
                      style={{cursor: 'pointer'}}
                      className="svgUser mx-1"
                      data-testid="button-delete"
                      onClick={btnDelete}
                      data-id_role={role.id_role}
                      data-id_usecase={role.id_usecase}
                      data-name_role={role.nama_role}
                      title="Hapus Role"
                      alt=""
                    />
                  </td>
                  <td className={`${style.roleText}`}>{role.nama_role}</td>
                </tr>
              ))}
            </tbody>
          </SimpleDatatable>
        </div>

        <div className="box-frame">
          <div className={`d-flex justify-content-between ${style.roleMenuHeader}`}>
            <div className="title-rolemenu">
              <p data-testid="title-rolemenu" className={`${style.textBody2}`}>
                Role Menu Management
              </p>
            </div>
            <div className={`select-option d-flex ml-auto ${style.selectOptionC}`}>
              <div className={`d-flex align-items-center mr-2 ${style.selectRoleMenuC}`}>
                <label className={`${style.textBody3} ${style.labelSelectRoleMenu} mr-2 my-0`} htmlFor="cat-menu">
                  Kategori Menu
                </label>
                <select
                  className={`form-control ${style.cursorPointer} ${style.textBody3} ${style.selectRoleMenu}`}
                  value={selectedCatMenu}
                  data-testid="select-menu"
                  onChange={(e) => {
                    setSelectedCatMenu(e.target.value);
                    if (e.target.value === 'Sub Menu') {
                      setSelectedTurunanDari(turunanDari[0].id_menu);
                    } else {
                      setSelectedTurunanDari(null);
                    }
                  }}
                >
                  <option value="Menu Utama">Menu Utama</option>
                  <option value="Sub Menu">Sub Menu</option>
                </select>
              </div>

              {selectedCatMenu === 'Sub Menu' && (
                <div className={`d-flex align-items-center mr-2 ${style.selectRoleMenuC}`}>
                  <label className={`${style.textBody3} ${style.labelSelectRoleMenu} mr-2 my-0`} htmlFor="cat-menu">
                    Turunan Dari
                  </label>
                  <select
                    className={`form-control ${style.cursorPointer} ${style.textBody3} ${style.selectRoleMenu}`}
                    data-testid="select-submenu"
                    value={selectedTurunanDari}
                    onChange={(e) => {
                      setSelectedTurunanDari(e.target.value);
                    }}
                  >
                    {turunanDari.map((cat) => (
                      <option
                        key={`turunan-${cat.id_menu}`}
                        value={cat.id_menu}
                      >
                        {cat.name_menu}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
          <p className={`text-center my-2 ${style.textBody4}`}>Menu</p>
          <div className="table-responsive">
            <table
              className="table table-hover table-striped"
              style={{ minHeight: 200 }}
            >
              <thead data-testid="thead-role-menu">
                <tr>
                  <th className={`${style.textBody4}`}>Roles</th>
                  {menus.map((menu, index) => (
                    <th className={`${style.textBody4} text-center`} key={`role-m-${index}-${menu.id_menu}-${randomNumber()}`}>{menu.name_menu}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {roles2.map((role, roleIndex) => (
                  <tr key={`role2-m-${roleIndex}-${randomNumber()}`}>
                    <td className={`${style.roleMenuText}`}>{role.nama_role}</td>
                    {menus.map((menu, menuIndex) => (
                      <td className={`text-center`} key={`td-role2-m-${role.id_role}-${menu.id_menu}-${randomNumber()}`}>
                        <input
                          type="checkbox"
                          style={{ cursor: 'pointer' }}
                          data-testid="checkbox-rolemenu"
                          data-id_role={role.id_role}
                          data-id_menu={menu.id_menu}
                          key={`checkbox-${selectedIdUsecase}-${role.id_role}-${
                            menu.id_menu
                          }-${randomNumber()}`}
                          checked={rbac.some(
                            (r) =>
                              r.id_role === role.id_role &&
                              r.id_menu === menu.id_menu
                          )}
                          onChange={handleCheckRole}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </>
  );
};

export default Roles;

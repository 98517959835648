import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../../constants/apiConstants';
import CustomAlert from '../../../../../Material/CustomAlert';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ]
};
const tempYear = new Date().getFullYear();

const EditVisi = (props) => {
  const [short_desc, setShortDesc] = useState('');
  const [description, setDescription] = useState('');
  const [successSubmit, setSuccessSubmit] = useState(false);

  useEffect(() => {
    axios.get(`${API_BASE_URL}visi/dataVisi/${props.idVisi}`)
      .then(({ data: { data } }) => {
        setShortDesc(data.short_desc);
        setDescription(data.description);
      })
      .catch((err) => {});
  }, [props]);

  const handleClick = () => {
    props.changePage('index', props.periode.id);
  }

  const handleChange = (e) => {
    if (e.target.name === 'short_desc') {
      setShortDesc(e.target.value);
    }
  };

  const submit = () => {
    let formData = new FormData();
    let year = props.periode.periode.split('-');
    
    formData.append('id', props.idVisi);
    formData.append('id_usecase', props.idUC);
    formData.append('start_year', year[0].trim());
    formData.append('end_year', year[1].trim());
    formData.append('short_desc', short_desc);
    formData.append('description', description);
    formData.append('id_periode', props.periode.id);

    axios.post(`${API_BASE_URL}visi/updateVisi`, formData)
      .then(() => {        
        setSuccessSubmit(true);
        setTimeout(()=> {
          handleClick();
        }, 2000)
      })
      .catch((err) => {});
  };

  return (
    <>
      {successSubmit && <CustomAlert page="Visi"></CustomAlert>}
      <div className="row rowMenu">
        <div className="col-6">
          <label className="my-1 mr-2 textMenu2">Periode {props.periode.periode}</label>          
        </div>
      </div>

      <div className="row rowMenu">
        <div className="col-12">
          <div className="form-group text-left">
            <label className="textMenu2 labelUC">Visi<span className="redText">*</span></label>
            <textarea name="short_desc" data-testid="short-desc" className="form-control" rows="8" value={short_desc} onChange={handleChange} />
          </div>
        </div>
      </div>

      <div className="row rowMenu">
        <div className="col-12">
          <div className="form-group text-left">
            <label className="textMenu2 labelUC">Detail Visi</label>
            <ReactQuill theme="snow" modules={modules} value={description} onChange={setDescription} />
          </div>
        </div>
      </div>

      <div className="row rowMenu">
        <div className="col-12 text-right">
          <button type="button" data-testid="btn-back" className="btn btn-outline-dark btnMenu" onClick={handleClick}>Kembali</button>
          <button type="button" data-testid="btn-save" className="btn btn-dark btnMenu" onClick={submit}>Simpan</button>
        </div>
      </div>
    </>
  );
}

export default EditVisi;
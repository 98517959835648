import React, { useState, useEffect } from 'react'
import TabsFacebook from './Tabs/Facebook'
import TabsInstagram from './Tabs/Instagram'
import TabsTwitter from './Tabs/Twitter'
import '../../../../assets/css/Analytics/Analytics.css'
import {API_GET_ANALYTICS} from '../../../../constants/apiConstants';
import axios from 'axios';
import {reactLocalStorage} from 'reactjs-localstorage';

const Index = () => {
  const [state, setState] = useState({
    linkTwitter: '',
    linkFacebook: '',
    linkInstagram: '',
  });
  const baseColor = reactLocalStorage.getObject('base_color');

  useEffect(() => {
    axios.get(`${API_GET_ANALYTICS}`)
    .then(({data: {data}, status}) => {
      if (status === 200) {
        setState((prevState) => ({
          ...prevState,
          linkTwitter: data.bigbox_twitter,
          linkFacebook: data.bigbox_fb,
          linkInstagram: data.bigbox_ig,
        }));
      }
      })
    .catch(() => {})
  }, [])

  return (
    <>      
      <div className="row">
        <div className="col-12">
          <p className="h3 mb-3">Social Media Analytics</p>
          <span className="line-2nd mb-4" style={{borderBottomColor : baseColor.color2}}></span>
        </div>
      </div>

      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <a className="nav-link active" id="nav-twitter-tab" data-toggle="tab" href="#nav-twitter" role="tab" aria-controls="nav-twitter" aria-selected="true">Twitter</a>
          <a className="nav-link" id="nav-facebook-tab" data-toggle="tab" href="#nav-facebook" role="tab" aria-controls="nav-facebook" aria-selected="false">Facebook</a>
          <a className="nav-link" id="nav-instagram-tab" data-toggle="tab" href="#nav-instagram" role="tab" aria-controls="nav-instagram" aria-selected="false">Instagram</a>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div className="tab-pane fade  position-relative min-vh-50 bg-white show active" id="nav-twitter" role="tabpanel" aria-labelledby="nav-twitter-tab">
          <TabsTwitter link={state.linkTwitter}></TabsTwitter>
        </div>
        <div className="tab-pane fade  position-relative min-vh-50 bg-white" id="nav-facebook" role="tabpanel" aria-labelledby="nav-facebook-tab">
          <TabsFacebook link={state.linkFacebook}></TabsFacebook>
        </div>
        <div className="tab-pane fade  position-relative min-vh-50 bg-white" id="nav-instagram" role="tabpanel" aria-labelledby="nav-instagram-tab">
          <TabsInstagram link={state.linkInstagram}></TabsInstagram>
        </div>
      </div>
    </>
  )
}

export default Index

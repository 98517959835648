import React, { useState, useEffect } from 'react';
import style from './Index.module.css';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants/apiConstants';
import { reactLocalStorage } from 'reactjs-localstorage';
import BlankImg from '../../../assets/img/sektorempty.svg';
import Loading from '../../../utils/Loading';

const Index = () => {
  const baseColor = reactLocalStorage.getObject('base_color');
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    url: '',
    informasi: ''
  });
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_BASE_URL}region_profile/detailByUser`)
      .then(({ data: { data }, status }) => {
        if (status === 200) {
          setState((prev) => ({
            ...prev,
            url: data.embed_url,
            informasi: data.embed_info
          }));
        }
        setLoading(false);
      })
      .catch(function (error) {
        setState((prev) => ({
          ...prev,
          url: '',
          informasi: '',
        }));
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="position-relative">
        <div className="box-page-title">
          <p className={style.title}>Region Profile</p>
          <span
            className="line-2nd"
            style={{ borderBottomColor: baseColor?.color2 }}
          ></span>
        </div>
      </div>

      <div className={`${style.separator}`}></div>

      {loading ? (
        <div className={`position-relative ${style.blankContainer}`}>
          <Loading />
        </div>
      ) : state.url ? (
        <div className={style.iframeContainer}>
          <div className='bg-white text-right p-3'>
            <button
              type="button"
              className="button-ghost"
              data-toggle="modal"
              data-target={`#detailModel`}
            >
              <a
                className=""
                style={{
                  color: baseColor?.color1,
                }}
              >
                <i className="fa fa-info-circle"></i> Informasi 
              </a>
            </button>
          </div>
          <iframe
            title="Region Profile"
            className="iframe"
            src={state.url}
            frameBorder="0"
          ></iframe>
          <div className={style.separator}>&nbsp;</div>
        </div>
      ) : (
        <div className={`${style.blankContainer}`}>
          <img className={`${style.blankImg}`} src={BlankImg} />
          <p className={`${style.blankP}`}>
            Anda belum memiliki data Region Profile
          </p>
        </div>
      )}
      <div
        className="modal fade"
        id={`detailModel`}
        aria-labelledby="detailModel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg isi-modal modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-8 mt-2">
                  <strong>
                    <p>Detail Informasi Profile Region </p>
                  </strong>
                </div>
                <div className="col-4">
                  <div className="text-right">
                    <button
                      type="button"
                      // style={{ color: local.color1 }}
                      className="btn button-ghost"
                      aria-label="Close"
                      data-dismiss="modal"
                    >
                      <p className="close">X</p>
                    </button>
                  </div>
                </div>
              </div>
              <div>{state.informasi}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Loading from "../../../../utils/Loading";
import sektorstyle from "../Sektor.module.css";
import { reactLocalStorage } from "reactjs-localstorage";
import styled from "styled-components";

const ReadLink = styled.button`
   width: 200px;
   height: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: ${(props) => props.fontColor} !important;
   background-color: ${(props) => props.backgroundColor} !important;
   border-radius: 8px;
   transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out !important;

   &:hover {
      border: 1px solid ${(props) => props.backgroundColor} !important;
      color: ${(props) => props.backgroundColor} !important;
      background-color: ${(props) => props.fontColor} !important;
   }
`;

function Informasi(props) {
   const params = useParams();
   const baseColor = reactLocalStorage.getObject("base_color");

   const [dataSektor, setDataSektor] = useState({
      short_desc: "",
      deskripsi_detail: "",
      nama_alamat: "",
      alamat: "",
      telepon: "",
      link_map: "",
      id_sektor: 0,
   });

   const [readmore, setReadmore] = useState({
      status: 0,
      text: "Lihat Selengkapnya",
   });

   useEffect(() => {
      setReadmore({
         status: 0,
         text: "Lihat Selengkapnya",
      });
   }, [params]);

   useEffect(() => {
      setDataSektor({
         short_desc: props.data.short_desc,
         deskripsi_detail: props.data.deskripsi_detail,
         nama_alamat: props.data.nama_alamat,
         alamat: props.data.alamat,
         telepon: props.data.telepon,
         link_map: props.data.link_map,
         id_sektor: props.data.id_sektor,
      });
   }, [props]);

   return (
      <>
         <div className={sektorstyle.boxSektor}>
            <div className="row">
               <div className="col-12">
                  {!props.data.id_sektor ? (
                     <div className="col-12 bg-white position-relative text-center min-vh-25">
                        <Loading />
                     </div>
                  ) : (
                     <>
                        <div dangerouslySetInnerHTML={{ __html: dataSektor.short_desc }} />
                        {dataSektor.deskripsi_detail !== "" && readmore.status === 1 && (
                           <>
                              <div
                                 className={sektorstyle.detaildecs}
                                 dangerouslySetInnerHTML={{
                                    __html: dataSektor.deskripsi_detail,
                                 }}
                              />
                           </>
                        )}
                        {dataSektor.deskripsi_detail !== "" && (
                           <>
                              <ReadLink
                                 data-testid="sektorReadmore"
                                 backgroundColor={baseColor.color1}
                                 fontColor={baseColor.color3}
                                 onClick={() => {
                                    if (readmore.status === 0) {
                                       setReadmore({
                                          status: 1,
                                          text: "Lihat Sebagian",
                                       });
                                    } else {
                                       setReadmore({
                                          status: 0,
                                          text: "Lihat Selengkapnya",
                                       });
                                    }
                                 }}
                              >
                                 {readmore.text}
                              </ReadLink>
                           </>
                        )}
                     </>
                  )}
               </div>
            </div>
         </div>

         <div className="box-frame">
            <div className="box-map">
               <div className="left-map">
                  <h5 data-testid="name-usecase">{dataSektor.nama_alamat}</h5>
                  <div className="box-contact">
                     <h5>Alamat:</h5>
                     <div data-testid="adress-usecase" className="desc-contact">
                        {dataSektor.alamat}
                     </div>
                  </div>
                  <div className="box-contact">
                     <h5>Telepon:</h5>
                     <div data-testid="contact-usecase" className="desc-contact">
                        {dataSektor.telepon}
                     </div>
                  </div>
               </div>
               <div className="right-map">
                  <div className="box-iframe-map">
                     <iframe data-testid="map-usecase" title={dataSektor.id_sektor} src={dataSektor.link_map} allowFullScreen loading="lazy"></iframe>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Informasi;

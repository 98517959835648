import React, { useState, useRef, useEffect, useCallback } from 'react';
import style from './YearPicker.module.css';
import {reactLocalStorage} from 'reactjs-localstorage';


const parseYearToStr = (yearWithData) => {
  return yearWithData.toString().split(',');
}
const YearPicker = (props, titleCustom) => {
  let periode = [];
  let bodyVal = [];

  /**
   * Initialize state
   */
  const [state, setState] = useState({
    getPeriode: null,
    indexPeriode: null,
    getBodyVal: null,
    indexBodyVal: null,
    yearVal: new Date(props.selected == false || isNaN(props.selected) ? new Date() : props.selected).getFullYear(),
  });
  const [showPanel, setShowPanel] = useState(false);
  const ref = useRef(null);
  const refSelect = useRef(null);
  const refUnSelect = useRef(null);

  
  const setPeriode = () => {
    for (let i = props.minYear; i <= props.maxYear; i += props.interval) {
      /**
       * Generate periode ex: 2020 - 2022
       */
      periode.push(i + ' - ' + (i + props.interval - 1));
    }
  };

  const getPeriode = (index = null) => {
    if (index === null) {
      /**
       * Get last periode
       */
      return periode[periode.length - 1];
    } else {
      return periode[index];
    }
  };

  const setBodyValTillCurrentYear = () => {
    let span = [];
    for (let i = props.minYear; i <= props.maxYear; i++) {
      span.push(
        <span
          key={i}
          data-key={i}
          data-testid="body-val"
          data-status={
            parseYearToStr(props.yearWithData).includes(String(i))
              ? 'black'
              : i > props.maxYear
              ? 'disabled'
              : 'red'
          }
          onClick={(e) => {
            handleClickVal(e);
          }}
          className={`${style.dpVal} ${
            parseYearToStr(props.yearWithData).includes(String(i))
              ? style.black
              : i > props.maxYear
              ? style.disabled
              : style.red
          }
          
          ${
            (state.yearVal == i && parseYearToStr(props.yearWithData).includes(String(i)))
              ? style.blackHover
              : (state.yearVal == i && i > props.maxYear)
              ? style.disabledHover
              : state.yearVal == i 
              ? style.redHover : null
          }
          `}
        >
          {i === new Date().getFullYear() ? <strong>{i}</strong> : i}
        </span>
      );
      if (i % 12 === 0) {
        bodyVal.push(span);
        span = [];
      } else if (i === props.maxYear) {
        bodyVal.push(span);
        span = [];
      }
    }
  };

  const setBodyValDefault = () => {
    let span = [];
    for (let i = props.minYear; i <= props.maxYear; i += props.interval) {
      span = [];
      for (let j = i; j < i + props.interval; j++) {
        span.push(
          <span
            key={j}
            data-key={j}
            data-testid="body-val"
            data-status={
              parseYearToStr(props.yearWithData).includes(String(j))
                ? 'black'
                : j > props.maxYear
                ? 'disabled'
                : 'red'
            }
            onClick={(e) => {
              handleClickVal(e);
            }}
            className={`
              ${style.dpVal} 
              ${
                parseYearToStr(props.yearWithData).includes(String(j))
                  ? style.black
                  : j > props.maxYear
                  ? style.disabled
                  : style.red
              }
              ${
                (state.yearVal == j && parseYearToStr(props.yearWithData).includes(String(j)))
                  ? style.blackHover
                  : (state.yearVal == j && j > props.maxYear)
                  ? style.disabledHover
                  : state.yearVal == j 
                  ? style.redHover : null
              }
              `}
            style={
              {
                background: state.yearVal == j && parseYearToStr(props.yearWithData).includes(String(j)) ?reactLocalStorage.getObject('base_color')?.color1: 'default',
                fontWeight: j === new Date().getFullYear() ? 'bold' : 'normal',
              }
            }
            onMouseOver={(e) => {
                if (parseYearToStr(props.yearWithData).includes(String(j))) {              
                  e.target.style.background = reactLocalStorage.getObject('base_color')?.color1 
                } else if (j > props.maxYear) {
                  e.target.style.background = 'default'                  
                } else {
                  e.target.style.background = '#DC4233'
                }
              }
            }
            onMouseOut={(e) => {
                if (state.yearVal == j) {
                  e.target.style.background = 'default';
                } else {
                  e.target.style.background = 'unset' 
                }
              }
            }
          >
            {j}
          </span>
        );
      }
      bodyVal.push(span);
      span = [];
    }
  };

  const setBodyVal = () => {
    if (props.showTillCurrentYear) {
      setBodyValTillCurrentYear();
    } else {
      setBodyValDefault();
    }
  };

  const getBodyVal = (index = null) => {
    if (index === null) {
      /**
       * Get last body value
       */
      return bodyVal[bodyVal.length - 1];
    } else {
      return bodyVal[index];
    }
  };

  /**
   * Method Handle
   */
  const handlePrevYear = () => {
    if (state.indexPeriode <= 0) {
      return;
    }
    setState((prev) => ({
      ...prev,
      getPeriode: getPeriode(prev.indexPeriode - 1),
      indexPeriode: prev.indexPeriode - 1,
      getBodyVal: getBodyVal(prev.indexBodyVal - 1),
      indexBodyVal: prev.indexBodyVal - 1,
    }));
  };

  const handleNextYear = () => {
    if (state.indexPeriode >= periode.length - 1) {
      return;
    }
    setState((prev) => ({
      ...prev,
      getPeriode: getPeriode(prev.indexPeriode + 1),
      indexPeriode: prev.indexPeriode + 1,
      getBodyVal: getBodyVal(prev.indexBodyVal + 1),
      indexBodyVal: prev.indexBodyVal + 1,
    }));
  };

  const handleClickVal = (e) => {
    const yearVal = Number(e.currentTarget.textContent);
    if (yearVal > props.maxYear) {
      return;
    }
    // const dataKey = Number(e.target.getAttribute('data-key'));
    // const span = document.querySelector('span[data-key="'+dataKey+'"]');
    // const activeClass = span.getAttribute('data-status')+'HoverYP';
    
    props.onChange(new Date(String(yearVal)));
    setState((prev) => ({
      ...prev,
      yearVal,
    }));
    setShowPanel(false);
  };

  const handleClickOutside = (e) => {
    /**
     * When clicked target is a child ref element then
     */
    if (ref.current && !ref.current.contains(e.target)) {
      setShowPanel(false);
    } else if (refSelect.current && refSelect.current.contains(e.target)) {
      setShowPanel(!showPanel);
    } else if (refUnSelect.current && refUnSelect.current.contains(e.target)) {
      return;
    } else {
      setShowPanel(true);
    }
  };

  setPeriode();
  setBodyVal();
  
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      getPeriode: getPeriode(),
      indexPeriode: periode.length - 1,
      getBodyVal: getBodyVal(),
      indexBodyVal: bodyVal.length - 1,
      yearVal: new Date(props.selected == false || isNaN(props.selected) ? new Date() : props.selected).getFullYear(),
    }));
  }, []);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      getPeriode: getPeriode(prev.indexPeriode),
      getBodyVal: getBodyVal(prev.indexBodyVal),
      yearVal: new Date(props.selected == false || isNaN(props.selected) ? new Date() : props.selected).getFullYear(),
    }));
  }, [props.selected, props.yearWithData]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showPanel]);

  return (
    <>
      <div ref={ref} className={`${style.dpContainer} ${props.className}`}>
        <div className="dpFilter">
          <div ref={refUnSelect} className={style.dpFilterLabelContainer}>
            <i
              className={`${style.icon} ${style.cursorDefault} far fa-question-circle`}
            ></i>{' '}
            Tahun {props.titleCustom}
            <div className={`${style.dpFilterLabelHover}`}>
              <p className="m-0">Keterangan tahun:</p>
              <ul className="m-0 pl-3">
                <li>
                  Warna{' '}
                  <span className={style.red}>
                    <strong>merah</strong>
                  </span>{' '}
                  adalah data kosong
                </li>
                <li>
                  Warna{' '}
                  <span className={style.black}>
                    <strong>hitam</strong>
                  </span>{' '}
                  adalah data tersedia
                </li>
              </ul>
            </div>
          </div>
          <button data-testid="select-year" ref={refSelect} className={`btn ${style.btnFilter}`}>
            <span data-testid="year-val">{state.yearVal}</span> <i className="fa fa-chevron-down"></i>
          </button>
        </div>
        {showPanel && (
          <div className={style.dpPanel}>
            <div className={style.dpPanelHead}>
              {state.indexPeriode > 0 ? (
                <span data-testid="prev-btn" onClick={handlePrevYear}>
                  <i className={`fa fa-angle-double-left ${style.icon}`}></i>
                </span>
              ) : (
                <span />
              )}
              <span>
                <strong data-testid="periode">{state.getPeriode}</strong>
              </span>
              {state.indexPeriode < periode.length - 1 ? (
                <span data-testid="next-btn" onClick={handleNextYear}>
                  <i className={`fa fa-angle-double-right ${style.icon}`}></i>
                </span>
              ) : (
                <span />
              )}
            </div>
            <div className={style.dpPanelBody}>{state.getBodyVal}</div>
          </div>
        )}
      </div>
    </>
  );
};

YearPicker.defaultProps = {
  minYear: 1,
  maxYear: new Date().getFullYear(),
  // maxYear: 2029,
  interval: 12,
  showTillCurrentYear: false,
  selected: new Date(),
  yearWithData: [],
  onChange: function () {},
  className: '',
};
export default React.memo(YearPicker);

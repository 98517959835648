import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../constants/apiConstants";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, useParams } from "react-router-dom";

import SimpleDatatable from "../../../Material/SimpleDatatable";

const NonIku = () => {
  const params = useParams();

  const header = useRef([
    {
      name: "title",
      value: "Nama File",
      sortable: true,
    },
    {
      name: "file_type",
      value: "Type File",
      sortable: true,
    },
    {
      name: "action",
      value: "Action",
    },
  ]);

  const listPerPage = useRef([5, 10, 25, 50]);
  const [item, setItem] = useState([]);
  const [search, setSearch] = useState("");
  const [per_page, setPerPage] = useState(listPerPage.current[0]);
  const [sortField, setSortField] = useState({
    column: "",
    dir: "",
  });

  const [datapaging, setDatapaging] = useState([]);
  const [loading, setLoading] = useState(false);

  const changeSearch = (data) => {
    setSearch(data);
  };

  const changePerPage = (data) => {
    setPerPage(data);
  };

  const changeSort = (data) => {
    setSortField(data);
  };

  const changePage = (data) => {
    setLoading(true);
    axios
      .get(
        `${data}&per_page=${per_page}&order_column=${sortField.column}&order_dir=${sortField.dir}&keyword=${search}`
      )
      .then(({ data: { data, links, from } }) => {
        setItem(data);
        setDatapaging(links);
        setLoading(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${API_BASE_URL}sector/data-non-iku/dashboard/${params.id_sektor}?per_page=${per_page}&page=1&order_column=${sortField.column}&order_dir=${sortField.dir}&keyword=${search}`
      )
      .then(({ data: { data, links, from } }) => {
        setItem(data);
        setDatapaging(links);
        setLoading(false);
      })
      .catch((error) => {});
  }, [params, per_page, sortField, search]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card" style={{ border: "none" }}>
            <div className="card-body">
              <SimpleDatatable
                listPerPage={listPerPage.current} //useRef
                searchable={true}
                header={header} //useRef
                datapaging={datapaging}
                loading={loading}
                //props
                changeSearch={changeSearch}
                changePerPage={changePerPage}
                changeSort={changeSort}
                changePage={changePage}
              >
                <tbody>
                  {item.map((data, index) => (
                    <tr key={index}>
                      <td width="70%">{data.title}</td>
                      <td>{data.file_type}</td>
                      <td>
                        <Link
                          className="btn btn-sm btn-primary"
                          to={{ pathname: data.file_link }}
                          target="_blank"
                        >
                          <i className="fas fa-file-download"></i> Download
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </SimpleDatatable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NonIku;

import React, { useState, useEffect } from 'react';
import {
  Link,
} from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Forgotstyle from './ForgotPassword.module.css'
import HeaderGlobal from '../Layouts/HeaderGlobal'
import { hhmmss } from '../../utils/tools';
import { API_BASE_URL } from '../../constants/apiConstants'
import MySwal from '../../utils/MySwal'

let cookies = new Cookies();

const Index = () => {

  const [modal, setModal] = useState('none');
  const [timeModal, setTimeModal] = useState(0);
  const [emailModal, setEmail] = useState('');

  const [refreshPage, setRefreshPage] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Email harus diisi')
        .email('Harus format email')
    }),
    onSubmit: (values) => {

      let formData = {
        email: values.email
      }

      formik.resetForm();

      axios.post(`${API_BASE_URL}sendRememberTokenByUser`, formData)
        .then(() => {

          setTimeModal(120);
          setEmail(formData.email);
          setModal('block');

          cookies.set('emailModal', `${formData.email}`, { path: '/', maxAge: 18000 });
          cookies.set('timeModal', `120`, { path: '/', maxAge: 18000 });
        })
        .catch((err) => {
          if (err.response.status === 400 && err.response.data.customError === 'T') {
            if (err.response.data.customError === 'T') {
              MySwal.fire({
                title: 'Atur Ulang Password',
                html: 'Anda telah melakukan permintaan atur ulang password sebanyak 3 kali Hubungi customer support kami di <b>noreply.idsg@gmail.com</b> atau klik dibawah ini',
                confirmButtonText: 'Hubungi Customer Support',
                confirmButtonColor: '#DC4233'
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    window.open(
                      'mailto:noreply.idsg@gmail.com',
                      '_blank' // <- This is what makes it open in a new window.
                    );
                  }
                })
            }
          }
        })
    },
  })

  useEffect(() => {
    if (timeModal > 0) {
      //cookies.set('emailModal', `${emailModal}`, { path: '/', maxAge: `18000` });
      cookies.set('timeModal', `${timeModal}`, { path: '/', maxAge: `18000` });

      setEmail(cookies.get('emailModal'));
      setModal('block');
    } else {

      if (cookies.get('timeModal') !== undefined && timeModal <= 0 && refreshPage === true) {
        cookies.remove('emailModal')
        cookies.remove('timeModal')
      }

      setEmail('');
      setModal('none');
    }
  }, [timeModal, refreshPage])

  useEffect(() => {
    if(timeModal <= 0) return;

    const interval = setInterval(() => {
      setTimeModal(timeModal => timeModal - 1);
    }, 1000);
    
    return () => clearInterval(interval);
  }, [timeModal]);

  useEffect(() => {
    if (cookies.get('emailModal') !== undefined && cookies.get('timeModal') !== undefined) {
      if (parseInt(cookies.get('timeModal')) === 1) {
        cookies.remove('emailModal')
        cookies.remove('timeModal')
      } else {
        setTimeModal(cookies.get('timeModal'));
        setEmail(cookies.get('email'));
  
        setRefreshPage(true);
  
        setModal('block');
      }
    }
  }, [])

  return (
    <HeaderGlobal>
      <div className="container">
        {/*{JSON.stringify(formik)}*/}
        <div className={`row justify-content-center ${Forgotstyle.paddingTop30VH}`}>
          <div className="col-12 col-lg-7">
            <form onSubmit={formik.handleSubmit}>
              <div className={`card mb-3 ${Forgotstyle.card}`}>
                <div className={`card-header ${Forgotstyle.cardHeader}`}>
                  Atur Ulang Password
                </div>
                <div className="card-body">
                  <label htmlFor="email" className={`${Forgotstyle.text} ${Forgotstyle.color}`}>
                    Email
                  </label>

                  <input
                    data-testid="email"
                    name="email"
                    id="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    type="text"
                    style={{ borderRadius: '10px !important'}}
                    className={`form-control ${Forgotstyle.inputSet} ${Forgotstyle.fieldRadius} ${formik.touched.email && formik.errors.email ? 'is-invalid' : null}`}
                    placeholder="Masukan Alamat Email"
                    {...formik.getFieldProps('email')}
                  />

                  {formik.touched.email && formik.errors.email && <>
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  </>}

                </div>

                <div className={`card-footer`}>
                  <div className="row">
                    <div className={`col-6 ${Forgotstyle.col}`}>
                      <Link className={Forgotstyle.btn2} to="/">
                        Kembali ke halaman login
                      </Link>
                    </div>
                    <div className="col-6 text-right">
                      <button
                        data-testid="btnSubmit"
                        type={formik.isValid && formik.dirty ? 'submit' : 'button'}
                        className={`btn btn-secondary ${!formik.isValid || !formik.dirty ? `${Forgotstyle.btn}` : `${Forgotstyle.btn3}`}`}
                      >Lanjutkan</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {/*MODAL*/}
            <div className="modal fade show" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" style={{
              display: `${modal}`
            }} role="dialog">
              <div className={`modal-dialog modal-lg modalDialogFP ${Forgotstyle.modalDialog}`}>
                <p className={`${Forgotstyle.text3} ${Forgotstyle.color3} ${Forgotstyle.marginBottom1} text-center`}>
                  Atur Ulang Password
                </p>
                <p className={`${Forgotstyle.text2} ${Forgotstyle.color3} ${Forgotstyle.marginBottom2} text-center`}>
                  Link atur ulang password telah dikirim ke email <b>{ emailModal }</b>. Cek email Anda
                </p>
                <p className={`${Forgotstyle.text2} ${Forgotstyle.color3} text-center`}>
                  Belum menerima email? mohon cek menu spam atau jika tidak ada, lakukan reset ulang menunggu <b>{ hhmmss(timeModal) }</b>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </HeaderGlobal>
  )
}

export default Index
import React from 'react';
import { BackgroundShadow } from '../../../PotensiDaerah/SDA/Utils';
import Datatable from '../../../../Datatable'
import esStyle from '../../../PotensiDaerah/SDA/index.module.css';
import { useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../constants/apiConstants';
import { useState } from 'react';

const Tabulasi = (props) => {
    const {jenis, tanggal, komoditas,  pasar} = props;
    const [stateDataTable, setStateDataTable] = useState({ tableData: {}, title: ""});
    const [load, setLoad]                     = useState(false)

    useEffect(() => {
        if ( (typeof jenis   === "undefined")   || (typeof komoditas === "undefined") || 
             (typeof tanggal === "undefined")   || (typeof pasar     === "undefined")) {
            return;
        }
        
        setLoad(false)
        axios
        .get(
            `${API_BASE_URL}sosial-ekonomi/harga-pangan/harga/tabulasi?jenis=${jenis}&komoditas=${komoditas}&tanggal=${tanggal}&pasar=${pasar}`
        )
        .then(({ data: { data } }) => {            
            setLoad(true)
            setStateDataTable({
              tableData: {
                ...data.data,
                base_endpoint:  `${API_BASE_URL}sosial-ekonomi/harga-pangan/harga/tabulasi?jenis=${jenis}&komoditas=${komoditas}&tanggal=${tanggal}&pasar=${pasar}`,
                right:[1]
              },
              title: data.title
            });
          })
          .catch(() => {
            setStateDataTable((prev) => ({
                ...prev,
                tableData: {},
                title: "",
            }));
          });
    }, [jenis, komoditas, tanggal, pasar]);


  return (
    <div className={esStyle.leafletContainer}>
        {
            load 
            ?
                <Datatable serverside={false} {...stateDataTable.tableData} />        
            : <div className="position-relative" style={{height:'400px'}}><BackgroundShadow content="" background="#c0c2c6" /></div>
        }
    </div>
  )
}

export default Tabulasi
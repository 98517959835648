import React, { useContext } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';

import Pertanian from './Pertanian';
import Hortikultura from './Hortikultura';
import Perkebunan from './Perkebunan';
import Perikanan from './Perikanan';

import './index.css'
import AppContext from '../../../../AppContext';

const SumberDayaAlam = () => {
  const { GlobalContext } = AppContext;
  const global = useContext(GlobalContext);
  const { globalState } = global;

  const baseColor = reactLocalStorage.getObject('base_color');


  return (
    <>    
      <p className="h3 mb-3">Sumber Daya Alam</p>
      <span
        className="line-2nd mb-3"
        style={{ borderBottomColor: baseColor.color2 }}
      ></span>

      <div className="row mb-4">
        <div className="col-md-12">
          <Pertanian />
        </div>
        <div className="col-md-12">
          <Hortikultura />
        </div>
        <div className="col-md-12">
          <Perkebunan />
        </div>
        <div className={`col-md-12`}>
          <Perikanan />
        </div>
      </div>
    </>
  );
};

export default SumberDayaAlam;

import React from 'react';
import Loading from '../../../../../utils/Loading';
import useLoadIframe from '../../../../../utils/useLoadIframe';

const Tokopedia = (props) => {
  const { link } = props;
  const [delayedState, status] = useLoadIframe(link);

  return (
    <>
      {delayedState === null ? <Loading /> : null}
      <iframe
        data-testid="iframe-tokopedia"
        src={link}
        frameBorder="0"
        width="100%"
        style={{
          width: '1px',
          minWidth: '100%',
          visibility: delayedState === null ? 'hidden' : 'visible',
          height: '2300px',
          maxHeight: delayedState === null ? '50vh' : 'unset',
        }}
      />
    </>
  );
};

export default Tokopedia;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ColumnChart from "../../../../Charts/ColumnChart";
import BarChart from "../../../../Charts/BarChart";
import PieChart from "../../../../Charts/PieChart";
import LineChart from "../../../../Charts/LineChart";
import style from "./index.module.css";
import { NumberWithDot, NumberFormat } from "../../../../Material/FormatNumber";
import { API_BASE_URL } from "../../../../../constants/apiConstants";

const Index = (props) => {
  const [state, setState] = useState({
    chartData: [],
    title: "",
  });

  useEffect(() => {
    if (!props.data || props.data.id_sektor == 0) {
      return;
    }
    axios
      .get(
        `${API_BASE_URL}sector/iku/chart/${props.data.id_sektor}?public=${props.public}`
      )
      .then(({ data: { data, title } }) => {
        setState({
          chartData: data,
          title: title,
        });
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          chartData: [],
          title: "",
        }));
      });
  }, [props.data]);

  return (
    state.chartData.length !== 0 && (
      <div className={style.boxIndicator}>
        <p
          data-testid="title"
          className={`${style.title} ${props.public ? "d-none" : ""} `}
        >
          {state.title}
        </p>
        <div className={style.chartContainer}>
          {state.chartData.map((data, index) => (
            <div key={index} className={style.chartCard}>
              <p className={style.chartTitle} title={data.widget_title}>
                {data.widget_title}
              </p>
              <p className={style.chartSubtitle}>{data.widget_subtitle}</p>
              <SelectChart {...data} />
            </div>
          ))}
        </div>
      </div>
    )
  );
};

const SelectChart = React.memo((data = {}) => {
  const { widget_data, widget_title } = data;
  let component = <></>;
  switch (data.widget_type) {
    case "chart_column":
      component = (
        <ColumnChart
          data={widget_data.chart_data}
          categories={widget_data.chart_categories}
          options={chartOptions(widget_data, widget_title)}
        />
      );
      break;
    case "chart_bar":
      component = (
        <BarChart
          data={widget_data.chart_data}
          categories={widget_data.chart_categories}
          options={chartOptions(widget_data, widget_title)}
        />
      );
      break;
    case "chart_line":
      component = (
        <LineChart
          data={widget_data.chart_data}
          categories={widget_data.chart_categories}
          options={chartOptions(widget_data, widget_title)}
        />
      );
      break;
    case "chart_pie":
      component = (
        <PieChart
          data={widget_data.chart_data}
          options={chartOptions(widget_data, widget_title)}
        />
      );
      break;
    default:
      break;
  }
  return component;
});

const chartOptions = (data, title) => {
  /**
   * Default options below for line, column and bar chart
   */
  let options = {
    chart: {
      height: 230,
      // width: 320,
    },
    exporting: {
      enabled: true,
      filename: title,
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (key) {
            return item.yAxis.options.title.text;
          }
          return false;
        },
      },
      chartOptions: {
        title: {
          text: "",
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            {
              text: "Export Image png",
              onclick: function () {
                this.exportChart({
                  type: "image/png",
                });
              },
            },
            {
              text: "Download XLS",
              onclick() {
                this.downloadXLS();
              },
            },
            {
              text: "Download CSV",
              onclick() {
                this.downloadCSV();
              },
            },
          ],
        },
      },
    },
    xAxis: {
      title: {
        text: data.xAxis_title,
      },
    },
    yAxis: {
      title: {
        text: data.yAxis_title,
      },
      labels: {
        formatter: function () {
          return NumberWithDot(this.value, 5);
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<div style="border-color:${this.color}">
        <table>
          <tr>
            <td>${data.xAxis_title} </td>
            <td>:</td>
            <td>${this.x}</td>                              
          </tr>
          <tr>
            <td>${data.yAxis_title} </td>
            <td>:</td>
            <td><strong>${NumberWithDot(this.y, 5)}</strong></span></td>
          </tr>
        </table>
      </div>`;
      },
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          rotation: -45,
          y: -17.5,
          x: 12.5,
          allowOverlap: true,
          crop: false,
          overflow: "allow",
          formatter: function () {
            return `<span style="font-style: normal; font-weight: normal; font-family: DM Sans, sans-serif; font-size: 12px;">${NumberWithDot(
              this.y,
              5
            )}</span>`;
          },
        },
      },
    },
  };
  switch (data.chart_type) {
    case "pie":
      options = {
        chart: {
          height: 230,
          width: 320,
        },
        tooltip: {
          formatter: function () {
            return `<div style="border-color:${this.color}">
                <table>
                  <tr>
                    <td> <span>${this.key}: </span> </td>
                    <td> <span><strong>${NumberWithDot(
                      this.y
                    )} (${NumberWithDot(
              this.percentage,
              2
            )}%)</strong></span></td>
                  </tr>
                </table>
              </div>`;
          },
          shared: true,
          useHTML: true,
        },
        legend: {},
        plotOptions: {
          pie: {
            colors: ["#3989DA", "#87C8F3"],
          },
        },
      };
      break;
    default:
      break;
  }

  return options;
};

export default React.memo(Index);

import React, { useContext } from 'react';
import style from './Loading.module.css';
import {reactLocalStorage} from 'reactjs-localstorage';

const Loading = (props) => {
  const base_color = reactLocalStorage.getObject('base_color');
  return (
    <>
      <div className={style.loadContainer}>
        <div
          className={`${style.loadDot} ${style.dotOne}`}
          style={{ backgroundColor: (props.color ? props.color : base_color.color4) }}
        ></div>
        <div
          className={`${style.loadDot} ${style.dotTwo}`}
          style={{ backgroundColor: (props.color ? props.color : base_color.color4) }}
        ></div>
        <div
          className={`${style.loadDot} ${style.dotThree}`}
          style={{ backgroundColor: (props.color ? props.color : base_color.color4) }}
        ></div>
      </div>
    </>
  );
};

export default Loading;

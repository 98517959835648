import React, {
  Suspense,
  lazy,
  //useState,
  //useEffect
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NoLogin from "./components/Auth/NoLogin";
import Login from "./components/Auth/Login";
import Dashboard from "./components/Modules/Dashboard/Dashboard";

//page setting password
import ForgotPassword from "./components/ForgotPassword/Index";

//page setting password
import SettingPassword from "./components/SettingPassword/Index";

//page not found
import NotFound from "./components/NotFound/Index";

//page content
import ContentMenu from "./components/Modules/Content/Menu/Index";
import Logo from "./components/Modules/Content/Logo/Index";
import Premium from "./components/Modules/Content/Premium/Index";

//import visi misi
import VisiMisi from "./components/Modules/VisiMisi/Index";

//import sector
import Sektor from "./components/Modules/Sektor/Index";

import Role from "./components/Modules/Users/Roles/Read";
import RoleCreate from "./components/Modules/Users/Roles/Create";
import RoleUpdate from "./components/Modules/Users/Roles/Update";

//page cases
//import Case from './components/Modules/Users/Cases/Read';
import CaseCreate from "./components/Modules/Users/Cases/Create";
import CaseUpdate from "./components/Modules/Users/Cases/Update";

//contoh page content
import NoContent from "./components/NoContent";

//import Analytics
import SocialMedia from "./components/Modules/Analytics/SocialMedia/Index";
import DigitalMedia from "./components/Modules/Analytics/DigitalMedia/Index";
import Ecommerce from "./components/Modules/Analytics/Ecommerce";

//import potensi
import EkonomiSda from "./components/Modules/PotensiDaerah/EkonomiSda/index";
import Demografi from "./components/Modules/PotensiDaerah/Demografi/Index";
import Perdagangan from "./components/Modules/PotensiDaerah/Perdagangan/Index";
import SumberDayaAlam from "./components/Modules/PotensiDaerah/SDA";

// import sosial ekonomi
import HargaPangan from "./components/Modules/SosialEkonomi/HargaPangan";

//menu pariwisata
// import TourismSummary from './components/Modules/Tourism/Summary'
// import TourismTracking from './components/Modules/Tourism/Tracking'

//import Analytics
import RegionProfile from "./components/Modules/RegionProfile/Index";

//import struktur organisasi BGN
// import StrukturOrganisasi from "./components/Modules/StrukturOrganisasi"

import NoLoginRouter from "./utils/NoLoginRoute";
import PrivateRoute from "./utils/PrivateRoute";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AppContext from "./AppContext";

import Loader from "./utils/Loading";

//import history from './utils/history'

import {
  Switch,
  Route,
  //Redirect,
  //useHistory
  //useLocation
} from "react-router-dom";

//import ScrollToTop from './utils/ScrollToTop'

//import user
const User = lazy(() => import("./components/Modules/Users/User/Read"));
const UserCreate = lazy(() => import("./components/Modules/Users/User/Create"));
const UserUpdate = lazy(() => import("./components/Modules/Users/User/Update"));

//import menu
const Menu = lazy(() => import("./components/Modules/Users/Menus/Read"));
const MenuCreate = lazy(() =>
  import("./components/Modules/Users/Menus/Create")
);
const MenuUpdate = lazy(() =>
  import("./components/Modules/Users/Menus/Update")
);

//page cases
const Case = lazy(() => import("./components/Modules/Users/Cases/Read"));
const Poi = lazy(() => import("./components/Modules/Poi/Index"));

//data sectoral
const DataSectoral = lazy(() => import("./components/Modules/DataSectoral"));

//menu pariwisata
const TourismSummary = lazy(() =>
  import("./components/Modules/Tourism/Summary")
);
const TourismTracking = lazy(() =>
  import("./components/Modules/Tourism/Tracking")
);
const TourismPelacakan = lazy(() =>
  import("./components/Modules/Tourism/Pelacakan")
);
const PeopleMovement = lazy(() =>
  import("./components/Modules/Tourism/PeopleMovement")
);
const TourismTrip = lazy(() => import("./components/Modules/Tourism/Trip"));
const TourismRingkasan = lazy(() =>
  import("./components/Modules/Tourism/Ringkasan")
);
const TourismMobility = lazy(() =>
  import("./components/Modules/Tourism/Mobility")
);
const TourismDemography = lazy(() =>
  import("./components/Modules/Tourism/Demography")
);
const TourismDataTrip = lazy(() =>
  import("./components/Modules/Tourism/TripData")
);

const PopulationSocial = lazy(() =>
  import("./components/Modules/PotensiDaerah/PopulationSocial")
);
const EconomyAndTrade = lazy(() =>
  import("./components/Modules/PotensiDaerah/EconomyAndTrade")
);

const DashboardG20 = lazy(() =>
  import("./components/Modules/DashboardG20/DashboardG20")
);

// Import User Tracking
const UserTracking = lazy(() => import("./components/Modules/UserTracking"));

// Import Kesehatan
const Kesehatan = lazy(() => import("./components/Modules/Kesehatan"));

//import sektor prioritas OPD
const OPD = lazy(() => import("./components/Modules/SektorPrioritasOPD"));

// Import Sosial Ekonomi
const Analisis = lazy(() =>
  import("./components/Modules/SosialEkonomi/Analisis")
);
const SentimenMedia = lazy(() =>
  import("./components/Modules/SosialEkonomi/SentimenMedia")
);
const AnalisisDataModeling = lazy(() =>
  import("./components/Modules/SosialEkonomi/AnalisisDataModeling")
);

// Import Dashboard Global
const DashboardGlobal = lazy(() =>
  import("./components/Modules/DashboardGlobal")
);

//CMS Usecase
const CmsSektorPrioritas = lazy(() =>
  import("./components/Modules/CMSUsecase/SektorPrioritas")
);

//Embed Sector
const EmbedSektorPrioritas = lazy(() =>
  import("./components/Embed/SectorEmbed")
);

//import Komunikasi
const komunikasi = lazy(() =>
  import("./components/Modules/PotensiDaerah/Telekomunikasi")
);

//import pariwisata telkomsel
// const PariwisataTelkomsel = lazy(() =>
//   import("./components/Modules/Pariwisata/index")
// );

// import incinerator
const DahsboardIncinerator = lazy(() =>
  import("./components/Modules/Incinerator/Dashboard/index")
);

const PolutanPartikulatGas = lazy(() =>
  import("./components/Modules/Incinerator/PolutanPartikulatGas/index")
);
const Meteoreologi = lazy(() =>
  import("./components/Modules/Incinerator/Meteoreologi/index")
);
const Incinerator = lazy(() =>
  import("./components/Modules/Incinerator/Incinerator/index")
);
const KapasitasSampah = lazy(() =>
  import("./components/Modules/Incinerator/KapasitasSampah/index")
);
const DynamicChart = lazy(() =>
  import("./components/Modules/PotensiDaerah/DynamicChart/index")
);
const MainWeb = lazy(() =>
  import("./components/Modules/embedfile/MainWeb/index")
);
const DiskominfoWeb = lazy(() =>
  import("./components/Modules/embedfile/Diskominfo/index")
);
const PenyediaWeb = lazy(() =>
  import("./components/Modules/embedfile/PenyediaJaringan/index")
);

const RencanaPembangunan = lazy(() =>
  import("./components/Modules/RencanaPembangunan/index")
);

const SebaranSekolah = lazy(() =>
  import("./components/Modules/InfraPendidikan/SebaranSekolah/index")
);

const SebaranDapur = lazy(() =>
  import("./components/Modules/InfraPendidikan/SebaranDapur/index")
)

const StrukturOrganisasi = lazy(() =>
import("./components/Modules/StrukturOrganisasi/index")
)

const PengelolaanSampah = lazy(() =>
  import("./components/Modules/PengelolaanSampah/index")
  )

function App() {

  const { GlobalProvider } = AppContext;

  return (
    <GlobalProvider>
      {/*<Router history={history}>*/}
      <div className="App">
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Switch>
            <NoLoginRouter noGlobal exact path="/" component={Login} />
            <NoLoginRouter path="/login" component={Login} />
            <NoLoginRouter path="/nologin" component={NoLogin} />

            <NoLoginRouter path="/forgot-password" component={ForgotPassword} />

            <Route
              exact
              path="/setting-password/:key/:token"
              component={SettingPassword}
            />

            <PrivateRoute
              path="/dashboard"
              component={Dashboard}
              slug="dashboard"
            />

            <PrivateRoute exact path="/visi-misi" component={VisiMisi} />

            <PrivateRoute path="/content/menu" component={ContentMenu} />
            <PrivateRoute path="/content/logo" component={Logo} />
            <PrivateRoute path="/content/premium" component={Premium} />

            <PrivateRoute
              exact
              path="/account-management/users"
              component={User}
            />
            <PrivateRoute
              exact
              path="/account-management/users/create"
              component={UserCreate}
            />
            <PrivateRoute
              exact
              path="/account-management/users/update/:id"
              component={UserUpdate}
            />

            <PrivateRoute
              exact
              path="/sektor/:id_sektor/:id_menu"
              component={Sektor}
            />
            <PrivateRoute
              exact
              path="/data-sectoral"
              component={DataSectoral}
            />

            <PrivateRoute
              exact
              path="/account-management/menus"
              component={Menu}
            />
            <PrivateRoute
              path="/account-management/menus/create"
              component={MenuCreate}
            />
            <PrivateRoute
              exact
              path="/account-management/menus/update/:id"
              component={MenuUpdate}
            />

            <PrivateRoute
              exact
              path="/account-management/roles"
              component={Role}
            />
            <PrivateRoute
              path="/account-management/roles/create/:id"
              component={RoleCreate}
            />
            <PrivateRoute
              path="/account-management/roles/update/:id/:id_usecase"
              component={RoleUpdate}
            />

            <PrivateRoute
              exact
              path="/account-management/cases"
              component={Case}
            />
            <PrivateRoute
              path="/account-management/cases/create"
              component={CaseCreate}
            />
            <PrivateRoute
              path="/account-management/cases/update/:id"
              component={CaseUpdate}
            />

            <PrivateRoute
              path="/analytics/social-media"
              component={SocialMedia}
            />
            <PrivateRoute
              path="/analytics/digital-media"
              component={DigitalMedia}
            />
            <PrivateRoute
              path="/analytics/ecommerce"
              component={Ecommerce}
            />

            <PrivateRoute
              exact
              path="/potensi-daerah/ekonomi-sda"
              component={EkonomiSda}
            />
            <PrivateRoute
              exact
              path="/potensi-daerah/demografi"
              component={Demografi}
            />
            <PrivateRoute
              exact
              path="/potensi-daerah/perdagangan-antar-wilayah"
              component={Perdagangan}
            />
            <PrivateRoute
              path="/potensi-daerah/sosial-kependudukan"
              component={PopulationSocial}
            />
            <PrivateRoute
              exact
              path="/potensi-daerah/ekonomi-perdagangan"
              component={EconomyAndTrade}
            />
            <PrivateRoute
              exact
              path="/potensi-daerah/sda"
              component={SumberDayaAlam}
            />

            <PrivateRoute
              exact
              path="/potensi-daerah/komunikasi"
              component={komunikasi}
            />

            <PrivateRoute path="/region-profile" component={RegionProfile} />
            <PrivateRoute path="/point-of-interest" component={Poi} />

            <PrivateRoute path="/tourism/summary" component={TourismSummary} />
            <PrivateRoute
              path="/pariwisata/ringkasan"
              component={TourismRingkasan}
            />
            <PrivateRoute
              path="/pariwisata/mobility"
              component={TourismMobility}
            />
            <PrivateRoute
              path="/pariwisata/demografi"
              component={TourismDemography}
            />
            <PrivateRoute
              path="/pariwisata/data-perjalanan"
              component={TourismDataTrip}
            />
            <PrivateRoute
              path="/tourism/tracking"
              component={TourismTracking}
            />
            <PrivateRoute
              path="/tourism/pelacakan"
              component={TourismPelacakan}
            />
            <PrivateRoute path="/people-movement" component={PeopleMovement} />
            <PrivateRoute path="/tourism/trip" component={TourismTrip} />
            <PrivateRoute path="/dashboard-g20" component={DashboardG20} />
            <PrivateRoute path="/empty" component={NoContent} />

            <PrivateRoute path="/user-tracking" component={UserTracking} />
            <PrivateRoute path="/kesehatan" component={Kesehatan} />
            <PrivateRoute path="/opd-priority-sector" component={OPD} />

            <PrivateRoute
              path="/sosial-ekonomi/harga-pangan"
              component={HargaPangan}
            />
            <PrivateRoute
              path="/sosial-ekonomi/analisis"
              component={Analisis}
            />
            <PrivateRoute
              path="/sosial-ekonomi/sentimen-media"
              component={SentimenMedia}
            />
            <PrivateRoute
              path="/sosial-ekonomi/data-modeling-analytics"
              component={AnalisisDataModeling}
            />

            <PrivateRoute
              path="/cms/sektor-prioritas"
              component={CmsSektorPrioritas}
            />

            <PrivateRoute
              path="/dashboard-global"
              component={DashboardGlobal}
            />

            {/* <PrivateRoute path="/pariwisata" component={PariwisataTelkomsel} /> */}

            <PrivateRoute
              path="/incinerator/realtime-dashboard"
              component={DahsboardIncinerator}
            />

            <PrivateRoute
              path="/detail-report/polutan-partikulat-gas"
              component={PolutanPartikulatGas}
            />

            <PrivateRoute
              path="/detail-report/meteorologi"
              component={Meteoreologi}
            />
            <PrivateRoute
              path="/detail-report/incinerator"
              component={Incinerator}
            />
            <PrivateRoute
              path="/detail-report/kapasitas-sampah"
              component={KapasitasSampah}
            />
            <PrivateRoute
              path="/dynamic-chart"
              component={DynamicChart}
            />
            <PrivateRoute
              path="/website-internal/website-utama"
              component={MainWeb}
            />
            <PrivateRoute
              path="/website-internal/diskominfoperstatik"
              component={DiskominfoWeb}
            />
            <PrivateRoute
              path="/website-internal/pelajarin"
              component={PenyediaWeb}
            />
            <PrivateRoute
              path="/rencana-pembangunan"
              component={RencanaPembangunan}
            />
             <PrivateRoute
              path="/infrastruktur-pendidikan/sebaran-sekolah"
              component={SebaranSekolah}
            />
            <PrivateRoute
              path="/infrastruktur-pendidikan/sebaran-dapur"
              component={SebaranDapur}
            />
            <PrivateRoute
              path="/struktur-organisasi"
              component={StrukturOrganisasi}
            />
            <PrivateRoute
              path="/pengelolaan-sampah"
              component={PengelolaanSampah}
            />
            {/* <PrivateRoute
              path="/incinerator/playback-cctv"
              component={PlaybackCCTV}
            /> */}

            {/* Embed */}
            <Route
              exact
              path="/embed/sektor/:id_sektor/:id_menu/:token"
              component={EmbedSektorPrioritas}
            />

            <Route path="/" component={NotFound} />
          </Switch>
        </Suspense>
      </div>
    </GlobalProvider>
  );
}

export default App;

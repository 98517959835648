import React, { useEffect, useState, useContext } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import logoSA from "../../assets/img/logo-sa.svg";
import {
  ACCESS_TOKEN_NAME,
  API_BASE_URL,
  API_GET_USECASE_DETAIL,
  API_GET_USERS_INFO,
  API_USER_INFO,
} from "../../constants/apiConstants";
import { reactLocalStorage } from "reactjs-localstorage";
import axios from "axios";
import "../../assets/css/Header/Header.css";
import AppContext from "../../AppContext";
import MySwal from "../../utils/MySwal";
import { dataWarningSA } from "../../utils/tools";
import Cookies from "universal-cookie";
import ContainerHeader from "./ContainerHeader";
import patterenBatik from "../../assets/img/pattern-batik.svg";
import asmatbatik from "../../assets/img/asmatbatik.svg";

const Header = (props) => {
  const cookies = new Cookies();
  const { GlobalContext } = AppContext;
  const global = useContext(GlobalContext);
  const { setGlobalState, globalState, dashboardLoading, setDashboardLoading } =
    global;
  const [menu, setMenu] = useState([]);
  const [temp, setTemp] = useState([]);
  const [burger, setBurger] = useState(false); // button burger in mobile size mode
  const [hide, setHide] = useState(false); // button chevron to hide menu when desktop mode
  const [rightmenu, setRightmenu] = useState(false); // button profile when in mobile size mode
  const [wlogout, setWlogout] = useState(false); // handel dropdown logout for desktop mode
  const [xtraLogo, setExtraLogo] = useState(false);
  const [logo, setLogo] = useState("");
  let history = useHistory();
  const [expanded, setExpanded] = useState({});
  const [usecase, setUsecase] = useState({});

  const handleSubmenu = (submenus) => {
    return submenus.map((submenu, index) => (
      <Link key={submenu.id_menu} className="linkRouter" to={submenu.link}>
        <li
          className={
            "menu sub-menu " + actives(props, submenu.link) + " margin-b-8"
          }
        >
          {" "}
          <div className="menu-name">{submenu.name_menu}</div>
        </li>
      </Link>
    ));
  };

  const handleMenuClick = (id) => {
    setExpanded((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleMenu = (menus) => {
    return menus.map((menu, key) => {
      return menu.is_have_child === 1 ? (
        <div key={menu.id_menu}>
          {menu.sub_menu.length !== 0 && (
            <>
              <li
                key={menu.id_menu}
                data-toggle={!hide && "collapse"}
                href={"#menuId-" + menu.id_menu}
                aria-expanded="false"
                onClick={() => {
                  setHide(false);
                  handleMenuClick(menu.id_menu);
                }}
                className={
                  "menu menuHeader linkRouter " + actives(props, menu.link)
                }
              >
                <i className={menu.icon + " margin-r-8 header"}></i>{" "}
                <div className="menu-name">{menu.name_menu}</div>{" "}
                <i
                  style={
                    expanded[menu.id_menu]
                      ? {
                          transform: "rotate(180deg)",
                          position: "relative",
                          top: "5px",
                        }
                      : { transform: "rotate(0deg)" }
                  }
                  className="fas fa-chevron-down float-right header"
                ></i>
              </li>
              <div
                className={
                  "collapse " +
                  handleCollapse(props, menu.link, hide) +
                  " margin-t-8"
                }
                id={"menuId-" + menu.id_menu}
                onClick={() => setBurger(!burger)}
              >
                {menu.id_parent === 0 && handleSubmenu(menu.sub_menu)}
              </div>
            </>
          )}
        </div>
      ) : (
        <div key={menu.id_menu} onClick={() => setBurger(!burger)}>
          <Link key={menu.id_menu} to={menu.link} className="linkRouter">
            <li
              onClick={() => setHide(false)}
              className={
                "menu menuHeader " + actives(props, menu.link) + " margin-b-8"
              }
            >
              <i className={menu.icon + " margin-r-8 header"}></i>
              <div className="menu-name">{menu.name_menu}</div>
            </li>
          </Link>
        </div>
      );
    });
  };

  const actives = (props, key) => {
    return props.history.location.pathname.includes(key) ? "actives" : "";
  };

  const handleCollapse = (props, key, hide) => {
    return props.history.location.pathname.includes(key) && !hide ? "show" : "";
  };

  const logout = () => {
    const data = {
      title: "Logout",
      html: `Apakah Anda yakin akan logout?`,
      confirmButtonText: "Ya, Logout",
    };

    MySwal.fire(dataWarningSA(data)).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${API_BASE_URL}user/logout`)
          .then(() => {
            cookies.remove("login_access_token");
            reactLocalStorage.remove("nologin");
            history.push("/");
          })
          .catch((error) => {});
      }
    });
  };

  
  useEffect(() => {
    getDetail(API_GET_USERS_INFO);
  }, [props]);

  const getZoom = async () => {
    await axios
      .get(`${API_BASE_URL}zoom/level`)
      .then(({ data }) => {
        reactLocalStorage.setObject("zoom", {
          zoom_map: data.data[0].zoom_level,
        });
      })
      .catch((error) => {});
  }

  const getDetail = (EP, payload) => {
    axios
      .get(`${EP}`)
      .then(({ data: { data } }) => {
        if (data.usecase.length !== 0) {getZoom()}
        setUsecase(data.usecase);
        reactLocalStorage.setObject("base_color", {
          color1: data.usecase.base_color1,
          color2: data.usecase.base_color2,
          color3: data.usecase.base_color3,
          color4: data.usecase.base_color4,
          level_usecase: data.usecase.level_usecase,
          kode_provinsi: data.usecase.kode_provinsi,
          kode_kab_kota: data.usecase.kode_kab_kota,
          nama_usecase: data.usecase.name_usecase
        });
        reactLocalStorage.setObject("lonlat", {
          center_lon: data.usecase.center_lon,
          center_lat: data.usecase.center_lat,
        });

        /** skala inject */
        document.getElementById("skala-script-2") &&
          document.getElementById("skala-script-2").remove();
        document.getElementById("skala-script-1") &&
          document.getElementById("skala-script-1").remove();
        document
          .querySelectorAll('script[src*="analytic.rocks"]')
          .forEach((val) => {
            val.remove();
          });
        if (data?.profile?.nik) {
          const script = document.createElement("script");
          script.innerHTML = `var _tar= {};_tar.ID="de43245e-4fbb-42d0-a359-21130e27b0df";_tar.UserID = "${data.profile.nik}";`;
          script.id = "skala-script-1";
          document.body.append(script);

          const script2 = document.createElement("script");
          script2.src = "https://track.analytic.rocks/load/";
          script2.async = true;
          script2.id = "skala-script-2";
          document.body.append(script2);
        }
        /** end of skala inject */

        setGlobalState((current) => {
          return {
            ...current,
            detailUsecase: data.usecase,
            profile: data.profile,
            id_role: data.profile.id_role,
          };
        });

        setDashboardLoading(false);

        setMenu(data.menu);
        let arrLink = [];
        if (data.menu.length > 0) {
          for (let i = 0; i < data.menu.length; i++) {
            if (data.menu[i].is_have_child === 0) {
              arrLink.push(data.menu[i].link);
            }

            if (data.menu[i].sub_menu.length > 0) {
              for (let j = 0; j < data.menu[i].sub_menu.length; j++) {
                arrLink.push(data.menu[i].sub_menu[j].link);
              }
            }
          }
        }
        setTemp(arrLink);

        //for dashboard g20
        if (data.profile.nik === "90020") {
          setExtraLogo(true);
          setLogo("Logo G20");
        }
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    let base = window.location.pathname;
    if (globalState.profile.nik !== "90020") {
      base.includes("tourism") || base.includes("kesehatan")
        ? setExtraLogo(true)
        : setExtraLogo(false);
      if (base.includes("tourism")) {
        setLogo("Logo Centryc");
      }
      if (base.includes("kesehatan")) {
        setLogo("Logo Admedika");
      }
    } else {
      setLogo("Logo G20");
    }
  }, [props]);

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <ContainerHeader
      burger={burger}
      hide={hide}
      rmenu={rightmenu}
      wlog={wlogout}
      primary={globalState.detailUsecase.base_color1}
      secondary={globalState.detailUsecase.base_color2}
      tertiary={globalState.detailUsecase.base_color3}
      sidemenucolor={globalState.detailUsecase.base_color4}
      location={props.location.pathname}
    >
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="navbar-brand">
          {/* web full  */}
          {/* if sidebar toogle button not clicked */}
          {globalState?.detailUsecase?.brands
            ?.filter((brand) =>
              // check if path url is  not torims and level usecase !== 0 if true render only bigview logo, false render all logo
              xtraLogo !== true &&
              globalState?.detailUsecase?.level_usecase !== 0
                ? brand.brand_desc !== "Logo Centryc" &&
                  brand.brand_desc !== "Logo Admedika"
                : brand.brand_desc === "Logo BigView" ||
                  brand.brand_desc === logo
            )
            ?.map((brand, key) => (
              <img
                key={`brand-${key}`}
                data-testid="full-logo"
                className="imgLogo logo-w-t"
                src={`${brand.brand_url}`}
                alt={`${brand.brand_desc}`}
              />
            ))}

          {/* if sidebar toogle button clicked */}
          {globalState?.detailUsecase?.brands_small
            ?.filter((brand) =>
              // check if path url is  not torims and level usecase !== 0 if true render only bigview logo, false render all logo
              xtraLogo !== true &&
              globalState?.detailUsecase?.level_usecase !== 0
                ? brand.brand_desc !== "Logo Centryc" &&
                  brand.brand_desc !== "Logo Admedika"
                : brand.brand_desc === "Logo BigView" ||
                  brand.brand_desc === logo
            )
            ?.map((brand, key) => (
              <img
                key={`small-brand-${key}`}
                data-testid="logo-only"
                className="imgLogo only-logo-web"
                src={`${brand.brand_url}`}
                alt={`${brand.brand_desc}`}
              />
            ))}
          {/* ======== */}

          {/* web mobile and small brands */}
          {/* button burger */}
          <button className="custom-toggler" onClick={() => setBurger(!burger)}>
            <span className="navbar-toggler-icon"></span>
          </button>

          {globalState?.detailUsecase?.brands_small
            ?.filter((brand) =>
              // check if path url is  not torims and level usecase !== 0 if true render only bigview logo, false render all logo
              xtraLogo !== true &&
              globalState?.detailUsecase?.level_usecase !== 0
                ? brand.brand_desc !== "Logo Centryc" &&
                  brand.brand_desc !== "Logo Admedika"
                : brand.brand_desc === "Logo BigView" ||
                  brand.brand_desc === logo
            )
            ?.map((brand, key) => (
              <img
                key={`small-brand-${key}`}
                data-testid="logo-only"
                className="imgLogo only-logo"
                src={`${brand.brand_url}`}
                alt={`${brand.brand_desc}`}
              />
            ))}

          {/* ======== */}
        </div>

        <button
          className={`custom-avtr avtr 
            ${
              Object.keys(reactLocalStorage.getObject("nologin")).length === 0
                ? ""
                : "d-none"
            }
          `}
          onClick={() => setRightmenu(!rightmenu)}
        >
          <img
            className="img-fluid mr-1"
            alt="Right profile"
            src={
              globalState.profile.id_usecase === null
                ? logoSA
                : "" + globalState.profile.pic_foto
            }
          />
          <span className="fas fa-chevron-down"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {/* <button className="custom-hide" onClick={() => setHide(!hide)}>
                     <span className={`${hide ? "fas fa-chevron-right" : "fas fa-chevron-left"}`}></span>
                  </button> */}
          </ul>
          <ul className="navbar-nav navbarRight">
            <li className="nav-item dropdown">
              <div className="avtr">
                <img
                  className="img-fluid"
                  alt="Nav profile"
                  src={
                    globalState.profile.id_usecase === null
                      ? logoSA
                      : "" + globalState.profile.pic_foto
                  }
                />
              </div>
            </li>
            <li className="nav-item iddty">
              <a className="titlename">{globalState.profile.name}</a>
              <a className="titlerole">{globalState.profile.nama_role}</a>
            </li>
            <li className="nav-item">
              <div
                className={`btn-logout ${
                  Object.keys(reactLocalStorage.getObject("nologin")).length ===
                  0
                    ? ""
                    : "d-none"
                }`}
                onClick={logout}
              >
                <a className="mr-2 ml-3">Logout</a>
                <svg
                  width="22"
                  className="lg"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 9H13V11H5V14L0 10L5 6V9ZM4 16H6.708C7.86269 17.0183 9.28669 17.6819 10.8091 17.9109C12.3316 18.14 13.8878 17.9249 15.291 17.2915C16.6942 16.6581 17.8849 15.6332 18.7201 14.3398C19.5553 13.0465 19.9995 11.5396 19.9995 10C19.9995 8.46042 19.5553 6.95354 18.7201 5.66019C17.8849 4.36683 16.6942 3.34194 15.291 2.7085C13.8878 2.07506 12.3316 1.85998 10.8091 2.08906C9.28669 2.31815 7.86269 2.98167 6.708 4H4C4.93066 2.75718 6.13833 1.74851 7.52707 1.05414C8.91581 0.359775 10.4473 -0.00116364 12 2.81829e-06C17.523 2.81829e-06 22 4.477 22 10C22 15.523 17.523 20 12 20C10.4473 20.0012 8.91581 19.6402 7.52707 18.9459C6.13833 18.2515 4.93066 17.2428 4 16Z"
                    fill="#4D4D4D"
                  />
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <div
        className="sideMenu"
        style={{
          zIndex: "100",
          backgroundImage:
            usecase.id_usecase === 559 ? `url(${patterenBatik})` : usecase.id_usecase === 380 ? `url(${asmatbatik})`:"none",
            backgroundSize: usecase.id_usecase === 559 ? "" : usecase.id_usecase === 380 ? "200px":"",
          
        }}
      >
        <div
          className="container container-menu"
          style={{
            position: "relative",
          }}
        >
          {/* Logo Usecase Start */}
          <div className="Header-Hamburger-Container">
            <button onClick={() => setHide(!hide)}>
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div className="side-logo">
            {globalState.profile.nik !== "90020" &&
              globalState.profile.nik !== "10001" && (
                <>
                  <div className="row mb-3 ">
                    <div className="col d-flex justify-content-center">
                      <img
                        alt="Side profile"
                        className="usecaselogo"
                        src={
                          globalState.profile.id_usecase === null
                            ? logoSA
                            : "" + globalState.detailUsecase.pic_logo
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <span className="title-usecase">
                        {globalState.profile.id_usecase === null
                          ? globalState.profile.name
                          : globalState.detailUsecase.name_usecase}
                      </span>
                    </div>
                  </div>
                </>
              )}
          </div>
          {/* Logo Usecase End */}

          {/* Side Menu Start*/}
          <ul className="menu">{handleMenu(menu)}</ul>
          {/* Side Menu End*/}
        </div>
        <div className={`itm-container ${ usecase.id_usecase === 380 ? "d-none":"d-block"}`}>
          <div className="itm">
            <svg
              width="304"
              height="274"
              viewBox="0 0 304 274"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 0.5C0.5 0.5 64.5 7 175 91C285.5 175 301.5 274 301.5 274H0.5V0.5Z"
                fill={
                  globalState.detailUsecase.base_color3 == undefined
                    ? "#FFFFFF"
                    : globalState.detailUsecase.base_color3
                }
                fillOpacity="0.05"
              />
              <path
                d="M304 131C304 131 246.62 128.091 136 170C25.3802 211.909 0 274 0 274H304V131Z"
                fill={
                  globalState.detailUsecase.base_color3 == undefined
                    ? "#FFFFFF"
                    : globalState.detailUsecase.base_color3
                }
                fillOpacity="0.05"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="content">
        {props.children}

        {globalState.detailUsecase?.footer_logo?.length !== 0 && (
          <div className="footer-brand d-flex justify-content-center">
            {globalState.detailUsecase?.footer_logo?.map((brand, key) => (
              <div>
                <span className="poweredby"> Powered by </span>
                <img
                  key={`brand-footer-${key}`}
                  className=""
                  src={`${brand.footer_url}`}
                  alt={`${brand.footer_desc}`}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="rightmenu">
        <div className="right-content">
          <div className="row">
            <div className="col-auto">
              <div className="avtr">
                <img
                  className="img-fluid"
                  src={
                    globalState.profile.id_usecase === null
                      ? logoSA
                      : "" + globalState.profile.pic_foto
                  }
                  alt="profile"
                />
              </div>
            </div>

            <div className="col">
              <div className="user-name">
                <div>
                  <h5 className="hstyled">{globalState.profile.name}</h5>
                  <span>{globalState.profile.nama_role}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="btn-logout right" onClick={logout}>
            <a className="ml-2">Logout</a>
            <svg
              width="22"
              className="lg ml-2"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 9H13V11H5V14L0 10L5 6V9ZM4 16H6.708C7.86269 17.0183 9.28669 17.6819 10.8091 17.9109C12.3316 18.14 13.8878 17.9249 15.291 17.2915C16.6942 16.6581 17.8849 15.6332 18.7201 14.3398C19.5553 13.0465 19.9995 11.5396 19.9995 10C19.9995 8.46042 19.5553 6.95354 18.7201 5.66019C17.8849 4.36683 16.6942 3.34194 15.291 2.7085C13.8878 2.07506 12.3316 1.85998 10.8091 2.08906C9.28669 2.31815 7.86269 2.98167 6.708 4H4C4.93066 2.75718 6.13833 1.74851 7.52707 1.05414C8.91581 0.359775 10.4473 -0.00116364 12 2.81829e-06C17.523 2.81829e-06 22 4.477 22 10C22 15.523 17.523 20 12 20C10.4473 20.0012 8.91581 19.6402 7.52707 18.9459C6.13833 18.2515 4.93066 17.2428 4 16Z"
                fill="#4D4D4D"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="footer">
        <span className="spanfooter">
          {/* {getCurrentYear()} © BigView. All Right Reserved */}
          {globalState.detailUsecase.footer_year} ©{" "}
          {globalState.detailUsecase.footer_brand}. All Right Reserved
        </span>
      </div>
    </ContainerHeader>
  );
};
export default withRouter(Header);

import React from 'react'
import error from '../assets/img/error.svg'
import emptyLine from '../assets/img/EmptyLine.svg'

const ResApiStatus = (props) => {
    const {status, height, message} = props;
    
    let img = null
    switch (status) {
        case 'nodata':
                img = emptyLine;
            break;
        case 'error':
                img = error;
            break;
        default:
            img = error;
            break;
    }

    return (
            <div className='x' style={{height:height, display: 'flex', justifyContent: 'center'}}>
                <div className='m-auto' style={{display:'grid'}}>
                    <img src={img} alt="Empty Data" className='m-auto'/>
                    <p>{message}</p>
                </div>
            </div>
    )
}

export default ResApiStatus;
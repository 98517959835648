import React from 'react';
import {
  Route,
  useHistory
} from "react-router-dom";

import axios from 'axios';
import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../constants/apiConstants';


function NoLoginRouter({noGlobal, component: Component, restricted, ...rest}) {
  let history = useHistory();
  const getCookie = (name) => {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin === -1) {
      begin = dc.indexOf(prefix);
      if (begin !== 0) return null;
    }
    else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end === -1) {
        end = dc.length;
      }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  return (
    <Route {...rest} render={props => {
      //console.log(rest)
      if (getCookie(ACCESS_TOKEN_NAME)) {
        axios.get(`${API_BASE_URL}user/userInfo`)
          .then(({ data: { data } }) => {
            history.push(data.profile.redirect_to)
          })
          .catch(err => {})
      } else {
        return (<Component {...props} />)
      }
    }} />
  )
}

export default NoLoginRouter;
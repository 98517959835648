import React, { useState, useEffect } from "react";
import {
  Map as LeafletMap,
  TileLayer,
  CircleMarker,
  Popup,
  GeoJSON,
  ZoomControl,
} from "react-leaflet";
import { reactLocalStorage } from "reactjs-localstorage";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import axios from "axios";
import esStyle from "../index.module.css";
import "../index.css";
import Loading from "../../../../../utils/Loading";
import { BackgroundShadow, Satuan } from "../Utils";
import { NumberWithDot, NumberFormat } from "../../../../Material/FormatNumber";
import DinamisMapZoom from "../../../../../utils/DinamisMapZoom";

const LuasPanenMaps = (props) => {
  const {
    indikatorTitle,
    indikator,
    jenis,
    startDate,
    statusDate,
    dataIndikatorCheck,
  } = props;

  const [tempMaps, setTempMaps] = useState([]);
  const [leflet, setleflet] = useState({});
  const [polyMap, setPolyMap] = useState({});
  const baseColor = reactLocalStorage.getObject("base_color");
  const lonlat = reactLocalStorage.getObject("lonlat");
  const [load, setLoad] = useState(false);

  useEffect(() => {
    // if( (!dataIndikatorCheck) || (typeof jenis === "undefined") ) return
    // setLoad(false);
    axios
      .get(
        `${API_BASE_URL}poda/sda/hortikultura/leaflet?&year=${startDate}&indikator=${indikator}&jenis=${jenis}`
      )
      .then(({ data }) => {
        setLoad(true);
        setleflet((prev) => ({
          ...prev,
          ...data.data,
        }));
      })
      .catch(() => {
        console.log("error");
      });
  }, [startDate, indikator, jenis]);

  useEffect(() => {
    if (!dataIndikatorCheck) return;
    setTempMaps(leflet.maps);
  }, [leflet.maps]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}pariwisata/map-usecase`)
      .then(({ data }) => {
        setPolyMap(data.features);
      })
      .catch(() => {
        console.log("error");
      });
  }, []);

  const [getZ, setZ] = useState(DinamisMapZoom());
  const [getZoom, setZoom] = useState(DinamisMapZoom());
  const onViewportChanged = (viewport) => {
    let newPixel = viewport.zoom;
    setZ(newPixel);
  };

  return (
    <div className={esStyle.leafletContainer}>
      {
        load ? (
          <div className="position-relative">
            <LeafletMap
              center={[lonlat.center_lat, lonlat.center_lon]} // +1 untuk geser ke kiri
              zoom={getZoom}
              height={335}
              onViewportChanged={onViewportChanged}
              zoomControl={false}
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
              />
              <ZoomControl position="bottomleft" />
              {polyMap?.geometry && Object.keys(polyMap.geometry).length && (
                <GeoJSON
                  data={polyMap}
                  style={{ color: baseColor.color1 }}
                  fillOpacity="0.3"
                  weight="2"
                />
              )}
              {tempMaps?.map((index, key) => {
                let rad = 0;
                if (getZ < getZoom) {
                  rad = getZoom - getZ;
                }
                return (
                  <CircleMarker
                    key={key}
                    center={[index.lat, index.lon]}
                    radius={Math.log(index.data * 10) - rad * 3}
                    color={"#FFFFFF"}
                    fillColor={baseColor.color1}
                    stroke={true}
                    fillOpacity="0.6"
                    weight="1"
                    onMouseOver={(e) => {
                      e.target.openPopup();
                    }}
                    onMouseOut={(e) => {
                      e.target.closePopup();
                    }}
                  >
                    <Popup>
                      <div className="d-flex row pt-2 pb-2 pl-2 pr-2 ll-marker">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <span>{startDate}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>
                                  <strong>{index.kota}</strong>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>
                                  {indikatorTitle} {jenis} :
                                  <strong>
                                    {/* {`${index.data.toLocaleString()} ${Satuan(indikatorTitle)}`}  */}
                                    {`${NumberWithDot(index.data, 2)} ${Satuan(
                                      indikatorTitle
                                    )}`}
                                  </strong>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Popup>
                  </CircleMarker>
                );
              })}
            </LeafletMap>
            {tempMaps.length === 0 ? (
              <BackgroundShadow content="Not available for this version" />
            ) : (
              // <Loading />
              ""
            )}
          </div>
        ) : (
          <Loading />
        )
        // <div className="position-relative" style={{height:'400px'}}><BackgroundShadow content="" background="#c0c2c6" /></div>
      }
    </div>
  );
};

export default LuasPanenMaps;

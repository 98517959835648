import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME } from '../../../../../../constants/apiConstants';

import 'react-quill/dist/quill.snow.css';
import childsektor from './ChildSektor.module.css';

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const Create = (props) => {
  const [usecase, setUseCase] = useState([]);

  const [link_iku, setLinkIku] = useState("");

  const [kode_sektor, setKodeSektor] = useState("");

  const [nama_alamat, setNamaAlamat] = useState("");
  const [telepon, setTelepon] = useState("");
  const [link_map, setLinkMap] = useState("");
  const [alamat, setAlamat] = useState("");

  const [id_menu, setIdMenu] = useState(0);
  const [deskripsi, setDeskripsi] = useState("");

  const [short_desc, setShortDesc] = useState("");
  const [deskripsi_detail, setDeskripsiDetail] = useState("");

  const [lengthDeskripsi, setLengthDeskripsi] = useState(0);
  const [stateIku, setStateIku] = useState("EMPTY");
  const [stateNonIku, setStateNonIku] = useState("EMPTY");

  const [stateElemen, setStateElemen] = useState("BIGVIEW");
  const [linkElemen, setLinkElemen] = useState("");

  const handleClick = () => {
    props.changePage("index");
  };

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}sector/all/${props.idUC}`, {
        headers: {
          Authorization: getCookie(ACCESS_TOKEN_NAME),
        },
      })
      .then(({ data: { data } }) => {
        setUseCase(data);
        setIdMenu(data[0].id_menu);
      })
      .catch((err) => {});
  }, [props]);

  const handleChange = (e) => {
    if (e.target.name === "id_menu") {
      setIdMenu(e.target.value);
    }
  };

  const submit = () => {
    let formData = new FormData();

    formData.append(`state_iku`, stateIku);
    formData.append(`link_iku`, stateIku === "EMBED" ? link_iku : "");
    formData.append(`state_non_iku`, stateNonIku);
    formData.append(`state_elemen`, stateElemen);
    formData.append(`link_elemen`, stateElemen === "BIGBOX" ? linkElemen : "");
    formData.append("kode_sektor", kode_sektor);
    formData.append(`nama_alamat`, nama_alamat);
    formData.append(`telepon`, telepon);
    formData.append(`link_map`, link_map);
    formData.append(`alamat`, alamat);

    formData.append(`id_usecase`, props.idUC);
    formData.append(`id_menu`, id_menu);
    formData.append(`deskripsi`, deskripsi);
    formData.append(`short_desc`, short_desc);
    formData.append(`deskripsi_detail`, deskripsi_detail);

    axios
      .post(`${API_BASE_URL}sector/add`, formData)
      .then(({ data: { data } }) => {
        alert("sukses");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLengthDeskripsi(deskripsi.length);
  }, [deskripsi]);

  return (
    <>
      <div className="row rowMenu">
        <div className="col-6">
          <form className="form-inline">
            <label className="my-1 mr-2 textMenu2 labelUC">Pilih Sektor</label>
            <select
              name="id_menu"
              id="selectUC"
              className="form-control my-1 mr-sm-2"
              onChange={handleChange}
            >
              {usecase.map((dt, index) => (
                <option key={index} value={dt.id_menu}>
                  {dt.name_menu}
                </option>
              ))}
            </select>
          </form>
        </div>
      </div>

      <div className="rowMenu">
        <p className={childsektor.textSektor}>IKU</p>
      </div>

      <div className="row rowMenu">
        <div className="col-4">
          <div className="form-group">
            <label className="textMenu2 labelUC">
              Status IKU<span className="redText">*</span>
            </label>
            <select
              className="form-control"
              value={stateIku}
              onChange={(e) => {
                setStateIku(e.target.value);
              }}
            >
              <option value="EMPTY">Empty</option>
              <option value="EMBED">Embed</option>
              <option value="DB">DB</option>
            </select>
          </div>
        </div>
        {stateIku === "EMBED" && (
          <div className="col-8">
            <div className="form-group text-left">
              <label className="textMenu2 labelUC">
                Token IKU<span className="redText">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Masukan token IKU"
                value={link_iku}
                onChange={(e) => setLinkIku(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>

      <div className="row rowMenu">
        <div className="col-4">
          <div className="form-group">
            <label className="textMenu2 labelUC">
              Data Non IKU<span className="redText">*</span>
            </label>
            <select
              className="form-control"
              value={stateNonIku}
              onChange={(e) => {
                setStateNonIku(e.target.value);
              }}
            >
              <option value="EMPTY">Empty</option>
              <option value="DB">DB</option>
            </select>
          </div>
        </div>
      </div>

      <div className="rowMenu">
        <p className={childsektor.textSektor}>Elemen Data</p>
      </div>

      <div className="row rowMenu">
        <div className="col-4">
          <div className="form-group">
            <label className="textMenu2 labelUC">
              Status Elemen Data<span className="redText">*</span>
            </label>
            <select
              className="form-control"
              value={stateElemen}
              onChange={(e) => {
                setStateElemen(e.target.value);
              }}
            >
              <option value="BIGVIEW">BigView</option>
              <option value="BIGBOX">BigBox</option>
            </select>
          </div>
        </div>
        {stateElemen === "BIGBOX" && (
          <div className="col-8">
            <div className="form-group text-left">
              <label className="textMenu2 labelUC">
                URL Elemen Data<span className="redText">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Masukan URL Elemen Data"
                value={linkElemen}
                onChange={(e) => setLinkElemen(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>

      <div className="rowMenu">
        <p className={childsektor.textSektor}>Kode Sektor</p>
      </div>

      <div className="row rowMenu">
        <div className="col-4">
          <div className="form-group">
            <label className="textMenu2 labelUC">
              Kode Sektor Data<span className="redText">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Masukan Kode Sektor"
              value={kode_sektor}
              onChange={(e) => setKodeSektor(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="rowMenu">
        <p className={childsektor.textSektor}>Informasi</p>
      </div>

      <div className="row rowMenu">
        <div className="col-4">
          <div className="form-group text-left">
            <label className="textMenu2 labelUC">
              Deskripsi<span className="redText">*</span>
            </label>
            <div className="input-group">
              <input
                type="text"
                className={`form-control ${childsektor.inputGroup}`}
                placeholder="Masukan Deskripsi"
                value={deskripsi}
                onChange={(e) => setDeskripsi(e.target.value)}
              />
              <div className={`input-group-append ${childsektor.inputAppend}`}>
                <span className="input-group-text">{lengthDeskripsi}/80</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="form-group text-left">
            <label className="textMenu2 labelUC">
              Nama Alamat<span className="redText">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Masukan Alamat Anda"
              value={nama_alamat}
              onChange={(e) => setNamaAlamat(e.target.value)}
            />
          </div>
        </div>

        <div className="col-4">
          <div className="form-group text-left">
            <label className="textMenu2 labelUC">
              Telepon<span className="redText">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Masukan No. Telepon"
              value={telepon}
              onChange={(e) => setTelepon(e.target.value)}
            />
          </div>
        </div>

        <div className="col-4">
          <div className="form-group text-left">
            <label className="textMenu2 labelUC">
              Link Map<span className="redText">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Masukan Link Map"
              value={link_map}
              onChange={(e) => setLinkMap(e.target.value)}
            />
          </div>
        </div>

        <div className="col-5">
          <div className="form-group text-left">
            <label className="textMenu2 labelUC">
              Alamat<span className="redText">*</span>
            </label>
            <textarea
              name="deskripsi"
              className="form-control"
              rows="3"
              placeholder="Masukan Alamat"
              value={alamat}
              onChange={(e) => setAlamat(e.target.value)}
            />
          </div>
        </div>

        {/*<div className="col-7">
          <div className="form-group text-left">
            <label className="textMenu2 labelUC">Informasi Pendek<span className="redText">* </span>(1 Alinea dari informasi lengkap)</label>
            <textarea placeholder="Masukan 1 alinea dari informasi lengkap" className="form-control" rows="3" onChange={(e) => setShortDesc(e.target.value)} value={short_desc} />
          </div>
        </div>*/}
      </div>

      <div className="row rowMenu">
        <div className="col-12">
          <div className="form-group text-left">
            <label className="textMenu2 labelUC">
              Informasi Pendek<span className="redText">* </span>(1 Alinea dari
              informasi lengkap)
            </label>
            <ReactQuill
              theme="snow"
              modules={modules}
              value={short_desc}
              onChange={setShortDesc}
              placeholder="Masukan Informasi Pendek"
            />
          </div>
        </div>
      </div>

      <div className="row rowMenu">
        <div className="col-12">
          <div className="form-group text-left">
            <label className="textMenu2 labelUC">
              Informasi Lengkap<span className="redText">*</span>
            </label>
            <ReactQuill
              name="informasi"
              theme="snow"
              modules={modules}
              value={deskripsi_detail}
              onChange={setDeskripsiDetail}
              placeholder="Masukan Informasi Lengkap"
            />
          </div>
        </div>
      </div>

      <div className="row rowMenu">
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-outline-dark btnMenu"
            onClick={handleClick}
          >
            Kembali
          </button>
          <button
            type="button"
            className="btn btn-dark btnMenu"
            onClick={submit}
          >
            Simpan
          </button>
        </div>
      </div>
    </>
  );
};

export default Create;
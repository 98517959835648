import React,{useState} from 'react';
import style from "./Index.module.css";
import "react-datepicker/dist/react-datepicker.css";
import PieGender from './PieGender';
import ColumnAge from './ColumnAge';
import BarEdu from './BarEdu';
import BarProf from './BarProf';
import Values from 'values.js';
import {reactLocalStorage} from 'reactjs-localstorage';

const Index = (props) => {
  const baseColor = reactLocalStorage.getObject('base_color');
  const [list, setList] = useState(new Values(baseColor.color1).shades(12))
  let temp = [];
  for(var i = 0; i < list.length; i++){
    temp.push(list[i].hexString())
  }
 
  return (
    <>
      <p className="h3 mb-3">Demografi</p>
      <span className="line-2nd mb-4" style={{borderBottomColor : baseColor.color2}}></span>
      <div className={`bg-white p-4`}>
        {/* row 1 */}

        <div className="row">
          {/* col gender */}
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <PieGender color={temp}/>
          </div>

          {/* col kelompok usia */}
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <ColumnAge color={baseColor.color1}/>
          </div>
        </div>

        <div className={style.separator}></div>

        {/* row 2 */}

        <div className="row">
          {/* col pendidikan */}
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <BarEdu color={baseColor.color1} />
          </div>

          {/* col top 10 pekerjaan */}
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <BarProf color={baseColor.color1}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

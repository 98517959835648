import noImage from "../../../assets/img/no_image.svg";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "../../../App.css";
import { API_GET_SECTOR_USECASE } from "../../../constants/apiConstants";

import { reactLocalStorage } from "reactjs-localstorage";

import ListSector from "./ListSector";
import AppContext from "../../../AppContext";
import Loading from "../../../utils/Loading";
import SwiperImg from "./SwiperImg";
import {
  jabatanProfile,
  locationProfile,
  wakilJabatanProfile,
  welcomeProfile,
  withoutWakil,
} from "../../../utils/exeption";

function Dashboard(props) {
  const { GlobalContext } = AppContext;
  const global = useContext(GlobalContext);
  const { globalState, dashboardLoading } = global;
  const [sector, setSector] = useState([]);
  const [image, setImage] = useState({
    ketua: noImage,
    wakil: noImage,
  });
  const baseColor = reactLocalStorage.getObject("base_color");

  useEffect(() => {
    if (globalState.id_role !== null) {
      getSector("GET", `${API_GET_SECTOR_USECASE + globalState.id_role}`, []);
      let imageKetua = `${globalState.detailUsecase.pic_gubernur}`;
      let imageWakil = `${globalState.detailUsecase.pic_wakil}`;
      setImage({
        ketua: imageKetua,
        wakil: imageWakil,
      });
    }
  }, [globalState]);

  const getSector = (method, EP, payload) => {
    axios
      .get(`${EP}`)
      .then(({ data: { data } }) => {
        setSector(data);
      })
      .catch(function (error) {});
  };

  return (
    <div className="boxContainer">
      <div className="box-page-title">
        <p className="page-title" data-testid="title-dashboard">
          Dashboard
        </p>
        <span
          className="line-2nd"
          style={{ borderBottomColor: baseColor.color2 }}
        ></span>
      </div>
      <div className="box-welcome">
        <div className="left-welcome">
          <div style={{ color: baseColor.color3 }} className="title-welcome">
            Selamat datang di
          </div>
          <div
            style={{
              color: baseColor.color3,
              fontFamily: "El Messiri",
              fontSize: "2.5rem",
            }}
            className="subtitle-welcome"
          >
            Executive Dashboard{" "}
          </div>

          {dashboardLoading ? (
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <Loading />
            </div>
          ) : (
            <h1
              data-testid="usecase-text"
              style={{
                color: baseColor.color3,
                fontFamily: "El Messiri",
                fontSize: "2.5rem",
                fontWeight: "bold",
              }}
            >
              {welcomeProfile[globalState.profile.nik]
                ? welcomeProfile[globalState.profile.nik]
                : globalState.detailUsecase.nama_daerah}
              {/* {globalState.detailUsecase.nama_daerah} */}
            </h1>
          )}

          <div
            className="filter-bg"
            style={{ background: baseColor.color4 }}
          ></div>
        </div>
        <div className="right-welcome">
          <div className="box-list-avatar">
            <div className="left-list-avatar">
              {dashboardLoading ? (
                ""
              ) : (
                <img
                  data-testid="image-ketua"
                  src={image.ketua}
                  height={94}
                  width={94}
                  alt="ketua"
                />
              )}
            </div>
            <div className="right-list-avatar">
              {dashboardLoading ? (
                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>
                  <div data-testid="name-ketua" className="title-list-avatar">
                    {globalState.profile.nik === "1223334444"
                      ? "Nama Kepala Daerah"
                      : globalState.detailUsecase.nama_gubernur}
                    {/* {globalState.detailUsecase.nama_gubernur} */}
                  </div>
                  <div
                    data-testid="title-ketua"
                    className="subtitle-list-avatar"
                  >
                    {jabatanProfile[globalState.profile.nik]
                      ? jabatanProfile[globalState.profile.nik]
                      : globalState.detailUsecase.title_gubernur}
                    {/* {globalState.detailUsecase.title_gubernur} */}
                  </div>
                </>
              )}
            </div>
            <div className="clearer"></div>
          </div>
          {withoutWakil.includes(globalState.profile.nik)  ? (
            ""
          ) : (
            <div className="box-list-avatar">
              <div className="left-list-avatar">
                {dashboardLoading ? (
                  ""
                ) : (
                  <img
                    data-testid="image-wakil"
                    src={image.wakil}
                    height={94}
                    width={94}
                    alt="wakil"
                  />
                )}
              </div>
              <div className="right-list-avatar">
                {dashboardLoading ? (
                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <Loading />
                  </div>
                ) : (
                  <>
                    <div data-testid="name-wakil" className="title-list-avatar">
                      {globalState.profile.nik === "1223334444"
                        ? "Nama Wakil Kepala Daerah"
                        : globalState.detailUsecase.nama_wakil}
                      {/* {globalState.detailUsecase.nama_wakil} */}
                    </div>
                    <div
                      data-testid="title-wakil"
                      className="subtitle-list-avatar"
                    >
                      {wakilJabatanProfile[globalState.profile.nik]
                        ? wakilJabatanProfile[globalState.profile.nik]
                        : globalState.detailUsecase.title_wakil}
                      {/* {globalState.detailUsecase.title_wakil} */}
                    </div>
                  </>
                )}
              </div>
              <div className="clearer"></div>
            </div>
          )}
        </div>
        <div className="clearer"></div>
      </div>
      <div className="box-info">
        {sector.length > 0 ? (
          <ListSector data-testid="list-sector" value={sector} />
        ) : (
          ""
        )}
      </div>
      <div
        className={`box-info ${
          globalState.profile.nik === "00444" ? "" : "d-none"
        }`}
      >
        <SwiperImg />
      </div>
      <div
        className="box-frame"
        style={
          dashboardLoading
            ? {
                display: "flex",
              }
            : {}
        }
      >
        <div
          className="box-map"
          style={
            dashboardLoading
              ? {
                  width: "100%",
                }
              : {}
          }
        >
          <div
            className="left-map"
            style={
              dashboardLoading
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : {}
            }
          >
            {!dashboardLoading ? (
              <>
                <h5 data-testid="name-usecase">
                  {locationProfile[globalState.profile.nik]
                    ? locationProfile[globalState.profile.nik]
                    : `Pemerintah ${globalState.detailUsecase.name_usecase}`}
                </h5>
                <div className="box-contact">
                  <h5>Alamat:</h5>
                  <div data-testid="adress-usecase" className="desc-contact">
                    {globalState.detailUsecase.alamat}
                  </div>
                </div>
                <div className="box-contact">
                  <h5>Telepon:</h5>
                  <div data-testid="contact-usecase" className="desc-contact">
                    {globalState.detailUsecase.telepon}
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  position: "relative",
                  zIndex: "3",
                }}
              >
                <Loading />
              </div>
            )}
          </div>
          <div
            className="right-map"
            style={
              dashboardLoading
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : {}
            }
          >
            {!dashboardLoading ? (
              <div className="box-iframe-map">
                <iframe
                  data-testid="map-usecase"
                  src={globalState.detailUsecase.link_map}
                  allowFullScreen
                  loading="lazy"
                ></iframe>
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  zIndex: "3",
                }}
              >
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

import { useState, useEffect } from 'react';
import axios from 'axios';

const useLoadIframe = (link) => {
  /**
   * Delay state use for delaying state and return back the results
   */
  const [delayState, setDelayState] = useState(null);

  /**
   * Status state use for flagging return request from server
   */
  const [status, setStatus] = useState(true);

  useEffect(() => {
    axios
      .get(link)
      .then(() => {
        setStatus(true);
      })
      .catch(() => {
        setStatus(false);
      });

    setTimeout(() => {
      setDelayState(link);
    }, 4000);
  }, [link]);

  return [delayState, status];
};

export default useLoadIframe;

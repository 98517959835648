import React, { useState, useEffect, useRef } from "react";

import TabsInformasi from "./Tabs/Informasi";
import TabsIku from "./Tabs/Iku";
import TabsNonIku from "./Tabs/NonIku";
import TabsElemenData from "./Tabs/ElemenData";

//import axios from 'axios';
//import axios from '../../../utils/connection';
import axios from "axios";

import { API_BASE_URL } from "../../../constants/apiConstants";
import { useParams } from "react-router-dom";

import sektorstyle from "./Sektor.module.css";
import { reactLocalStorage } from "reactjs-localstorage";
//import ReactDOM from 'react-dom'

function Index() {
   const tabInfoRef = useRef(null);
   const baseColor = reactLocalStorage.getObject("base_color");
   const [dataSektor, setDataSektor] = useState({
      short_desc: "",
      deskripsi_detail: "",
      nama_alamat: "",
      alamat: "",
      telepon: "",
      link_map: "",
      id_sektor: 0,
      nama_sektor: "",
      link_iku: "",
      state_iku: "",
      link_elemen: "",
      state_elemen: "EMPTY",
   });

   const params = useParams();

   useEffect(() => {
      axios
         .get(`${API_BASE_URL}sector/detail/${params.id_sektor}`)
         .then(({ data: { data } }) => {
            setDataSektor({
               short_desc: data.short_desc,
               deskripsi_detail: data.deskripsi_detail,
               nama_alamat: data.nama_alamat,
               alamat: data.alamat,
               telepon: data.telepon,
               link_map: data.link_map,
               id_sektor: data.id_sektor,
               nama_sektor: data.nama_sektor,
               link_iku: data.link_iku,
               state_iku: data.state_iku,
               state_non_iku: data.state_non_iku,
               state_elemen: data.state_elemen,
               link_elemen: data.link_elemen,
               name_usecase: data.name_usecase,
            });
         })
         .catch((err) => false);
      tabInfoRef.current.click();
   }, [params]);

   return (
      <>
         <div className="row mb-5">
            <div className="col-12 position-relative">
               <div className="box-page-title">
                  <p data-testid="title-page" className={sektorstyle.textSektor}>
                     {dataSektor.nama_sektor}
                  </p>
                  <span className="line-2nd" style={{ borderBottomColor: baseColor.color2 }}></span>
               </div>
            </div>
         </div>

         <div className={`row ${sektorstyle.rowSektor}`}>
            <div className="col-12">
               <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                     <a data-testid="title-tab" className="nav-link active" id="nav-informasi-tab" data-toggle="tab" href="#nav-informasi" role="tab" ref={tabInfoRef} aria-controls="nav-informasi" aria-selected="true">
                        Informasi
                     </a>
                     <a data-testid="title-tab2" className="nav-link" id="nav-iku-tab" data-toggle="tab" href="#nav-iku" role="tab" aria-controls="nav-iku" aria-selected="false">
                        Data IKU
                     </a>
                     {dataSektor.state_non_iku?.toUpperCase() === "DB" && (
                        <a data-testid="title-tab3" className="nav-link" id="nav-noniku-tab" data-toggle="tab" href="#nav-noniku" role="tab" aria-controls="nav-noniku" aria-selected="false">
                           Data Non IKU
                        </a>
                     )}

                     {(dataSektor.state_elemen?.toUpperCase() === "BIGVIEW" || dataSektor.state_elemen?.toUpperCase() === "DB" || dataSektor.state_elemen?.toUpperCase() === "BIGBOX") && (
                        <a data-testid="title-tab2" className="nav-link" id="nav-elemen-tab" data-toggle="tab" href="#nav-elemen" role="tab" aria-controls="nav-elemen" aria-selected="false">
                           Data Sektoral
                        </a>
                     )}
                  </div>
               </nav>
               <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-informasi" role="tabpanel" aria-labelledby="nav-informasi-tab">
                     <TabsInformasi data={dataSektor}></TabsInformasi>
                  </div>
                  <div className="tab-pane fade" id="nav-iku" role="tabpanel" aria-labelledby="nav-iku-tab">
                     <TabsIku data={dataSektor}></TabsIku>
                  </div>
                  <div className="tab-pane fade" id="nav-noniku" role="tabpanel" aria-labelledby="nav-noniku-tab">
                     <TabsNonIku data={dataSektor} />
                  </div>
                  <div className="tab-pane fade" id="nav-elemen" role="tabpanel" aria-labelledby="nav-elemen-tab">
                     {(dataSektor.state_elemen?.toUpperCase() === "BIGVIEW" || dataSektor.state_elemen?.toUpperCase() === "DB" || dataSektor.state_elemen?.toUpperCase() === "BIGBOX") && <TabsElemenData data={dataSektor}></TabsElemenData>}
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Index;

import React,{useState} from 'react';
import esStyle from "./EkonomiSda.module.css";
import LineInflation from './LineInflation';
import BarTopTrade from './BarTopTrade';
import LinePDB from './LinePDB';
import LinePAD from './LinePAD';
import LineTrendTrade from './LineTrendTrade';
import BarTopPAD from './BarTopPAD';
import PieContribSDA from './PieContribSDA';
import LineRevSDA from './LineRevSDA';
import BarRevSDA from './BarRevSDA';
import Values from 'values.js';
import {reactLocalStorage} from 'reactjs-localstorage';

const Index = () => {
  const baseColor = reactLocalStorage.getObject('base_color');
  const [list, setList] = useState(new Values(baseColor.color1).shades(12))
  let temp = [];
  for(var i = 0; i < list.length; i++){
    temp.push(list[i].hexString())
  }
 
  return (
    <>
      <div className="row">
        <div className="col-6">
          <p className={esStyle.textEs}>Ekonomi & SDA</p>
          <span className="line-2nd mb-3" style={{borderBottomColor : baseColor.color2}}></span>
        </div>
      </div>

      <div className={`${esStyle.boxEs} ${esStyle.marginTop20}`}>
        <div className="row">
          <div className="col-12">
            <p className={esStyle.textEs3}>Ekonomi & Perdagangan</p>
          </div>
        </div>

        <div className="row">

          <div className="col-12 col-md-6 mt-3">
            <LineInflation color={baseColor.color1}  />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <BarTopTrade color={baseColor.color1} />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <LinePDB color={baseColor.color1}  />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <LinePAD color={baseColor.color1}  />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <LineTrendTrade color={baseColor.color1}  />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <BarTopPAD color={baseColor.color1}/>
          </div>
        </div>
      </div>

      <div className={`${esStyle.boxEs} ${esStyle.marginTop20}`}>
        <div className="row">
          <div className="col-12">
            <p className={esStyle.textEs3}>Sumber Daya Alam</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 mt-3">
            <PieContribSDA color={temp} />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <LineRevSDA color={baseColor.color1} />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <BarRevSDA color={baseColor.color1}/>
          </div>

        </div>
      </div>
    </>
  );
}

export default Index;
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_BASE_URL } from '../../../../../constants/apiConstants';

import esStyle from '../../../PotensiDaerah/SDA/index.module.css';
import FooterCard from '../HargaRataRataDanPerubahan/FooterCard';
import Chart from './Chart';
import Tabulasi from './Tabulasi';

const PerubahanHarga = (props) => {
    const [listKota, setListKota]       = useState();
    const [kota, setKota]               = useState();
    const [listPeriode, setListPeriode] = useState();
    const [periode, setPeriode]         = useState();

    useEffect(() => {
        axios
        .get(
            `${API_BASE_URL}sosial-ekonomi/harga-pangan/perubahan-harga/filter-kabkot`
        )
        .then(({ data: { data }, status }) => {
            setListKota(data);
            setKota(data[0].value);
          })
          .catch(() => {});
    }, []);

    useEffect(() => {
        axios
        .get(
            `${API_BASE_URL}sosial-ekonomi/harga-pangan/perubahan-harga/filter-periode`
        )
        .then(({ data: { data }, status }) => {
            setListPeriode(data);
            setPeriode(data[0].value);
          })
          .catch(() => {});
    }, []);

    const tooltip = <>
                        <p>Catatan : </p>
                        <ol type="a">
                            <li>Day-to-Day (DTD): Perbandingan harga komoditas pada tanggal yang dipilih (d) hingga 5 hari sebelumnya (d-5).</li>
                            <li>Week-to-Week (WTW): Perbandingan harga komoditas minggu berjalan (w) hingga 5 minggu sebelumnya (w-5). 
                                <br />Satu minggu terhitung dari Senin sampai tanggal dipilih untuk minggu berjalan (w) 
                                <br />dan Senin sampai Minggu untuk minggu-minggu sebelumnya (w-1, …, w-5).</li>
                            <li>Month-to-Month (MTM): Perbandingan harga komoditas bulan berjalan (m) hingga 5 bulan sebelumnya (m-5).</li>
                        </ol>
                    </>
        
    

  return (
    <div className='card border-0'>
            <div className='card-header bg-transparent border-0'>
                <div className="row">
                    <div className="col"><span className={esStyle.textEs4}>Perubahan Harga</span></div>

                    <div className="form-inline justify-content-end">
                        <div className="form-group mb-2 mr-2 col-4-auto">
                            <label className="col-auto">Kab/Kota</label>
                            <div className='col'>
                                <select className={`form-control form-control-md `}
                                    data-testid="select-kota"
                                    value={kota}
                                    onChange={(event) => {setKota(event.target.value)}}
                                >
                                    {
                                        listKota?.map((v, i)=>{
                                            return (
                                                <option key={i} value={v.value} data-nama={v.nama}>{v.nama}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-group mb-2 col-4-auto">
                            <label className="col-auto">Periode Perbandingan</label>
                            <div className='col'>
                                <select className={`form-control form-control-md `}
                                    data-testid="select-periode"
                                    onChange={(event) => {setPeriode(event.target.value)}}
                                >
                                    {
                                        listPeriode?.map((v, i)=>{
                                            return (
                                                <option key={i} value={v.value} data-nama={v.nama}>{v.nama}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           
           
            <div className='card-body pt-1'>
                <div className='row'>
                    <div className="col-md-7"><Chart {...props} kota={kota} periode={periode} /></div>
                    <div className="col-md-5"><Tabulasi {...props} kota={kota} periode={periode} /></div>
                </div>

                <div className={`ml-1 catatan`} style={{
                        fontFamily: 'DM Sans',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '12px',
                        color: '#797979'
                    }}
                >
                    {/* <p>Catatan : </p>
                    <ol type="a">
                        <li>Day-to-Day (DTD): Perbandingan harga komoditas pada tanggal yang dipilih (d) hingga 5 hari sebelumnya (d-5).</li>
                        <li>Week-to-Week (WTW): Perbandingan harga komoditas minggu berjalan (w) hingga 5 minggu sebelumnya (w-5). Satu minggu terhitung dari Senin sampai tanggal dipilih untuk minggu berjalan (w) dan Senin sampai Minggu untuk minggu-minggu sebelumnya (w-1, …, w-5).</li>
                        <li>Month-to-Month (MTM): Perbandingan harga komoditas bulan berjalan (m) hingga 5 bulan sebelumnya (m-5).</li>
                    </ol> */}
                </div>
                <FooterCard 
                    title="Perubahan Harga"                     
                    url={`${API_BASE_URL}sosial-ekonomi/harga-pangan/perubahan-harga/detail?jenis=${props.jenis}&komoditas=${props.komoditas}&tanggal=${props.tanggal}&pasar=${props.pasar}&periode=${periode}`} 
                    enableTooltip={true}
                    labelTooltip="Informasi Data" 
                    tooltip={tooltip}
                />
            </div>
        </div>
  )
}

export default PerubahanHarga
import React, { useState, useEffect, useRef } from 'react';
//import axios from 'axios';
import '../../../../App.css';
import '../../../../assets/css/Logo/Logo.css';
import {
  API_BASE_URL,
} from '../../../../constants/apiConstants';
//import PrivateRoute from '../../../utils/PrivateRoute';
import { withRouter } from 'react-router-dom';
import NoLogo from '../../../../assets/img/nologo.png';
import axios from 'axios';
//import ReactDOM from 'react-dom'

function Index() {
  // state to save file to upload to server
  const [file, setFile] = useState(null);
  // state to display logo image in the web interface 
  const [imgData, setImgData] = useState(NoLogo);
  // state to store use case from db
  const [useCase, setUseCase] = useState([]);
  // state to store selected use case 
  const [selectUsecase, setSelectUsecase] = useState('');
  // state for flagging when form submitted 
  const [status, setStatus] = useState(true);
  const [isEditLogo, setIsEditLogo] = useState(0);

  const hiddenFileInput = useRef(null);

  /**
   * Function to handle button Delete
   */
  const handleDeleteImage = (event) => {
    setIsEditLogo(1);

    setFile(null);
    setImgData(NoLogo);
    hiddenFileInput.current.value = null;
  }

  /**
   * Programatically click the hidden file input element
   * when the Button component is clicked
   */
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  /**
   * Call a function (passed as a prop from the parent component)
   * to handle the user-selected file
   */
  const handleChange = (event) => {
    if (event.target.files[0]) {
      setIsEditLogo(1);
      //data gambar
      const fileUploaded = event.target.files[0];
      setFile(fileUploaded);

      // //data sementara
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }

  };
  
  /**
   * Function to handle button save logo
   */
  const handleSave = () => {
    let formData = new FormData();

    formData.append('id_usecase', selectUsecase);
    formData.append('pic_logo', file);
    formData.append('is_edit_logo', isEditLogo);

    axios.post(`${API_BASE_URL}usecase/updateUseCaseLogo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(({ data: { data } }) => {
        alert('sukses')
        setStatus(!status);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    axios.get(`${API_BASE_URL}usecase/list`)
      .then(({ data: { data } }) => {
        setUseCase(data);
        //setSelectUsecase(data[0].id_usecase)
      })
      .catch(err => {})
  }, [])

  useEffect(() => {
    if (useCase.length !== 0) {
      setSelectUsecase(useCase[0].id_usecase)
    }
  }, [useCase])

useEffect(() => {
  setIsEditLogo(0);
  axios.get(`${API_BASE_URL}usecase/getLogo/${selectUsecase}`)
  .then(({data : { data }}) => {
    setFile(null);
    setImgData(`${data.pic_logo}`);
  })
  .catch(err => {})
}, [selectUsecase])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p data-testid="title" className="textLogo">Logo</p>
        </div>
      </div>
      <div className="box-frame">
        <div className="row">
          <div className="col-6">
            <div className="form-group row">
              <label className="col-2 col-form-label textLogo2 labelUC">
                Use Case
              </label>
              <div className="col-4">
                <select data-testid="select-usecase" id="selectUC" className="form-control" onChange={(e) => setSelectUsecase(e.target.value)} value={selectUsecase}>
                  {useCase.map((dataUC, index) => (
                    <option key={index} value={dataUC.id_usecase}>
                      {dataUC.name_usecase}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-left">
            <p className="textLogo3">
              Logo<span className="redText">*</span>
            </p>
            <div id="uploadImg">
              <img data-testid="image" width="100" height="100" src={imgData} alt="nologo" />
              <div id="divUpload">
                <input
                  data-testid="input-image"
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: 'none' }}
                />
                <button data-testid="btn-delete" type="button" className="btn btn-outline-dark btnLogo" onClick={handleDeleteImage}>
                  Delete
                </button>
                <button
                  data-testid="btn-add"
                  type="button"
                  className="btn btn-dark btnLogo"
                  onClick={handleClick}
                >
                  Upload
                </button>
                <p className="textLogo4">Gambar ekstensi .jpg, .png max. 5mb</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-right">
            <button data-testid="btnSubmit" type="button" className="btn btn-dark" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Index);

import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { API_DETAIL_PERIODE, API_UPDATE_PERIODE } from '../../../../../../constants/apiConstants';
import CustomAlert from '../../../../../Material/CustomAlert';

const CreatePeriode = (props) => {  
  const yearNow = new Date().getFullYear();
  const [errorSelect, setErrorSelect] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');
  const [optionYears, setOptionYears] = useState([]);
  
  useEffect(() => {
    let years = [];
    for (let i = (yearNow - 15); i <= (yearNow + 15); i++) {
      years.push(i);
    }
    setOptionYears(years);

    axios.get(`${API_DETAIL_PERIODE}${props.idPeriode}`)
      .then(({data: {data}}) => {
        setStartYear(data.start_year)
        setEndYear(data.end_year)
      })
      .catch((err) => {
        if(err.response.status === 400) {
          // console.log(err.response.status);
        }
      });
  }, [])

  const handleSelectStartYear = (e) => {
    setErrorSelect(false);    
    setStartYear(e.target.value);
  }

  const handleSelectEndYear = (e) => {
    setErrorSelect(false);    
    setEndYear(e.target.value);
  }

  const handleBackBtn = () => {
    props.changePage('index', props.idPeriode);
  }

  const handleSaveBtn = () => {
    let formData = new URLSearchParams();
    formData.append('start_year', startYear);
    formData.append('end_year', endYear);
    formData.append('id_usecase', props.idUC);
    
    axios.put(`${API_UPDATE_PERIODE}${props.idPeriode}`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(() => {        
        setSuccessSubmit(true);
        setTimeout(()=> {
          handleBackBtn();
        }, 2000)
      })
      .catch((err) => {        
        if(err.response.status === 400) {
          setErrorSelect(true);
        }
      });

  }
  
  return (
    <>
      {successSubmit && <CustomAlert page="Periode"></CustomAlert>}
      <div className="row rowMenu">
        <div className="col-12">
          <form action="" className="form form-inline">            
            <div className="form-group">
              <label htmlFor="period">Periode Tahun</label>
              <select className="form-control my-1 mx-2 mr-sm-2" data-testid="select-startyear" name="start-year" id="start-year" onChange={handleSelectStartYear} value={startYear}>
                <option value="">Pilih Tahun</option>
              {optionYears.map((dtYear, index) => (
                <option key={index} value={dtYear}>{dtYear}</option>
              ))}
              </select>
              -
              <select className="form-control my-1 mx-2 mr-sm-2" data-testid="select-endyear" name="end-year" id="end-year" onChange={handleSelectEndYear} value={endYear}>
                <option value="">Pilih Tahun</option>
              {optionYears.map((dtYear, index) => (
                <option key={index} value={dtYear}>{dtYear}</option>
              ))}
              </select>
              { errorSelect && <span data-testid="error-select" className="text text-danger mx-1">Periode sudah digunakan</span>}
            </div>
          </form>
        </div>
      </div>
      <div className="row rowMenu">
        <div className="col-12 text-right">
          <button type="button" className="btn btn-outline-dark btnMenu" data-testid="btn-back" onClick={handleBackBtn}>Kembali</button>
          <button type="button" className="btn btn-dark btnMenu" data-testid="btn-save" onClick={handleSaveBtn}>Simpan</button>
        </div>
      </div>
    </>
    
  )
}

export default CreatePeriode

import React, {useState, useEffect} from 'react';
import Select from 'react-select'
import style from './index.module.css';
import TableElement from './TableElement';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../constants/apiConstants';

const reactSelectStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    overflow: 'unset',
    textOverflow: 'unset',
    whiteSpace: 'unset',
  }),
}

const ElementData = (props) => {
  const [stateElData, setStateElData] = useState([]);
  const [stateListElData, setStateListElData] = useState([]);
  const [stateTahun, setStateTahun] = useState([]);
  const [stateListTahun, setStateListTahun] = useState([]);
  const [statePageTitle, setStatePageTitle] = useState('');
  
  const convertStateToParam = (stateVal) => {
    if (!stateVal?.length) return false;
    let elParam = '';
    stateVal.forEach(val => {
      elParam += val?.value + ', '
    })
    elParam = elParam.slice(0, -2);
    return elParam
  }
  

  useEffect(() => {
    if (!props.data?.id_sektor) return;
    axios.get(`${API_BASE_URL}sector/table/title?id_sektor=${props.data.id_sektor}`)
    .then(({ data: { data, title } }) => {
      setStatePageTitle(title)
    })
    .catch(() => {})

    axios.get(`${API_BASE_URL}sector/list/elemen?id_sektor=${props.data.id_sektor}`)
    .then(({ data: { data } }) => {
      setStateListElData(data);
      setStateElData([]);
    })
    .catch(() => {})
  }, [props.data])

  useEffect(() => {
    if (!props.data?.id_sektor || !stateElData.length) {
      setStateTahun([]); setStateListTahun([]);
      return;
    }
    const elParam = convertStateToParam(stateElData);
    axios.get(`${API_BASE_URL}sector/list/tahun?id_sektor=${props.data.id_sektor}&element=${elParam}`)
    .then(({ data: { data } }) => {
      setStateListTahun(data);
      setStateTahun([]);
    })
    .catch(() => {})
  }, [props.data, stateElData])


  return (
    <div className={`${style.container}`}>
      <div className={`row justify-content-between mb-5`}>
        <div className="col-sm-12 col-md-5">
          <p className={`${style.pageTitle}`}>{statePageTitle}</p>
        </div>
        <div className="col-sm-12 col-md-7">
          <div className="row justify-content-end">
            <div className="col-sm-12 col-md-8 my-1">
              <label className={`${style.label}`} htmlFor="select-elemen-data">Elemen Data</label>
              <Select
                styles={reactSelectStyles}
                isMulti={true}
                id="select-elemen-data"
                options={stateListElData}
                value={stateElData}
                onChange={(option) => { setStateElData(option); }}
              >
              </Select>
            </div>
            <div className="col-sm-12 col-md-4 my-1">
              <label className={`${style.label}`} htmlFor="select-tahun">Tahun</label>            
              <Select
                styles={reactSelectStyles}
                isMulti={true}
                id="select-tahun"
                options={stateListTahun}
                value={stateTahun}
                onChange={(option) => { setStateTahun(option); }}
              >
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TableElement 
            data={props.data} 
            filter={{
              tahun: convertStateToParam(stateTahun),
              element: convertStateToParam(stateElData),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ElementData;

import React, { useState, useEffect } from "react";
import IframeResizer from "iframe-resizer-react";
import sektorstyle from "../Sektor.module.css";
import Loading from "../../../../utils/Loading";
import Indicator from "./Indicator/Index";
import IndicatorSummary from "./IndicatorSummary/Index";
import DataCategory from "./DataCategory/Index";
import BlankImg from "../../../../assets/img/sektorempty.svg";

const Iku = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [props]);

  const resizedCallback = (iframe) => {
    if (iframe.type === "resetPage") {
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  return (
    <>
      {props.data?.state_iku === "DB" && (
        <>
          <Indicator data={props.data} public={props.public} />
          <IndicatorSummary data={props.data} public={props.public} />
          <DataCategory data={props.data} />
        </>
      )}

      {props.data?.state_iku === "EMPTY" && (
        <div className={`${sektorstyle.blankContainer}`}>
          <img className={`${sektorstyle.blankImg}`} src={BlankImg} />
          <p className={`${sektorstyle.blankP}`}>
            Anda belum memiliki data IKU
          </p>
        </div>
      )}

      {props.data?.state_iku === "EMBED" && (
        <div className="min-vh-50 position-relative bg-white">
          {loading === true && <Loading />}
          <IframeResizer
            title={`iku-${props.data.id_sektor}`}
            src={`https://bigquery.bigbox.co.id/public/dashboard/${props.data.link_iku}#titled=false`}
            frameBorder="0"
            resizedCallback={resizedCallback}
            style={{
              width: "1px",
              minWidth: "100%",
              visibility: loading === true ? "hidden" : "visible",
              maxHeight: loading === true ? "0px" : "unset",
            }}
          />
        </div>
      )}
    </>
  );
};

Iku.defaultProps = {
  public: false,
};

export default Iku;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants/apiConstants";
import CustomAlert from "../../../../Material/CustomAlert";

const Analytics = (props) => {
  const [selectedMenu, setSelectedMenu] = useState("social");
  const [selectedSource, setSelectedSource] = useState("EMBED");
  const [alert, setAlert] = useState({
    type: "success",
    message: "",
    show: false,
  });
  const [flagSubmit, setFlagSubmit] = useState(false);
  const [tokenAnalytics, setTokenAnalytics] = useState("");
  const [urlAnalytics, setUrlAnalytics] = useState("");


  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}usecase/getSourceAnalytic?id_usecase=${props.idUC}&analytic=${selectedMenu}`
      )
      .then(({ data: { data } }) => {
        setSelectedSource(data.source_digital || data.source_social);
      })
      .catch(() => {});
  }, [props.idUC, selectedMenu, flagSubmit]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.set(`analytic`, selectedMenu);
    formData.set(`source`, selectedSource);
    axios
      .post(
        `${API_BASE_URL}usecase/setSourceAnalytic?id_usecase=${props.idUC}&analytic=${selectedMenu}&source=${selectedSource}`,
        formData
      )
      .then(({ data: { data } }) => {
        setFlagSubmit((prev) => !prev);
        setAlert((prev) => ({
          ...prev,
          type: "success",
          message: data.message,
          show: true,
        }));
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            show: false,
          }));
        }, 5000);
      })
      .catch(() => {
        setFlagSubmit((prev) => !prev);
        setAlert((prev) => ({
          ...prev,
          type: "danger",
          message: "Analytics gagal disimpan",
          show: true,
        }));
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            show: false,
          }));
        }, 5000);
      });
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}usecase/getTokenAnalytic?id_usecase=${props.idUC}`)
      .then(({ data: { data } }) => {
        // setBaseUrlOPD(
        //   data.token_OPD === null
        //     ? "https://dbd.admedika.co.id/trusted/"
        //     : data.token_admedika
        // );
        setTokenAnalytics(data.token_bigbox === null ? "" : data.token_bigbox);
        setUrlAnalytics(data.url_analytcs === null ? "" : data.url_analytcs);
      })
      .catch(() => {});
  }, [props.idUC, flagSubmit]);

  const handleSubmitToken = () => {
    const formData1 = new FormData();
    formData1.append(`id_usecase`, props.idUC);
    // formData.append(`token_OPD`, baseUrlOPD);
    formData1.append(`token_bigbox`, tokenAnalytics);
    formData1.append(`url_analytcs`, urlAnalytics);
    axios
      .post(`${API_BASE_URL}usecase/setTokenAnalytic`, formData1)
      .then(({ data: { data } }) => {
        setFlagSubmit((prev) => !prev);
        setAlert((prev) => ({
          ...prev,
          type: "success",
          message: data.message,
          show: true,
        }));
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            show: false,
          }));
        }, 5000);
      })
      .catch(() => {
        setFlagSubmit((prev) => !prev);
        setAlert((prev) => ({
          ...prev,
          type: "danger",
          message: "token gagal disimpan",
          show: true,
        }));
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            show: false,
          }));
        }, 5000);
      });
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-4">
          <label className="textMenu2 labelUC">
            Token Analytics<span className="redText">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Masukan Token Analytics"
            value={tokenAnalytics}
            onChange={(e) => setTokenAnalytics(e.target.value)}
          />
        </div>
        <div className="col-4">
          <label className="textMenu2 labelUC">
            URL Analytics<span className="redText">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Masukan Token Analytics"
            value={urlAnalytics}
            onChange={(e) => setUrlAnalytics(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <button
          type="button"
          className="btn btn-dark btnMenu ml-auto mx-3 mt-3"
          onClick={handleSubmitToken}
          data-testid="btn-submit"
        >
          Simpan
        </button>
      </div>
      <hr></hr>
      <br></br>
      {alert.show && <CustomAlert type="success" message={alert.message} />}
      <div className="row">
        <div className="col-4">
          <label className="textMenu2 labelUC">
            Pilih Analytic<span className="redText">*</span>
          </label>
          <select
            className="form-control"
            value={selectedMenu}
            onChange={(e) => setSelectedMenu(e.target.value)}
            data-testid="select-menu"
          >
            <option value="social">Social Media</option>
            <option value="digital">Digital Media</option>
          </select>
        </div>
        <div className="col-4">
          <label className="textMenu2 labelUC">
            Sumber Data<span className="redText">*</span>
          </label>
          <select
            className="form-control"
            value={selectedSource}
            onChange={(e) => setSelectedSource(e.target.value)}
            data-testid="select-source"
          >
            <option value="EMBED">Embed</option>
            <option value="DB">DB</option>
          </select>
        </div>
      </div>
      <div className="d-flex mx-3 my-5">
        <button
          type="button"
          className="btn btn-dark btnMenu ml-auto"
          onClick={handleSubmit}
          data-testid="btn-submit"
        >
          Simpan
        </button>
      </div>
    </>
  );
};

export default Analytics;

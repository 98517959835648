import React, { useState, useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import axios from 'axios';
import style from './datatable.module.css';
import { NumberWithDot } from '../Material/FormatNumber';

/**
 * Function to check if sortable_fields is defined or not
 * if not, then init sortable_fields to true for each fields
 * Required params: [props.sortable_fields, props.t_head]
 */
const checkSortableFields = (sortable_fields, order_col) => {
  if (!Object.keys(sortable_fields).length) {
    order_col.forEach((key) => {
      sortable_fields[key] = true;
    });
  }
  return sortable_fields;
};

/**
 * Function to init sortList state
 * This sortList state is used for change icon sort in each th element
 * Required params: [props.sortable_fields] after init
 */
const initSortList = (sortable_fields) => {
  let sortList = {};
  Object.entries(sortable_fields).forEach(([key, value]) => {
    if (value === true) {
      sortList[key] = '';
    }
  });
  /**
   * sortList return example
   * {
   *  element: '',
   *  urusan: '',
   *  total: '',
   * };
   */
  return sortList;
};

/**
 * Function to check props total_data
 * If total_data = null then set total tbody equals to tbody.length
 */
const checkTotalData = (total_data, tbody = []) => {
  total_data = total_data === null ? tbody.length : total_data;
  return total_data;
};

const DatatableServerside = (props) => {
  let {
    t_head,
    t_body,
    sortable_fields,
    total_data,
    pagination,
    pagination_max,
    pagination_max_current,
    per_page,
    base_endpoint,
    order_col,
    params,
    right,
    center,
    isHeight,
  } = props;
  per_page = !per_page ? t_body.length : per_page;
  total_data = checkTotalData(total_data, t_body);
  sortable_fields = checkSortableFields(sortable_fields, order_col);
  const [state, setState] = useState({
    t_body,
    sortList: initSortList(sortable_fields),
    currentPage: 1,
    total_data,
    pagination_max_current,
    params,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      t_body,
      sortList: initSortList(sortable_fields),
      currentPage: 1,
      total_data,
      pagination_max_current,
      params,
    }));
  }, [props]);

  useEffect(() => {
    if (base_endpoint === '') {
      return;
    }
    let offset = (state.currentPage - 1) * t_body.length || '';

    let countOrder = Object.values(state.sortList).reduce(
      (calc, val) => ((calc ? 1 : 0) + (val ? 1 : 0)), 0
    );
    let order = '';
    /**
     * if single sort
     */
    if (countOrder === 1) {
      let [order_by, order_dir] = Object.entries(state.sortList).find(
        ([key, val]) => val
      ) || ['', ''];
      order = `${order_by && '&order_by=' + order_by}${
        order_dir && '&order_dir=' + order_dir
      }`;
    } else if (countOrder > 1) {
    /**
     * if multiple sort
     */
      Object.entries(state.sortList).forEach(([key, value]) => {
        order += value && `${key} ${value}, `;
      });
      order = order && '&order=' + order?.slice(0,-2);
    }

    axios
      .get(
        `${base_endpoint}?${per_page && 'limit=' + per_page}${
          offset && '&offset=' + offset
        }${order}${params}`
      )
      .then(
        ({
          data: {
            data: { widget_data },
          },
        }) => {
          setState((prev) => ({
            ...prev,
            t_body: widget_data.t_body,
            total_data: widget_data.total_data,
          }));
        }
      )
      .catch(() => {});
  }, [state.currentPage, state.sortList, base_endpoint, per_page, t_body.length]);

  const handleOrder = (key, event) => {
    let orderDir = '';
    let tempSortList = {};
    switch (state.sortList[key]) {
      case '':
        orderDir = 'asc';
        break;
      case 'asc':
        orderDir = 'desc';
        break;
      default:
        orderDir = '';
        break;
    }
    /**
     * if shite + click
     */
    if (event.shiftKey) {
      tempSortList = state.sortList;
      delete tempSortList[key];
      tempSortList[key] = orderDir;
      setState((prev) => ({
        ...prev,
        sortList: {
          ...tempSortList,
        },
      }));
    } else {
      tempSortList = initSortList(sortable_fields);
      delete tempSortList[key];
      tempSortList[key] = orderDir;
      setState((prev) => ({
        ...prev,
        sortList: {
          ...tempSortList,
        },
      }));
    }
  };

  const handleClickPagination = (page) => {
    switch (page) {
      case 'prev':
        if (state.currentPage <= 1) {
          return;
        }
        setState((prev) => ({
          ...prev,
          currentPage: Number(prev.currentPage) - 1,
          pagination_max_current:
            Number(prev.currentPage - 1) < prev.pagination_max_current
              ? Number(prev.currentPage) - pagination_max
              : prev.pagination_max_current,
        }));
        break;
      case 'next':
        if (state.currentPage + 1 > Math.ceil(state.total_data / per_page)) {
          return;
        }
        setState((prev) => ({
          ...prev,
          currentPage: Number(prev.currentPage) + 1,
          pagination_max_current:
            Number(prev.currentPage + 1) >=
            prev.pagination_max_current + pagination_max
              ? Number(prev.currentPage) + 1
              : prev.pagination_max_current,
        }));
        break;
      case 'prev...':
        setState((prev) => ({
          ...prev,
          currentPage: prev.pagination_max_current - pagination_max,
          pagination_max_current: prev.pagination_max_current - pagination_max,
        }));
        break;
      case 'next...':
        setState((prev) => ({
          ...prev,
          currentPage: prev.pagination_max_current + pagination_max,
          pagination_max_current: prev.pagination_max_current + pagination_max,
        }));
        break;
      case 'first':
        setState((prev) => ({
          ...prev,
          currentPage: 1,
          pagination_max_current: 1,
        }));
        break;
      case 'last':
        setState((prev) => ({
          ...prev,
          currentPage: Math.ceil(state.total_data / per_page),
          pagination_max_current:
            Math.ceil(state.total_data / per_page) +
            1 -
            (Math.ceil(state.total_data / per_page) % pagination_max
              ? Math.ceil(state.total_data / per_page) % pagination_max
              : pagination_max),
        }));
        break;
      default:
        setState((prev) => ({
          ...prev,
          currentPage: Number(page),
        }));
        break;
    }
  };

  return (
    <>
      <div className={`${style._datatable_container}`}>
        <table className={style._datatable}>
        <thead>
            <tr>
              {order_col.map((key, i) => (
                <th className={`${style.th } ${isHeight ? style.customHeight : '' }`} key={key}>
                  <div
                    data-testid="th-order"
                    className={`d-flex position-relative cursor-pointer ${style.cursorPointer}`}
                    onClick={(event) => {
                      handleOrder(key, event);
                    }}
                  >
                     <div className={`position-relative ${right?.length !== 0 && right?.includes(i) ? `ml-auto` : 'default'}`}>
                        <span className={`mr-2 ${right?.length !== 0 && right?.includes(i) ? `${style.isRight}` : 'default'}`}>
                          {t_head[key]}
                        </span>
                        </div>

                      <span
                        className={` ml-2 ${
                          sortable_fields[key] === false && "d-none"
                        }`}
                      >
                        <i
                          className={`fa fa-caret-up position-absolute ${
                            style.caretUp
                          } ${state.sortList[key] === "desc" && "d-none"}`}
                        ></i>
                        <i
                          className={`fa fa-caret-down position-absolute ${
                            style.caretDown
                          } ${state.sortList[key] === "asc" && "d-none"}`}
                        ></i>
                      </span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {state.t_body?.map((row, index) => (
              <tr className={`${style.tr} ${isHeight ? style.customHeigh : '' }`} key={index}>
                {order_col.map((key, i) => (
                  <td className={`${style.td} ${isHeight ? style.customHeight : '' }`} key={key + '-' + index}>
                    {isNaN(row[key]) || typeof row[key] === 'string'
                      // ? right?.length !== 0 && right?.includes(i) ? <div className="text-right mr-3">{row[key]}</div> : row[key]
                      ? right?.length !== 0 && right?.includes(i) 
                        ? <div className="text-right mr-3">{row[key]}</div> 
                        : center?.length !== 0 && center?.includes(i) 
                          ? <div className="text-center">{row[key]}</div> 
                          : row[key]
                      : right?.length !== 0 && right?.includes(i) ? <div className="text-right mr-3">{`${NumberWithDot(row[key], 2)}`}</div> : NumberWithDot(row[key], 2)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {pagination &&
          per_page &&
          t_body.length !== 0 &&
          Math.ceil(state.total_data / per_page) > 1 && (
            <div className={`${style.paginationContainer} text-right`}>
              <span
                onClick={() => {
                  handleClickPagination('prev');
                }}
                data-testid="btn-prev"
                className={`${style.paginationEl} ${
                  state.currentPage === 1 && style.paginationElDisabled
                }`}
              >
                <i className="fa fa-chevron-left"></i>
              </span>
              {
                <span
                  key={`page-first`}
                  onClick={() => {
                    handleClickPagination('first');
                  }}
                  data-testid="btn-page-first"
                  className={`${style.paginationEl} ${
                    state.currentPage === 1 && style.paginationElActive
                  }`}
                  style={
                    state.currentPage === 1
                      ? {
                          backgroundColor:
                            reactLocalStorage.getObject('base_color')?.color1,
                        }
                      : {}
                  }
                >
                  1
                </span>
              }
              {state.currentPage > pagination_max && (
                <span
                  key={`page-prev-...`}
                  data-testid="btn-page-prev"
                  className={`${style.paginationEl}`}
                  onClick={() => {
                    handleClickPagination('prev...');
                  }}
                >
                  ...
                </span>
              )}
              {Array.from(
                {
                  length:
                    Math.ceil(state.total_data / per_page) < pagination_max
                      ? Math.ceil(state.total_data / per_page)
                      : pagination_max,
                },
                (_, i) => i + state.pagination_max_current
              ).map(
                (val) => {
                  return (val > 1 &&
                  val < Math.ceil(state.total_data / per_page) && (
                    <span
                      data-testid="btn-pagination"
                      key={`page-${val}-`}
                      onClick={() => {
                        handleClickPagination(val);
                      }}
                      className={`${style.paginationEl} ${
                        state.currentPage === val && style.paginationElActive
                      }`}
                      style={
                        state.currentPage === val
                          ? {
                              backgroundColor:
                                reactLocalStorage.getObject('base_color')
                                  ?.color1,
                            }
                          : {}
                      }
                    >
                      {val}
                    </span>
                  ))
                }
              )}
              {state.pagination_max_current <=
                Math.ceil(state.total_data / per_page) - pagination_max &&
                Math.ceil(state.total_data / per_page) > pagination_max && (
                  <span
                    key={`page-next-...`}
                    data-testid="btn-page-next"
                    className={`${style.paginationEl}`}
                    onClick={() => {
                      handleClickPagination('next...');
                    }}
                  >
                    ...
                  </span>
                )}
              {
                <span
                  key={`page-last`}
                  onClick={() => {
                    handleClickPagination('last');
                  }}
                  data-testid="btn-page-last"
                  className={`${style.paginationEl} ${
                    state.currentPage ===
                      Math.ceil(state.total_data / per_page) &&
                    style.paginationElActive
                  }`}
                  style={
                    state.currentPage === Math.ceil(state.total_data / per_page)
                      ? {
                          backgroundColor:
                            reactLocalStorage.getObject('base_color')?.color1,
                        }
                      : {}
                  }
                >
                  {Math.ceil(state.total_data / per_page)}
                </span>
              }
              <span
                onClick={() => {
                  handleClickPagination('next');
                }}
                data-testid="btn-next"
                className={`${style.paginationEl} ${
                  state.currentPage ===
                    Math.ceil(state.total_data / per_page) &&
                  style.paginationElDisabled
                }`}
              >
                <i className="fa fa-chevron-right"></i>
              </span>
            </div>
          )}
      </div>
    </>
  );
};

DatatableServerside.defaultProps = {
  t_head: {},
  t_body: [],
  sortable_fields: {},
  total_data: null,
  pagination: true,
  pagination_max: 5,
  pagination_max_current: 1,
  per_page: 10,
  per_page_list: [10, 15, 25, 50],
  base_endpoint: '',
  order_col: [],
  params: '',
  right: [],
  center: [],
};

export default React.memo(DatatableServerside);

import React, {useState} from 'react'
import { reactLocalStorage } from 'reactjs-localstorage';
import Datatable from '../../../../Datatable'
import { randomNumber } from '../../../../../utils/random';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../constants/apiConstants';
import Loading from '../../../../../utils/Loading';

const FooterCard = (props) => {
  const {title, url}                          = props;
  const [stateDataTable, setStateDataTable]   = useState({ tableData: {}, title: ""});
  const baseColor                             = reactLocalStorage.getObject('base_color');
  const id                                    = `id-${randomNumber()}`;
  const [load, setLoad]                       = useState(false);

  const handleDetail = (komoditas) => {
    setLoad(false);
    axios
        .get(
            `${url}`
        )
        .then(({ data: { data } }) => {
            setLoad(true)
            setStateDataTable({
              tableData: {
                ...data.data.data,
                base_endpoint: `${url}`,
                right:[1,2,3,4,5]
              },
              title: data.data.title
            });
          })
          .catch(() => {
            setStateDataTable((prev) => ({
                ...prev,
                tableData: {},
                title: "",
            }));
          });

  }

  return (
    <div>
      <div className="row">
        {
        props.enableTooltip
        ?
          <div className="col-6 sumber-data" style={{
              fontFamily: 'DM Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '140%',
              color: '#797979'
          }}>
            <div className="dpFilter dpFilterLabelContainer">
              <i className={`icon far fa-question-circle`}></i> {props.labelTooltip}
              <div className={`dpFilterLabelHover`}>
                <div  style={{
                      fontFamily: 'DM Sans',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '12px',
                      color: '#797979'
                  }}
                >
                  {props.tooltip}
                </div>
              </div>
            </div>
          </div>
        :""
        }
          
          <div className="col text-right">
            <button
              type="button"
              className="button-ghost"
              data-toggle="modal"
              data-target={`#id-${id}`}
              onClick={() => {handleDetail(title)} }
            >
              <span className="link-detail" style={{ color: baseColor.color1 }}>
                Lihat Detail <i className="fa fa-long-arrow-alt-right"></i>
              </span>
            </button>
          </div>
        </div>

        {/* Modal */}
        <div
          className="modal fade"
          id={`id-${id}`}
          tabIndex="-1"
          aria-labelledby={`id-${id}Label`}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl isi-modal modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  <div className="col-8 mt-2">
                    <strong>
                      <p>{stateDataTable.title}</p>
                    </strong>
                  </div>
                  <div className="col-4">
                    <div className="text-right">
                      <button
                        type="button"
                        style={{ color: baseColor.color1 }}
                        className="btn button-ghost"
                        aria-label="Close"
                        data-dismiss="modal"
                      >
                        <p className="close">X</p>
                      </button>
                    </div>
                  </div>
                </div>
                {
                  load 
                  ? 
                    <Datatable serverside={false} {...stateDataTable.tableData} />
                  :
                    <Loading />
                }
                
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default FooterCard

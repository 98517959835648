import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import indexstyle from "./index.module.css";
import { reactLocalStorage } from "reactjs-localstorage";
import AppContext from "../../../AppContext";
import asmat1 from "../../../assets/img/asmat1.jpeg"
import asmat2 from "../../../assets/img/asmat2.jpeg"
import asmat3 from "../../../assets/img/asmat3.jpeg"
import asmat4 from "../../../assets/img/asmat4.jpeg"
import asmat5 from "../../../assets/img/asmat5.jpeg"
import asmat6 from "../../../assets/img/asmat6.jpeg"
import asmat7 from "../../../assets/img/asmat7.jpeg"


function Index() {
  const { GlobalContext } = AppContext;
  const global = useContext(GlobalContext);
  const { globalState } = global;
  const baseColor = reactLocalStorage.getObject("base_color");
  return (
    <>
      <div className="card">
        <div id="pemerintah" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#pemerintah" data-slide-to="0" class="active"></li>
            <li data-target="#pemerintah" data-slide-to="1"></li>
            <li data-target="#pemerintah" data-slide-to="2"></li>
            <li data-target="#pemerintah" data-slide-to="3"></li>
            <li data-target="#pemerintah" data-slide-to="4"></li>
            <li data-target="#pemerintah" data-slide-to="5"></li>
            <li data-target="#pemerintah" data-slide-to="6"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-50 h-50 mx-auto" src={asmat1} alt="First slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-50 h-50 mx-auto" src={asmat2} alt="Second slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-50 h-50 mx-auto" src={asmat3} alt="Third slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-50 h-50 mx-auto" src={asmat4} alt="Fourth slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-50 h-50 mx-auto" src={asmat5} alt="Fifth slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-50 h-50 mx-auto" src={asmat6} alt="Sixth slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-50 h-50 mx-auto" src={asmat7} alt="Seventh slide" />
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#pemerintah"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#pemerintah"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </>
  );
}

export default Index;

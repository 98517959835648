export const API_BASE_URL = 'https://api-bigview.bigbox.co.id/';
export const ACCESS_TOKEN_NAME = 'login_access_token';
export const MENUS = 'menu';
export const USERS = 'user';
export const API_POST_LOGIN=`${API_BASE_URL}login`
export const API_GET_CAPTCHA=`${API_BASE_URL}get-captcha`
export const API_GET_USERS=`${API_BASE_URL}user/getUser`
export const API_GET_USERS_INFO=`${API_BASE_URL}user/userInfo`
export const API_GET_TOKEN_NOLOGIN=`${API_BASE_URL}get-token-embed`


/****
 * USER
 */
export const API_GET_USER = `${API_BASE_URL}user/getUser`;
export const API_INSERT_USER = `${API_BASE_URL}user/insertUser`;
export const API_UPDATE_USER = `${API_BASE_URL}user/updateUser`;
export const API_DELETE_USER = `${API_BASE_URL}user/deleteUser/`;
export const API_USER_INFO = `${API_BASE_URL}user/userInfo`;

/****
 * USE CASE
 */
export const API_GET_USECASE = `${API_BASE_URL}usecase/getUseCase`;
export const API_GET_USECASE_DETAIL = `${API_BASE_URL}usecase/detail/`;
export const API_INSERT_USECASE = `${API_BASE_URL}usecase/insertUseCase`;
export const API_UPDATE_USECASE = `${API_BASE_URL}usecase/updateUseCase`;
export const API_DELETE_USECASE = `${API_BASE_URL}usecase/deleteUseCase/`;
export const API_GET_USECASE_WITHROLE = `${API_BASE_URL}usecase/usecaseWithRole`;

/****
 * MENU
 */
export const API_GET_MENU = `${API_BASE_URL}menu?per_page=50`;
export const API_INSERT_MENU = `${API_BASE_URL}menu/add`;
export const API_UPDATE_MENU = `${API_BASE_URL}menu/edit/`;
export const API_DELETE_MENU = `${API_BASE_URL}menu/delete/`;
export const API_DETAIL_MENU = `${API_BASE_URL}menu/detail/`;
export const API_MENU_AND_ICON = `${API_BASE_URL}menu/list-parent`;

/****
 * USER MENU
 */
export const API_GET_USERMENU = `${API_BASE_URL}user_menu`;
export const API_GET_USERMENU_BY_ID_USECASE = `${API_BASE_URL}user_menu/findByUseCase/`;
export const API_INSERT_USERMENU = `${API_BASE_URL}user_menu/add`;
export const API_UPDATE_USERMENU = `${API_BASE_URL}user_menu/{$id}/edit`;
export const API_DELETE_USERMENU = `${API_BASE_URL}user_menu/delete`;
export const API_DETAIL_USERMENU = `${API_BASE_URL}user_menu/{$id}/detail`;

/**
 * VISI - MISI
 */
export const API_DELETE_VISI = `${API_BASE_URL}visi/deleteVisi/`;
export const API_GET_VISI_BY_UC_ID = `${API_BASE_URL}visi/findVisiUseCase/`;
export const API_DELETE_MISI = `${API_BASE_URL}misi/deleteMisi/`;
export const API_GET_MISI_BY_UC_ID = `${API_BASE_URL}misi/findMisiUseCase/`;
export const API_GET_PERIODE_VISI = `${API_BASE_URL}visi/getPeriode/`;
export const API_SUBMIT_PERIODE = `${API_BASE_URL}visi/submitPeriode`;

/**
 * PERIODE
 */
export const API_BASE_PERIODE = `${API_BASE_URL}periode`;
export const API_INSERT_PERIODE = `${API_BASE_URL}periode/insert`;
export const API_GET_PERIODE = `${API_BASE_URL}periode/get`;
export const API_DETAIL_PERIODE = `${API_BASE_URL}periode/detail/`;
export const API_LIST_PERIODE_BY_ID_USECASE = `${API_BASE_URL}periode/list-select`;
export const API_DELETE_PERIODE = `${API_BASE_URL}periode/delete/`;
export const API_UPDATE_PERIODE = `${API_BASE_URL}periode/update/`;

/****
 * ROLE
 */
export const API_GET_ROLE = `${API_BASE_URL}role`;
export const API_GET_ROLE_BY_ID_USECASE = `${API_BASE_URL}role/findByUseCase/`;
export const API_INSERT_ROLE = `${API_BASE_URL}role/add`;
export const API_UPDATE_ROLE = `${API_BASE_URL}role/edit/`;
export const API_DELETE_ROLE = `${API_BASE_URL}role/delete/`;
export const API_DETAIL_ROLE = `${API_BASE_URL}role/detail/`;

/****
 * PROPINSI
 */
export const API_GET_PROPINSI = `${API_BASE_URL}listProvinsi`;
export const API_GET_KOTA_ALL = `${API_BASE_URL}listProvinsiKota`;
export const API_GET_KOTA_PROPINSI = `${API_BASE_URL}listKota?id_provinsi=`;
/**
 *SECTOR
 */
export const API_GET_SECTOR_USECASE = `${API_BASE_URL}sector/sectorUseCase/`;

/**
 * Potensi Daerah - Demografi
 */
export const API_GET_DEMOGRAFI_GENDER = `${API_BASE_URL}potensi_daerah/demografiGender`;
export const API_GET_DEMOGRAFI_AGE = `${API_BASE_URL}potensi_daerah/demografiAge`;
export const API_GET_DEMOGRAFI_EDUCATION = `${API_BASE_URL}potensi_daerah/demografiEdu`;
export const API_GET_DEMOGRAFI_PROFESSION = `${API_BASE_URL}potensi_daerah/demografiJob`;

/**
 * Potensi Daerah - Perdagangan antar wilayah
 */

export const API_GET_PERDAGANGAN = `${API_BASE_URL}poda/ekonomi/perdaganganAntarWilayah`;

/**
 * REGION PROFILE
 */
export const API_GET_REGION_PROFILE = `${API_BASE_URL}region_profile/detail`;

/**
 * Analytics
 */
export const API_GET_ANALYTICS = `${API_BASE_URL}analytics/bigbox`;

/**
 * Admedika
 */
export const API_GET_ADMEDIKA = `${API_BASE_URL}kesehatan/embedAdmedika`;

import React, { useState, useEffect } from 'react';

import esStyle from './EkonomiSda.module.css';

import PieChart from '../../../Charts/PieChart';

import axios from 'axios';
import { API_BASE_URL } from '../../../../constants/apiConstants';
import { NumberWithDot } from '../../../Material/FormatNumber';
import YearPicker from '../../../../utils/YearPicker';

const PieContribSDA = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [statusDate, setStatusDate] = useState(false);
  const [statusChart, setStatusChart] = useState('');
  const [yearWData, setYearWData] = useState([]);

  const [dataChart, setDataChart] = useState({
    data: [],
    options: {
      tooltip: {
        formatter: function () {
          return `<div style="border-color:${this.color}">
                    <table>
                      <tr>
                        <td> <span>${startDate.getFullYear() || new Date().getFullYear()} </span> </td>
                      </tr>
                      <tr>
                        <td> <span>${this.x}: </span> </td>
                        <td> <span><strong>${NumberWithDot(
                          this.y
                        )}%</strong></span></td>
                      </tr>
                    </table>
                  </div>`;
        },
        shared: true,
        useHTML: true,
      },
      legend: {},
      plotOptions: {
        pie: {
          colors: props.color,
        },
      },
    },
  });
  const [word, setWord] = useState('');

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}potensi_daerah/get-sda-kontribusi/year`)
      .then(({ data: { data }, status }) => {
        setStartDate(new Date(`${data.selected_years}`));
        setYearWData(data.years);
      })
      .catch(() => {})
      .finally(() => {
        setStatusDate(true);
      });
  }, []);

  useEffect(() => {
    if (statusDate === false) {
      return;
    }
    axios
      .get(
        `${API_BASE_URL}potensi_daerah/get-sda-kontribusi?year=${startDate.getFullYear() || new Date().getFullYear()}`
      )
      .then(({ data: { data, sumber_data, status } }) => {
        setStatusChart(status);
        setWord(sumber_data);

        setDataChart({
          data: data,
        });
      })
      .catch(() => {});
  }, [startDate, statusDate]);

  return (
    <>
      <p className={esStyle.textEs4}>Kontribusi SDA</p>
      {statusChart !== 'Blank' ? (
        <>
          <div className={`text-right ${esStyle.marginBottom20}`}>
            <div className={esStyle.divEs}>
              <YearPicker
                yearWithData={yearWData}
                onChange={(date) => setStartDate(date)}
                selected={startDate}
              />
            </div>
          </div>
        </>
      ) : (
        ''
      )}

      <PieChart
        key="eco-pie-contrib-sda"
        data={dataChart.data}
        options={dataChart.options}
      />

      {statusChart === 'Exists' ? (
        <>
          <p className={`${esStyle.textEs5} ${esStyle.colorEs3}`}>{word}</p>
        </>
      ) : (
        <p className={`${esStyle.textEs5} ${esStyle.colorEs3} text-center`}>
          {statusChart === 'Empty'
            ? 'Tidak ada data untuk ditampilkan'
            : 'Data tidak tersedia'}
        </p>
      )}
    </>
  );
};

export default PieContribSDA;

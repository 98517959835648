import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../constants/apiConstants';
import TabRegion from './Tabs/Region';
import TabPOI from './Tabs/Poi';
import {
  Link,
  Switch,
  useRouteMatch,
  Route
} from "react-router-dom";
import axios from 'axios';

import Region from './Tabs/Region';
//import Tes from './Tabs/Tes';
import TabsAnalisisDataModeling from './Tabs/DataModeling';


import premiumstyle from './premium.module.css'

function Index() {
  const [useCase, setUseCase] = useState([]);
  const [idUC, setIdUC] = useState(0);

  const TabsLink = ({ label, to, activeOnlyWhenExact }) => {
    let match = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact
    });

    return (
      <Link className={`nav-link ${match ? "active" : ""}`} to={to}>{label}</Link>
    );
  }

  useEffect(() => {
    axios.get(`${API_BASE_URL}usecase/list`)
      .then(({ data: { data } }) => {
        setUseCase(data);
        setIdUC(data[0].id_usecase)
      })
      .catch((err) => { });
  }, [])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p className={`${premiumstyle.text} ${premiumstyle.color}`}>Premium Content</p>
        </div>
      </div>

      <div className={`row ${premiumstyle.marginTop20}`}>
        <div className="col-12">
          <label className={`${premiumstyle.text2} ${premiumstyle.color2} ${premiumstyle.label}`}>Use Case</label>
          <select
            data-testid="select-usecase"
            className={`form-control ${premiumstyle.select}`}
            value={idUC}
            onChange={(e) => setIdUC(e.target.value)}>
            {useCase.map((data, index) => (
              <option key={index} value={data.id_usecase}>{data.name_usecase}</option>
            ))}
          </select>
        </div>
      </div>

      <div className={`row ${premiumstyle.marginTop20}`}>
        <div className="col-12">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <TabsLink
                activeOnlyWhenExact={true}
                to="/content/premium"
                label="Region Profile"
              ></TabsLink>
              <TabsLink
                to="/content/premium/poi"
                label="POI"
              ></TabsLink>
              <TabsLink
                to="/content/premium/datamodeling"
                label="Analisis Data Modeling"
              ></TabsLink>
            </div>
          </nav>
          <Switch>
            <Route exact path="/content/premium">
              <Region idUC={idUC}></Region>
            </Route>
            <Route path="/content/premium/poi">
              <TabPOI idUC={idUC}></TabPOI>
            </Route>
            <Route path="/content/premium/datamodeling">
              <TabsAnalisisDataModeling idUC={idUC}></TabsAnalisisDataModeling>
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}

export default Index;
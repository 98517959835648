import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { API_BASE_URL } from "../../../constants/apiConstants";
import indexstyle from "./index.module.css";
import Loading from "../../../utils/Loading";
import { reactLocalStorage } from "reactjs-localstorage";
import AppContext from "../../../AppContext";
import "../../../assets/css/ContentMenu/ContentMenu.css"
//import ReactDOM from 'react-dom'
import Accordion from "react-bootstrap/Accordion";

function Index() {
   const { GlobalContext } = AppContext;
   const global = useContext(GlobalContext);
   const { globalState } = global;
   const [readVisi, setReadVisi] = useState("Lihat Selengkapnya");
   const [dataVisi, setDataVisi] = useState({
      header: "",
      detail: "",
      periode: "",
      namaKabKota: "",
   });

   const [dataMisi, setDataMisi] = useState([]);

   const [listSM, setListSM] = useState([]);
   const [selectMisi, setSelectMisi] = useState({
      id: "",
      misi: "",
   });

   const [loading, setLoading] = useState(false);
   const [loading2, setLoading2] = useState(false);

   const changeMisi = (data) => {
      if (data.id !== selectMisi) {
         setSelectMisi({
            id: data.id,
            misi: data.misi,
         });
      }
   };
   const baseColor = reactLocalStorage.getObject("base_color");
   useEffect(() => {
      /**
       * Endpoint Get Visi
       */
      setLoading2(true);
      axios
         .get(`${API_BASE_URL}visi/detailVisiByUseCase`)
         .then(({ data: { data } }) => {
            console.log(data);
            setDataVisi({
               header: data.short_desc,
               detail: data.description,
               periode: data.start_year + "-" + data.end_year,
               namaKabKota: data.nama_kab_kota,
               idPeriode: data.id_periode,
            });

            setLoading2(false);
         })
         .catch((err) => {});
   }, []);

   useEffect(() => {
      setLoading(true);

      axios
         .get(`${API_BASE_URL}misi?id_periode=${dataVisi.idPeriode}`)
         .then(({ data: { data } }) => {
            setListSM(data);

            setDataMisi(data);
            //setSelectMisi(data[0].id);
         })
         .catch((err) => {});
      setTimeout(() => {
         setLoading(false);
      }, 1000);
      console.log(dataMisi);
   }, [dataVisi]);

   //  useEffect(() => {
   //     setLoading(true);
   //     axios
   //        .get(`${API_BASE_URL}misi/detailMisiByUseCase?id_misi=${selectMisi.id}`)
   //        .then(({ data: { data } }) => {
   //           console.log(data);
   //           setDataMisi({
   //              header: data.short_desc,
   //              detail: data.description,
   //           });

   //           setLoading(false);
   //        })
   //        .catch((err) => {});
   //  }, [selectMisi]);

   
   return (
      <>
         <div className="row">
            <div className="col-12">
               <p data-testid="title-page" className={`${indexstyle.textVM} ${indexstyle.color}`}>
                  Visi / Misi {dataVisi.namaKabKota} tahun {dataVisi.periode}
               </p>
               <span className="line-2nd" style={{ borderBottomColor: baseColor.color2 }}></span>
            </div>
         </div>

         <div className={indexstyle.boxVM + " mt-4"}>
            <div className="row">
               <div className="col-12">
                  <p className={`${indexstyle.textVM2} ${indexstyle.color}`}>Visi</p>
               </div>
            </div>

            {loading2 === true ? (
               <>
                  <div className={`row`}>
                     <div className="col-12 text-center">
                        <Loading></Loading>
                     </div>
                  </div>
               </>
            ) : (
               <>
                  <div className="row">
                     <div className="col-12">
                        <p data-testid="visi-header" className={`${indexstyle.textVM2} ${indexstyle.color}`}>
                           {dataVisi.header}
                        </p>
                        <div data-testid="detail-visi" className="collapse" id="detailVisi" dangerouslySetInnerHTML={{ __html: dataVisi.detail }} />
                        {dataVisi.detail && dataVisi.detail !== "" && (
                           <>
                              <p
                                 data-testid="visi-see-more"
                                 data-toggle="collapse"
                                 href="#detailVisi"
                                 aria-expanded="false"
                                 style={{ color: baseColor.color1 }}
                                 className={indexstyle.readLink}
                                 onClick={() => {
                                    if (readVisi === "Lihat Selengkapnya") {
                                       setReadVisi("Lihat Sebagian");
                                    } else {
                                       setReadVisi("Lihat Selengkapnya");
                                    }
                                 }}
                              >
                                 {readVisi}
                              </p>
                           </>
                        )}
                     </div>
                  </div>
               </>
            )}
         </div>

         <div className={`${indexstyle.boxVM} ${indexstyle.rowVM}`}>
            <div className="row">
               <div className="col-12">
                  <p className={`${indexstyle.textVM2} ${indexstyle.color}`}>Misi</p>
               </div>
            </div>

            {loading === true ? (
               <>
                  <div className={`row`}>
                     <div className="col-12 text-center">
                        <Loading></Loading>
                     </div>
                  </div>
               </>
            ) : (
               <>
                  <div className={`row`}>
                     <div className="col-12">
                        <p className={`${indexstyle.textVM3} ${indexstyle.color}`}>
                           {globalState.profile.nik === "00038" ? "" : selectMisi.misi}
                           {/* {selectMisi.misi} */}
                        </p>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12">
                        {/* <p data-testid="misi-header" className={`${indexstyle.textVM3} ${indexstyle.color2}`}>{dataMisi.header}</p> */}
                        <div data-testid="misi-header" className={`${indexstyle.textVM3} ${indexstyle.color2}`} dangerouslySetInnerHTML={{ __html: dataMisi.header }} />
                        <div data-testid="detail-misi" dangerouslySetInnerHTML={{ __html: dataMisi.detail }} />
                     </div>
                  </div>

                  <Accordion defaultActiveKey={["0"]} alwaysOpen>
                     {dataMisi.map((data, index) => (
                        <Accordion.Item key={index} eventKey={index.toString()}>
                           <Accordion.Header className="custom-focus">
                              {" "}
                              <p className={`${indexstyle["accordion-header"]} d-flex gap-2`}>
                                 {index + 1}.{" "}
                                 <span  dangerouslySetInnerHTML={{ __html: data.misi }} />
                              </p>
                           </Accordion.Header>
                           <Accordion.Body>
                              <div data-testid="detail-misi" dangerouslySetInnerHTML={{ __html: data.description }} />
                           </Accordion.Body>
                        </Accordion.Item>
                     ))}
                  </Accordion>
               </>
            )}
         </div>
      </>
   );
}

export default Index;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../../constants/apiConstants';
import esStyle from '../../../PotensiDaerah/SDA/index.module.css';
import Widget from './Widget';
import ResApiStatus from '../../../../../utils/ResApiStatus';

const PerubahanHarga = (props) => {
    const {jenis, tanggal, pasar}         = props;
    const [listKota, setListKota]         = useState();
    const [kota, setKota]                 = useState();
    const [widget, setWidget]             = useState();
    const [load, setLoad]                 = useState(false);

    useEffect(() => {
        axios
        .get(
            `${API_BASE_URL}sosial-ekonomi/harga-pangan/rata-rata/filter-kabkot`
        )
        .then(({ data: { data }, status }) => {
            
            const index = data.map(e => e.nama).indexOf('Kota Surabaya');
            setListKota(data);
            setKota(data[0].value);
        })
        .catch(() => {});            
    }, []);

    useEffect(() => {
        if (    (typeof jenis   === "undefined") || (typeof kota  === "undefined") || 
                (typeof tanggal === "undefined") || (typeof pasar === "undefined")) {
            return;
        }

        setLoad(false);
        axios
        .get(
            `${API_BASE_URL}sosial-ekonomi/harga-pangan/rata-rata/widget?jenis=${jenis}&tanggal=${tanggal}&pasar=${pasar}&kabkot=${kota}`
        )
        .then(({ data: { data }, status }) => {
            setLoad(true);
            setWidget(data)
        })
        .catch(() => {});            
    }, [jenis, kota, tanggal, pasar]);

  return (
    <div className='card border-light box-content mt-3'>        
            <div className='card-header bg-transparent border-0'>
                <div className="row">
                    <div className="col"><span className={esStyle.textEs}>Harga Rata-Rata dan Perubahan</span></div>

                    <div className="col form-inline justify-content-end">
                        <div className="form-group mb-2 mr-2 col-4-auto">
                            <label className="col-auto">Kab/Kota</label>
                            <div className='col'>
                                <select className={`form-control form-control-md `}
                                    data-testid="select-kota"
                                    value={kota}
                                    onChange={(event) => {setKota(event.target.value)}}
                                >
                                    {
                                        listKota?.map((v, i)=>{
                                            return (
                                                <option key={i} value={v.value} data-nama={v.nama}>{v.nama}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
            
            <div className='card-body pt-1'>
                <div className='row'>
                    {
                        load
                        ?
                            widget
                            ?
                                Object.keys(widget).length > 0
                                ?
                                    widget?.map((v, i)=>{
                                        return <Widget data={v} key={i} jenis={jenis} pasar={pasar} kota={kota} tanggal={tanggal} />                            
                                    })
                                : 
                                    <div className='col'><ResApiStatus status='nodata' height='300px' message='Tidak Ada data untuk ditampilkan' /></div>
                                    
                            : ''
                        :
                            <div className='col-12'>
                                <div style={{height: "250px", backgroundColor:"#c0c2c6"}}></div>
                            </div>
                    }
                </div>
                
                <div>
                    <div className="row">
                        <div className="col-6 sumber-data" style={{
                                fontFamily: 'DM Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '140%',
                                color: '#797979'
                            }}
                        >
                            <div className="dpFilter dpFilterLabelContainer">
                                <i className={`icon far fa-question-circle`}></i> Informasi Data
                                <div className={`dpFilterLabelHover`}>
                                    <div className={`ml-1 catatan`} style={{
                                            fontFamily: 'DM Sans',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: '12px',
                                            color: '#797979'
                                        }}
                                    >
                                        
                                        <p>Catatan : <br/>
                                            Perubahan harga menunjukan peningkatan atau penurunan harga komoditas <b>tanggal yang dipilih (d)</b> dibandingkan dengan <b>hari sebelumnya (d-1).</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
  )
}

export default PerubahanHarga
import React, { useEffect, useState } from 'react'
import Peta from './Peta'
import Tabulasi from './Tabulasi'
import Chart from './Chart'

import esStyle from '../../../PotensiDaerah/SDA/index.module.css';
import PerubahanHarga from '../PerubahanHarga';
import { API_BASE_URL } from '../../../../../constants/apiConstants';
import axios from 'axios';

const HargaPanganPerJenisKomoditas = (props) => {  
    const {jenis}                                   = props;
    const [listKomoditas, setListKomoditas]         = useState();
    const [komoditas, setKomoditas]                 = useState();
    
    useEffect(() => {
        if ( typeof jenis   === "undefined") {
            return;
        }
        axios
        .get(
            `${API_BASE_URL}sosial-ekonomi/harga-pangan/filter-komoditas?jenis=${jenis}`
        )
        .then(({ data: { data }, status }) => {
            setListKomoditas(data);
            setKomoditas(data[0].value);
        })
        .catch(() => {});
        
    }, [jenis]);

  return (
    <div className='card border-light box-content mt-3'>
            <div className='card-header bg-transparent border-0'>
                <div className="row">
                    <div className="col"><span className={esStyle.textEs}>Harga Pangan Per Jenis Komoditas</span></div>
                    <div className="col form-inline justify-content-end">
                        <div className="form-group mb-2 mr-2 col-4-auto">
                            <label className="col-auto">Komoditas</label>
                            <div className='col'>
                                <select className={`form-control form-control-md `}
                                    data-testid="select-komoditas"
                                    value={komoditas}
                                    onChange={(event) => {setKomoditas(event.target.value)}}
                                >
                                    {
                                        listKomoditas?.map((v, i)=>{
                                            return (
                                                <option key={i} value={v.value} data-nama={v.nama}>{v.nama}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           
           
            <div className='card-body pt-1'>
                <div className='row'>                    
                    <div className="col-sm-12"><p className={`pt-3 ${esStyle.textEs4}`}>Harga Rata-rata per Kabupaten Kota</p></div>
                </div>
                <div className='row'>
                    <div className="col-md-7"><Peta {...props} komoditas={komoditas} /></div>
                    <div className="col-md-5"><Tabulasi  {...props} komoditas={komoditas} /></div>
                </div>
                <div className='row mt-4'>
                    <div className="col-md-12"><Chart {...props} komoditas={komoditas} /></div>
                </div>
                <div className='row mt-4'>
                    <div className="col-md-12 pl-0"><PerubahanHarga {...props} komoditas={komoditas} /></div>
                </div>
            </div>       
        </div>
  )
}

export default HargaPanganPerJenisKomoditas
import React, { useState, useEffect } from "react";
import { BackgroundShadow, Satuan } from '../../../PotensiDaerah/SDA/Utils';
import ColumnChart from '../../../../Charts/ColumnChart';
import { NumberWithDot, NumberFormat } from '../../../../Material/FormatNumber';
import moment from "moment";


import esStyle from '../../../PotensiDaerah/SDA/index.module.css';
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants/apiConstants";

const Chart = (props) => {
  const {jenis, tanggal, komoditas, pasar} = props;
  const [load, setLoad]             = useState(false)  
  const [dataChart, setDataChart]   = useState({data: [], categories: [], options: {}});

  useEffect(() => {
    if (  (typeof jenis   === "undefined") || (typeof komoditas === "undefined") || 
          (typeof tanggal === "undefined") || (typeof pasar     === "undefined")) {
      return;
    }
    
    setLoad(false)
    axios
    .get(
        `${API_BASE_URL}sosial-ekonomi/harga-pangan/harga/chart-column?jenis=${jenis}&komoditas=${komoditas}&tanggal=${tanggal}&pasar=${pasar}`
    )
    .then(({ data: { data } }) => {            
        setLoad(true)
        
        setDataChart({
          data: data.widget_data.chart_data,
          categories: data.widget_data.chart_categories,
          options: {
            // colors:[props.color],
             tooltip: {
              formatter: function () {
                return `<div style="border-color:${this.color}">
                    <table>
                      <tr>
                        <td>${moment(tanggal).format("DD MMM YYYY")} </td>
                      </tr>
                      <tr>
                        <td> <strong>${this.x} </strong> </td>
                      </tr>
                      <tr>
                        <td>Harga ${jenis}: <strong>Rp ${NumberWithDot(this.y, 2)} </strong></td>
                      </tr>
                    </table>
                  </div>`;
              },
               shared: true,
               useHTML: true,
             },
             xAxis: {
              //  min: 0,
              //  max: 4,
              //  scrollbar: {
              //    enabled: true,
              //  },
               title: {
                 text: null,
               },
             },
             yAxis: {
               title: {
                 text: `Harga ${jenis} per Kg (Rp)`,
               },
               labels: {
                 formatter: function () {
                   return NumberFormat(this.value, 1);
                 },
               },
             },
           }
        })

      })
      .catch(() => {});
}, [jenis, komoditas, tanggal, pasar]);



  return (
    <div className={esStyle.leafletContainer}>
      {
        load 
        ?
          <ColumnChart
            key="bar-top-luas-panen"
            data={dataChart.data}
            categories={dataChart.categories}
            options={dataChart.options}
            tooltip={dataChart.options.tooltip}
            />
        : <div className="position-relative" style={{height:'400px'}}><BackgroundShadow content="" background="#c0c2c6" /></div>
      }
    </div>
  )
}

export default Chart
import React from 'react';

import { reactLocalStorage } from 'reactjs-localstorage';
const baseColor = reactLocalStorage.getObject("base_color");

 const BackgroundShadow = (props) => {

    return (
      <div className="position-absolute d-flex justify-content-center align-items-center"  
        style={{
          bottom:0, 
          left:0, 
          width:'100%', 
          height: '100%', 
          // background: 'rgba(0,0,0,0.4)'
          background: props.background ? props.background : 'rgba(192, 194, 198, 0.5)'
        }}>                
        <span 
            style={{
                fontSize: '1em', 
                // paddingTop: '10%', 
                color: '#ffffff',
                fontWeight: 'bold',
                // background: baseColor.color1,
                // width: '40%',
                // height: '10%',
                // padding: '6px',
                // borderRadius: '10px',
            }}>
        {props.content}
        </span>
      </div>
    )
}

export default BackgroundShadow;